import React from 'react'
import _ from 'lodash'
import { Label, Table } from 'semantic-ui-react'
import { CompanyVerifiedLabel, DimmerLoader, MyCompanyLabel } from '..'
import { NoData } from '../NotFound'
import { countries, sectors } from '../../util/Constant'
import { CompanyLink, DetailsButton } from '../link'

const CompanyTable = ({ companies, loading, pagination }) => {
  if (!loading && companies.length < 1) {
    return <NoData text="No result found" />
  }

  return (
    <Table sortable padded="very" basic="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="COMPANY" />
          <Table.HeaderCell content="SECTOR" />
          <Table.HeaderCell content="LOCATION" />
          <Table.HeaderCell content="DESCRIPTION" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell>
              <DimmerLoader />
            </Table.Cell>
          </Table.Row>
        ) : (
          <>
            {companies.map(company => {
              const hasSectors = company.misc_info && company.misc_info.sectors

              return (
                <Table.Row key={company.uuid}>
                  <Table.Cell>
                    <CompanyVerifiedLabel company={company} iconOnly />
                    <MyCompanyLabel company={company} iconOnly />
                    <CompanyLink company={company} />
                  </Table.Cell>
                  <Table.Cell>
                    {hasSectors.length > 0
                      ? company.misc_info.sectors.map((sector, index) => (
                          <Label
                            key={index}
                            basic
                            size="small"
                            content={sectors[sector]}
                            className="uppercase my-2"
                          />
                        ))
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {company.city}, {countries[company.country]}
                  </Table.Cell>
                  <Table.Cell>
                    <p style={{ maxWidth: 400 }}>
                      {_.get(company, 'misc_info.about', '-')}
                    </p>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <DetailsButton company={company} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </>
        )}
      </Table.Body>
      {pagination && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={6} content={pagination} />
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

export default CompanyTable
