import React from 'react'
import { Modal, Header, Button } from 'semantic-ui-react'
import OfferInfoTable from './OfferInfoTable'

const RejectOfferModal = ({ offer, isOwner, open, onComplete, onClose }) => {
  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
      <Modal.Header content={isOwner ? 'CANCEL OFFER' : 'REJECT OFFER'} />
      <Modal.Content>
        <OfferInfoTable offers={[offer]} />
        <Header
          as="h4"
          content={
            isOwner
              ? 'Do you want to cancel the offer?'
              : 'Do you want to reject the offer?'
          }
        />
        <Button
          fluid
          color="red"
          size="small"
          content={isOwner ? 'Cancel offer' : 'Reject offer'}
          onClick={onComplete}
        />
      </Modal.Content>
    </Modal>
  )
}

export default RejectOfferModal
