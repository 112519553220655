import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, Segment, Header, Statistic } from 'semantic-ui-react'
import { NumberFormat, MoneyFormat } from '../'

export const StatisticsWidget = ({
  data,
  dataSchema,
  title,
  noTitle,
  divided,
  horizontal,
  width,
  color,
  inverted,
  className,
  style,
}) => {
  if (data) {
    return (
      <Segment
        basic={divided}
        padded={divided ? undefined : 'very'}
        color={!divided ? color : undefined}
        inverted={!divided && inverted}
        className={classNames(className, { 'p-0': 'p-0' })}
      >
        {!noTitle && <>{!divided && <Header as="h2" content={title} />}</>}

        <Grid className="p-0">
          <Grid.Row columns={width || 'equal'}>
            {dataSchema.map(
              schema =>
                data[schema.key] !== undefined && (
                  <Grid.Column textAlign="center" key={schema.key}>
                    <Segment
                      basic={!divided}
                      color={divided ? color : undefined}
                      inverted={divided && inverted}
                      className={className}
                      style={style}
                    >
                      <Statistic
                        horizontal={horizontal}
                        inverted={inverted}
                        color={!inverted ? color : undefined}
                        size={
                          (Object.keys(data).length > 4 && 'tiny') ||
                          (Object.keys(data).length > 3 && 'small') ||
                          'small'
                        }
                      >
                        <Statistic.Value
                          content={
                            schema.isPrice ? (
                              <MoneyFormat value={data[schema.key]} />
                            ) : (
                              <p>
                                <NumberFormat
                                  float={schema.isFloat}
                                  amount={data[schema.key]}
                                />
                              </p>
                            )
                          }
                        />
                        <Statistic.Label content={schema.title} />
                      </Statistic>
                    </Segment>
                  </Grid.Column>
                )
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }

  return null
}

StatisticsWidget.propTypes = {
  title: PropTypes.string,
  divided: PropTypes.bool,
  width: PropTypes.number,
}
