import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import { AmountLabel, AskPriceLabel, DimmerLoader } from '../../components'
import { UserLink } from '../../components/link'
import { SuccessModal } from '../../components/confirm'
import WalletDecryptor from '../../components/Wallet/WalletEncrypter'
import { signMessage } from '../../util/blockchain'
import { useModal } from '../../util/hooks'
import {
  client,
  GET_TRADE_DETAILS,
  GET_TRADE_SIGNATURE_DATA,
  SIGN_TRADE,
} from '../../util/API/Apollo'

const Signature = ({ trade, open, onClose, onSigned }) => {
  const [signing, setSigning] = useState(false)

  const signatureData = useQuery(GET_TRADE_SIGNATURE_DATA, {
    variables: { trade_id: trade.uuid },
    client,
  })

  const [signTrade, signTradeMutation] = useMutation(SIGN_TRADE, {
    refetchQueries: [
      { query: GET_TRADE_DETAILS, variables: { uuid: trade.uuid } },
    ],
    awaitRefetchQueries: true,
    client,
  })

  const handleSubmit = async wallet => {
    setSigning(true)

    const signedMessage = await signMessage(
      wallet,
      signatureData.data.getTradeSignatureData
    )

    await signTrade({
      variables: {
        trade_id: trade.uuid,
        signed_message: signedMessage,
      },
    })

    setSigning(false)
    onSigned()
  }

  return (
    <Modal open={open} onClose={onClose} dimmer="blurring" closeIcon>
      <Modal.Header content="SIGN FOR TRANSFER" />
      <Modal.Content>
        <DimmerLoader
          active={signatureData.loading || signTradeMutation.loading || signing}
        />
        <Table basic="very" padded fixed size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="TRANSFER" />
              <Table.HeaderCell content="TRADE AMOUNT" />
              <Table.HeaderCell content="TRADE PRICE" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <UserLink user={trade.seller_info} />
                <Icon name="arrow right" color="red" />
                <UserLink user={trade.buyer_info} />
              </Table.Cell>
              <Table.Cell>
                <AmountLabel amount={trade.accepted_offer.token_amount} />
              </Table.Cell>
              <Table.Cell>
                <AskPriceLabel price={trade.accepted_offer.token_price} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <WalletDecryptor onDecrypted={handleSubmit} />
      </Modal.Content>
    </Modal>
  )
}

const SignTradeButton = ({ show, trade }) => {
  const signModal = useModal()
  const successModal = useModal()

  return (
    <>
      <SuccessModal
        {...successModal.props}
        onClose={() => {
          successModal.hide()
          signModal.hide()
        }}
        content="Your digital signature is being processed."
      />
      {signModal.isOpen && (
        <Signature
          {...signModal.props}
          trade={trade}
          onSigned={successModal.show}
        />
      )}
      {show && (
        <Button
          fluid
          content="Sign for transfer"
          primary
          onClick={signModal.show}
        />
      )}
    </>
  )
}

export default SignTradeButton
