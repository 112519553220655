import React, { useState } from 'react'
import {
  Button,
  Header,
  Icon,
  Divider,
  Segment,
  Transition,
} from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import { client, RESEND_ACTIVATION } from '../../util/API/Apollo'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'
import { useMounted, useRouterState } from '../../util/hooks'
import { DimmerLoader } from '../index'

const RegisterCompleted = () => {
  const [isEmailSent, setEmailIsSent] = useState(false)
  const resendEmail = useRouterState('email')
  const mounted = useMounted()

  const [resend, { loading }] = useMutation(RESEND_ACTIVATION, {
    variables: { email: resendEmail },
    onCompleted: () => {
      setEmailIsSent(true)
    },
    client,
  })

  return (
    <Transition visible={mounted} animation="fade" duration={500}>
      <div>
        <Segment padded="very" className="text-center">
          <DimmerLoader active={loading} />
          <div className="stc-logo">
            <STCLogo />
          </div>
          <Header
            as="h5"
            color="purple"
            className="uppercase"
            content="create an account"
          />
          {isEmailSent ? (
            <p>
              <br />
              <br />
              The activation link has been sent again to your email address.
            </p>
          ) : (
            <>
              <p>
                <Icon size="big" color="green" name="check" />
                <br />
                <br />
                Please check your inbox.
                <br />
                An activation link has been sent to your email address.
                <br />
                <br />
                <br />
                <small>
                  If you cannot find your activation email, please check your
                  spam/junk folders
                </small>
              </p>
              <Divider horizontal content="or" />
              <Button
                basic
                className="link px-0"
                content="Request a new one"
                onClick={resend}
              />
            </>
          )}
        </Segment>
      </div>
    </Transition>
  )
}

export default RegisterCompleted
