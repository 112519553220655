import React from 'react'
import { Icon } from 'semantic-ui-react'
import {
  ApprovalStatus,
  ApprovalType,
  Info,
  TxStatus,
} from '../../util/Constant'
import { PopupIcon } from '../index'

const ActionApprovalStatus = ({ approval, icon }) => {
  let props = {}
  if (!approval) {
    props = {
      name: 'time',
      color: 'blue',
      content: 'Pending Account Verification',
    }
  } else if (approval.tx_status === TxStatus.PENDING) {
    props = {
      name: 'spinner',
      color: 'blue',
      content: Info.WAITING_BLOCKCHAIN,
      loading: true,
    }
  } else {
    switch (approval.status) {
      case ApprovalStatus.INITIALIZED: {
        props = { name: 'time', color: 'blue' }
        switch (approval.approval_type) {
          case ApprovalType.VERIFICATION_KYC:
          case ApprovalType.VERIFICATION_ACC:
            props.content = 'Waiting for document'
            break
          case ApprovalType.VERIFICATION_MSG:
          case ApprovalType.TRANSFERABILITY:
          case ApprovalType.VERIFICATION_FINANCIAL_ADVISOR:
          case ApprovalType.VERIFICATION_BROKER_DEALER:
          case ApprovalType.VERIFICATION_COMPANY:
            props.content = 'Pending verification'
            break
          default:
            console.error('Invalid approval status:', approval.approval_type)
        }
        break
      }
      case ApprovalStatus.REJECTED:
        props = { name: 'times', color: 'red', content: 'Rejected' }
        break
      case ApprovalStatus.WAITING: {
        props = { name: 'time', color: 'blue', content: 'Waiting for approval' }
        switch (approval.approval_type) {
          case ApprovalType.VERIFICATION_KYC:
          case ApprovalType.VERIFICATION_ACC:
          case ApprovalType.TRANSFERABILITY:
          case ApprovalType.VERIFICATION_MSG:
          case ApprovalType.VERIFICATION_BROKER_DEALER:
          case ApprovalType.VERIFICATION_FINANCIAL_ADVISOR:
          case ApprovalType.VERIFICATION_COMPANY:
            props.content = 'Waiting for approval'
            break
          default:
            console.error('Invalid approval type:', approval.approval_type)
        }
        break
      }
      case ApprovalStatus.CANCELED:
        props = { name: 'times', color: 'red', content: 'Canceled' }
        break
      case ApprovalStatus.APPROVED: {
        props = {
          name: 'check',
          color: 'green',
        }
        switch (approval.approval_type) {
          case ApprovalType.VERIFICATION_KYC:
          case ApprovalType.VERIFICATION_BROKER_DEALER:
          case ApprovalType.VERIFICATION_FINANCIAL_ADVISOR:
            props.content = 'Account is verified'
            break
          case ApprovalType.VERIFICATION_ACC:
            props.content = 'Accredited investor is verified'
            break
          case ApprovalType.VERIFICATION_MSG:
          case ApprovalType.VERIFICATION_COMPANY:
            props.content = 'Approved'
            break
          case ApprovalType.TRANSFERABILITY:
            props.content = 'Transferable'
            break
          default:
            console.error('Invalid approval type:', approval.approval_type)
        }
        break
      }
      default:
        console.error('Invalid approval status:', approval.status)
    }
  }
  if (icon) {
    return <PopupIcon {...props} />
  } else {
    const { content, ...iconProps } = props
    return (
      <>
        <Icon {...iconProps} />
        {content}
      </>
    )
  }
}

export default ActionApprovalStatus
