import React from 'react'
import { useApproval, useModal } from '../../util/hooks'
import { Button, Grid, Header } from 'semantic-ui-react'
import {
  ApprovalStatus,
  ApprovalType,
  TxStatus,
  UserType,
} from '../../util/Constant'
import { SubMessage } from '../index'
import ProviderVerificationForm from './ProviderVerificationForm'

const ProviderVerificationInfoDetails = ({ approval, approvalType }) => {
  const isRejected = approval && approval.status === ApprovalStatus.REJECTED
  const isPending =
    approval &&
    (approval.status === ApprovalStatus.WAITING ||
      approval.tx_status === TxStatus.PENDING)
  const modal = useModal()

  if (isPending) {
    return (
      <>
        <SubMessage
          info
          content="Waiting for approval"
          icon="spinner"
          loading
          fluid
        />
      </>
    )
  }

  return (
    <>
      <ProviderVerificationForm
        approvalType={approvalType}
        open={modal.isOpen}
        onClose={modal.hide}
        onSubmit={modal.hide}
      />
      {isRejected && <p>{approval.reject_reason}</p>}
      <Button
        primary
        fluid
        size="small"
        content={approval ? 'RETRY' : 'Add certificate document'}
        onClick={modal.show}
        data-test-id="start-provider-document"
      />
    </>
  )
}

const ProviderVerificationInfo = ({ user, isProfile, isDashboard }) => {
  const approvalType = React.useMemo(() => {
    if (user.user_type === UserType.FINANCIAL_ADVISOR)
      return ApprovalType.VERIFICATION_FINANCIAL_ADVISOR
    if (user.user_type === UserType.BROKER_DEALER)
      return ApprovalType.VERIFICATION_BROKER_DEALER
    throw new Error(`invalid user type: ${user.user_type}`)
  }, [user])

  const approval = useApproval(user.approvals.data, approvalType)
  if (approval && approval.status === ApprovalStatus.APPROVED) return null

  if (isProfile) {
    return (
      <>
        <Header as="h3" content="CERTIFICATE VERIFICATION" />
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <ProviderVerificationInfoDetails
                approval={approval}
                approvalType={approvalType}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
  if (isDashboard) {
    return (
      <>
        <Header as="h4" content="CERTIFICATE VERIFICATION" />
        <ProviderVerificationInfoDetails
          approval={approval}
          approvalType={approvalType}
        />
      </>
    )
  }
}

export default ProviderVerificationInfo
