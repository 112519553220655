import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Popup, Table } from 'semantic-ui-react'
import {
  AgeInfo,
  AskAmountLabel,
  AskPriceLabel,
  PPDSLabel,
  TransferabilityIcon,
} from '../index'
import { SuccessModal } from '../confirm'
import { useModal } from '../../util/hooks'
import RemoveListingButton from './RemoveListingButton'
import link, { CompanyLink, ContractLink } from '../link'

const ListingTableLegend = () => (
  <>
    <p>
      <Icon name="check" color="green" />: Transferable securities
    </p>
    <p>
      <Icon name="exclamation triangle" color="yellow" />: Transferable
      securities with restriction
    </p>
    <p>
      <Icon name="time" color="blue" />: Transferability Pending
    </p>
    <p>
      <Icon name="tags" color="yellow" />: There are incoming offers to the
      listing
    </p>
  </>
)

const ListingsTable = ({
  listings,
  remove,
  pagination,
  refetchQueries,
  hasLegend = false,
}) => {
  const removeSuccessModal = useModal()

  return (
    <>
      <SuccessModal
        content="Your listing has been removed."
        {...removeSuccessModal.props}
      />
      <Table basic="very" padded="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="LISTING ID" />
            <Table.HeaderCell content="UNDERLYING ASSET" />
            <Table.HeaderCell content="ISSUER" />
            <Table.HeaderCell content="ASK AMOUNT" />
            <Table.HeaderCell content="ASK PRICE" />
            <Table.HeaderCell content="AGE" />
            {remove && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listings.map(listing => {
            const {
              hashid,
              amount,
              price,
              token,
              has_offers,
              created_at,
            } = listing

            return (
              <Table.Row key={hashid}>
                <Table.Cell>
                  {has_offers && (
                    <Popup
                      inverted
                      content="There are incoming offers to this listing"
                      trigger={
                        <Icon name="tags" color="yellow" className="mr-2" />
                      }
                    />
                  )}
                  <TransferabilityIcon token={token} />
                  <Link to={link.listing(listing)}>{hashid}</Link>
                </Table.Cell>
                <Table.Cell>
                  <ContractLink contract={token.contract} />
                </Table.Cell>
                <Table.Cell>
                  <CompanyLink company={token.contract.issuer} />
                </Table.Cell>
                <Table.Cell>
                  <AskAmountLabel amount={amount} />
                </Table.Cell>
                <Table.Cell>
                  <AskPriceLabel price={price} />
                  <br />
                  <br />
                  <PPDSLabel data={{ amount, price }} />
                </Table.Cell>
                <Table.Cell>
                  <AgeInfo iso str={created_at} />
                </Table.Cell>
                {remove && (
                  <Table.Cell>
                    <RemoveListingButton
                      listing={listing}
                      onRemove={removeSuccessModal.show}
                      refetchQueries={refetchQueries}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            {hasLegend && (
              <Table.HeaderCell colSpan={4}>
                {<ListingTableLegend />}
              </Table.HeaderCell>
            )}
            {pagination && (
              <Table.HeaderCell colSpan={7} content={pagination} />
            )}
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  )
}

export default ListingsTable
