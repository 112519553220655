import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Header, Segment } from 'semantic-ui-react'
import CoolTable from '../CoolTable'
import { ProviderActionType } from '../../util/Constant'
import { getTableData } from '../../routes/ProviderActions'
import {
  client,
  GET_PROVIDER_ACTION_COUNTS,
  GET_PROVIDER_ACTIONS,
} from '../../util/API/Apollo'
import { DimmerLoader } from '../index'
import TabMenu from '../TabMenu'

const PendingActionsTable = ({ action_type }) => {
  const { loading, data } = useQuery(GET_PROVIDER_ACTIONS, {
    variables: {
      action_type,
      page: 1,
      count: 5,
      orderBy: [{ field: 'created_at', order: 'DESC' }],
    },
    client,
  })
  const actions = _.get(data, 'providerActions.data', [])
  const tableData = getTableData({ action_type, actions })

  if (loading) return <DimmerLoader active />
  return <CoolTable {...tableData} />
}

const getTabs = data => {
  const schema = [
    {
      key: 'account',
      actionType: ProviderActionType.CUSTOMER_APPROVAL,
      field: 'customerApproval',
    },
    {
      key: 'medallion',
      actionType: ProviderActionType.CUSTOMER_MSG,
      field: 'customerMsg',
    },
    {
      key: 'assets',
      actionType: ProviderActionType.ASSET_TRANSFERABILITY,
      field: 'assetTransferability',
    },
    {
      key: 'provider-approval',
      actionType: ProviderActionType.PROVIDER_APPROVAL,
      field: 'providerApproval',
    },
    {
      key: 'provider-request',
      actionType: ProviderActionType.PROVIDER_REQUEST,
      field: 'providerRequest',
    },
    {
      key: 'company',
      actionType: ProviderActionType.COMPANY_VERIFICATION,
      field: 'companyVerification',
    },
    {
      key: 'trade',
      actionType: ProviderActionType.TRADE_APPROVAL,
      field: 'tradeApproval',
    },
  ]
  return schema.filter(
    item => _.get(data, `${item.field}.paginatorInfo.total`, 0) > 0
  )
}

const PendingActionsProvider = () => {
  const { loading, data } = useQuery(GET_PROVIDER_ACTION_COUNTS, {
    client,
  })

  const tabs = React.useMemo(() => getTabs(data), [data])

  if (loading || tabs.length === 0) return null

  return (
    <Segment padded="very">
      <Header as="h2" content="PENDING ACTIONS" />
      <TabMenu defaultTab={tabs[0].key}>
        <TabMenu.Left>
          <TabMenu.Menu>
            {tabs.map(tab => (
              <TabMenu.Item key={tab.key} name={tab.key} />
            ))}
          </TabMenu.Menu>
        </TabMenu.Left>
        <TabMenu.Right>
          {tabs.map(tab => (
            <TabMenu.Pane key={tab.key} name={tab.key}>
              <PendingActionsTable action_type={tab.actionType} />
            </TabMenu.Pane>
          ))}
        </TabMenu.Right>
      </TabMenu>
    </Segment>
  )
}

export default PendingActionsProvider
