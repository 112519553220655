import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { client, GET_ME } from '../API/Apollo'
import { PageLoader } from '../../components'
import { UserType } from '../Constant'

const MeContext = React.createContext(null)

const isOwnerFn = me => ({ contract, token, listing, offer }) => {
  if (!me) return false
  let result = false
  if (contract && contract.owner.uuid === me.uuid) result = true
  if (token && token.owner.uuid === me.uuid) result = true
  if (listing && listing.seller.uuid === me.uuid) result = true
  if (offer && offer.owner.uuid === me.uuid) result = true
  return result
}

const getMeUtils = me => {
  if (!me) return null
  const isIndividual = me.user_type === UserType.INDIVIDUAL
  const isInstitutional = me.user_type === UserType.INSTITUTIONAL
  const isEmployee = me.user_type === UserType.INSTITUTIONAL
  const isBrokerDealer = me.user_type === UserType.BROKER_DEALER
  const isFinancialAdvisor = me.user_type === UserType.FINANCIAL_ADVISOR
  const isTransferAgent = me.user_type === UserType.TRANSFER_AGENT

  const isCustomer = isIndividual || isInstitutional || isEmployee
  const isProvider = isBrokerDealer || isFinancialAdvisor

  return {
    isIndividual,
    isInstitutional,
    isEmployee,
    isBrokerDealer,
    isFinancialAdvisor,
    isTransferAgent,
    isCustomer,
    isProvider,
  }
}

const setSentryUser = data => {
  Sentry.configureScope(scope =>
    scope.setUser({
      id: _.get(data, 'me.uuid'),
      email: _.get(data, 'me.email'),
    })
  )
}

export const MeProvider = ({ children }) => {
  const { loading, data } = useQuery(GET_ME, {
    onCompleted: setSentryUser,
    client,
  })
  const me = _.get(data, 'me')
  const meUtils = React.useMemo(() => getMeUtils(me), [me])
  const isOwner = React.useMemo(() => isOwnerFn(me), [me])

  if (loading) return <PageLoader />

  const value = me ? { ...me, ...meUtils, isOwner } : null

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>
}

export const useMe = () => {
  return React.useContext(MeContext)
}
