import React from 'react'
import { Dropdown, Input, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useDebounceQuery } from '../../../util/hooks'
import { client, SEARCH_ALL } from '../../../util/API/Apollo'
import util from '../../../util'
import useSearchResults from '../../Search/useSearchResults'
import link from '../../link'

const ResultSet = ({ results, field, input }) => {
  if (!results || !results[field]) return null

  return (
    <>
      <Dropdown.Header>{results[field].name}</Dropdown.Header>
      <div style={{ position: 'relative ' }}>
        {results[field].total > 2 && (
          <Link
            to={link.search(field, input)}
            style={{ position: 'absolute', right: 0, bottom: 0, margin: 10 }}
          >
            <small>
              See all {results[field].total} {field}
            </small>
          </Link>
        )}
      </div>
      <Dropdown.Divider />
      {results[field].results.map(r => {
        return (
          <Dropdown.Item key={r.item.hashid} as={Link} to={r.to}>
            <div className="mb-3">{r.content}</div>
            <small className="text-grey">{r.item.hashid}</small>
          </Dropdown.Item>
        )
      })}
    </>
  )
}

const NavSearch = () => {
  const [input, setInput] = React.useState('')
  const showSearch = input.length >= 2
  const { loading, data } = useDebounceQuery(SEARCH_ALL, {
    variables: { search: input, count: 2 },
    skip: !showSearch,
    client,
  })

  const results = useSearchResults({ data })
  const noData = util.isEmptyObject(results)

  const showInfo = !showSearch && noData && !loading
  const showData = showSearch && !noData
  const showNoResult = showSearch && noData && !loading
  const showLoading = showSearch && noData && loading

  // debug
  // React.useEffect(() => {
  //   window.dev.searchNav()
  // }, [])

  return (
    <Dropdown
      style={{ width: 300 }}
      icon={false}
      trigger={
        <Input
          value={input}
          onChange={(e, { value }) => setInput(value)}
          icon="search"
          iconPosition="left"
          placeholder="Search"
          data-test-id="nav-search"
          fluid
          loading={loading}
        />
      }
    >
      <Dropdown.Menu
        className="mt-5"
        style={{ width: showData ? 400 : 'auto' }}
      >
        {showData && (
          <>
            <ResultSet results={results} input={input} field="contracts" />
            <ResultSet results={results} input={input} field="tokens" />
            <ResultSet results={results} input={input} field="companies" />
            <ResultSet results={results} input={input} field="listings" />
            <ResultSet results={results} input={input} field="offers" />
            <ResultSet results={results} input={input} field="trades" />
          </>
        )}
        {showInfo && (
          <Message content="Search asset, token, user, company, offer, trade with ID or text" />
        )}
        {showNoResult && <Message content="Nothing found" />}
        {showLoading && <Message content="Searching" />}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NavSearch
