import React from 'react'
import { Divider, Header, Table } from 'semantic-ui-react'
import { NumberFormat } from '../index'

const ContractStrategyDetails = ({ contract }) => (
  <>
    <Header as="h4" content="Investment Strategy" />
    <div>{contract.details.investment_strategy}</div>
    <Divider />
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="COMPANY" />
          <Table.HeaderCell content="TYPE" />
          <Table.HeaderCell content="AMOUNT" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {contract.details.investment_info.map((info, index) => (
          <Table.Row key={index}>
            <Table.Cell content={info.company} />
            <Table.Cell content={info.type} />
            <Table.Cell content={<NumberFormat usd amount={info.amount} />} />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </>
)

export default ContractStrategyDetails
