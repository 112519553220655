import React, { Fragment } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import {
  Divider,
  Dropdown,
  Icon,
  Item,
  Label,
  Segment,
} from 'semantic-ui-react'
import { NoData } from '../../NotFound'
import {
  client,
  DELETE_NOTIFICATION,
  GET_LAST_NOTIFICATIONS,
  READ_NOTIFICATION,
} from '../../../util/API/Apollo'
import { AgeInfo, DimmerLoader } from '../../index'

const iconColors = {
  check: 'green',
  spinner: 'blue',
  'info circle': 'blue',
  times: 'red',
  tags: 'yellow',
}
const NotificationItem = ({ notif }) => {
  const { header = '', content = '', icon = 'info', link } = notif.data

  const [read] = useMutation(READ_NOTIFICATION, {
    variables: { notification_id: notif.uuid },
    refetchQueries: [{ query: GET_LAST_NOTIFICATIONS }],
    client,
  })

  const [remove, { loading: removeLoading }] = useMutation(
    DELETE_NOTIFICATION,
    {
      variables: { notification_id: notif.uuid },
      refetchQueries: [{ query: GET_LAST_NOTIFICATIONS }],
      awaitRefetchQueries: true,
      client,
    }
  )

  const linkProps = link ? { as: Link, to: link } : {} // optional url

  return (
    <Segment basic className="clickable m-0">
      <Item.Group>
        <Item style={{ position: 'relative' }} onClick={read} {...linkProps}>
          <DimmerLoader active={removeLoading} />
          <Item.Image size="mini">
            <Label
              circular
              color={iconColors[icon]}
              size="huge"
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                marginRight: '8px',
              }}
            >
              <Icon name={icon} style={{ margin: 0 }} />
            </Label>
          </Item.Image>

          <Item.Content>
            <Item.Header>
              {header}
              {!notif.read_at && (
                <Icon name="bell" color="red" className="ml-5" size="small" />
              )}
            </Item.Header>

            <Dropdown
              icon="ellipsis horizontal"
              style={{ float: 'right' }}
              simple
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Remove"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    remove()
                  }}
                />
                {!notif.read_at && (
                  <Dropdown.Item
                    text="Mark as read"
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      read()
                    }}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>

            <Item.Description>
              <p dangerouslySetInnerHTML={{ __html: content }} />
            </Item.Description>
            <Item.Extra>
              <AgeInfo iso simple str={notif.created_at} />
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

const SystemMessages = ({ notifications }) => {
  if (notifications.length === 0) {
    return (
      <>
        <NoData text="You do not have any notifications" size="small" />
      </>
    )
  }
  return (
    <>
      {notifications.map((notif, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <Divider className="m-0" />}
            <NotificationItem notif={notif} />
          </Fragment>
        )
      })}
    </>
  )
}

export default SystemMessages
