import React from 'react'
import { Item, Label } from 'semantic-ui-react'
import util from '../../util'
import { countries, sectors } from '../../util/Constant'
import companyPlaceholder from '../../images/company-placeholder.png'
import { CompanyVerifiedLabel, MyCompanyLabel } from '../index'
import { CompanyLink } from '../link'

const CompanySummary = ({ company, ...props }) => {
  const { misc_info } = company

  return (
    <Item.Group {...props}>
      <Item>
        <Item.Image
          src={util.prependImage(company.logo, companyPlaceholder)}
          className="img-rounded"
        />
        <Item.Content>
          <Item.Header className="py-5">
            <CompanyLink company={company} className="uppercase" />{' '}
            <CompanyVerifiedLabel company={company} />
            <MyCompanyLabel company={company} />
          </Item.Header>
          <Item.Meta>
            {misc_info &&
              misc_info.sectors &&
              misc_info.sectors.map(sector => (
                <Label
                  key={sector}
                  basic
                  content={sectors[sector]}
                  className="my-2 uppercase"
                />
              ))}
          </Item.Meta>
          <Item.Meta>
            <Label
              content={` ${company.city}, ${countries[company.country]}`}
              className="my-2 uppercase"
            />
          </Item.Meta>
          <Item.Extra className="text-ellipsis">
            {misc_info && misc_info.about}
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default CompanySummary
