import React from 'react'
import { Button, Divider, Header } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { usePage } from '../../util/hooks'
import { client, GET_NOTIFICATIONS } from '../../util/API/Apollo'
import { APIPagination, DimmerLoader } from '../../components'
import { NotificationFilter } from '../../util/Constant'

const COUNT = 10

const Notifications = () => {
  const [page, setPage] = usePage(1)
  const { loading, data } = useQuery(GET_NOTIFICATIONS, {
    variables: { count: COUNT, page, only: NotificationFilter.SYSTEM },
    client,
  })
  const notifications = _.get(data, 'notifications.data', [])
  const paginatorInfo = _.get(data, 'notifications.paginatorInfo')

  return (
    <>
      <DimmerLoader className="py-50" active={loading} />
      {notifications.map(notif => {
        const { header = '', content = '', icon = 'info', link } = notif.data

        return (
          <React.Fragment key={notif.uuid}>
            <div className="flex-spread">
              <div>
                <Header
                  sub
                  icon={{ name: icon, loading: icon === 'spinner' }}
                  content={header}
                />
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </div>
              {link && <Button content="Details" as={Link} to={link} />}
            </div>
            <Divider />
          </React.Fragment>
        )
      })}
      <APIPagination
        count={COUNT}
        paginatorInfo={paginatorInfo}
        handleChange={setPage}
      />
    </>
  )
}

export default Notifications
