import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Divider,
  Header,
  Icon,
  Segment,
  Table,
} from 'semantic-ui-react'
import { AgeInfo } from '../index'
import link, { UserLink } from '../link'

const TradesSimpleTable = ({ trades }) => {
  if (trades.length > 0) {
    return (
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="TRADE ID" />
            <Table.HeaderCell content="TRANSFER" />
            <Table.HeaderCell content="AGE" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {trades.map(trade => {
            const { hashid, seller_info, buyer_info, created_at } = trade

            return (
              <Table.Row key={hashid}>
                <Table.Cell>
                  <Link to={link.trade(trade)}>{hashid}</Link>
                </Table.Cell>
                <Table.Cell>
                  <UserLink user={seller_info} />
                  <Icon name="arrow right" color="red" />
                  <UserLink user={buyer_info} />
                </Table.Cell>
                <Table.Cell>
                  <AgeInfo iso str={created_at} />
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    size="mini"
                    content="View"
                    as={Link}
                    to={link.trade(trade)}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Segment basic textAlign="center">
      <Divider />
      <Header as="h4" icon color="black">
        <Icon name="ban" />
        NO PENDING ACTIONS FOUND
      </Header>
      <Divider />
    </Segment>
  )
}

export default TradesSimpleTable
