import React from 'react'
import _ from 'lodash'
import { ContractStatus, TxStatus } from '../../util/Constant'
import { AmountLabel, PopupIcon } from '../index'

export const ContractTradeStatusIcon = ({ contract }) => {
  if (contract.total_trades > 0) {
    return (
      <PopupIcon
        name="shopping cart"
        color="red"
        content="This asset has been traded on marketplace."
      />
    )
  }
  return null
}

export const ContractStatusIcon = ({ contract }) => {
  if (contract.tx_status === TxStatus.PENDING) {
    return <PopupIcon content="Deploying" name="loading spinner" color="blue" />
  }
  switch (contract.status) {
    case ContractStatus.DRAFT:
      return <PopupIcon content="Draft" name="edit" color="pink" />
    case ContractStatus.LOCKED:
      return (
        <PopupIcon
          content="Waiting for approval"
          name="loading spinner"
          color="blue"
        />
      )
    case ContractStatus.DEPLOYED:
    case ContractStatus.PAID:
      return null
    default:
      console.error('invalid contract status', contract.status)
      return null
  }
}

export const ContractAmountLabel = ({ contract }) => {
  return <AmountLabel token amount={_.get(contract, 'token_numbers.initial')} />
}
