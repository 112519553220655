import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router'
import * as yup from 'yup'
import _ from 'lodash'
import {
  CREATE_CONTRACT,
  GET_CONTRACT,
  UPDATE_CONTRACT,
} from '../../util/API/Apollo'
import { AssetCodes, ContractType } from '../../util/Constant'
import util from '../../util'
import { DimmerLoader } from '../../components'
import link from '../../components/link'

export const getDocFields = contract_type => {
  const underlyingSecurity = {
    key: 'underlying_security',
    title: 'Underlying Security',
    asset_code: AssetCodes.asset_underlying_security,
    info:
      'A copy of the physical document or book-entry statement which represents the underlying security',
  }
  const auditReport = {
    key: 'audit_report',
    title: 'VC Audit Report',
    asset_code: AssetCodes.asset_vc_audit,
  }
  const subscriptionDoc = {
    key: 'subscription_document',
    title: 'Subscription Documentation / Side Letters',
    asset_code: AssetCodes.asset_vc_subscription,
  }
  const ppm = {
    key: 'ppm',
    title: 'PPM',
    asset_code: AssetCodes.asset_ppm,
    info: 'Private placement memorandum of the underlying security',
  }

  switch (contract_type) {
    case ContractType.SAFE:
    case ContractType.EQUITY:
    case ContractType.CONVERTIBLE_NOTE:
      return [underlyingSecurity]
    case ContractType.VC:
      return [underlyingSecurity, auditReport, subscriptionDoc]
    case ContractType.FOF:
      return [underlyingSecurity, ppm]
    default:
      return []
  }
}

const getDocSchema = contract_type => {
  const schema = {}
  const fields = getDocFields(contract_type)
  fields.forEach(({ key, title }) => {
    schema[key] = yup
      .object()
      .nullable()
      .required(`${title} is required`)
  })
  return yup.object(schema)
}

export const getSchema = (type, step) => {
  const contract_type = yup.string().required('Contract type is required')
  const issuer = yup.object().required('Issuer company is required')
  const agreement_date = yup
    .string()
    .label('Issue date')
    .required('Issue date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      excludeEmptyString: true,
      message: 'Issue date is invalid',
    })

  const purchase_amount = yup
    .string()
    .required('Amount is required')
    .matches(/^\d{3,}$/, {
      excludeEmptyString: true,
      message: 'Amount cannot be less then $100',
    })
  const face_value = yup.string().required('Face value is required')
  const number_of_shares = yup.string().required('Number of shares is required')

  const fund_name = yup.string().required('Fund name is required')
  const nominal_value_per_share = yup
    .string()
    .required('Nominal value per share is required')
  const share_type = yup.string().required('Share type is required')

  const total_committed_capital = yup
    .string()
    .required('Fund Amount is required')
  const initial_closing_date = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    excludeEmptyString: true,
    message: 'Initial closing date is invalid',
  })
  const final_closing_date = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    excludeEmptyString: true,
    message: 'Final closing date is invalid',
  })

  let detailsSchema = {}
  let documents = getDocSchema(type)

  switch (type) {
    case ContractType.SAFE:
    case ContractType.CONVERTIBLE_NOTE:
      detailsSchema = { ...detailsSchema, purchase_amount }
      break
    case ContractType.EQUITY:
      detailsSchema = {
        ...detailsSchema,
        face_value,
        number_of_shares,
      }
      break
    case ContractType.FOF:
      detailsSchema = {
        ...detailsSchema,
        purchase_amount,
        fund_name,
        nominal_value_per_share,
        share_type,
      }
      break
    case ContractType.VC:
      detailsSchema = {
        ...detailsSchema,
        total_committed_capital,
        initial_closing_date,
        final_closing_date,
      }
      break
    default:
      detailsSchema = { ...detailsSchema }
      break
  }

  switch (step) {
    case 'type': // no validation on this step
    case 'company': // no validation on this step
    case 'details':
    case 'financials':
      return yup.object().shape({
        contract_type,
        issuer,
        agreement_date,
        details: yup.object().shape(detailsSchema),
      })
    case 'documents':
      return yup.object().shape({
        contract_type,
        issuer,
        agreement_date,
        details: yup.object().shape({
          ...detailsSchema,
          terms_agreement: yup
            .bool()
            .required('You must accept user agreement')
            .equals([true], 'You must accept user agreement'),
        }),
        documents,
      })
    case 'finish':
      return yup.object().shape({
        contract_type,
        issuer,
        agreement_date,
        details: yup.object().shape(detailsSchema),
        documents,
      })
    default:
      throw new Error(`invalid step "${step}"`)
  }
}

const detailFields = [
  'purchase_amount',
  'convert_to',
  'qualified_financing_amount',
  'early_exit_multiple',
  'valuation_cap',
  'discount_rate',
  'face_value',
  'rofr',
  'restriction',
  'number_of_shares',
  'comments',
  'fund_name',
  'nominal_value_per_share',
  'share_type',
  // 'investment_strategy',
  'sectors',
  'class_name',
  'class_prefix',
  'seniority',
  'pre_money_valuation',
  'accrual_frequency',
  'interest_rate',
  'maturity_date',
  'url_web',
  'distribution_per_annum',
  'as_of',
  'distribution_currency',
  'distribution_frequency',
  'distribution_per_annum',
  'penalty_period',
  'irr',
  'penalty_frequency',
  'hurdle_rate',
  'total_committed_capital',
  'investment_period',
  'initial_closing_date',
  'final_closing_date',
  'pre_deployment_token_amount',
  'terms_agreement',
]

export const getEmptyOptionalDetails = () => ({
  phonenumber: '',
  email: '',
  url_web: '',
  contact_firstname: '',
  contact_lastname: '',
  contact_email: '',
  contact_phonenumber: '',
})

export const getEmptyStrategyDetails = () => ({
  investment_strategy: '',
  investment_info: [{ company: '', type: '', amount: '' }],
})
export const getEmptyERPDetails = () => ({
  lockup_period: '',
  early_redemption_from_date: '',
  early_redemption_to_date: '',
  early_redemption_penalty: '',
})
const getEmptyForm = () => {
  // fields object
  const details = detailFields.reduce(
    (details, key) => ({ ...details, [key]: '' }),
    {}
  )
  details.rofr = false
  details.restriction = false
  details.terms_agreement = false
  details.sectors = []

  return {
    contract_type: null,
    agreement_date: '',
    issuer: null,
    details,
    // shareholder_invitations: [],
    documents: {
      underlying_security: null,
      creosafe_agreement_document: null,
      audit_report: null,
      subscription_document: null,
      ppm: null,
    },
    optionalDetails: getEmptyOptionalDetails(),
    erpDetails: getEmptyERPDetails(),
    strategyDetails: getEmptyStrategyDetails(),
    financial_data: [],
  }
}

export const mapContractToForm = contract => {
  const form = getEmptyForm()
  if (!contract) return form

  form.contract_type = contract.contract_type
  form.agreement_date = contract.agreement_date
  form.issuer = contract.issuer
  for (const key in contract.details) {
    form.details[key] = contract.details[key]
  }
  // form.shareholder_invitations = contract.shareholder_invitations.map(
  //   ({ __typename, ...rest }) => rest
  // )

  for (const { document_key, document } of contract.documents) {
    form.documents[document_key] = document
  }
  form.financial_data = contract.financial_data.map(
    ({ __typename, ...rest }) => rest
  )

  // has optional details
  if (contract.details.email) {
    form.optionalDetails = {
      phonenumber: _.get(contract, 'details.phonenumber', ''),
      email: _.get(contract, 'details.email', ''),
      url_web: _.get(contract, 'details.url_web', ''),
      contact_firstname: _.get(contract, 'details.contact_firstname', ''),
      contact_lastname: _.get(contract, 'details.contact_lastname', ''),
      contact_email: _.get(contract, 'details.contact_email', ''),
      contact_phonenumber: _.get(contract, 'details.contact_phonenumber', ''),
    }
  }
  // has erp details
  if (contract.details.lockup_period) {
    form.erpDetails = {
      lockup_period: _.get(contract, 'details.lockup_period', ''),
      early_redemption_penalty: _.get(
        contract,
        'details.early_redemption_penalty',
        ''
      ),
      early_redemption_from_date: _.get(
        contract,
        'details.early_redemption_from_date',
        ''
      ),
      early_redemption_to_date: _.get(
        contract,
        'details.early_redemption_to_date',
        ''
      ),
    }
  }
  // has investment strategy
  if (contract.details.investment_info) {
    form.strategyDetails = {
      investment_strategy: _.get(contract, 'details.investment_strategy', ''),
      investment_info: _.get(contract, 'details.investment_info', []),
    }
  }

  return form
}

export const mapFormToInput = form => {
  const financialDataInput = form.financial_data.map(data => {
    const newData = util.cleanObject(data)
    if (newData.documents) {
      newData.documents = newData.documents.map(
        ({ title, description, file }) =>
          util.cleanObject({ title, description, document_file: file.uuid })
      )
    }
    return newData
  })

  const details = util.cleanObject({
    ...form.details,
    ...form.optionalDetails,
    ...form.erpDetails,
    ...form.strategyDetails,
  })

  const documentsInput = Object.keys(form.documents)
    .filter(document_key => Boolean(form.documents[document_key]))
    .map(document_key => {
      return {
        document_key,
        document_uuid: form.documents[document_key].uuid,
      }
    })
  const input = {
    contract_type: form.contract_type,
    agreement_date: form.agreement_date,
    issuer: form.issuer.uuid,
    financial_data: financialDataInput,
    // shareholder_invitations: form.shareholder_invitations,
    documents: documentsInput,
    details: JSON.stringify(details),
  }
  return util.cleanObject(input)
}

export const SaveMutation = ({
  contract,
  formHook,
  children,
  stepsHook,
  ...props
}) => {
  const mutation = contract ? UPDATE_CONTRACT : CREATE_CONTRACT
  const refetchQueries = contract
    ? [{ query: GET_CONTRACT, variables: { uuid: contract.uuid } }]
    : []
  // redirect to contract edit form if just created, go to next step otherwise
  const onCompleted = contract ? stepsHook.nextStep : _.noop
  return (
    <Mutation
      mutation={mutation}
      onCompleted={onCompleted}
      refetchQueries={refetchQueries}
      awaitRefetchQueries
      {...props}
    >
      {(save, { loading, data }) => {
        if (_.get(data, 'createContract')) {
          return <Redirect to={link.draftContract(data.createContract)} />
        }

        const submit = async () => {
          if (await formHook.validate()) {
            if (!formHook.isDirty) stepsHook.nextStep()
            const input = mapFormToInput(formHook.form)
            const variables = { input }
            if (contract) variables.contract_id = contract.uuid
            save({ variables })
          }
        }

        return (
          <>
            <DimmerLoader active={loading} />
            {children({ submit, loading, data })}
          </>
        )
      }}
    </Mutation>
  )
}

const Confirm = ({ onConfirm, ...props }) => (
  <Modal
    actions={[
      { key: 'no', content: 'No', basic: true, inverted: true },
      {
        key: 'yes',
        content: 'Yes',
        color: 'red',
        icon: 'remove',
        inverted: true,
        onClick: onConfirm,
      },
    ]}
    content={<Modal.Content content="Your changes will be lost" />}
    header={<Header icon="exclamation circle" content="Are you sure?" />}
    basic
    size="small"
    {...props}
  />
)

/**
 * "go back step" button or confirmation modal in steps
 * @param dirty {boolean} show 'are you sure' modal first if true
 * @param goBack {function}
 * @param props for semantic Button component
 */
export const GoBackButton = ({ dirty, goBack, ...props }) => {
  if (dirty) {
    return (
      <Confirm
        onConfirm={goBack}
        trigger={
          <Button type="button" content="Back" floated="left" {...props} />
        }
      />
    )
  }
  return (
    <Button
      type="button"
      content="Back"
      floated="left"
      onClick={goBack}
      {...props}
    />
  )
}
