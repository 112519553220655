import React from 'react'
import { Segment, Message, Icon } from 'semantic-ui-react'
import { useParams } from 'react-router'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_TRADE_DETAILS } from '../../util/API/Apollo'
import { CSBreadcrumb, PageLoader, SingleGrid } from '../../components'
import {
  PaymentStatus,
  TradeActors,
  TradeStatus,
  TxStatus,
} from '../../util/Constant'
import { NoData } from '../../components/NotFound'
import TabMenu from '../../components/TabMenu'
import TradeCommonDetails from '../../components/Trade/TradeCommonDetails'
import ContractCommonDetails from '../../components/Contract/ContractCommonDetails'
import SignTradeButton from './SignTradeButton'
import PayTradeButton from './PayTradeButton'
import TradeTxsTable from '../../components/Trade/TradeTxsTable'
import link from '../../components/link'

const useSignature = trade => {
  return React.useMemo(() => {
    if (!trade) return null
    return trade.signatures.find(
      signature => signature.signature_role === trade.trade_role
    )
  }, [trade])
}

const useTradePayment = trade => {
  return React.useMemo(() => {
    if (!trade) return null
    return trade.payments.find(
      payment => payment.payment_role === TradeActors.BUYER
    )
  }, [trade])
}

const TradeDetails = () => {
  const { uuid } = useParams()
  const { loading, data } = useQuery(GET_TRADE_DETAILS, {
    variables: { uuid },
    client,
  })

  const trade = _.get(data, 'trade')
  const tradeDone = _.get(trade, 'status') === TradeStatus.COMPLETED
  const txDone = _.get(trade, 'tx_status') === TxStatus.VERIFIED

  const initializing = !tradeDone && !txDone
  const active = !tradeDone && txDone
  const completed = tradeDone && txDone

  const currentSignature = useSignature(trade)
  const buyerPayment = useTradePayment(trade)

  if (loading) {
    return <PageLoader />
  }
  if (!trade) {
    return <NoData content="TRADE NOT FOUND" />
  }
  const isSignable =
    active && _.get(currentSignature, 'tx_status') === TxStatus.NONE
  const isPayable =
    active &&
    buyerPayment.tx_status === TxStatus.NONE &&
    buyerPayment.status === PaymentStatus.WAITING &&
    trade.trade_role === TradeActors.BUYER
  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Trades', to: link.myPendingTrades }]}
        title={trade.hashid}
      />
      <SingleGrid padded>
        <Segment padded="very">
          <TabMenu defaultTab="trade-details">
            <TabMenu.Left>
              <TabMenu.Menu>
                <TabMenu.Item name="trade-details" />
                <TabMenu.Item name="asset-details" />
              </TabMenu.Menu>
              <>
                {initializing && (
                  <p>
                    <Icon name="spinner" loading /> Starting trade process
                  </p>
                )}
                {completed && (
                  <p>
                    <Icon name="check" /> Transfer is completed
                  </p>
                )}
                <SignTradeButton show={isSignable} trade={trade} />
                <br />
                <PayTradeButton show={isPayable} trade={trade} />
                {!completed && (
                  <Message size="small" info>
                    <Message.Header>
                      In order for a Trade to be completed; Buyer, Seller and
                      Broker Dealer must sign for the Trade, and the Payment
                      needs to be completed.
                    </Message.Header>
                  </Message>
                )}
              </>
            </TabMenu.Left>
            <TabMenu.Right>
              <TabMenu.Pane name="trade-details">
                <TradeTxsTable trade={trade} />
                <br />
                <br />
                <TradeCommonDetails trade={trade} />
              </TabMenu.Pane>
              <TabMenu.Pane name="asset-details">
                <ContractCommonDetails
                  contract={trade.accepted_offer.listing.token.contract}
                />
              </TabMenu.Pane>
            </TabMenu.Right>
          </TabMenu>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default TradeDetails
