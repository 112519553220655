import React from 'react'
import { Button, Segment, Form, Grid, Header, Image } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import _ from 'lodash'
import CompanySearchInput from '../../components/Company/CompanySearchInput'
import { client, GET_COMPANY } from '../../util/API/Apollo'
import CompanySummary from '../../components/Company/CompanySummary'
import { GoBackButton } from './common'
import { ContentLoader } from '../../components'
import createAssetImage from '../../images/create_asset.svg'

const CompanyStepInfo = () => (
  <>
    <Header
      as="h2"
      color="purple"
      textAlign="left"
      content="SELECT AN ISSUER"
    />
    <div className="text-center">
      <Image size="large" centered src={createAssetImage} />
    </div>
  </>
)

function CompanyStep({ contract, formHook, stepsHook }) {
  const [tempCompany, setTempCompany] = React.useState(formHook.form.issuer)

  const handleSubmit = () => {
    const { uuid, name } = tempCompany
    formHook.set('issuer', { uuid, name })
    stepsHook.nextStep()
  }

  const { loading, data } = useQuery(GET_COMPANY, {
    variables: { uuid: _.get(tempCompany, 'uuid') },
    skip: !tempCompany,
    client,
  })

  const loadedTempCompany = _.get(data, 'company')

  return (
    <Segment padded="very" as={Form} onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <CompanyStepInfo />
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={16}
            mobile={16}
            verticalAlign="middle"
          >
            <p className="text-center">
              The Issuer may already be listed in the
              <br /> <strong>STC Digital Securities Platform</strong>,<br />{' '}
              please search below.
              <br />
              <br />
              <small>
                If the Issuer is not listed, you will be able to add a new
                issuer.
              </small>
            </p>
            <CompanySearchInput
              value={tempCompany}
              onChange={c => setTempCompany(c)}
              type="issuer"
              disabled={Boolean(contract)}
            />
            <ContentLoader active={loading} />
            {loadedTempCompany && (
              <CompanySummary company={loadedTempCompany} />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              primary
              type="submit"
              floated="right"
              content={contract ? 'Next' : 'Save & Next'}
              disabled={!tempCompany}
              data-test-id="tokenize-next"
            />
            <GoBackButton
              goBack={stepsHook.prevStep}
              disabled={Boolean(contract)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default CompanyStep
