import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Item,
  Segment,
  TextArea,
} from 'semantic-ui-react'
import _ from 'lodash'
import {
  ASK_PROVIDER,
  client,
  GET_DISCUSSION_WITH_PROVIDER,
  GET_PROFILE,
} from '../../util/API/Apollo'
import { useTab } from '../../util/hooks'
import { DimmerLoader } from '../../components'
import { ProviderType } from '../../util/Constant'
import useForm from '../../util/hooks/form'
import { BasicMessage, DiscussionItem } from './Messages'

const initialForm = { message: '' }
const CustomerDiscussion = ({ type }) => {
  const { loading, data, refetch } = useQuery(GET_DISCUSSION_WITH_PROVIDER, {
    variables: { provider_type: type, count: 10, page: 1 },
    client,
  })
  const messages = _.get(data, 'discussionWithProvider.data', []).reverse()
  return (
    <Segment className="pb-50">
      <DimmerLoader active={loading} />
      {messages.map((message, index) => (
        <BasicMessage key={index} message={message} />
      ))}
      <AskMutation type={type} refetch={refetch} />
    </Segment>
  )
}

const AskMutation = ({ type, refetch }) => {
  const { input, form, clear } = useForm('message', initialForm)
  const handleSubmit = () => {
    clear()
    refetch()
  }
  const [ask, { loading }] = useMutation(ASK_PROVIDER, {
    variables: { provider_type: type, message: form.message },
    onCompleted: handleSubmit,
    client,
  })

  return (
    <Form onSubmit={ask} loading={loading}>
      <Form.Field>
        <TextArea placeholder="Enter the message" {...input('message')} />
      </Form.Field>
      <Button
        primary
        content="Send"
        icon="paper plane"
        labelPosition="right"
        floated="right"
        type="submit"
        disabled={!form.message}
      />
    </Form>
  )
}

const CustomerDiscussions = () => {
  const { itemProps, isActive } = useTab(null)
  const { loading, data } = useQuery(GET_PROFILE, { client })

  if (loading)
    return (
      <Container fluid textAlign="center" className="py-massive">
        <DimmerLoader active={loading} />
      </Container>
    )

  const providers = _.get(data, 'me.providers', [])
  if (providers.length === 0) {
    return (
      <Container fluid textAlign="center" className="py-massive">
        <Header as="h3" icon color="black">
          <Icon name="ban" />
          YOU DO NOT HAVE ANY PROVIDERS
        </Header>
      </Container>
    )
  }

  const bd = providers.find(p => p.provides === ProviderType.BROKER_DEALER)
  const fa = providers.find(p => p.provides === ProviderType.FINANCIAL_ADVISOR)
  const ta = providers.find(p => p.provides === ProviderType.TRANSFER_AGENT)

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={4}>
          <Item.Group link>
            {bd && (
              <DiscussionItem
                user={bd.officers[0]}
                {...itemProps('broker-dealer')}
              />
            )}
            {fa && (
              <DiscussionItem
                user={fa.officers[0]}
                {...itemProps('financial-advisor')}
              />
            )}
            {ta && (
              <DiscussionItem
                user={ta.officers[0]}
                {...itemProps('transfer-agent')}
              />
            )}
          </Item.Group>
        </Grid.Column>
        <Grid.Column width={12}>
          {isActive(null) && (
            <Container fluid className="flex center h-100p">
              <Header as="h3" icon color="black">
                <Icon name="comment" />
                SELECT A PROVIDER TO MESSAGE
              </Header>
            </Container>
          )}
          {isActive('broker-dealer') && (
            <CustomerDiscussion type={ProviderType.BROKER_DEALER} />
          )}
          {isActive('financial-advisor') && (
            <CustomerDiscussion type={ProviderType.FINANCIAL_ADVISOR} />
          )}
          {isActive('transfer-agent') && (
            <CustomerDiscussion type={ProviderType.TRANSFER_AGENT} />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default CustomerDiscussions
