import React from 'react'
import { Link } from 'react-router-dom'
import { Divider, Header, Icon, Label, Segment } from 'semantic-ui-react'
import { AgeInfo, OfferAmountLabel, OfferPriceLabel, PPDSLabel } from '../index'
import { OfferDirection } from '../../util/Constant'
import { ListingInfoTable } from '../Listing'
import link, { ContractLink, UserLink } from '../link'

const Offer = ({ offer }) => {
  if (offer) {
    const {
      direction,
      hashid,
      listing,
      created_at,
      token_amount,
      token_price,
      from,
      to,
    } = offer

    return (
      <>
        <Segment placeholder padded="very">
          <Header as="h2" content="Listing details" />
          <ListingInfoTable listing={listing} />
        </Segment>
        <Header as="h4" content="Offer ID" />
        {direction === OfferDirection.INCOMING && (
          <Label color="red" basic icon="arrow down" content="INCOMING OFFER" />
        )}
        {direction === OfferDirection.OUTGOING && (
          <Label color="green" basic icon="arrow up" content="OUTGOING OFFER" />
        )}{' '}
        <Link to={link.offer(offer)}>{hashid}</Link>
        <Divider />
        <Header as="h4" content="Underlying Asset" />
        <ContractLink contract={listing.token.contract} />
        <Divider />
        <Header as="h4" content="Offer" />
        <UserLink user={from} />
        <Icon name="arrow right" color="red" />
        <UserLink user={to} />
        <Divider />
        <Header as="h4" content="Age" />
        <AgeInfo iso str={created_at} />
        <Divider />
        <Header as="h4" content="Offer amount" />
        <OfferAmountLabel amount={token_amount} />
        <Divider />
        <Header as="h4" content="Offer price" />
        <OfferPriceLabel price={token_price} />
        <Divider />
        <Header as="h4" content="PPDS" />
        <PPDSLabel data={{ amount: token_amount, price: token_price }} />
      </>
    )
  }

  return null
}

export default Offer
