import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { AgeInfo, OfferAmountLabel, OfferPriceLabel, PPDSLabel } from '../index'
import { UserLink } from '../link'

const PreviousOffers = ({ offers }) => {
  return (
    <Table basic="very" fixed padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="OFFER" />
          <Table.HeaderCell content="OFFER AMOUNT" />
          <Table.HeaderCell content="OFFER PRICE" />
          <Table.HeaderCell content="PPDS" />
          <Table.HeaderCell content="AGE" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map(offer => {
          const {
            hashid,
            token_amount,
            token_price,
            created_at,
            from,
            to,
          } = offer

          return (
            <Table.Row key={hashid}>
              <Table.Cell>
                <UserLink user={from} />
                <Icon name="arrow right" color="red" />
                <UserLink user={to} />
              </Table.Cell>
              <Table.Cell>
                <OfferAmountLabel amount={token_amount} />
              </Table.Cell>
              <Table.Cell>
                <OfferPriceLabel price={token_price} />
              </Table.Cell>
              <Table.Cell>
                <PPDSLabel
                  data={{ amount: token_amount, price: token_price }}
                />
              </Table.Cell>
              <Table.Cell>
                <AgeInfo iso str={created_at} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default PreviousOffers
