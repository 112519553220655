import debug from 'debug'
import * as Sentry from '@sentry/browser'
import util from '../index'

const log = debug('app:api')

// Custom fetch function for handling re-authorization in apollo
const customFetch = (uri, options) => {
  try {
    // This reference will let us check later on if we are executing getting the refresh token.
    let refreshingPromise = null

    // Create initial fetch, this is what would normally be executed in the link without the override
    let initialRequest = fetch(uri, options)

    // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
    // Here we return the initialRequest promise
    return initialRequest
      .then(response => {
        return response.json()
      })
      .then(json => {
        // We should now have the JSON from the response of initialRequest
        // We check that we do and look for errors from the GraphQL server
        if (json && json.errors && json.errors[0]) {
          // If it has the error 'User is not logged in' (that's our implementation of a 401)
          // we execute the next steps in the re-auth flow
          if (json.errors[0].message === 'Token has expired') {
            if (!refreshingPromise) {
              log('Token has expired, refreshing')

              // Execute the re-authorization request and set the promise returned to refreshingPromise
              refreshingPromise = fetch('/graphql', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                  query: 'query { refreshToken { payload { jwt_payload } } }',
                }),
              })
            }
            return refreshingPromise.then(() => {
              // Now that the refreshing promise has been executed, set it to null
              refreshingPromise = null

              // Return the promise from the new fetch (which should now have used an active access token)
              // If the initialRequest had errors, this fetch that is returned below is the final result.
              return fetch(uri, options)
            })
          } else if (json.errors[0].message === 'Unauthenticated.') {
            log('Session has expired, logging out')
          }
        }
        // If there were no errors in the initialRequest, we need to repackage the promise and return it as the final result.
        if (util.isDev()) {
          try {
            const operation = Object.keys(json.data)[0]
            const query = JSON.parse(options.body).query
            const variables = JSON.parse(options.body).variables
            log(operation, { data: json.data[operation], query, variables })
          } catch (err) {
            // upload requests have no query but form data
            log('NO JSON DATA', json)
          }
        }
        const result = {}
        result.ok = true
        result.json = () =>
          new Promise(function(resolve, reject) {
            resolve(json.data)
          })
        result.text = () =>
          new Promise(function(resolve, reject) {
            resolve(JSON.stringify(json))
          })
        return result
      })
  } catch (err) {
    console.error('ERROR:', err)
    Sentry.captureException(err)
  }
}

export default customFetch
