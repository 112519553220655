import React, { useMemo } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Container, Grid, Header, Icon, Divider } from 'semantic-ui-react'
import CreateProfileNavbar from './CreateProfileNavbar'
import { PageLoader } from '../../components'
//import Footer from '../../components/Layout/Footer'
import ProfileForm from '../../components/Profile/ProfileForm/ProfileForm'
import {
  getCreateProfileSteps,
  mapFormToInput,
  mapProfileToForm,
} from '../../components/Profile/ProfileForm/util'
import { SuccessModal } from '../../components/confirm'
import { client, CREATE_PROFILE, GET_PROFILE } from '../../util/API/Apollo'
import { useConfirmBeforeLeave, useModal } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import { useSteps } from '../../util/hooks/steps'
import { useMe } from '../../util/hooks/me'
import { ReactComponent as PoweredByLogo } from '../../images/powered_by.svg'

const CreateProfile = () => {
  const defaultMe = useMe() // shouldn't be asynchronous because of default step
  const { loading, data, error } = useQuery(GET_PROFILE, { client })
  const me = _.get(data, 'me', {})
  const defaultForm = useMemo(() => mapProfileToForm(me), [me])
  const formHook = useForm('profile', defaultForm)
  const { form, isDirty } = formHook
  const steps = useMemo(
    () => getCreateProfileSteps(form.user_type, defaultMe).map(s => s.key),
    [form.user_type, defaultMe]
  )
  const stepsHook = useSteps(steps, steps[0])
  const successModal = useModal()
  const [save, { data: mutationData, loading: mutating }] = useMutation(
    CREATE_PROFILE,
    {
      client,
    }
  )

  const handleSubmit = async () => {
    const input = mapFormToInput(form)
    await save({ variables: { input } })
  }

  useConfirmBeforeLeave(isDirty)

  if (mutationData) {
    return <Redirect to="/" />
  }
  if (loading || mutating) return <PageLoader />
  if (error) {
    return (
      <Container>
        <Header as="h1" icon>
          <Icon name="ban" />
          {_.get(error, 'graphQLErrors.0.message')}
        </Header>
      </Container>
    )
  }
  return (
    <>
      <SuccessModal
        content="Your profile has been completed successfully."
        button={{ as: Link, to: '/' }}
        {...successModal.props}
      />
      <Grid className="m-0 p-0">
        <Grid.Row>
          <Grid.Column className="p-20">
            <CreateProfileNavbar />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer="16" className="px-30">
            <ProfileForm
              formHook={formHook}
              stepsHook={stepsHook}
              onComplete={handleSubmit}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="px-0" textAlign="center">
            <Divider />
            <small>© 2020 STC Digital Securities. All rights reserved.</small>
            <br />
            <br />
            <PoweredByLogo style={{ width: '100px' }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default CreateProfile
