import React, { useState, useRef, useMemo } from 'react'
import { Container, Dropdown, Divider, Button } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { client, SEARCH_OFFICER } from '../../util/API/Apollo'

const OfficerSearch = ({
  value,
  onChange,
  disabled,
  type,
  autoFocus = false,
  showForm,
  ...props
}) => {
  const [input, setInput] = useState('') // input user entered used by query
  const [waiting, setWaiting] = useState(false)
  const inputTimeout = useRef(null)

  const { loading, data } = useQuery(SEARCH_OFFICER, {
    variables: { fullName: input, user_type: type, page: 1, count: 10 },
    skip: input.length < 2,
    client,
  })

  const officer = _.get(data, 'searchOfficer.data', [])

  const results = useMemo(() => {
    // manipulate data for semantic
    return officer.map(officer => ({
      key: officer.uuid,
      value: officer.uuid,
      text: officer.fullname,
      officer,
    }))
  }, [officer])

  const handleSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length < 2) return
    clearTimeout(inputTimeout.current)
    setWaiting(true)
    inputTimeout.current = setTimeout(() => {
      setInput(searchQuery)
      setWaiting(false)
    }, 500)
  }
  const handleChange = (e, { value }) => {
    const officer = results.find(c => c.value === value).officer
    onChange(officer, { ...props, value: officer })
  }

  let noResultMessage = <br />
  if (!(waiting || loading)) {
    if (input.length < 2) {
      noResultMessage = 'Enter at least 2 characters'
    } else {
      noResultMessage = (
        <Container textAlign="center">
          <Divider />
          <p>Officer not found</p>
          <Button primary onClick={showForm} content="Invite an officer" />
        </Container>
      )
    }
  }

  return (
    <Dropdown
      fluid
      search
      selection
      loading={loading || waiting}
      options={results}
      searchInput={{ name: 'officer' }}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      disabled={disabled}
      text={_.get(value, 'name', '')}
      noResultsMessage={noResultMessage}
      placeholder={`Enter Officer Name (Enter at least 2 characters)`}
      icon="search"
      {...props}
    />
  )
}

export default OfficerSearch
