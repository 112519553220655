import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Icon,
  Segment,
  Transition,
} from 'semantic-ui-react'
import * as yup from 'yup'
import PasswordStrengthMeter from '../components/Auth/PasswordStrengthMeter'
import Toast from '../util/Toast'
import useForm from '../util/hooks/form'
import { useMounted, useRouterState } from '../util/hooks'
import { ACTIVATE_ACCOUNT, client, RESEND_ACTIVATION } from '../util/API/Apollo'
import { ReactComponent as STCLogo } from '../images/stc_logo.svg'
import { DimmerLoader } from '../components'
import link from '../components/link'

const getSchema = strength =>
  yup
    .object()
    .shape({
      password_confirmation: yup
        .string()
        .required('Password confirmation is required'),
      password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password cannot exceed 20 characters')
        .test(
          'password-strength-check',
          'Password is not secure enough',
          () => strength >= 2
        ),
    })
    .test(
      'password_confirmation',
      'Passwords do not match',
      value => value.password === value.password_confirmation
    )

const initialForm = { token: '', password: '', password_confirmation: '' }

const ActivateAccount = () => {
  const [passwordStrength, setPasswordStrength] = useState(0)
  const mounted = useMounted()
  const params = useParams()
  const history = useHistory()
  const resendEmail = useRouterState('email')
  const schema = useMemo(() => getSchema(passwordStrength), [passwordStrength])
  const { form, field, set, validate } = useForm('activation', initialForm, {
    schema,
  })
  const [showTokenInput, setShowTokenInput] = useState(true)

  useEffect(() => {
    const token = params && params.token
    if (token) {
      set('token', token)
      setShowTokenInput(false)
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const [activate, { loading }] = useMutation(ACTIVATE_ACCOUNT, {
    variables: { input: form },
    onCompleted: () => {
      history.push({
        pathname: link.login,
        state: {
          message: 'Your account is activated. You can log in now.',
        },
      })
    },
    onError: () => Toast.error('Invalid token'),
    client,
  })

  const [resend, resendMutation] = useMutation(RESEND_ACTIVATION, {
    variables: { email: resendEmail },
    client,
  })

  const handleSubmit = async () => {
    if (await validate()) activate()
  }

  const ResendEmail = (
    <>
      <Divider hidden />
      <Divider horizontal content="did not receive activation email" />
      <p className="text-center">
        If your activation email doesn't show up, make sure to check your
        spam/junk folders or send again a key for activation.
        <br />
        <Button
          basic
          className="link px-0"
          content="Send again"
          onClick={resend}
        />
      </p>
    </>
  )

  return (
    <Grid className="h-100vh" centered celled="internally">
      <Grid.Row>
        <Grid.Column computer={8} tablet={8} mobile={16} verticalAlign="middle">
          <Transition visible={mounted} animation="fade" duration={500}>
            <Segment padded="very" textAlign="left">
              <DimmerLoader active={loading || resendMutation.loading} />
              <div className="stc-logo">
                <STCLogo />
              </div>
              <Header
                as="h5"
                color="purple"
                textAlign="center"
                className="uppercase"
                content="Activate my account"
              />
              <p className="text-center">
                You are entering the STC Digital Securities Platform,
                <br />
                please set your password to activate your account
              </p>
              <Form onSubmit={handleSubmit}>
                {showTokenInput && (
                  <Form.Field
                    label="Activation key"
                    fluid
                    icon="key"
                    iconPosition="left"
                    placeholder="Activation key"
                    control={Input}
                    required
                    {...field('token')}
                  />
                )}
                <Form.Field
                  type="password"
                  label="New password"
                  placeholder="●●●●●●"
                  icon="lock"
                  iconPosition="left"
                  required
                  control={Input}
                  {...field('password')}
                />
                <Form.Field
                  type="password"
                  label="Confirm password"
                  placeholder="●●●●●●"
                  icon="lock"
                  iconPosition="left"
                  required
                  control={Input}
                  {...field('password_confirmation')}
                />
                <Form.Field>
                  <PasswordStrengthMeter
                    password={form.password}
                    onChange={setPasswordStrength}
                  />
                </Form.Field>
                <Form.Field
                  fluid
                  type="submit"
                  content="Activate my account"
                  color="purple"
                  control={Button}
                  disabled={!form.token || !form.password}
                />
              </Form>
              {params && !params.token && resendEmail && (
                <>
                  {!resendMutation.data ? (
                    ResendEmail
                  ) : (
                    <Message info>
                      <Icon name="info" />
                      An activation mail has been sent to your email.
                    </Message>
                  )}
                </>
              )}
            </Segment>
          </Transition>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ActivateAccount
