import React, { useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { PageLoader, SingleGrid, CSBreadcrumb } from '../components'
import { useModal } from '../util/hooks'
import useForm from '../util/hooks/form'
import { useSteps } from '../util/hooks/steps'
import { ADD_NEW_PROVIDER, client } from '../util/API/Apollo'
import { SuccessModal } from '../components/confirm'
import {
  getAddNewProviderSteps,
  getEmptyNewProviderForm,
  mapFormToInput,
} from '../components/Profile/ProfileForm/util'
import ProfileForm from '../components/Profile/ProfileForm/ProfileForm'
import link from '../components/link'

const initialForm = getEmptyNewProviderForm()

const AddNewProvider = () => {
  const formHook = useForm('new-provider', initialForm)
  const { form } = formHook
  const steps = useMemo(() => getAddNewProviderSteps(form.user_type), [
    form.user_type,
  ])
  const stepsHook = useSteps(steps.map(s => s.key), 'type')

  const successModal = useModal()
  const [addNewProvider, { loading }] = useMutation(ADD_NEW_PROVIDER, {
    variables: { input: form },
    onCompleted: () => {
      successModal.show()
    },
    client,
  })

  const handleSubmit = async () => {
    const input = mapFormToInput(form, { isCustomer: true })
    await addNewProvider({ variables: { input } })
  }

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Providers', to: link.providers }]}
        title="Add new provider"
      />
      <SingleGrid padded>
        <PageLoader active={loading} />
        <SuccessModal
          content="An invitation link has been sent to your provider"
          button={{ as: Link, to: link.providers }}
          {...successModal.props}
        />
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <ProfileForm
                  formHook={formHook}
                  stepsHook={stepsHook}
                  onComplete={handleSubmit}
                  isProvider
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </SingleGrid>
    </>
  )
}

export default AddNewProvider
