import React from 'react'
import {
  Modal,
  Form,
  Header,
  Segment,
  Button,
  Divider,
  Popup,
} from 'semantic-ui-react'
import { PPDSLabel } from '../index'
import { NumberInput } from '../input'
import PreviousOffers from './PreviousOffers'
import ListingInfoTable from '../Listing/ListingInfoTable'

const MakeOfferModal = ({
  listing,
  previousOffers,
  formHook,
  onComplete,
  open,
  onClose,
}) => {
  const { amount, price } = listing
  const { form, field } = formHook

  const listingPPDS = Number(price) / Number(amount)
  const offerPPDS = Number(form.price) / Number(form.amount)

  return (
    <Modal
      size="fullscreen"
      closeIcon
      dimmer="blurring"
      open={open}
      onClose={onClose}
    >
      <Modal.Header content="MAKE OFFER" />
      <Modal.Content>
        <Segment placeholder padded="very">
          <Header as="h2" content="Listing details" />
          <ListingInfoTable listing={listing} />
        </Segment>
        {previousOffers.length > 0 && (
          <Segment color="purple" padded="very">
            <Header as="h2" color="purple" content="Offer history" />
            <Divider hidden />
            <PreviousOffers offers={previousOffers} />
          </Segment>
        )}

        <Segment color="black" padded="very">
          <Header as="h2" content="Offer details" />
          <Divider hidden />
          <Form>
            <Popup
              inverted
              content="The number of Digital Securities that you want to buy/sell. The face value of each Digital Security is $1"
              trigger={
                <Form.Field
                  required
                  icon="coin"
                  iconPosition="left"
                  label="Offer amount"
                  max={amount}
                  control={NumberInput}
                  {...field('amount')}
                />
              }
            />
            <Popup
              inverted
              content="The value of the Digital Securities that you want to buy/sell"
              trigger={
                <Form.Field
                  required
                  icon="dollar"
                  iconPosition="left"
                  label="Offer price"
                  control={NumberInput}
                  {...field('price')}
                />
              }
            />
            {Number(form.amount) > 0 && (
              <Header
                sub
                content={
                  <>
                    ppds{' '}
                    <PPDSLabel
                      data={{ amount: form.amount, price: form.price }}
                    />
                    {offerPPDS > listingPPDS && (
                      <span className="ml-2" style={{ color: 'red' }}>
                        You are offering a higher PPDS than listing PPDS!
                      </span>
                    )}
                  </>
                }
              />
            )}
            <br />
            <Button
              primary
              fluid
              content="Make offer"
              disabled={!(Number(form.amount) && Number(form.price))}
              onClick={onComplete}
            />
          </Form>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

export default MakeOfferModal
