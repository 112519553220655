import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Segment, Message } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  PageLoader,
  SingleGrid,
} from '../../components'
import { OffersTable } from '../../components/Offer'
import {
  client,
  GET_PURCHASE_OFFERS,
  GET_SALES_OFFERS,
} from '../../util/API/Apollo'
import { usePage } from '../../util/hooks'
import { NoData } from '../../components/NotFound'

const COUNT = 10

const OffersIntro = ({ type }) => (
  <Message>
    <Message.Header>
      Here you can engage in private negotiations:
    </Message.Header>
    <Message.List>
      <Message.Item>
        View your{' '}
        <strong>{type === 'purchase' ? 'Buy Offers' : 'Sell Offers'}</strong>{' '}
        and incoming <strong>Counter Offers</strong> from{' '}
        {type === 'purchase' ? 'sellers' : 'buyers'}
      </Message.Item>
      <Message.Item>
        Manage your offers (accept, reject, cancel, counter)
      </Message.Item>
    </Message.List>
  </Message>
)

const Offers = ({ type, title }) => {
  const [page, setPage] = usePage(1)

  let query, key
  if (type === 'purchase') {
    query = GET_PURCHASE_OFFERS
    key = 'purchaseOffers'
  }
  if (type === 'sales') {
    query = GET_SALES_OFFERS
    key = 'salesOffers'
  }

  const { data, loading } = useQuery(query, {
    variables: { count: COUNT, page },
    client,
  })

  const offers = _.get(data, `${key}.data`, [])
  const paginatorInfo = _.get(data, `${key}.paginatorInfo`)

  if (loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb title={title} />

      <SingleGrid padded>
        {offers.length > 0 ? (
          <Segment padded="very">
            <OffersIntro type={type} />
            <br />
            <OffersTable
              offers={offers}
              pagination={
                <APIPagination
                  count={COUNT}
                  paginatorInfo={paginatorInfo}
                  handleChange={setPage}
                  floated="right"
                />
              }
            />
          </Segment>
        ) : (
          <NoData text={`There are no ${type} offers yet`} fluid />
        )}
      </SingleGrid>
    </>
  )
}

export default Offers
