import React, { useState } from 'react'
import { Button, Grid, Icon, Image } from 'semantic-ui-react'
import {
  CannotAccess,
  Login,
  Register,
  RegisterCompleted,
} from '../components/Auth'

import authImage from '../images/login_image.svg'

const CurrentForm = ({ currentForm, ...props }) => {
  switch (currentForm) {
    case 'login':
      return <Login {...props} />
    case 'register':
      return <Register {...props} />
    case 'register-completed':
      return <RegisterCompleted {...props} />
    case 'cannot-access':
      return <CannotAccess {...props} />
    default:
      return <Login {...props} />
  }
}

const Auth = () => {
  const [currentForm, setCurrentForm] = useState('login')

  return (
    <Grid className="h-100vh bg-secondary" padded columns={2}>
      <Grid.Row>
        <Grid.Column
          only="computer"
          computer={8}
          verticalAlign="middle"
          className="p-50"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Image src={authImage} size="big" />
          </div>
        </Grid.Column>
        <Grid.Column
          computer={8}
          tablet={16}
          mobile={16}
          verticalAlign="middle"
          className="p-50"
        >
          <div style={{ position: 'absolute', top: '10px' }}>
            {currentForm !== 'login' && (
              <Button
                basic
                className="link px-0 inverted"
                onClick={() => setCurrentForm('login')}
              >
                <Icon name="left chevron" size="small" />
                Back
              </Button>
            )}
          </div>
          <CurrentForm {...{ currentForm, setCurrentForm }} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Auth
