import React from 'react'
import { Button } from 'semantic-ui-react'
import { useModal } from '../../util/hooks'
import { useMutation } from '@apollo/react-hooks'
import {
  client,
  DELETE_CONTRACT,
  GET_DRAFT_CONTRACTS,
} from '../../util/API/Apollo'
import { ConfirmModal } from '../confirm'
import { PageLoader } from '../index'

const RemoveContractButton = ({ contract, onDeleted }) => {
  const confirmModal = useModal()
  const [remove, { loading }] = useMutation(DELETE_CONTRACT, {
    variables: { contract_id: contract.uuid },
    refetchQueries: [{ query: GET_DRAFT_CONTRACTS }],
    awaitRefetchQueries: true,
    onCompleted: onDeleted,
    client,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} onConfirm={remove} />
      <PageLoader active={loading} />
      <Button color="red" content="remove asset" onClick={confirmModal.show} />
    </>
  )
}

export default RemoveContractButton
