import React from 'react'
import _ from 'lodash'
import useForm from '../../../util/hooks/form'
import { Button, Form, Input, Modal, TextArea } from 'semantic-ui-react'
import { DocumentUpload } from '../../upload'
import * as yup from 'yup'

const initialForm = {
  title: '',
  description: '',
  document_file: null,
}

const schema = yup.object().shape({
  title: yup.string().required('Title is required for documents'),
  document_file: yup
    .object()
    .shape({})
    .nullable()
    .required('Document file is required'),
})

const FinancialDocumentModal = ({
  open,
  onClose,
  value = [],
  onChange = _.noop,
  ...props
}) => {
  const { form, field, validate, clear } = useForm(
    'financial-document',
    initialForm,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newData = [...value, _.clone(form)]
      onChange(newData, { ...props, value: newData })
      clear()
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeIcon dimmer="blurring">
      <Modal.Header content="ADD FINANCIAL DOCUMENT" />
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field
            required
            label="Title"
            placeholder="Title"
            icon="pencil"
            iconPosition="left"
            control={Input}
            {...field('title')}
          />
          <Form.Field
            label="Description"
            placeholder="Description"
            control={TextArea}
            {...field('description')}
          />
          <Form.Field
            required
            label="Document"
            control={DocumentUpload}
            {...field('document_file')}
          />
          <Button primary type="submit" fluid size="small" content="Add" />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default FinancialDocumentModal
