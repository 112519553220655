import React from 'react'
import { Button, Form, Modal, TextArea } from 'semantic-ui-react'
import useForm from '../../util/hooks/form'
import { useMutation } from '@apollo/react-hooks'
import {
  client,
  GET_PROVIDER_ACTION_DETAIL,
  REJECT_NEW_CUSTOMER_DOC,
} from '../../util/API/Apollo'
import { useModal } from '../../util/hooks'
import { ConfirmModal, SuccessModal } from '../confirm'
import { PageLoader } from '../index'

const initialForm = {
  reason: '',
}

const KYCACCRejectionButton = ({ action, show, approvalType }) => {
  const formModal = useModal()
  const confirmModal = useModal()
  const successModal = useModal()
  const { form, field } = useForm('kyc-acc-rejection', initialForm)

  const [reject, { loading }] = useMutation(REJECT_NEW_CUSTOMER_DOC, {
    variables: {
      provider_action_id: action.uuid,
      reason: form.reason,
      approval_type: approvalType,
    },
    refetchQueries: [
      { query: GET_PROVIDER_ACTION_DETAIL, variables: { uuid: action.uuid } },
    ],
    onCompleted: successModal.show,
    client,
    awaitRefetchQueries: true,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} />
      <SuccessModal
        content="You rejected the document"
        {...successModal.props}
        onClose={() => {
          successModal.hide()
          formModal.hide()
        }}
      />
      <PageLoader active={loading} />
      {show && (
        <>
          <Button color="red" content="reject" onClick={formModal.show} />
          <Modal
            as={Form}
            onSubmit={reject}
            closeIcon
            dimmer="blurring"
            {...formModal.props}
          >
            <Modal.Header content="REJECT REQUEST" />
            <Modal.Content>
              <Form.Field
                required
                label="Reason"
                placeholder="Reason for Rejection"
                control={TextArea}
                {...field('reason')}
              />
              <Button
                fluid
                content="reject"
                color="red"
                disabled={!form.reason}
                type="submit"
                data-test-id="finish-kyc-acc-rejection"
              />
            </Modal.Content>
          </Modal>
        </>
      )}
    </>
  )
}

export default KYCACCRejectionButton
