import React from 'react'
import {
  Button,
  Divider,
  Form,
  Header,
  Input,
  Message,
  Icon,
  Segment,
  Transition,
} from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { client, LOGIN } from '../../util/API/Apollo'
import useForm from '../../util/hooks/form'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'
import { useMounted, useRouterState } from '../../util/hooks'
import { DimmerLoader } from '../index'
import * as yup from 'yup'
import link from '../link'

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
  password: yup.string().required('Password is required'),
})

const initialForm = {
  email: '',
  password: '',
}

const Login = ({ setCurrentForm }) => {
  const mounted = useMounted()
  const message = useRouterState('message')
  const { form, field, validate } = useForm('login', initialForm, {
    schema: schema,
  })

  const [login, { loading, error, data }] = useMutation(LOGIN, {
    variables: form,
    onError: _.noop,
    onCompleted: data => {
      localStorage.setItem(
        'expires_at',
        _.get(data, 'login.payload.expires_at')
      )
    },
    client,
  })

  const handleSubmit = async () => {
    if (await validate()) {
      await login()
    }
  }

  if (data) return <Redirect to="/" />

  return (
    <Transition visible={mounted} animation="fade" duration={500}>
      <Segment padded="very">
        <DimmerLoader active={loading} />
        <div className="stc-logo">
          <STCLogo />
        </div>
        <Header
          color="purple"
          as="h5"
          textAlign="center"
          className="uppercase"
          content="Welcome to Digital Securities Transfer Platform"
        />
        {message && (
          <Message success>
            <Header sub>
              <Icon name="check" />
              {message}
            </Header>
          </Message>
        )}
        {error && (
          <Message error>
            <Header sub>
              <Icon name="exclamation triangle" />
              {_.get(error, 'graphQLErrors[0].message', 'Something is wrong')}
            </Header>
          </Message>
        )}
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Field
            type="email"
            label="Email address"
            placeholder="example@digitalstc.com"
            icon="envelope"
            iconPosition="left"
            control={Input}
            {...field('email')}
          />
          <Form.Field
            type="password"
            label="Password"
            placeholder="●●●●●●"
            icon="lock"
            iconPosition="left"
            control={Input}
            {...field('password')}
          />
          <Form.Field
            fluid
            type="submit"
            content="login"
            color="purple"
            control={Button}
          />
          <Form.Field>
            <Link
              to={link.login}
              className="underlined"
              onClick={() => setCurrentForm('cannot-access')}
            >
              Can’t access your account?
            </Link>
          </Form.Field>
        </Form>
        <br />
        <Divider horizontal content="don't you have an account" />
        <Button
          secondary
          id="register-btn"
          fluid
          content="Create an Account"
          onClick={() => setCurrentForm('register')}
        />
      </Segment>
    </Transition>
  )
}

export default Login
