import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { client, GET_PAYMENT_SCHEMA } from '../../util/API/Apollo'
import { sendEth, sendUsdc } from '../../util/blockchain'
import { DimmerLoader } from '../index'
import MyWallets from '../Wallet/MyWallets'
import WalletDecryptor from '../Wallet/WalletEncrypter'

const PKStep = ({ onCompleted, payment, action, recipient }) => {
  const [signing, setSigning] = React.useState(false)
  const { loading, data } = useQuery(GET_PAYMENT_SCHEMA, {
    variables: {
      action,
      currency: _.get(payment, 'price.currency'),
    },
    skip: !_.get(payment, 'price.currency'),
    client,
  })

  const signTx = async wallet => {
    setSigning(true)
    const {
      schema,
      to: defaultTo,
      recipient: defaultRecipient,
    } = data.blockchainPaymentSchema

    let sentTx
    if (payment.price.currency === 'ETH') {
      sentTx = await sendEth(
        wallet,
        schema,
        payment.cost.eth,
        defaultTo || recipient
      )
    } else if (payment.price.currency === 'USDC') {
      sentTx = await sendUsdc(
        wallet,
        schema,
        payment.cost.usdc,
        defaultTo,
        defaultRecipient || recipient
      )
    }

    setSigning(false)
    onCompleted(sentTx)
  }

  return (
    <>
      <DimmerLoader active={loading || signing} />
      <MyWallets />
      <WalletDecryptor onDecrypted={signTx} />
    </>
  )
}

export default PKStep
