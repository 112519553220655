import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useMe } from '../../util/hooks/me'
import { useWalletBalance } from '../../util/hooks'
import { DimmerLoader } from '../index'
import useForm from '../../util/hooks/form'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_EXCHANGE_RATE } from '../../util/API/Apollo'
import _ from 'lodash'
import { utils } from 'ethers'
import { convertUsdToEth } from '../../util/blockchain'
import { BlockchainActions, MIN_TX_COST } from '../../util/Constant'
import StartStep from './StartStep'
import { useSteps } from '../../util/hooks/steps'
import PKStep from './PKStep'
import BankStep from './BankStep'

const parseBalance = ({ eth, usdc }) => ({
  eth: utils.parseEther(eth),
  usdc: Number(usdc),
})
const stringifyBalance = ({ eth, usdc }) => ({
  eth: utils.formatEther(eth),
  usdc: String(usdc),
})

/**
 * @param balance {{ eth, usdc, loading }}
 * @param price {{ amount, currency }}
 * @return {{
 *  wallet: { eth: string, usdc: string },
 *  cost: { eth: string, usdc: string }
 *  totalCost: { eth: string, usdc: string }
 *  required: { eth: string, usdc: string }
 *  hasEnoughBalance: { eth: boolean, usdc: boolean }
 *  price: { amount: string, currency: string }
 * }}
 */
const usePayment = (balance, price) => {
  return React.useMemo(() => {
    if (balance.loading || !price) {
      const emptyBalance = { eth: '0', usdc: '0' }
      return {
        wallet: emptyBalance,
        cost: emptyBalance,
        totalCost: emptyBalance,
        remain: emptyBalance,
        required: emptyBalance,
        hasEnoughBalance: { eth: false, usdc: false },
        price,
      }
    }
    const { eth, usdc } = parseBalance(balance)
    const txCost = utils.parseEther(String(MIN_TX_COST))
    const cost = { eth: utils.parseEther('0'), usdc: 0 }

    if (price.currency === 'ETH') {
      cost.eth = utils.parseEther(price.amount)
    } else if (price.currency === 'USDC') {
      cost.usdc = Number(price.amount)
    }

    const totalCost = {
      eth: cost.eth.add(txCost),
      usdc: cost.usdc,
    }
    const remain = {
      eth: eth.sub(totalCost.eth),
      usdc: usdc - totalCost.usdc,
    }
    const required = {
      eth: totalCost.eth.sub(eth),
      usdc: -remain.usdc,
    }
    const hasEnoughBalance = {
      eth: remain.eth.gte(0),
      usdc: remain.usdc >= 0,
    }

    return {
      wallet: stringifyBalance({ eth, usdc }),
      cost: stringifyBalance(cost),
      totalCost: stringifyBalance(totalCost),
      remain: stringifyBalance(remain),
      required: stringifyBalance(required),
      hasEnoughBalance,
      price,
    }
  }, [balance, price])
}

const steps = ['start', 'ask-pk', 'send-payload']
const initialForm = {
  currency: 'ETH',
}
const defaultCurrencies = ['ETH', 'USDC']
const PaymentModal = ({
  open,
  onClose,
  amount,
  recipient,
  onTxSent,
  currencies = defaultCurrencies,
  action = BlockchainActions.SERVICE_PAYMENT,
  payment_id,
  bank_info,
}) => {
  const { primary_wallet } = useMe()
  const walletBalance = useWalletBalance(primary_wallet)
  const formHook = useForm('payment', initialForm)
  const exchangeQuery = useQuery(GET_EXCHANGE_RATE, {
    fetchPolicy: 'network-only',
    client,
  })
  const exchange = _.get(exchangeQuery.data, 'exchange')

  const price = React.useMemo(() => {
    if (!exchange) return null
    if (formHook.form.currency === 'ETH') {
      return {
        amount: convertUsdToEth(amount, exchange),
        currency: 'ETH',
      }
    } else if (formHook.form.currency === 'USDC') {
      return { amount, currency: 'USDC' }
    } else if (formHook.form.currency === 'USD') {
      return { amount, currency: 'USD' }
    }
  }, [formHook.form.currency, amount, exchange])

  const payment = usePayment(walletBalance, price)

  const { isActive, nextStep, setActiveStep, prevStep } = useSteps(
    steps,
    'start'
  )

  const handleClose = () => {
    setActiveStep('start')
    formHook.clear()
    onClose()
  }

  const handleTx = sentTx => {
    handleClose()
    let paymentInput
    if (action === BlockchainActions.TOKEN_PAYMENT) {
      paymentInput = {
        currency: payment.price.currency,
        exchange_id: exchange.uuid,
        payment_id: payment_id,
        tx_hash: sentTx.hash,
      }
    } else {
      paymentInput = {
        tx_hash: sentTx.hash,
        exchange_id: exchange.uuid,
        wallet_id: primary_wallet.uuid,
        currency: payment.price.currency,
      }
    }
    onTxSent(paymentInput, formHook.form.currency)
  }

  const handleBankPayment = doc => {
    handleClose()
    onTxSent(doc, formHook.form.currency)
  }

  const isUSD = formHook.form.currency === 'USD'

  return (
    <Modal
      dimmer="blurring"
      closeIcon
      closeOnDimmerClick={false}
      id="payment-modal"
      open={open}
      onClose={handleClose}
    >
      <DimmerLoader active={walletBalance.loading || exchangeQuery.loading} />
      <Modal.Header>PAYMENT</Modal.Header>
      <Modal.Content>
        {isActive('start') && (
          <StartStep
            onCompleted={nextStep}
            onClose={handleClose}
            formHook={formHook}
            payment={payment}
            amount={amount}
            currencies={currencies}
          />
        )}
        {isActive('ask-pk') && isUSD && (
          <BankStep onCompleted={handleBankPayment} bank_info={bank_info} />
        )}
        {isActive('ask-pk') && !isUSD && (
          <PKStep
            onCompleted={handleTx}
            onClose={handleClose}
            payment={payment}
            action={action}
            recipient={recipient}
          />
        )}
        {!isActive('start') && <Button content="Back" onClick={prevStep} />}
      </Modal.Content>
    </Modal>
  )
}

export default PaymentModal
