import * as yup from 'yup'
import util from '../../../util'

const URL_REGEX = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/

export const emptyCompanyForm = {
  name: '',
  tax_id: '',
  logo: null,
  sectors: [],
  about: '',
  phonenumber: '',
  email: '',
  address: '',
  state: '',
  country: 'US',
  city: '',
  zipcode: '',
  homepage: '',
  url_linkedin: '',
  url_crunchbase: '',
  url_facebook: '',
  url_instagram: '',
  url_twitter: '',
  url_telegram: '',
  contact_people: [],
  financial_data: [],
  financial_documents: [],
}

export const contactPerson = {
  emptyForm: {
    firstname: '',
    lastname: '',
    phonenumber: '',
    email: '',
  },
  schema: yup.object().shape({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    phonenumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^\d{7,10}$/, {
        excludeEmptyString: true,
        message: 'Phone number is invalid',
      }),
    email: yup
      .string()
      .required('Email is required')
      .email('Email is invalid'),
  }),
}

const basicInfoSchema = {
  name: yup.string().required('Corporation name is required'),
  tax_id: yup
    .string()
    .required('Tax ID number is required')
    .matches(/^\d{9}$/, {
      excludeEmptyString: true,
      message: 'Tax ID number is invalid',
    }),
  about: yup.string().max(2000, 'About is too long (Max. 2000 characters)'),
}

const contactInfoSchema = {
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
  phonenumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Phone number is invalid',
    }),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  zipcode: yup
    .string()
    .required('Zipcode is required')
    .matches(/^\d{5,6}$/, {
      excludeEmptyString: true,
      message: 'Zipcode is invalid',
    }),
  country: yup
    .string()
    .oneOf(['US', 'CA', 'SG'])
    .required('Country is required'),
  homepage: yup
    .string()
    .required('Website is required')
    .matches(URL_REGEX, {
      message: 'Website is invalid',
      excludeEmptyString: true,
    }),
}

export const getSchema = step => {
  let schema

  switch (step) {
    case 'basic':
      schema = yup.object().shape({ ...basicInfoSchema })
      break
    case 'contact':
      schema = yup
        .object()
        .shape({ ...basicInfoSchema, ...contactInfoSchema })
        .test(
          'state',
          'State is required',
          value => value.country === 'SG' || value.state
        )
      break
    default:
      schema = yup.object()
  }

  return schema
}

const miscFields = ['headquarter', 'sectors', 'about', 'bio', 'tax_id']

export const mapFormToInput = form => {
  const input = { ...util.cleanObject(form) }
  const miscInfo = {}

  for (const field of miscFields) {
    if (input[field]) miscInfo[field] = input[field]
    delete input[field]
  }

  if (form.logo) input.logo = form.logo.uuid

  input.misc_info = JSON.stringify(miscInfo)

  input.contact_people = util.inputizePeople(input.contact_people)

  input.financial_documents = input.financial_documents.map(
    ({ title, description, document_file }) =>
      util.cleanObject({
        title,
        description,
        document_file: document_file.uuid,
      })
  )

  return input
}

export const mapCompanyToForm = company => {
  const form = { ...emptyCompanyForm }

  if (!company) return emptyCompanyForm

  const fields = [
    'name',
    'founded_date',
    'logo',
    'phonenumber',
    'email',
    'address',
    'state',
    'country',
    'city',
    'zipcode',
    'homepage',
    'contact_people',
    'financial_data',
    'financial_documents',
  ]

  for (const field of fields) {
    if (company[field]) form[field] = company[field]
  }

  for (const field of miscFields) {
    if (company.misc_info[field]) form[field] = company.misc_info[field]
    else form[field] = ''
  }

  form.contact_people = form.contact_people.map(
    ({ __typename, ...person }) => person
  )

  form.financial_data = form.financial_data.map(({ __typename, ...data }) => ({
    ...data,
  }))

  form.financial_documents = form.financial_documents.map(
    ({ _typename, ...data }) => ({ ...data })
  )

  return form
}
