import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { Segment, Icon, Message } from 'semantic-ui-react'
import _ from 'lodash'
import { ContentLoader, CSBreadcrumb, SingleGrid } from '../../components'
import {
  ContractTabMenuItems,
  ContractTabMenuPanes,
} from '../../components/Contract/util'
import { useMe } from '../../util/hooks/me'
import { client, GET_TOKEN_DETAILS } from '../../util/API/Apollo'
import TabMenu from '../../components/TabMenu'
import SellTokensButton from '../../components/Token/SellTokensButton'
import TokenCommonDetails from '../../components/Token/TokenCommonDetails'
import ListingsTable from '../../components/Listing/ListingsTable'
import { TxStatus } from '../../util/Constant'
import link from '../../components/link'

const TokenDetails = () => {
  const { isOwner } = useMe()
  const { uuid } = useParams()

  const { data, loading } = useQuery(GET_TOKEN_DETAILS, {
    variables: { uuid },
    client,
  })

  if (loading) {
    return <ContentLoader />
  }

  const { token } = data

  const showListings =
    isOwner({ contract: _.get(token, 'contract') }) &&
    _.get(token, 'listings.length', 0) > 0

  const txVerified = _.get(token, 'tx_status') === TxStatus.VERIFIED

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Digital Securities', to: link.tokens }]}
        title={token.hashid}
      />

      <SingleGrid padded>
        <Segment padded="very">
          <Message info hidden={txVerified}>
            <Icon name="spinner" loading />
            Your Digital Securities are being verified on Blockchain. This may
            take a few minutes, please wait.
          </Message>
          <TabMenu defaultTab="digital-securities">
            <TabMenu.Left>
              <TabMenu.Menu>
                <TabMenu.Item name="digital-securities" />
                {showListings && <TabMenu.Item name="my-listings" />}
                <ContractTabMenuItems contract={token.contract} />
              </TabMenu.Menu>
              {isOwner({ token }) && <SellTokensButton token={token} />}
            </TabMenu.Left>
            <TabMenu.Right>
              <TabMenu.Pane name="digital-securities">
                <TokenCommonDetails token={token} />
              </TabMenu.Pane>
              <TabMenu.Pane name="my-listings">
                <ListingsTable listings={_.get(token, 'listings', [])} />
              </TabMenu.Pane>
              <ContractTabMenuPanes
                contract={token.contract}
                showInvitations={false}
              />
            </TabMenu.Right>
          </TabMenu>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default TokenDetails
