import React from 'react'
import { Divider, Header, Label } from 'semantic-ui-react'
import _ from 'lodash'
import { IfUserIs, PhoneNumber, TaxIdFormat } from '../index'
import { sectors } from '../../util/Constant'
import { Email, WebsiteLink } from '../link'

const CompanyCommonDetails = ({ company }) => {
  const hasSectors = _.get(company, 'misc_info.sectors', []).length > 0
  return (
    <>
      <Divider />
      <Header as="h4" content="Tax ID Number" />
      <p>
        <TaxIdFormat value={company.misc_info.tax_id} />
      </p>
      <Divider />
      <Header as="h4" content="Sector" />
      {hasSectors
        ? company.misc_info.sectors.map((sector, index) => (
            <Label
              key={index}
              basic
              size="small"
              content={sectors[sector]}
              className="my-2"
            />
          ))
        : '—'}
      <Divider />
      <Header as="h4" content="About" />
      <p>{_.get(company, 'misc_info.about', '—')}</p>
      <IfUserIs ta>
        <Divider />
        <Header as="h4" content="Website" />
        <WebsiteLink href={company.homepage} />
        <Divider />
        <Header as="h4" content="Email" />
        <Email value={company.email} />
        <Divider />
        <Header as="h4" content="Phone number" />
        <PhoneNumber value={company.phonenumber} />
        <Divider />
        <Header as="h4" content="Address" />
        {company.address}
      </IfUserIs>
    </>
  )
}

export default CompanyCommonDetails
