import React from 'react'
import { Segment, Header } from 'semantic-ui-react'
import { OffersSimpleTable } from '../Offer'
import { TradesSimpleTable } from '../Trade'
import { AdvicesSimpleTable } from '../Advice'
import TabMenu from '../TabMenu'
import { Info } from '../../util/Constant'

const PendingActionsCustomer = ({ actions }) => {
  const hasOffers = actions.offers.length > 0
  const hasTrades = actions.trades.length > 0
  const hasAdvice = actions.advice.length > 0

  let defaultTab

  if (hasOffers) defaultTab = 'offers'
  else if (hasTrades) defaultTab = 'trades'
  else if (hasAdvice) defaultTab = 'advice'

  if (!(hasAdvice || hasTrades || hasOffers)) return null

  return (
    <Segment padded="very">
      <Header as="h2" content="PENDING ACTIONS" />
      <p>
        Please view all actions that you need to take by clicking on the Offers,
        Trades and Advice tabs below. 
      </p>
      <TabMenu top defaultTab={defaultTab}>
        <TabMenu.Top>
          {hasOffers && (
            <TabMenu.Item
              name="offers"
              content="Offers"
              info={Info.OFFERS_TAB_ITEM}
            />
          )}
          {hasTrades && (
            <TabMenu.Item
              name="trades"
              content="Trades"
              info={Info.TRADES_TAB_ITEM}
            />
          )}
          {hasAdvice && (
            <TabMenu.Item
              name="advice"
              content="Advice"
              info={Info.ADVICES_TAB_ITEM}
            />
          )}
        </TabMenu.Top>
        <TabMenu.Bottom>
          <TabMenu.Pane name="offers">
            <OffersSimpleTable offers={actions.offers} />
          </TabMenu.Pane>
          <TabMenu.Pane name="trades">
            <TradesSimpleTable trades={actions.trades} />
          </TabMenu.Pane>
          <TabMenu.Pane name="advice">
            <AdvicesSimpleTable advices={actions.advice} />
          </TabMenu.Pane>
        </TabMenu.Bottom>
      </TabMenu>
    </Segment>
  )
}

export default PendingActionsCustomer
