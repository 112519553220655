import React, { useState } from 'react'
import _ from 'lodash'

import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Table,
} from 'semantic-ui-react'
import { bankInfoSchema } from '../../components/Profile/schema'

const BankDetailsForm = ({ formHook, onUpdate, onClose }) => {
  const { field, validate, isDirty, clear } = formHook
  const handleSubmit = async () => {
    if (await validate(bankInfoSchema)) {
      onUpdate()
      onClose()
    }
  }

  return (
    <Form>
      <Form.Field
        label="Bank name"
        placeholder="Bank name"
        control={Input}
        {...field('bank_info.bank_name')}
      />
      <Form.Field
        label="Account number"
        placeholder="Account number"
        control={Input}
        {...field('bank_info.account_number')}
      />
      <Form.Field
        label="RNT / ABA"
        placeholder="RNT / ABA"
        control={Input}
        {...field('bank_info.rtn_aba')}
      />
      <Form.Field
        label="SWIFT / BIC"
        placeholder="SWIFT / BIC"
        control={Input}
        {...field('bank_info.swift_bic')}
      />
      <Button
        primary
        size="small"
        content="Save"
        type="button"
        floated="right"
        disabled={!isDirty}
        onClick={handleSubmit}
      />
      <Button
        size="small"
        content="Cancel"
        type="button"
        floated="right"
        onClick={() => {
          onClose()
          clear()
        }}
      />
    </Form>
  )
}

const BankDetails = ({ user, bank_info, formHook, handleUpdate }) => {
  const [isEditing, setIsEditing] = useState(false)
  let details = bank_info || {}
  if (user) {
    details = {
      bank_name: _.get(user, 'profile.bank_info.bank_name'),
      account_number: _.get(user, 'profile.bank_info.account_number'),
      rtn_aba: _.get(user, 'profile.bank_info.rtn_aba'),
      swift_bic: _.get(user, 'profile.bank_info.swift_bic'),
    }
  }
  return (
    <Grid padded>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h3" content="BANK DETAILS" />
        </Grid.Column>
        {!_.get(user, 'is_verified') && formHook && !isEditing && (
          <Grid.Column verticalAlign="middle" textAlign="right">
            <Icon
              link
              color="grey"
              name="edit"
              onClick={() => setIsEditing(true)}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          {!isEditing ? (
            <Table basic="very" size="small" padded="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="BANK NAME" />
                  <Table.HeaderCell content="ACCOUNT NUMBER" />
                  <Table.HeaderCell content="RTN/ABA" />
                  <Table.HeaderCell content="SWIFT/BIC" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell content={details.bank_name || '-'} />
                  <Table.Cell content={details.account_number || '-'} />
                  <Table.Cell content={details.rtn_aba || '-'} />
                  <Table.Cell content={details.swift_bic || '-'} />
                </Table.Row>
              </Table.Body>
            </Table>
          ) : (
            <BankDetailsForm
              formHook={formHook}
              onUpdate={() => {
                handleUpdate()
                setIsEditing(false)
              }}
              onClose={() => setIsEditing(false)}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default BankDetails
