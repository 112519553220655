import React from 'react'
import * as yup from 'yup'
import { Button, Form, Input, Modal } from 'semantic-ui-react'
import { DateField, NumberInput } from '../../../components/input'
import useForm from '../../../util/hooks/form'
import _ from 'lodash'

const schema = yup.object().shape({
  lockup_period: yup.string().required('Face value is required'),
  early_redemption_penalty: yup.string().required('Penalty is required'),
  early_redemption_from_date: yup
    .string()
    .required('Penalty from date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      excludeEmptyString: true,
      message: 'Penalty from date is invalid',
    }),
  early_redemption_to_date: yup
    .string()
    .required('Penalty to date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      excludeEmptyString: true,
      message: 'Penalty to date is invalid',
    }),
})

const ERPDetailsForm = ({ open, onClose, value, onChange, ...props }) => {
  const { field, form, validate, clear } = useForm(
    'contract-erp-details',
    value,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newValue = _.clone(form)
      onChange(newValue, { ...props, value: newValue })
      onClose()
    }
  }
  const handleClose = () => {
    clear()
    onClose()
  }

  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={handleClose}>
      <Modal.Header content="ADD EARLY REDEMPTION PENALTY" />
      <Modal.Content>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Field
            required
            label="Lock Period"
            fluid
            placeholder="Lock Period"
            icon="pencil"
            iconPosition="left"
            control={Input}
            {...field('lockup_period')}
          />
          <DateField
            required
            label="From Date"
            past
            placeholder="From Date"
            iconPosition="left"
            {...field('early_redemption_from_date')}
          />
          <DateField
            required
            label="To Date"
            past
            placeholder="To Date"
            iconPosition="left"
            {...field('early_redemption_to_date')}
          />
          <Form.Field
            required
            label="Early Redemption Penalty"
            fluid
            placeholder="Early Redemption Penalty"
            icon="percent"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('early_redemption_penalty')}
          />
          <Button
            type="submit"
            primary
            fluid
            content="ADD"
            data-test-id="finish-add-erp-details"
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default ERPDetailsForm
