import React from 'react'
import { Grid, Header, Icon, Popup, Segment } from 'semantic-ui-react'
import { Info, PaymentStatus, TradeActors, TxStatus } from '../../util/Constant'
import { EtherscanTxButton } from '../link'

const PaymentTxStatus = ({ payment }) => {
  switch (payment.tx_status) {
    case TxStatus.NONE: {
      return (
        <>
          <Icon name="time" />
          {payment.status === PaymentStatus.WAITING && 'Pending'}
          {payment.status === PaymentStatus.PENDING &&
            'Pending for receipt confirmation'}
        </>
      )
    }
    case TxStatus.PENDING:
      return (
        <>
          <Icon name="spinner" loading />
          {Info.WAITING_BLOCKCHAIN}
        </>
      )
    case TxStatus.VERIFIED:
      return (
        <>
          <Icon name="check" color="green" />
          <br />
          <br />
          <EtherscanTxButton hash={payment.tx_hash} />
        </>
      )
    case TxStatus.FAILED:
      return (
        <>
          <Icon name="times" />
          Failed
        </>
      )
    default:
      console.error('invalid buyer payment:', payment)
  }
}

const roleLabels = {
  [TradeActors.BUYER]: 'Buyer signature',
  [TradeActors.SELLER]: 'Seller signature',
  [TradeActors.BROKER_DEALER]: 'Broker dealer signature',
}

const SignatureTxStatus = ({ signature }) => {
  switch (signature.tx_status) {
    case TxStatus.NONE:
      return (
        <>
          <Icon name="time" />
          Pending
        </>
      )
    case TxStatus.PENDING:
      return (
        <>
          <Icon name="spinner" loading />
          {Info.WAITING_BLOCKCHAIN}
        </>
      )
    case TxStatus.VERIFIED:
      return (
        <>
          <Icon name="check" color="green" />
          <br />
          <br />
          <EtherscanTxButton hash={signature.tx_hash} />
        </>
      )
    case TxStatus.FAILED:
      return (
        <>
          <Icon name="times" color="red" />
          Failed
        </>
      )
    default:
      console.error('Invalid signature: ', signature)
  }
}

const SignatureColumn = ({ trade, role }) => {
  const signature = trade.signatures.find(
    signature => signature.signature_role === role
  )

  return (
    <Grid.Column textAlign="center">
      <Segment padded>
        <Header
          as="h4"
          className="text-nowrap uppercase"
          content={roleLabels[role]}
        />
        <SignatureTxStatus signature={signature} />
      </Segment>
    </Grid.Column>
  )
}
const PaymentColumn = ({ trade }) => {
  const payment = trade.payments.find(
    payment => payment.payment_role === TradeActors.BUYER
  )

  return (
    <Grid.Column textAlign="center">
      <Segment padded>
        <Header as="h4" color="black" className="text-nowrap uppercase">
          TRANSACTION PAYMENT
          <Popup
            inverted
            position="top center"
            content="Payment will be released & settlement will take place when all signatures are complete."
            trigger={
              <Icon
                name="info"
                size="small"
                className="ml-4"
                style={{ marginTop: '-2px' }}
              />
            }
          />
        </Header>

        <PaymentTxStatus payment={payment} />
      </Segment>
    </Grid.Column>
  )
}

const TradeTxsTable = ({ trade }) => {
  return (
    <Grid padded>
      <Grid.Row columns="equal">
        <SignatureColumn trade={trade} role={TradeActors.SELLER} />
        <SignatureColumn trade={trade} role={TradeActors.BUYER} />
      </Grid.Row>
      <Grid.Row columns="equal">
        <SignatureColumn trade={trade} role={TradeActors.BROKER_DEALER} />
        <PaymentColumn trade={trade} />
      </Grid.Row>
    </Grid>
  )
}

export default TradeTxsTable
