import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Button, Form, Input, Modal, Radio } from 'semantic-ui-react'
import _ from 'lodash'
import useForm from '../../util/hooks/form'
import { GET_PROFILE, SUBMIT_VERIFICATION_INFO } from '../../util/API/Apollo'
import { DimmerLoader } from '../index'
import { AssetCodes } from '../../util/Constant'
import { DocumentUpload } from '../upload'

const initialForm = {
  link: '',
  crd: '',
  doc: null,
  asset_code: null,
}
const ProviderVerificationForm = ({
  approvalType,
  onSubmit,
  open,
  onClose,
}) => {
  const { form, input } = useForm('provider-verification', initialForm)
  const [submit, { loading }] = useMutation(SUBMIT_VERIFICATION_INFO, {
    variables: {
      input: {
        approval_type: approvalType,
        proof_crd: form.crd,
        proof_link: form.link,
        document: _.get(form, 'doc.uuid'),
      },
    },
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
    onCompleted: onSubmit,
  })

  return (
    <Modal open={open} onClose={onClose} as={Form} onSubmit={submit}>
      <Modal.Header content="CERTIFICATE DOCUMENT APPROVAL" />
      <DimmerLoader active={loading} />
      <Modal.Content>
        <Form.Group widths="equal">
          <Form.Field>
            <Radio
              label="Individual"
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.provider_proof_individual,
              })}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Firm"
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.provider_proof_institutional,
              })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            required
            control={Input}
            fluid
            label="CRD#"
            placeholder="CRD#"
            {...input('crd')}
          />
          <Form.Field
            required
            control={Input}
            fluid
            label="Status Verification Link"
            placeholder="https://"
            {...input('link')}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            required
            label="Certificate Document"
            control={DocumentUpload}
            asset_code={form.asset_code}
            disabled={!form.asset_code}
            {...input('doc')}
          />
        </Form.Group>
        <Button
          content="Send to approval"
          fluid
          primary
          type="submit"
          disabled={!(form.crd && form.link && form.doc)}
          data-test-id="finish-provider-document"
        />
      </Modal.Content>
    </Modal>
  )
}

export default ProviderVerificationForm
