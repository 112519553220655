import React from 'react'
import { Form, Header, Grid, Label } from 'semantic-ui-react'
import { ImageUpload } from '../../upload'
import { AssetCodes, UserTypeNames } from '../../../util/Constant'
import userPlaceholder from '../../../images/user-placeholder.png'

const ProfilePreview = ({ formHook }) => {
  const { form, field } = formHook

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={6}>
          <Form.Field
            placeholder={userPlaceholder}
            asset_code={AssetCodes.profile_photo}
            control={ImageUpload}
            {...field('photo')}
          />
        </Grid.Column>
        <Grid.Column computer={10}>
          <Header
            as="h1"
            color={form.firstname || form.lastname ? 'black' : 'grey'}
          >
            {form.firstname || form.lastname ? (
              <>
                {form.firstname} {form.lastname}
              </>
            ) : (
              'e.g. John Smith'
            )}
          </Header>
          <Label
            color="purple"
            className="uppercase"
            content={UserTypeNames[form.user_type || form.provider_type]}
          />
          <br />
          <br />
          {!form.photo && (
            <small>
              Please upload a profile picture that will represent you
            </small>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ProfilePreview
