import React from 'react'
import _ from 'lodash'
import { ApprovalStatusMessage } from '../../routes/ProfileDetails/MSGDetails'
import CoolTable from '../CoolTable'
import { FileLink } from '../link'

const MSGApprovalsTable = ({ approvals }) => {
  return (
    <CoolTable
      headers={[{ content: 'Status' }, { content: 'Document' }]}
      data={approvals.map((approval, index) => ({
        key: index,
        cellData: [
          { content: <ApprovalStatusMessage approval={approval} /> },
          {
            content: (
              <FileLink file={_.get(approval, 'verification_data.document')} />
            ),
          },
        ],
      }))}
    />
  )
}

export default MSGApprovalsTable
