import React from 'react'
import classNames from 'classnames'
import { Progress } from 'semantic-ui-react'

const StepWizard = ({ stepsHook }) => {
  const { activeIndex } = stepsHook

  return (
    <div className="step-wizard">
      {stepsHook.steps.map((step, index) => (
        <div
          className={classNames('step', { active: index === activeIndex })}
          key={index}
          onClick={() => index < activeIndex && stepsHook.setActiveStep(step)}
        >
          <div className="step-bar">
            <Progress
              percent={index === activeIndex ? 100 : 0}
              color={index === activeIndex && 'purple'}
              className="mb-0"
              size="tiny"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default StepWizard
