import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Header,
  Segment,
  Transition,
  Icon,
  Divider,
} from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import * as yup from 'yup'
import {
  client,
  REQUEST_RESET_PASSWORD,
  RESEND_ACTIVATION,
} from '../../util/API/Apollo'
import useForm from '../../util/hooks/form'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'
import { useMounted } from '../../util/hooks'
import { DimmerLoader } from '../index'

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
})

const ForgotPassword = ({ initialForm }) => {
  const mounted = useMounted()
  const [sendMessage, setSendMessage] = useState(false)
  const [sendAgain, setSendAgain] = useState(false)
  const { form, validate } = useForm('forgot-password', initialForm, {
    schema,
  })

  const [forgotPassword, forgotPasswordMutation] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      variables: { email: form.email },
      onCompleted: () => {
        setSendMessage(true)
      },
      client,
    }
  )

  const [resendActivation, resendActivationMutation] = useMutation(
    RESEND_ACTIVATION,
    {
      variables: { email: form.email },
      onCompleted: () => {
        setSendMessage(true)
      },
      client,
    }
  )

  const handleSendEmail = async e => {
    e.preventDefault()
    if (sendAgain) {
      await resendActivation()
    } else {
      if (await validate()) await forgotPassword()
    }
  }

  const SendEmailForm = (
    <>
      <Header
        as="h5"
        color="purple"
        textAlign="center"
        className="uppercase"
        content="account recovery"
      />
      <br />
      <br />
      <p className="text-center">
        You can send a password reset link to
        <br />
        <br />
        {form.email}
      </p>
      <br />
      <br />
      <Form onSubmit={e => handleSendEmail(e)}>
        <Form.Field
          fluid
          content="Reset my password"
          color="purple"
          control={Button}
          disabled={!form.email}
        />
      </Form>
    </>
  )

  const SendMessage = (
    <>
      <Header
        as="h5"
        color="purple"
        textAlign="center"
        className="uppercase"
        content="account recovery"
      />
      <br />
      <p className="text-center">
        <Icon size="big" color="green" name="check" />
        <br />
        <br />
        Please check your inbox
        <br />
        for account recovery instructions.
        <br />
        <br />
        <br />
        <small className="text-center">
          If you cannot find your reset email, check your spam/junk folders
        </small>
        <Divider horizontal content="or" />
        <Button
          basic
          className="link px-0"
          content="Request a new one"
          onClick={() => {
            setSendMessage(false)
            setSendAgain(true)
          }}
        />
      </p>
    </>
  )

  return (
    <Transition visible={mounted} animation="fade" duration={500}>
      <div>
        <Segment padded="very">
          <div className="stc-logo">
            <STCLogo />
          </div>
          <DimmerLoader
            active={
              forgotPasswordMutation.loading || resendActivationMutation.loading
            }
          />
          {sendMessage ? SendMessage : SendEmailForm}
        </Segment>
      </div>
    </Transition>
  )
}

ForgotPassword.propTypes = {
  initialForm: PropTypes.shape({
    email: PropTypes.string,
  }),
}

ForgotPassword.defaultProps = {
  initialForm: {
    email: '',
  },
}

export default ForgotPassword
