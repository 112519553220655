import React from 'react'
import { Button, Form, Header, Input } from 'semantic-ui-react'
import * as yup from 'yup'
import useForm from '../../../util/hooks/form'
import { NumberInput, PhoneInput } from '../../input'

const schema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  phonenumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Phone number is invalid',
    }),
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
  share_amount: yup.string().required('Fund Amount is required'),
})

const initialForm = {
  firstname: '',
  lastname: '',
  phonenumber: '',
  email: '',
  share_amount: '',
}

const InvitationForm = ({ onSubmit }) => {
  const { form, field, validate, clear } = useForm('shareholder', initialForm, {
    schema,
  })

  const handleSubmit = async () => {
    if (await validate()) {
      onSubmit(form)
      clear()
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Header as="h2" content="Shareholder details" />
      <br />
      <Form.Group widths="equal">
        <Form.Field
          label="First Name"
          icon="user"
          iconPosition="left"
          fluid
          placeholder="First Name"
          required
          control={Input}
          {...field('firstname')}
        />
        <Form.Field
          label="Last Name"
          icon="user"
          iconPosition="left"
          fluid
          placeholder="Last Name"
          required
          control={Input}
          {...field('lastname')}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          label="Phone"
          icon="phone"
          iconPosition="left"
          fluid
          placeholder="Phone"
          required
          control={PhoneInput}
          {...field('phonenumber')}
        />
        <Form.Field
          label="Email"
          icon="envelope"
          iconPosition="left"
          fluid
          placeholder="Email"
          required
          control={Input}
          {...field('email')}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          label="Fund Amount"
          icon="dollar"
          iconPosition="left"
          fluid
          placeholder="Fund Amount"
          float
          required
          control={NumberInput}
          {...field('share_amount')}
        />
      </Form.Group>
      <Button content="Add Shareholder" primary fluid type="submit" />
    </Form>
  )
}

export default InvitationForm
