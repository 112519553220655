import React from 'react'
import _ from 'lodash'
import * as yup from 'yup'
import { Button, Form, Input, Modal, TextArea } from 'semantic-ui-react'
import { NumberInput } from '../../../components/input'
import useForm from '../../../util/hooks/form'

const schema = yup.object().shape({
  investment_strategy: yup.string().required('Strategy is required'),
  investment_info: yup
    .array()
    .min(1, 'Investment info is required')
    .of(
      yup.object().shape({
        company: yup.string().required('Company is required'),
        type: yup.string().required('Type is required'),
        amount: yup.string().required('Amount is required'),
      })
    ),
})

const emptyInfo = { company: '', type: '', amount: '' }
const InvestmentInfoForm = ({
  value = [emptyInfo],
  onChange = _.noop,
  ...props
}) => {
  const handleChange = (e, { name, value: inputValue, index }) => {
    const newValue = [...value]
    newValue[index] = { ...newValue[index], [name]: inputValue }
    onChange(newValue, { ...props, value: newValue })
  }

  const push = () => {
    const newValue = value ? [...value, { ...emptyInfo }] : [{ ...emptyInfo }]
    onChange(newValue, { ...props, value: newValue })
  }
  const pop = () => {
    const newValue = [...value]
    newValue.pop()
    onChange(newValue, { ...props, value: newValue })
  }

  return (
    <>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Invested Company</label>
        </Form.Field>
        <Form.Field>
          <label>Investment Type</label>
        </Form.Field>
        <Form.Field>
          <label>Invested Amount</label>
        </Form.Field>
      </Form.Group>
      {value &&
        value.map(({ company, type, amount }, index) => (
          <Form.Group key={index} widths="equal">
            <Form.Field
              required
              control={Input}
              fluid
              placeholder="Invested Company"
              icon="building"
              iconPosition="left"
              value={company}
              onChange={handleChange}
              name="company"
              index={index}
            />
            <Form.Field
              required
              control={Input}
              fluid
              placeholder="Investment Type"
              icon="pencil"
              iconPosition="left"
              value={type}
              onChange={handleChange}
              name="type"
              index={index}
            />
            <Form.Field
              required
              control={NumberInput}
              fluid
              icon="dollar"
              iconPosition="left"
              placeholder="Invested Amount"
              value={amount}
              onChange={handleChange}
              name="amount"
              index={index}
            />
          </Form.Group>
        ))}
      <Button.Group>
        <Button type="button" icon="plus" onClick={push} />
        <Button type="button" icon="minus" onClick={pop} />
      </Button.Group>
    </>
  )
}

const StrategyDetailsForm = ({ open, onClose, value, onChange, ...props }) => {
  const { field, form, validate, clear } = useForm(
    'contract-strategy-details',
    value,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newValue = _.clone(form)
      onChange(newValue, { ...props, value: newValue })
      onClose()
    }
  }
  const handleClose = () => {
    clear()
    onClose()
  }

  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={handleClose}>
      <Modal.Header content="INVESTMENT INFORMATION" />
      <Modal.Content>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Field
            required
            label="Investment Strategy"
            placeholder="Investment Strategy"
            control={TextArea}
            {...field('investment_strategy')}
          />
          <InvestmentInfoForm {...field('investment_info')} />
          <br />
          <br />
          <Button
            type="submit"
            primary
            fluid
            content="ADD"
            data-test-id="finish-add-erp-details"
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default StrategyDetailsForm
