import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL',
    key: 'total_amount',
  },
  {
    title: 'TRADED',
    key: 'traded_amount',
  },
  {
    title: 'LISTINGS',
    key: 'listed_amount',
  },
  {
    title: 'SHAREHOLDERS',
    key: 'total_shareholders',
  },
]

const SecuritiesWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
  horizontal,
  className,
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      dataSchema={dataSchema}
      title="SECURITIES SUMMARY"
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
      horizontal={horizontal}
      className={className}
    />
  )
}

export default SecuritiesWidget
