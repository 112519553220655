import React, { useState } from 'react'
import { Grid, Segment, Form, Input, Header } from 'semantic-ui-react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import {
  APIPagination,
  ContentLoader,
  CSBreadcrumb,
  IfUserIs,
  SingleGrid,
} from '../../components'
import { NoData } from '../../components/NotFound'
import { usePage, useSort, useDebounceQuery } from '../../util/hooks'
import {
  client,
  GET_RESERVED_TOKENS,
  GET_TOKENS,
  SEARCH_TOKENS,
} from '../../util/API/Apollo'
import TokensTable from '../../components/Token/TokensTable'
import { TokenStatus } from '../../util/Constant'

const COUNT = 10

const ReservedTokens = () => {
  const { data } = useQuery(GET_RESERVED_TOKENS, { client })
  const tokens = _.get(data, 'tokens.data', [])
  if (tokens.length === 0) return null
  return (
    <Segment padded="very">
      <Header as="h2" content="RESERVED DIGITAL SECURITIES" />
      <strong>
        <p>
          Digital Securities are mirrored images of your underlying physical or
          book-entry assets. After claiming these Digital Securities, you will
          be able to list them on the Marketplace.
        </p>
        <br />
        <p>
          Pursuant to STC enabling Transferability, you will be able to execute
          a trade and sell them to attain liquidity over the STC Digital
          Securities Platform.
        </p>
      </strong>
      <TokensTable tokens={tokens} />
    </Segment>
  )
}

const Tokens = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const { orderBy } = useSort()

  const { loading, data } = useQuery(GET_TOKENS, {
    variables: {
      page,
      orderBy,
      count: COUNT,
      only: [TokenStatus.AVAILABLE, TokenStatus.PENDING, TokenStatus.LOCKED],
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const [input, setInput] = useState('')
  const showSearch = input.length >= 2
  const { loading: searchLoading, data: searchData } = useDebounceQuery(
    SEARCH_TOKENS,
    {
      variables: { search: input },
      skip: !showSearch,
      client,
    }
  )

  const paginatorInfo = _.get(data, 'tokens.paginatorInfo')
  const tokens = _.get(data, 'tokens.data', [])
  const searchTokens = _.get(searchData, 'searchTokens.data')

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="My Digital Securities" />

      <SingleGrid padded>
        <IfUserIs customer>
          <ReservedTokens />
        </IfUserIs>
        {tokens.length > 0 ? (
          <>
            <Grid className="py-15">
              <Grid.Row>
                <Grid.Column>
                  <Segment padded="very">
                    <Form>
                      <Form.Field
                        control={Input}
                        value={input}
                        loading={searchLoading}
                        onChange={(e, { value }) => setInput(value)}
                        placeholder="Search Digital Securities"
                        icon="search"
                      />
                    </Form>
                    <br />
                    <TokensTable
                      tokens={searchTokens || tokens}
                      loading={loading}
                      footer={
                        !searchTokens && (
                          <APIPagination
                            paginatorInfo={paginatorInfo}
                            handleChange={setPage}
                            count={COUNT}
                          />
                        )
                      }
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ) : (
          <>
            <NoData text="THERE ARE NO DIGITAL SECURITIES YET" fluid />
          </>
        )}
      </SingleGrid>
    </>
  )
}

export default Tokens
