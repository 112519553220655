import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Form,
  Header,
  Input,
  Segment,
  Transition,
  Message,
} from 'semantic-ui-react'
import { useLazyQuery } from '@apollo/react-hooks'
import ForgotPassword from './ForgotPassword'
import { client, IS_ACTIVATED } from '../../util/API/Apollo'
import useForm from '../../util/hooks/form'
import { UserRegistrationStatus } from '../../util/Constant'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'
import { useMounted } from '../../util/hooks'
import { DimmerLoader } from '../index'
import link from '../link'

const initialForm = {
  email: '',
}

const CannotAccess = ({ setCurrentForm }) => {
  const [emailNotFound, setEmailNotFound] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const mounted = useMounted()
  const history = useHistory()
  const { form, field } = useForm('check-email-status', initialForm)

  const [isActivated, { loading, data }] = useLazyQuery(IS_ACTIVATED, {
    variables: form,
    client,
  })

  //change form by email status
  useEffect(() => {
    if (data) {
      switch (data.isActivated) {
        case UserRegistrationStatus.ACTIVATED:
          return setForgotPassword(true)
        case UserRegistrationStatus.NOT_ACTIVATED:
          return history.push({
            pathname: link.activate,
            state: { email: form.email },
          })
        case UserRegistrationStatus.NOT_FOUND:
          return setEmailNotFound(true)
        default:
          return
      }
    }
  }, [data, form.email, history])

  const EmailNotFound = (
    <>
      <Message error>
        <Header
          sub
          icon="times"
          content={`Email address ${form.email} not found`}
        />
      </Message>
      <Button
        fluid
        type="submit"
        content="create an account"
        color="purple"
        onClick={() => setCurrentForm('register')}
      />
    </>
  )

  const CannotAccessForm = (
    <Form onSubmit={isActivated}>
      <Form.Field
        type="email"
        label="Email address"
        placeholder="example@digitalstc.com"
        icon="envelope"
        iconPosition="left"
        required
        control={Input}
        {...field('email')}
      />
      <Form.Field
        fluid
        type="submit"
        content="continue"
        color="purple"
        control={Button}
        disabled={!form.email}
      />
    </Form>
  )

  if (forgotPassword) return <ForgotPassword initialForm={form} />

  return (
    <Transition visible={mounted} animation="fade" duration={500}>
      <div>
        <Segment padded="very">
          <DimmerLoader active={loading} />
          <div className="stc-logo">
            <STCLogo />
          </div>
          <Header
            as="h5"
            color="purple"
            textAlign="center"
            className="uppercase"
            content="Can’t access your account?"
          />
          {emailNotFound ? EmailNotFound : CannotAccessForm}
        </Segment>
      </div>
    </Transition>
  )
}

export default CannotAccess
