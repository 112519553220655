import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL',
    key: 'count',
  },
  {
    title: 'PENDING',
    key: 'pending_count',
  },
  {
    title: 'COMPLETED',
    key: 'completed_count',
  },
  {
    title: 'TRADING VOLUME',
    key: 'traded_amount',
  },
  {
    title: 'TRADING VALUE',
    key: 'traded_volume',
    isPrice: true,
  },
]

const TradesWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
  className,
  horizontal,
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      dataSchema={dataSchema}
      title="TRADES SUMMARY"
      horizontal={horizontal}
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
      className={className}
    />
  )
}

export default TradesWidget
