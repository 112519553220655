import React, { useCallback, useState, useMemo } from 'react'
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Transition,
  Form,
} from 'semantic-ui-react'
import { NumberInput } from '../../components/input'
import util from '../../util'
import { ContractType, Transferability, UserType } from '../../util/Constant'

const FilterOptions = ({ formHook, ...props }) => {
  const { field, isDirty, clear } = formHook

  return (
    <Form>
      <Grid divided padded {...props}>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h4" content="Ownership type" />
            <Form.Field
              label="Individuals"
              className="mb-5"
              control={Checkbox}
              {...field(`seller.${UserType.INDIVIDUAL}`, { type: 'checkbox' })}
            />
            <Form.Field
              label="Institutionals"
              className="mb-5"
              control={Checkbox}
              {...field(`seller.${UserType.INSTITUTIONAL}`, {
                type: 'checkbox',
              })}
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4" content="Securities type" />
            <Form.Field
              label="Fund of Funds"
              className="mb-5"
              control={Checkbox}
              {...field(`contract.${ContractType.FOF}`, { type: 'checkbox' })}
            />
            <Form.Field
              label="LP Shares"
              className="mb-5"
              control={Checkbox}
              {...field(`contract.${ContractType.VC}`, { type: 'checkbox' })}
            />
            <Form.Field
              label="Equity"
              className="mb-5"
              control={Checkbox}
              {...field(`contract.${ContractType.EQUITY}`, {
                type: 'checkbox',
              })}
            />
            <Form.Field
              label="Convertible Note"
              className="mb-5"
              control={Checkbox}
              {...field(`contract.${ContractType.CONVERTIBLE_NOTE}`, {
                type: 'checkbox',
              })}
            />
            <Form.Field
              label="Safe"
              className="mb-5"
              control={Checkbox}
              {...field(`contract.${ContractType.SAFE}`, {
                type: 'checkbox',
              })}
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4" content="Ask prices" />
            <Form.Field
              icon="dollar"
              iconPosition="left"
              label="Min. Price"
              placeholder="Min. Price"
              className="mb-5"
              control={NumberInput}
              {...field('price.min')}
            />
            <Form.Field
              icon="dollar"
              iconPosition="left"
              label="Max. Price"
              placeholder="Max. Price"
              className="mb-5"
              control={NumberInput}
              {...field('price.max')}
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4" content="Transferability status" />
            <Form.Field
              label="All"
              className="mb-5"
              control={Checkbox}
              {...field('transferable', { type: 'radio', value: '' })}
            />
            <Form.Field
              label="Transferable without restriction"
              className="mb-5"
              control={Checkbox}
              {...field('transferable', {
                type: 'radio',
                value: Transferability.FULL,
              })}
            />
            <Form.Field
              label="Transferable with restriction"
              className="mb-5"
              control={Checkbox}
              {...field('transferable', {
                type: 'radio',
                value: Transferability.LIMITED,
              })}
            />
            <Form.Field
              label="Transferability pending"
              className="mb-5"
              control={Checkbox}
              {...field('transferable', {
                type: 'radio',
                value: Transferability.NOT_ALLOWED,
              })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {isDirty && (
              <Button
                floated="right"
                size="small"
                color="red"
                content="Clear"
                onClick={clear}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

const Labels = ({ labels }) => (
  <Grid columns={1}>
    <Grid.Row>
      <Grid.Column>
        <span className="mr-tiny">Show only:</span>
        {labels.map(({ text, func }) => (
          <Label key={text} as="a" onClick={func}>
            <Icon name="times" /> {text}
          </Label>
        ))}
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

const getLabels = formHook => {
  const labels = []
  const { form, set } = formHook
  const { seller, contract, price, favorite, transferable } = form

  if (form.seller[UserType.INDIVIDUAL])
    labels.push({
      text: 'Individuals',
      func: () => set(`seller.${UserType.INDIVIDUAL}`, false),
    })
  if (seller[UserType.INSTITUTIONAL])
    labels.push({
      text: 'Institutionals',
      func: () => set(`seller.${UserType.INSTITUTIONAL}`, false),
    })
  if (contract[ContractType.FOF])
    labels.push({
      text: 'Fund of Funds',
      func: () => set(`contract.${ContractType.FOF}`, false),
    })
  if (contract[ContractType.VC])
    labels.push({
      text: 'LP Shares',
      func: () => set(`contract.${ContractType.VC}`, false),
    })
  if (contract[ContractType.EQUITY])
    labels.push({
      text: 'Equity',
      func: () => set(`contract.${ContractType.EQUITY}`, false),
    })
  if (contract[ContractType.CONVERTIBLE_NOTE])
    labels.push({
      text: 'Convertible Note',
      func: () => set(`contract.${ContractType.CONVERTIBLE_NOTE}`, false),
    })
  if (contract[ContractType.SAFE])
    labels.push({
      text: 'Safe',
      func: () => set(`contract.${ContractType.SAFE}`, false),
    })

  if (price.min !== '' || price.max !== '') {
    const minPrice =
      price.min === '' ? '$0' : `$${util.formatNumber(price.min)}`
    const maxPrice = price.max === '' ? '∞' : `$${util.formatNumber(price.max)}`
    labels.push({
      text: `${minPrice} - ${maxPrice}`,
      func: () => {
        set('price', { min: '', max: '' })
      },
    })
  }

  if (favorite)
    labels.push({
      text: 'My Favorites',
      func: () => set('favorite', false),
    })
  if (transferable === Transferability.LIMITED) {
    labels.push({
      text: 'Transferable with restriction',
      func: () => set('transferable', ''),
    })
  }
  if (transferable === Transferability.FULL) {
    labels.push({
      text: 'Transferable Without Restriction',
      func: () => set('transferable', ''),
    })
  }
  if (transferable === Transferability.NOT_ALLOWED) {
    labels.push({
      text: 'Pending',
      func: () => set('transferable', ''),
    })
  }

  return labels
}

const Filter = ({ formHook, ...props }) => {
  const [visible, setVisible] = useState(true)
  const toggleFilter = useCallback(() => setVisible(!visible), [visible])
  const labels = useMemo(() => getLabels(formHook), [formHook])

  const { field } = formHook

  return (
    <div {...props}>
      <div className="flex-spread">
        <Input
          fluid
          placeholder="Search"
          icon="search"
          iconPosition="left"
          className="mr-10"
          style={{ flexGrow: 1 }}
          {...field('search')}
        />
        <Button
          primary
          content={visible ? 'Hide filter' : 'Show filter'}
          onClick={toggleFilter}
        />
      </div>

      <Transition visible={visible} animation="fade" duration={200}>
        <FilterOptions formHook={formHook} />
      </Transition>
      {labels.length > 0 && <Labels labels={labels} />}
    </div>
  )
}

export default Filter
