import React from 'react'
import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react'
import { ContractType } from '../../util/Constant'
import createAssetImage from '../../images/create_asset.svg'

const contractTypes = [
  {
    value: ContractType.FOF,
    title: 'Fund of Funds',
    description:
      'If you are a representative of a sponsor company or a fund holding multiple funds, ' +
      'who wants to digitize the LP shares of each fund under management please select this option. ' +
      'This option allows you monitor all the funds that you digitize on the same screen.',
  },
  {
    value: ContractType.VC,
    title: 'LP Shares',
    description:
      'If you are a legal representative of a Venture Capital or a Private Equity, who wants to ' +
      'tokenize the portfolio under management please select this option.',
  },
  {
    value: ContractType.EQUITY,
    title: 'Equity',
    description:
      'If you hold common or preferred shares of a corporation please select this option and then ' +
      'upload a copy of the stock certificate that you want to digitize.',
  },
  {
    value: ContractType.CONVERTIBLE_NOTE,
    title: 'Convertible Note',
    description:
      'If you hold a standard convertible note other than SAFE please select this option and then ' +
      'upload a copy of your convertible note.',
  },
  {
    value: ContractType.SAFE,
    title: 'Safe / Simple Agreement for Future Equity',
    description:
      'If you hold a SAFE that you want to digitize and sell, please select this option and then ' +
      'upload a copy of your SAFE',
  },
]

const TypeStepInfo = () => (
  <>
    <div className="text-center">
      <Header
        as="h2"
        color="purple"
        textAlign="left"
        content="SELECT ASSET TYPE"
      />
      <Image size="large" centered src={createAssetImage} />
    </div>
    <small className="text-justify">
      When this step is completed, the security you introduce will initially be
      referred to as an “Asset” in our platform; and you can view it under
      “Asset Manager” title on the sidebar.
      <br />
      <br />
      An “Asset” in our Platform refers to the mirrored image of the original
      physical or book-entry security itself with no ownership information (i.e.
      has not been assigned to any shareholder)
      <br />
      <br />
      When you assign shareholder(s) to the “Asset”, the Asset will be converted
      to a Digital Security which is a fractionalized & mirrored image of the
      original security that can be sold on the Marketplace to attain liquidity.
      Each Digital Security represents a face value of $1 of the respective
      original security.
      <br />
      <br />
      You can view your Digital Securities, under “My Digital Securities”, under
      “My Portfolio” title on the sidebar.
    </small>
  </>
)

const TypeStep = ({ formHook, stepsHook }) => {
  return (
    <Segment padded="very">
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <TypeStepInfo />
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            {contractTypes.map(({ value, title, description }) => {
              const handleClick = () => {
                formHook.set('contract_type', value)
                stepsHook.nextStep()
              }
              return (
                <Segment
                  key={value}
                  name={value}
                  className="clickable"
                  onClick={handleClick}
                  padded
                >
                  <Header as="h3" className="uppercase" content={title} />
                  <p>{description}</p>
                  <Button
                    primary
                    size="small"
                    content="select"
                    type="button"
                    name={value}
                    data-test-id={`select-contract-type-${value}`}
                    onClick={handleClick}
                  />
                </Segment>
              )
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default TypeStep
