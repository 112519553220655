import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Grid, Button, Segment, Form, Input } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  ContentLoader,
  SingleGrid,
  IfUserIs,
} from '../components'
import link from '../components/link'
import { AccountsTable } from '../components/Account'
import { NoData } from '../components/NotFound'
import { CustomersWidget } from '../components/Widget'
import { usePage, useDebounceQuery, useDashboardData } from '../util/hooks'
import {
  GET_PROVIDER_CUSTOMERS,
  SEARCH_CUSTOMER,
  REQUEST_DATA_EXPORT,
  client,
} from '../util/API/Apollo'
import { DataExportType } from '../util/Constant'
import addCustomerImage from '../images/add_customer.svg'

const COUNT = 10

const Customers = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const [input, setInput] = useState('')
  const noSearch = input.length < 2

  const { customers: customersInfo } = useDashboardData()

  const providerCustomers = useQuery(GET_PROVIDER_CUSTOMERS, {
    variables: {
      count: COUNT,
      page,
      orderBy: [{ field: 'created_at', order: 'DESC' }],
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const customers = _.get(providerCustomers, 'data.providerCustomers.data', [])
  const paginatorInfo = _.get(
    providerCustomers,
    'data.providerCustomers.paginatorInfo'
  )

  const providerCustomerSearch = useDebounceQuery(SEARCH_CUSTOMER, {
    variables: { fullName: input },
    skip: noSearch,
    client,
  })

  const customerSearch = _.get(
    providerCustomerSearch,
    'data.searchCustomer.data'
  )

  const [exportData, exportDataMutation] = useMutation(REQUEST_DATA_EXPORT, {
    variables: { export_type: DataExportType.CUSTOMERS },
    client,
  })

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="Customers" />
      {customers.length > 0 ? (
        <SingleGrid padded>
          <Grid className="py-15">
            <Grid.Row className="py-0">
              <Grid.Column>
                <IfUserIs ta>
                  <Button
                    primary
                    floated="right"
                    as={Link}
                    to={link.newCustomer}
                    content="Add new customer"
                  />
                </IfUserIs>

                <Button
                  floated="right"
                  content="export customers"
                  onClick={exportData}
                  loading={exportDataMutation.loading}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CustomersWidget data={customersInfo} divided />
                <Segment padded="very">
                  <Form>
                    <Form.Field
                      fluid
                      control={Input}
                      value={input}
                      loading={providerCustomerSearch.loading}
                      onChange={(e, { value }) => setInput(value)}
                      placeholder="Search Customer"
                      icon="search"
                    />
                  </Form>
                  <br />
                  <AccountsTable
                    accounts={customerSearch || customers}
                    loading={
                      providerCustomerSearch.loading ||
                      providerCustomers.loading
                    }
                    type="customers"
                    pagination={
                      !customerSearch && (
                        <APIPagination
                          paginatorInfo={paginatorInfo}
                          handleChange={setPage}
                          count={COUNT}
                        />
                      )
                    }
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SingleGrid>
      ) : (
        <NoData
          image={addCustomerImage}
          text="there are no customers yet"
          fluid
        >
          <Button
            primary
            as={Link}
            to={link.newCustomer}
            content="Add new customer"
          />
        </NoData>
      )}
    </>
  )
}

export default Customers
