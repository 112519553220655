import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { Divider, Segment } from 'semantic-ui-react'
import {
  CSBreadcrumb,
  IfUserIs,
  PageLoader,
  SingleGrid,
} from '../../components'
import AccountDetails from './AccountDetails'
import CompanyDetails from './CompanyDetails'
import BankDetails from './BankDetails'
import AccountVerification from './AccountVerification'
import MSGDetails from './MSGDetails'
import ProvidersDetails from './ProvidersDetails'
import { client, GET_PROFILE, UPDATE_PROFILE } from '../../util/API/Apollo'
import {
  mapFormToInput,
  mapProfileToForm,
} from '../../components/Profile/ProfileForm/util'
import useForm from '../../util/hooks/form'
import ProviderVerificationInfo from '../../components/Verification/ProviderVerificationInfo'

const ProfileDetails = () => {
  const { data, loading } = useQuery(GET_PROFILE, { client })
  const user = _.get(data, 'me', {})
  const defaultForm = useMemo(() => mapProfileToForm(user), [user])
  const formHook = useForm('profile', defaultForm)

  const [update, updateMutation] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [{ query: GET_PROFILE }],
    awaitRefetchQueries: true,
    client,
  })

  const hasCompany = _.get(user, 'profile.company')

  const handleUpdate = async () => {
    const input = mapFormToInput(formHook.form)
    await update({ variables: { input } })
  }

  if (loading || updateMutation.loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb title="Profile" />

      <SingleGrid padded>
        <Segment padded="very">
          <AccountDetails
            user={user}
            formHook={formHook}
            handleUpdate={handleUpdate}
          />
          {hasCompany && (
            <>
              <Divider />
              <CompanyDetails
                profile={user.profile}
                formHook={formHook}
                handleUpdate={handleUpdate}
              />
            </>
          )}
          <IfUserIs customer>
            <Divider />
            <BankDetails
              user={user}
              formHook={formHook}
              handleUpdate={handleUpdate}
            />
            {!user.is_verified && (
              <>
                <Divider />
                <AccountVerification user={user} />
              </>
            )}
            <Divider />
            <MSGDetails user={user} />
            <Divider />
            <ProvidersDetails user={user} />
          </IfUserIs>
          <IfUserIs provider>
            <Divider />
            <ProviderVerificationInfo user={user} isProfile />
          </IfUserIs>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default ProfileDetails
