import React from 'react'
import _ from 'lodash'
import { useModal } from '../../../util/hooks'
import FinancialDataTabs from '../FinancialDataTabs'
import FinancialDataModal from './FinancialDataModal'
import FinancialDocumentModal from './FinancialDocumentModal'
import { Button, Container } from 'semantic-ui-react'

/**
 * @param dataProps {{ value, onChange }}
 * @param documentProps {{ value, onChange }}
 * @param props
 * @returns {*}
 * @constructor
 */
const FinancialDataForm = ({ dataProps, documentProps, ...props }) => {
  const financialDataModal = useModal()
  const financialDocModal = useModal()

  const handleDataRemove = data => {
    const newValue = _.clone(dataProps.value).filter(d => d !== data)
    dataProps.onChange(newValue, { ...dataProps, value: newValue })
  }

  const handleDocumentRemove = data => {
    const newValue = _.clone(documentProps.value).filter(d => d !== data)
    documentProps.onChange(newValue, { ...documentProps, value: newValue })
  }

  return (
    <>
      <Container textAlign="center" className="mb-15">
        <Button
          size="small"
          content="add data"
          onClick={financialDataModal.show}
          data-test-id="add-financial-data"
        />
        <Button
          size="small"
          content="add document"
          onClick={financialDocModal.show}
          data-test-id="add-financial-document"
        />
      </Container>

      <FinancialDataTabs
        data={dataProps.value}
        onDataRemove={handleDataRemove}
        documents={documentProps.value}
        onDocumentRemove={handleDocumentRemove}
        {...props}
      />
      <FinancialDataModal
        {...financialDataModal.props}
        {...dataProps}
        {...props}
      />
      <FinancialDocumentModal
        {...financialDocModal.props}
        {...documentProps}
        {...props}
      />
    </>
  )
}

export default FinancialDataForm
