import React from 'react'
import {
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Popup,
  Table,
  TextArea,
} from 'semantic-ui-react'
import _ from 'lodash'
import {
  ContactDistributionFrequency,
  ContractShareType,
  ContractType,
} from '../../../util/Constant'
import { DateField, NumberInput } from '../../../components/input'
import { DateInfo, NumberFormat, PhoneNumber } from '../../../components'
import util from '../../../util'
import { Email, WebsiteLink } from '../../../components/link'

const shareTypes = Object.keys(ContractShareType).map(key => ({
  key,
  value: key,
  text: ContractShareType[key],
}))

const frequencyTypes = Object.keys(ContactDistributionFrequency).map(key => ({
  key,
  value: key,
  text: ContactDistributionFrequency[key],
}))

const currencies = ['USD', 'ETH', 'USDC', 'TETHER'].map(c => ({
  key: c,
  text: c,
  value: c,
}))

const ERPInfo = ({ formHook }) => {
  const { form } = formHook
  return (
    <>
      <Header as="h3" content="EARLY REDEMPTION PENALTY" />
      <Header as="h4" content="Lock-period" />
      <div>{_.get(form, 'erpDetails.lockup_period') || '-'}</div>
      <Divider />
      <Header as="h4" content="From date" />
      <DateInfo
        api
        str={_.get(form, 'erpDetails.early_redemption_from_date')}
      />
      <Divider />
      <Header as="h4" content="To date" />
      <DateInfo api str={_.get(form, 'erpDetails.early_redemption_to_date')} />
      <Divider />
      <Header as="h4" content="Early Redemption Penalty" />
      <>
        <NumberFormat
          amount={_.get(form, 'erpDetails.early_redemption_penalty') || '-'}
        />
        <Icon name="percent" />
      </>
      <Divider />
    </>
  )
}

const OptionalDetailsInfo = ({ formHook }) => {
  const { form } = formHook
  return (
    <>
      <Header as="h3" content="OPTIONAL DETAILS" />
      <Header as="h4" content="Phone number" />
      <PhoneNumber value={_.get(form, 'optionalDetails.phonenumber')} />
      <Divider />
      <Header as="h4" content="Email address" />
      <Email value={_.get(form, 'optionalDetails.email')} />
      <Divider />
      <Header as="h4" content="Web address" />
      <WebsiteLink href={_.get(form, 'optionalDetails.url_web')} />
      <Divider />
      <Header as="h3" content="Contact person" />
      <Header as="h4" content="Contact person name" />
      <div>
        {_.get(form, 'optionalDetails.contact_firstname')}{' '}
        {_.get(form, 'optionalDetails.contact_lastname')}
      </div>
      <Divider />
      <Header as="h4" content="Contact person phone number" />
      <PhoneNumber value={_.get(form, 'optionalDetails.contact_phonenumber')} />
      <Divider />
      <Header as="h4" content="Contact person email address" />
      <Email value={_.get(form, 'optionalDetails.contact_email')} />
      <Divider />
    </>
  )
}

const StrategyInfo = ({ formHook }) => {
  const { form } = formHook
  const investmentInfo = _.get(form, 'strategyDetails.investment_info', [])
  return (
    <>
      <Header as="h3" content="INVESTMENT STRATEGY" />
      <Header as="h4" content="Investment Strategy" />
      <div>{_.get(form, 'strategyDetails.investment_strategy') || '-'}</div>
      <Divider />
      {investmentInfo.length > 0 && (
        <Table basic="very" padded="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="COMPANY" />
              <Table.HeaderCell content="TYPE" />
              <Table.HeaderCell content="AMOUNT" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {investmentInfo.map(({ company, type, amount }, index) => (
              <Table.Row key={index}>
                <Table.Cell content={company} />
                <Table.Cell content={type} />
                <Table.Cell content={amount} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  )
}

const DetailsComplexForm = ({ formHook }) => {
  const { form, field } = formHook
  const type = form.contract_type

  return (
    <>
      <Form widths="equal">
        {type === ContractType.FOF && (
          <>
            <Form.Field
              label="Fund name"
              fluid
              placeholder="Fund name"
              icon="pencil"
              iconPosition="left"
              required
              control={Input}
              {...field('details.fund_name')}
            />
            <Popup
              inverted
              content="Offering closing date"
              trigger={
                <DateField
                  label="Issuance date"
                  past
                  placeholder="Issuance date"
                  iconPosition="left"
                  required
                  {...field('agreement_date')}
                />
              }
            />
            <Popup
              inverted
              content="Total offering amount"
              trigger={
                <Form.Field
                  label="Amount"
                  fluid
                  icon="dollar"
                  iconPosition="left"
                  placeholder="Amount"
                  required
                  control={NumberInput}
                  {...field('details.purchase_amount')}
                />
              }
            />
            <Form.Field
              label="Share type"
              placeholder="Share type"
              fluid
              selection
              options={shareTypes}
              required
              control={Dropdown}
              {...field('details.share_type')}
            />
            <Popup
              inverted
              content="Share offering price"
              trigger={
                <Form.Field
                  label="Nominal value of share (Per unit/Share)"
                  fluid
                  icon="dollar"
                  iconPosition="left"
                  placeholder="Nominal value of share"
                  float
                  required
                  control={NumberInput}
                  {...field('details.nominal_value_per_share')}
                />
              }
            />
            <Form.Field
              label="Distribution per annum"
              fluid
              placeholder="Distribution per annum"
              icon="percent"
              iconPosition="left"
              float
              control={NumberInput}
              {...field('details.distribution_per_annum')}
            />
            <Form.Field
              label="Distribution currency"
              placeholder="Distribution Currency"
              fluid
              selection
              options={currencies}
              control={Dropdown}
              {...field('details.distribution_currency')}
            />
            <Form.Field
              label="Distribution frequency"
              placeholder="Distribution frequency"
              fluid
              selection
              options={frequencyTypes}
              control={Dropdown}
              {...field('details.distribution_frequency')}
            />
          </>
        )}
        {type === ContractType.VC && (
          <>
            <DateField
              label="Issuance date"
              past
              placeholder="Issuance date"
              iconPosition="left"
              required
              {...field('agreement_date')}
            />
            <Form.Field
              label="Fund amount"
              fluid
              icon="dollar"
              iconPosition="left"
              placeholder="Fund amount"
              required
              control={NumberInput}
              {...field('details.total_committed_capital')}
            />
            <Form.Field
              label="Investment period"
              fluid
              placeholder="Investment Period"
              icon="pencil"
              iconPosition="left"
              control={Input}
              {...field('details.investment_period')}
            />
            <DateField
              label="Initial closing date"
              placeholder="Initial closing date"
              iconPosition="left"
              {...field('details.initial_closing_date')}
            />
            <DateField
              label="Final closing date"
              placeholder="Final closing date"
              iconPosition="left"
              {...field('details.final_closing_date')}
            />
            <Form.Field
              label="Hurdle rate"
              fluid
              placeholder="Hurdle rate"
              icon="percent"
              iconPosition="left"
              float
              control={NumberInput}
              {...field('details.hurdle_rate')}
            />
            <Form.Field
              label="As of"
              fluid
              placeholder="As of"
              icon="pencil"
              iconPosition="left"
              control={Input}
              {...field('details.as_of')}
            />
            <Form.Field
              label="IRR"
              fluid
              placeholder="IRR"
              icon="pencil"
              iconPosition="left"
              control={Input}
              {...field('details.irr')}
            />
            <Form.Field
              label="Comments"
              placeholder="Comments"
              control={TextArea}
              {...field('details.comments')}
            />
          </>
        )}

        {!util.isEmptyObject(form.erpDetails) && (
          <ERPInfo formHook={formHook} />
        )}
        {!util.isEmptyObject(form.optionalDetails) && (
          <OptionalDetailsInfo formHook={formHook} />
        )}
        {form.strategyDetails.investment_strategy && (
          <StrategyInfo formHook={formHook} />
        )}
      </Form>
    </>
  )
}

export default DetailsComplexForm
