import * as yup from 'yup'
import moment from 'moment'

export const profileDetailsSchema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  date_of_birth: yup
    .string()
    .label('Birthday')
    .required('Birthday is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      excludeEmptyString: true,
      message: 'Birthday is invalid',
    })
    .test('18-plus', 'The age must be greater than 18', value => {
      const m = moment(value, 'YYYY-MM-DD')
      return m.isValid() && m.isBefore(moment().subtract(18, 'years'))
    }),
  tax_id: yup
    .string()
    .required('Tax ID number is required')
    .matches(/^\d{9}$/, {
      excludeEmptyString: true,
      message: 'Tax ID number is invalid',
    }),
})

export const getProfileContactDetailsSchema = withEmail => {
  const shape = {
    email_alt: yup.string().email('Email is invalid'),
    phonenumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^\d{7,10}$/, {
        excludeEmptyString: true,
        message: 'Phone number is invalid',
      }),
    phonenumber_alt: yup.string().matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Alternative phone number is invalid',
    }),
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    zipcode: yup
      .string()
      .required('Zipcode is required')
      .matches(/^\d{5,6}$/, {
        excludeEmptyString: true,
        message: 'Zipcode is invalid',
      }),
    country: yup
      .string()
      .oneOf(['US', 'CA', 'SG'])
      .required('Country is required'),
  }
  if (withEmail) {
    shape.email = yup
      .string()
      .required('Email is required')
      .email('Email is invalid')
      .available()
  }
  return yup
    .object()
    .shape(shape)
    .test(
      'phonenumber_alt',
      'Alternative phone number should be different from your primary phone number',
      value => value.phonenumber !== value.phonenumber_alt
    )
    .test(
      'email_alt',
      'Alternative email address should be different than your primary email address',
      value => value.email !== value.email_alt
    )
    .test(
      'state',
      'State is required',
      value => value.country === 'SG' || value.state
    )
}

export const profileCompanySchema = yup.object().shape({
  company: yup
    .object()
    .nullable()
    .required('Company is required'),
  company_position: yup.string().required('Position is required'),
  letter_of_authorization: yup
    .object()
    .nullable()
    .required('Letter of authorization is required'),
})

export const bankInfoSchema = yup.object().shape({
  bank_info: yup.object().shape({
    bank_name: yup.string().required('Bank name is required'),
    account_number: yup.string().required('Account number is required'),
    rtn_aba: yup.string().required('RTN / ABA is required'),
    swift_bic: yup.string().required('SWIFT / BIC is required'),
  }),
})
