import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import {
  AgeInfo,
  OfferAmountLabel,
  OfferPriceLabel,
  PPDSLabel,
} from '../../components'
import link, { UserLink } from '../link'

const OfferInfoTable = ({ offers }) => {
  return (
    <Table basic="very" fixed padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="OFFER ID" />
          <Table.HeaderCell content="OFFER BY" />
          <Table.HeaderCell content="OFFER AMOUNT" />
          <Table.HeaderCell content="OFFER PRICE" />
          <Table.HeaderCell content="PPDS" />
          <Table.HeaderCell content="AGE" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map(offer => {
          const { hashid, owner, created_at, token_amount, token_price } = offer

          return (
            <Table.Row key={hashid}>
              <Table.Cell>
                <Link to={link.offer(offer)}>{hashid}</Link>
              </Table.Cell>
              <Table.Cell>
                <UserLink user={owner} />
              </Table.Cell>
              <Table.Cell>
                <OfferAmountLabel amount={token_amount} />
              </Table.Cell>
              <Table.Cell>
                <OfferPriceLabel price={token_price} />
              </Table.Cell>
              <Table.Cell>
                <PPDSLabel
                  data={{ amount: token_amount, price: token_price }}
                />
              </Table.Cell>
              <Table.Cell>
                <AgeInfo iso str={created_at} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default OfferInfoTable
