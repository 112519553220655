import React from 'react'
import { Divider, Header } from 'semantic-ui-react'
import { PhoneNumber, TaxIdFormat } from '../index'
import util from '../../util'
import { Email } from '../link'

const ProfileCommonDetails = ({ user }) => {
  return (
    <>
      <Header as="h4" content="Email address" />
      <Email value={user.email} />
      <Divider />
      <Header as="h4" content="Alternative Email Address" />
      <Email value={user.profile.email_alt} />
      <Divider />
      <Header as="h4" content="Phone Number" />
      <PhoneNumber value={user.profile.phonenumber} />
      <Divider />
      <Header as="h4" content="Alternative Phone Number" />
      <PhoneNumber value={user.profile.phonenumber_alt} />
      <Divider />
      <Header as="h4" content="Birthday" />
      {util.formatAPIDate(user.profile.date_of_birth)}
      <Divider />
      <Header as="h4" content="Tax ID Number" />
      <TaxIdFormat value={user.profile.tax_id} />
      <Divider />
      <Header as="h4" content="Address" />
      {user.profile.address}
      {user.profile.city && <>&nbsp;{user.profile.city}&#44;&nbsp;</>}
      {user.profile.state && <>{user.profile.state}&#44;&nbsp;</>}
      {user.profile.country && <>{user.profile.country}</>}
      <Divider hidden />
    </>
  )
}

export default ProfileCommonDetails
