import React, { useState, useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Header,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react'
import { DimmerLoader, NumberFormat } from '../index'
import { NumberInput } from '../input'
import { SuccessModal } from '../confirm'
import { DocumentUpload } from '../upload'
import WalletDecryptor from '../Wallet/WalletEncrypter'
import { signMessage, prettifyAddress } from '../../util/blockchain'
import { useModal } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import {
  AssetCodeNames,
  BlockchainSignedMessageTags,
} from '../../util/Constant'
import {
  APPROVE_TRANSFERABILITY,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'

const TxModal = ({ action, open, onClose, form, onSubmit }) => {
  const [signing, setSigning] = useState(false)
  const [signedMessage, setSignedMessage] = useState(null)

  const contract = action.actionable
  const { amount, doc, rofr, transferability_warning, restriction } = form

  const [approve, approveMutation] = useMutation(APPROVE_TRANSFERABILITY, {
    variables: {
      provider_action_id: action.uuid,
      input: {
        amount,
        rofr,
        restriction,
        transferability_warning,
        document: doc && doc.uuid,
        signed_message: signedMessage,
      },
    },
    onCompleted: onSubmit,
    refetchQueries: [
      { query: GET_PROVIDER_ACTION_DETAIL, variables: { uuid: action.uuid } },
    ],
    awaitRefetchQueries: true,
    client,
  })

  const handleApprove = async wallet => {
    setSigning(true)
    const document_hash = doc.hash

    // Message format: investor_address:1:document_hash
    const message = `${prettifyAddress(contract.contract_address)}:${
      BlockchainSignedMessageTags.TRANSFERABLE
    }:${prettifyAddress(document_hash)}`

    const signedMessage = await signMessage(wallet, message)

    setSignedMessage(signedMessage)
    setSigning(false)
    await approve()
  }

  return (
    <Modal open={open} onClose={onClose} dimmer="blurring" closeIcon>
      <Modal.Header content="TRANSFERABILITY" />
      <DimmerLoader active={signing || approveMutation.loading} />
      <Modal.Content>
        <WalletDecryptor onDecrypted={handleApprove} />
      </Modal.Content>
    </Modal>
  )
}

const docTypes = [
  'legal_opinion_letter',
  'authorization_letter',
  'blanket_opinion',
  'lockup_agreement',
  'reliance_letter',
]

const docTypeOptions = docTypes.map(type => ({
  key: type,
  value: type,
  text: AssetCodeNames[type],
}))

const initialForm = {
  rofr: true,
  restriction: true,
  transferability_warning: true,
  asset_code: '',
  amount: '',
  doc: null,
}

const ContractVerificationForm = ({ open, onClose, action }) => {
  const contract = action.actionable
  const defaultForm = useMemo(
    () => ({
      ...initialForm,
      amount: contract.token_numbers.initial,
    }),
    [contract]
  )

  const { form, input, field } = useForm('contract-verification', defaultForm)
  const txModal = useModal(false)
  const successModal = useModal(false)

  const handleSubmit = () => {
    onClose()
    successModal.show()
  }

  return (
    <>
      <SuccessModal
        {...successModal.props}
        content="You approved the contract"
      />
      <Modal
        dimmer="blurring"
        closeIcon
        open={open}
        onClose={onClose}
        as={Form}
        onSubmit={txModal.show}
      >
        <Modal.Header content="ASSET VERIFICATION" />
        <TxModal
          {...txModal.props}
          action={action}
          form={form}
          onSubmit={handleSubmit}
        />
        <Modal.Content>
          <Segment color="black" padded="very">
            <Header as="h2" content="Asset verification details" />
            <Divider hidden />
            <Form.Group widths="equal">
              <Form.Field
                label="Transferable type"
                selection
                placeholder="Select"
                options={docTypeOptions}
                required
                control={Dropdown}
                {...field('asset_code')}
              />
              <Form.Field
                label="Transferable amount"
                icon="coin"
                iconPosition="left"
                max={contract.token_numbers.initial}
                required
                control={NumberInput}
                {...field('amount')}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Checkbox}
                {...input('rofr', { type: 'checkbox' })}
                label={`The issuer ${
                  form.rofr ? 'may' : 'will not'
                } invoke RoFR`}
              />
              <Form.Field
                control={Checkbox}
                {...input('restriction', { type: 'checkbox' })}
                label={`This asset ${
                  form.restriction ? 'has' : 'has no'
                } Restriction`}
              />
              <Form.Field
                control={Checkbox}
                {...input('transferability_warning', { type: 'checkbox' })}
                label={`Transferable ${
                  form.transferability_warning ? 'with' : 'without'
                } Restriction`}
              />
            </Form.Group>
            <Form.Field
              label="Upload Transferable Document"
              asset_code={form.asset_code}
              disabled={!form.asset_code}
              required
              control={DocumentUpload}
              {...field('doc')}
            />
            <Header sub>
              Max. transferable amount
              <Label color="blue" size="small">
                <NumberFormat amount={contract.token_numbers.initial} />
              </Label>
            </Header>
          </Segment>
          <Button
            type="submit"
            fluid
            primary
            content="Next"
            disabled={!form.doc}
            data-test-id="finish-contract-verification"
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default ContractVerificationForm
