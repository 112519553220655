import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Segment } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  PageLoader,
  SingleGrid,
} from '../../components'
import { ListingsTable } from '../../components/Listing'
import { NoData } from '../../components/NotFound'
import { usePage } from '../../util/hooks'
import { client, GET_MY_LISTINGS } from '../../util/API/Apollo'

const COUNT = 10

const MyListings = () => {
  const [page, setPage] = usePage(1)

  const { data, loading } = useQuery(GET_MY_LISTINGS, {
    variables: { page, count: COUNT },
    client,
  })

  const listings = _.get(data, 'listings.data', [])
  const paginatorInfo = _.get(data, 'listings.paginatorInfo')

  if (loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb title="My Listings" />

      <SingleGrid padded>
        {listings.length > 0 ? (
          <Segment padded="very">
            <ListingsTable
              listings={listings}
              remove
              refetchQueries={[
                { query: GET_MY_LISTINGS, variables: { page, count: COUNT } },
              ]}
              hasLegend
              pagination={
                <APIPagination
                  paginatorInfo={paginatorInfo}
                  handleChange={setPage}
                  count={COUNT}
                />
              }
            />
          </Segment>
        ) : (
          <NoData text="You have no listings yet" fluid />
        )}
      </SingleGrid>
    </>
  )
}

export default MyListings
