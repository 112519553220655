import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Segment } from 'semantic-ui-react'
import DraftContracts from './DraftContracts'
import {
  CSBreadcrumb,
  SingleGrid,
  ContentLoader,
  APIPagination,
} from '../../components'
import { NoData } from '../../components/NotFound'
import { ContractTable } from '../../components/Contract'
import { usePage, useSort } from '../../util/hooks'
import { client, GET_CONTRACTS } from '../../util/API/Apollo'
import { ContractStatus } from '../../util/Constant'

const COUNT = 10

const Contracts = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const { sort, orderBy } = useSort()

  const { loading, data } = useQuery(GET_CONTRACTS, {
    variables: {
      page,
      orderBy,
      count: COUNT,
      only: [ContractStatus.DEPLOYED, ContractStatus.PAID],
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const paginatorInfo = _.get(data, 'contracts.paginatorInfo')
  const contracts = _.get(data, 'contracts.data', [])

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="My Assets" />

      <SingleGrid padded>
        <DraftContracts />
        {contracts.length > 0 ? (
          <Segment padded="very">
            <ContractTable
              contracts={contracts}
              loading={loading}
              getSortProps={sort}
              pagination={
                <APIPagination
                  paginatorInfo={paginatorInfo}
                  handleChange={setPage}
                  count={COUNT}
                />
              }
            />
          </Segment>
        ) : (
          <NoData text="THERE ARE NO ASSETS YET" fluid />
        )}
      </SingleGrid>
    </>
  )
}

export default Contracts
