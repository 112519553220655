import React from 'react'
import { useModal } from '../../util/hooks'
import util from '../../util'
import {
  ApprovalStatus,
  ApprovalType,
  Info,
  TxStatus,
} from '../../util/Constant'
import _ from 'lodash'
import ACCVerificationForm from './ACCVerificationForm'
import { GET_PROFILE } from '../../util/API/Apollo'
import { Button, Header, Segment } from 'semantic-ui-react'
import { SubMessage } from '../index'

export const ApprovalStatusMessage = ({ approval }) => {
  if (approval.tx_status === TxStatus.PENDING)
    return (
      <SubMessage
        content={Info.WAITING_BLOCKCHAIN}
        info
        icon="spinner"
        loading
        fluid
      />
    )

  switch (approval.status) {
    case ApprovalStatus.INITIALIZED:
    case ApprovalStatus.REJECTED:
      return null
    case ApprovalStatus.WAITING:
      return (
        <SubMessage
          info
          content="Waiting for approval"
          icon="spinner"
          loading
          fluid
        />
      )
    case ApprovalStatus.APPROVED:
      return (
        <SubMessage
          positive
          content="Your Accredited Investor status has been verified"
          icon="check"
          fluid
        />
      )
    case ApprovalStatus.CANCELED:
      return (
        <SubMessage
          negative
          content="Canceled"
          icon="exclamation triangle"
          fluid
        />
      )
    default:
      throw new Error(`invalid approval status ${approval.status}`)
  }
}

const ACCVerificationInfo = ({ user }) => {
  const formModal = useModal()

  const approval = React.useMemo(
    () => util.getApproval(user.approvals.data, ApprovalType.VERIFICATION_ACC),
    [user]
  )

  const awaitingVerification = !approval
  const readyToStart = _.get(approval, 'status') === ApprovalStatus.INITIALIZED
  const readyToRetry = _.get(approval, 'status') === ApprovalStatus.REJECTED

  return (
    <>
      <ACCVerificationForm
        user={user}
        refetchQueries={[{ query: GET_PROFILE }]}
        onSubmit={formModal.hide}
        {...formModal.props}
      />

      {awaitingVerification && (
        <SubMessage
          info
          icon="spinner"
          loading
          content="Awaiting Accredited Investor Status Verification from STC"
          fluid
        />
      )}

      {approval && <ApprovalStatusMessage approval={approval} />}

      {readyToStart && (
        <Segment>
          <Header sub content="Awaiting an accredited investor" />
          You need an accredited investor to take action.
          <br />
          <br />
          <Button
            primary
            fluid
            content="Upload accredited investor file"
            onClick={formModal.show}
            data-test-id="start-acc-document"
          />
        </Segment>
      )}

      {readyToRetry && (
        <Segment>
          <Header sub>Your accredited investor is not approval</Header>
          {approval.reject_reason}
          <br />
          <br />
          <Button
            primary
            fluid
            content="Retry"
            onClick={formModal.show}
            data-test-id="start-acc-document"
          />
        </Segment>
      )}
    </>
  )
}

export default ACCVerificationInfo
