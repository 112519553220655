import React from 'react'
import {
  Button,
  Form,
  Header,
  Icon,
  Label,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import _ from 'lodash'
import {
  AgeInfo,
  AmountLabel,
  DimmerLoader,
  NumberFormat,
} from '../../components'
import { SuccessModal } from '../../components/confirm'
import { NumberInput } from '../../components/input'
import { TOKEN_COMMISSION_RATE, TxStatus } from '../../util/Constant'
import { client, CREATE_TOKEN, GET_CONTRACT } from '../../util/API/Apollo'
import { useModal } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import PaymentModal from '../../components/PaymentModal'
import link, { CompanyLink, ContractLink } from '../../components/link'

const ContractTable = ({ contract }) => {
  return (
    <Table basic="very" padded fixed size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="ASSET ID" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell content="AMOUNT" />
          <Table.HeaderCell content="AGE" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <ContractLink contract={contract} />
          </Table.Cell>
          <Table.Cell>
            <CompanyLink company={contract.issuer} />
          </Table.Cell>
          <Table.Cell>
            <AmountLabel amount={contract.token_numbers.initial} />
          </Table.Cell>
          <Table.Cell>
            <AgeInfo iso str={contract.created_at} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

const TokenizationModal = ({ contract, open, onClose }) => {
  const { available } = contract.token_numbers
  const txVerified = contract.tx_status === TxStatus.VERIFIED

  const initialForm = React.useMemo(
    () => ({
      amount: contract.token_numbers.available,
    }),
    [contract]
  )
  const { form, field } = useForm('tokenization', initialForm)
  const paymentModal = useModal(false)
  const successModal = useModal(false)

  const paymentAmount = Math.ceil(Number(form.amount) * TOKEN_COMMISSION_RATE)

  const [tokenize, { loading: tokenLoading, data: tokenData }] = useMutation(
    CREATE_TOKEN,
    {
      refetchQueries: [
        { query: GET_CONTRACT, variables: { uuid: contract.uuid } },
      ],
      onCompleted: () => {
        paymentModal.hide()
        onClose()
        successModal.show()
      },
      client,
    }
  )

  const handlePayment = async payment => {
    await tokenize({
      variables: {
        contract_id: contract.uuid,
        amount: form.amount,
        payment,
      },
    })
  }

  return (
    <>
      <SuccessModal
        open={successModal.isOpen}
        content="Your asset has been digitized. You will be able to verify & see your digitized asset under Digital Securities tab, under your portfolio."
        button={{
          content: 'Details',
          as: Link,
          to: link.token(_.get(tokenData, 'createToken')),
        }}
      />
      <Modal open={open} onClose={onClose} dimmer="blurring" closeIcon>
        <DimmerLoader active={tokenLoading} />
        <PaymentModal
          {...paymentModal.props}
          onTxSent={handlePayment}
          amount={paymentAmount}
        />
        <Modal.Header content="CREATE DIGITAL SECURITIES" />
        <Modal.Content>
          <ContractTable contract={contract} />
          {Number(available) === 0 ? (
            <Header as="h3" icon color="pink" textAlign="center">
              <Icon name="exclamation triangle" />
              You cannot create new tokens from this asset.
            </Header>
          ) : (
            <Segment color="black" padded="very">
              <Form onSubmit={paymentModal.show}>
                <Form.Field
                  label="Amount"
                  fluid
                  icon="coin"
                  iconPosition="left"
                  placeholder="Amount"
                  max={available}
                  disabled={!(available > 0 && txVerified)}
                  required
                  control={NumberInput}
                  {...field('amount')}
                />
                <Button
                  fluid
                  primary
                  content="Create"
                  icon="coin"
                  type="submit"
                  disabled={!(available > 0 && form.amount > 0 && txVerified)}
                  data-test-id="finish-create-token"
                />
              </Form>
              <Header sub>
                Available Digital Securities
                <Label size="small" color="blue">
                  <NumberFormat amount={contract.token_numbers.available} /> /{' '}
                  <NumberFormat amount={contract.token_numbers.initial} />
                </Label>
              </Header>
            </Segment>
          )}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default TokenizationModal
