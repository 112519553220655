import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'semantic-ui-react'
import CompanyForm from './CompanyForm'
import { DimmerLoader } from '../index'
import { client, CREATE_COMPANY } from '../../util/API/Apollo'

const CreateCompanyModal = ({ open, onClose, onComplete }) => {
  const [createCompany, createCompanyMutation] = useMutation(CREATE_COMPANY, {
    onCompleted: data => {
      onComplete(data.createCompany)
      onClose()
    },
    client,
  })

  return (
    <Modal
      size="fullscreen"
      dimmer="blurring"
      closeOnDimmerClick={false}
      closeIcon
      open={open}
      onClose={onClose}
    >
      <Modal.Content>
        <DimmerLoader active={createCompanyMutation.loading} />
        <CompanyForm
          handleComplete={input => createCompany({ variables: { input } })}
          mutation={createCompanyMutation}
        />
      </Modal.Content>
    </Modal>
  )
}

export default CreateCompanyModal
