import link from '../../link'
import { ProviderActionType } from '../../../util/Constant'

const items = {
  dashboard: {
    title: 'Dashboard',
    icon: 'tachometer alternate',
    to: '/',
  },
  myPortfolio: {
    title: 'My Portfolio',
    icon: 'folder open',
    subitems: [
      {
        title: 'My Digital Securities',
        to: link.tokens,
      },
    ],
  },
  assetManager: {
    title: 'Asset Manager',
    icon: 'file',
    to: link.contracts,
  },
  offers: {
    title: 'My Offers',
    icon: 'tags',
    subitems: [
      {
        title: 'My Buy Offers',
        to: link.myPurchaseOffers,
      },
      {
        title: 'My Sell Offers',
        to: link.mySaleOffers,
      },
    ],
  },
  taActions: {
    title: 'Actions',
    icon: 'tasks',
    subitems: [
      {
        title: 'Account',
        to: link.actions(ProviderActionType.CUSTOMER_APPROVAL),
      },
      {
        title: 'Medallion Signature',
        to: link.actions(ProviderActionType.CUSTOMER_MSG),
      },
      {
        title: 'Asset',
        to: link.actions(ProviderActionType.ASSET_TRANSFERABILITY),
      },
      {
        title: 'Provider',
        to: link.actions(ProviderActionType.PROVIDER_APPROVAL),
      },
      {
        title: 'Company',
        to: link.actions(ProviderActionType.COMPANY_VERIFICATION),
      },
      {
        title: 'Trade Payments',
        to: link.actions(ProviderActionType.TRADE_APPROVAL),
      },
    ],
  },
  faActions: {
    title: 'Actions',
    icon: 'tasks',
    subitems: [
      {
        title: 'Provider Requests',
        to: link.actions(ProviderActionType.PROVIDER_REQUEST),
      },
    ],
  },
  bdActions: {
    title: 'Actions',
    icon: 'tasks',
    subitems: [
      {
        title: 'Trade Signatures',
        to: link.myPendingTrades,
      },
      {
        title: 'Provider Requests',
        to: link.actions(ProviderActionType.PROVIDER_REQUEST),
      },
    ],
  },
  accounts: {
    title: 'Accounts',
    icon: 'users',
    subitems: [
      {
        title: 'Customers',
        to: link.customers,
      },
      {
        title: 'Providers',
        to: link.providers,
      },
    ],
  },
  providerCustomers: {
    title: 'Customers',
    to: link.customers,
    icon: 'users',
  },
  portfoliosManager: {
    title: 'Portfolio Manager',
    icon: 'file alternate',
    subitems: [
      {
        title: 'Digital Securities',
        to: link.tokens,
      },
      {
        title: 'Assets',
        to: link.contracts,
      },
    ],
  },
  marketplace: {
    title: 'Marketplace',
    icon: 'shopping cart',
    subitems: [
      {
        title: 'Marketplace',
        to: link.marketplace,
      },
      {
        title: 'My Listings',
        to: link.myListings,
      },
    ],
  },
  providerMarketplace: {
    title: 'Marketplace',
    to: link.marketplace,
    icon: 'shopping cart',
  },
  data: {
    title: 'Data',
    to: link.companies,
    icon: 'chart area',
  },
  reports: {
    title: 'Reports',
    to: link.reports,
    icon: 'chart line',
  },
  trades: {
    title: 'Trades',
    icon: 'exchange',
    subitems: [
      {
        title: 'Pending',
        to: link.myPendingTrades,
      },
      {
        title: 'Completed',
        to: link.myCompletedTrades,
      },
    ],
  },
  message: {
    title: 'Messages',
    to: link.messages,
    icon: 'comment',
  },
  mediaCenter: {
    title: 'File Manager',
    to: link.fileManager,
    icon: 'file',
  },
  marketplaceActivities: {
    title: 'Market Activities',
    icon: 'shopping cart',
    subitems: [
      {
        title: 'Listings',
        to: link.listingActivities,
      },
      {
        title: 'Offers',
        to: link.offerActivities,
      },
      {
        title: 'Trades',
        to: link.tradeActivities,
      },
    ],
  },
}

export default items
