import React, { useMemo } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Dropdown, Header, Label, Segment } from 'semantic-ui-react'
import { CopyInput, NumberFormat } from '../index'
import { client, GET_EXCHANGE_RATE, GET_MY_WALLET } from '../../util/API/Apollo'
import { useWalletBalance } from '../../util/hooks'

const useWalletOptions = wallet => {
  return useMemo(() => {
    return [
      {
        key: wallet.uuid || '',
        text: wallet.name || 'Primary Wallet',
        value: wallet.uuid,
      },
    ]
  }, [wallet])
}

const MyWallets = () => {
  const walletQuery = useQuery(GET_MY_WALLET, { client })
  const exchangeQuery = useQuery(GET_EXCHANGE_RATE, { client })
  const wallet = _.get(walletQuery, 'data.me.primary_wallet', {})
  const walletOptions = useWalletOptions(wallet)
  const walletBalance = useWalletBalance(wallet)

  return (
    <Segment
      padded="very"
      loading={
        walletQuery.loading || walletBalance.loading || exchangeQuery.loading
      }
    >
      <Header as="h2" content="MY ETHEREUM WALLET" />
      <Dropdown
        label="Wallet name"
        selection
        fluid
        options={walletOptions}
        value={wallet.uuid}
      />
      <Header as="h4" content="Wallet ID" />
      <CopyInput value={wallet.address || ''} />
      <Header sub>
        Wallet balance
        <Label
          color="purple"
          icon="ethereum"
          size="small"
          content={<NumberFormat amount={walletBalance.eth} float digits={6} />}
        />
        <Label
          color="purple"
          icon="usdc"
          size="small"
          content={<NumberFormat amount={walletBalance.usdc} />}
        />
      </Header>
      <Header sub>
        ETH rate
        {!exchangeQuery.loading && (
          <Label size="small">
            <NumberFormat
              usd
              amount={_.get(exchangeQuery, 'data.exchange.price')}
            />
          </Label>
        )}
      </Header>
    </Segment>
  )
}

export default MyWallets
