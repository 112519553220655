import React from 'react'
import { Button, Grid, Header, Icon, Popup } from 'semantic-ui-react'
import { ProviderType } from '../../util/Constant'
import { useModal } from '../../util/hooks'
import _ from 'lodash'
import OfficerForm from './OfficerForm'
import { useMe } from '../../util/hooks/me'
import { UserLink } from '../link'

const ProviderInfo = ({ user, providerType }) => {
  const me = useMe()
  const isMe = user.uuid === me.uuid

  const providerFormModal = useModal()

  const provider = _.get(user, 'providers', []).find(p => {
    return p.provides === providerType
  })
  const invitations = _.get(user, 'provider_invitations.data', []).filter(i => {
    return i.invitation_type === providerType
  })
  const actions = _.get(user, 'provider_requests', []).filter(action => {
    return action.provider.provides === providerType
  })

  const showProviderRequest = !provider && actions.length > 0
  const showInvitation =
    !provider && !showProviderRequest && invitations.length > 0
  const invitable = !provider && !showInvitation && !showProviderRequest

  return (
    <>
      <OfficerForm {...providerFormModal.props} providerType={providerType} />
      {provider && <UserLink user={provider.officers[0]} />}
      {showProviderRequest && (
        <>
          <Icon name="time" color="blue" />
          <UserLink user={actions[0].provider.officers[0]} />
        </>
      )}
      {showInvitation && (
        <>
          <p>
            <Icon name="time" color="blue" />
            Awaiting provider response
          </p>
          <p>{invitations[0].fullname}</p>
        </>
      )}
      {invitable &&
        (isMe ? (
          <Popup
            inverted
            content={
              providerType === ProviderType.BROKER_DEALER
                ? 'A Broker-Dealer is needed for you to be able to access the Marketplace and start trading within our platform in a compliant manner.'
                : 'This step will notify your Financial Advisor that you have become a member in our platform, and you would like to (are open to) receive Investment Advice from your financial advisor regarding the private securities that are listed in the Marketplace. By means of this invitation, your Financial Advisor will become aware of this platform (if not already registered with us) and will have the opportunity to view investment opportunities in the Market Platform and provide you with investment advice.'
            }
            trigger={
              <Button
                primary
                content="Select & Invite"
                onClick={providerFormModal.show}
              />
            }
          />
        ) : (
          '-'
        ))}
    </>
  )
}

const UserProvidersTable = ({ user }) => {
  return (
    <Grid divided>
      <Grid.Row columns="3">
        <Grid.Column textAlign="center">
          <Header as="h4" content="TRANSFER AGENT" />
          <ProviderInfo
            providerType={ProviderType.TRANSFER_AGENT}
            user={user}
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Header as="h4" content="BROKER DEALER" />
          <ProviderInfo providerType={ProviderType.BROKER_DEALER} user={user} />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Header as="h4" content="FINANCIAL ADVISOR" />
          <ProviderInfo
            providerType={ProviderType.FINANCIAL_ADVISOR}
            user={user}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default UserProvidersTable
