import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Divider,
  Header,
  Icon,
  Segment,
  Table,
} from 'semantic-ui-react'
import { AgeInfo } from '../index'
import link, { UserLink } from '../link'

const OffersSimpleTable = ({ offers }) => {
  if (offers.length > 0) {
    return (
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="OFFER ID" />
            <Table.HeaderCell content="OFFER BY" />
            <Table.HeaderCell content="AGE" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offers.map(offer => {
            const { hashid, owner, created_at } = offer

            return (
              <Table.Row key={hashid}>
                <Table.Cell>
                  <Link to={link.offer(offer)}>{hashid}</Link>
                </Table.Cell>
                <Table.Cell>
                  <UserLink user={owner} />
                </Table.Cell>
                <Table.Cell>
                  <AgeInfo iso str={created_at} />
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    size="mini"
                    content="View"
                    as={Link}
                    to={link.offer(offer)}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Segment basic textAlign="center">
      <Divider />
      <Header as="h4" icon color="black">
        <Icon name="ban" />
        NO PENDING ACTIONS FOUND
      </Header>
      <Divider />
    </Segment>
  )
}

export default OffersSimpleTable
