import moment from 'moment'
import { format, parse } from 'date-fns'
import _ from 'lodash'
import './validation'

const devHosts = [
  'local.creosafe.io',
  'dev.creosafe.io',
  'beta.creosafe.io',
  'test.creosafe.io',
  'creosafe.loc',
  'creosafe.test',
]

const localHosts = [
  '127.0.0.1',
  'localhost',
  'local.creosafe.io',
  'creosafe.loc',
  'creosafe.test',
]

const API_DATE_FORMAT = 'yyyy-MM-dd'

const util = {
  isLocal() {
    return localHosts.includes(window.location.hostname)
  },
  isDev() {
    return devHosts.includes(window.location.hostname)
  },
  formatNumber(amount) {
    return new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 18,
    }).format(amount)
  },

  formatNumberFloat(amount, digits = 2) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
    }).format(amount)
  },

  // @TODO remove
  formatAPIDate(dateStr) {
    if (!dateStr) return ''
    return moment(dateStr, 'YYYY-MM-DD').format('MM-DD-YYYY')
  },

  parseAPIDate(dateStr) {
    return parse(dateStr, API_DATE_FORMAT, new Date())
  },

  formatDateForAPI(date) {
    if (!date) return ''
    return format(date, API_DATE_FORMAT)
  },

  formatISODate(dateStr) {
    return moment(dateStr).format('MM-DD-YYYY')
  },

  sleep(duration) {
    return new Promise(resolve => setTimeout(resolve, duration))
  },

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },

  // return true if all fields of object are falsy
  isEmptyObject(obj) {
    return Object.keys(obj).every(key => !Boolean(obj[key]))
  },

  // replace photos with uuid
  inputizePeople(people) {
    return people.map(person => {
      const { photo, ...newPerson } = person
      if (photo) {
        newPerson.photo = photo.uuid
      }
      return this.cleanObject(newPerson)
    })
  },

  // remove null, undefined and empty strings from object
  cleanObject(obj) {
    const res = { ...obj }
    Object.keys(res).forEach(key => {
      const value = res[key]
      if (value === null || value === undefined || value === '') {
        delete res[key]
      }
    })
    return res
  },

  prependImage(imgFile, defaultImg) {
    if (!imgFile) return defaultImg
    return imgFile.link_as_avatar
  },

  getApproval(approvals, type) {
    return _.sortBy(approvals, 'created_at')
      .reverse()
      .find(a => a.approval_type === type)
  },

  // utils for localStorage
  clearStorage() {
    return localStorage.clear()
  },

  getItemFromStorage(key) {
    if (!localStorage) return

    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      console.error(`Error getting item ${key} from localStorage`, e)
    }
  },

  saveItemToStorage(key, item) {
    if (!localStorage) return

    try {
      return localStorage.setItem(key, JSON.stringify(item))
    } catch (e) {
      console.error(`Error storing item ${key} to localStoragee`, e)
    }
  },

  removeItemFromStorage(key) {
    if (!localStorage) return

    try {
      return localStorage.removeItem(key)
    } catch (e) {
      console.error(`Error removing item ${key} from localStoragee`, e)
    }
  },
}

export default util
