import React from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
  client,
  GET_LAST_NOTIFICATIONS,
  NEW_NOTIFICATION,
} from '../../../util/API/Apollo'
import { Button, Icon, Menu, Popup, Tab } from 'semantic-ui-react'
import UserMessages from './UserMessages'
import SystemMessages from './SystemMessages'
import link from '../../link'

const MessagesContent = ({ message_notifications, system_notifications }) => {
  const defaultActiveIndex = React.useMemo(() => {
    const hasNewMessage = message_notifications.find(notif => !notif.read_at)
    const hasNewNotif = system_notifications.find(notif => !notif.read_at)
    return hasNewMessage ? 0 : hasNewNotif ? 1 : 0
  }, [message_notifications, system_notifications])
  return (
    <>
      <Tab
        defaultActiveIndex={defaultActiveIndex}
        className="m-10"
        menu={{ secondary: true, pointing: true, className: 'mt-0 mb-20' }}
        panes={[
          {
            menuItem: 'Messages',
            render: () => (
              <UserMessages notifications={message_notifications} />
            ),
          },
          {
            menuItem: 'Notifications',
            render: () => (
              <SystemMessages notifications={system_notifications} />
            ),
          },
        ]}
      />
      <Button
        basic
        attached="bottom"
        size="large"
        content="VIEW ALL"
        className="py-15"
        as={Link}
        to={link.messages}
      />
    </>
  )
}

const Messages = () => {
  const { data, loading, refetch } = useQuery(GET_LAST_NOTIFICATIONS, {
    client,
  })
  useSubscription(NEW_NOTIFICATION, {
    client,
    onError: err => {
      console.error(err)
    },
    onSubscriptionData: async () => {
      await refetch()
    },
  })

  const system_notifications = _.get(data, 'me.system_notifications', [])
  const message_notifications = _.get(data, 'me.message_notifications', [])

  const hasNew = React.useMemo(() => {
    const allNotifs = [...system_notifications, ...message_notifications]
    return allNotifs.find(notif => !notif.read_at)
  }, [system_notifications, message_notifications])

  return (
    <Popup
      disabled={loading}
      on="click"
      style={{ minWidth: 400, padding: 0 }}
      position="bottom center"
      positionFixed
      content={
        <MessagesContent {...{ system_notifications, message_notifications }} />
      }
      trigger={
        <Menu.Item>
          Messages
          <Icon.Group>
            {hasNew && <Icon corner name="circle" color="red" />}
          </Icon.Group>
        </Menu.Item>
      }
    />
  )
}

export default Messages
