import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_USER_DETAILS } from '../util/API/Apollo'
import { useParams } from 'react-router'
import { CSBreadcrumb, PageLoader, SingleGrid } from '../components'
import TabMenu from '../components/TabMenu'
import _ from 'lodash'
import ProfileSummary from '../components/Profile/ProfileSummary'
import ProfileCommonDetails from '../components/Profile/ProfileCommonDetails'
import CompanySummary from '../components/Company/CompanySummary'
import { ApprovalType, AssetCodeNames } from '../util/Constant'
import { Divider, Header, Segment } from 'semantic-ui-react'
import { useApproval } from '../util/hooks'
import link, { FileLink } from '../components/link'

const ProviderDetails = () => {
  const { uuid } = useParams()
  const { loading, data } = useQuery(GET_USER_DETAILS, {
    variables: { uuid },
    client,
  })

  const user = _.get(data, 'user')
  const approvalBD = useApproval(
    _.get(user, 'approvals.data', []),
    ApprovalType.VERIFICATION_BROKER_DEALER
  )
  const approvalFA = useApproval(
    _.get(user, 'approvals.data', []),
    ApprovalType.VERIFICATION_FINANCIAL_ADVISOR
  )
  const approval = approvalBD || approvalFA

  if (loading) return <PageLoader active={loading} />

  const assetCode = _.get(approval, 'verification_data.document.asset_code')

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Providers', to: link.providers }]}
        title={user.hashid}
      />
      <SingleGrid padded>
        <Segment padded="very">
          <TabMenu defaultTab="account-details">
            <TabMenu.Left>
              <TabMenu.Menu>
                <TabMenu.Item name="account-details" />
                {_.get(user, 'profile.company') && (
                  <TabMenu.Item name="company-details" />
                )}
                <TabMenu.Item name="certificate-details" />
              </TabMenu.Menu>
            </TabMenu.Left>
            <TabMenu.Right>
              <ProfileSummary user={user} />
              <TabMenu.Pane name="account-details">
                <ProfileCommonDetails user={user} />
              </TabMenu.Pane>
              <TabMenu.Pane name="company-details">
                <CompanySummary company={user.profile.company} />
              </TabMenu.Pane>
              <TabMenu.Pane name="certificate-details">
                <Header as="h4" content="Type" />
                <p>{assetCode ? AssetCodeNames[assetCode] : '-'}</p>
                <Divider />
                <Header as="h4" content="CRD" />
                <p>{_.get(approval, 'verification_data.proof_crd', '-')}</p>
                <Divider />
                <Header as="h4" content="Certificate document" />
                <FileLink
                  file={_.get(approval, 'verification_data.document')}
                />
              </TabMenu.Pane>
            </TabMenu.Right>
          </TabMenu>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default ProviderDetails
