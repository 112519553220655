import React from 'react'
import { Grid, Icon, Menu, Table } from 'semantic-ui-react'
import {
  FinancialDataType,
  FinancialDataTypeNames,
} from '../../../util/Constant'
import { useTab } from '../../../util/hooks'
import { NumberFormat } from '../../index'
import { FileLink } from '../../link'

const DocumentsTable = ({ data, onRemove }) => {
  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="DOCUMENT NAME" />
          <Table.HeaderCell content="DOCUMENT" />
          <Table.HeaderCell content="DESCRIPTION" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {onRemove && (
                  <Icon
                    link
                    color="red"
                    name="trash"
                    onClick={() => {
                      onRemove(item)
                    }}
                  />
                )}
                {item.title}
              </Table.Cell>
              <Table.Cell>
                <FileLink file={item.document_file} />
              </Table.Cell>
              <Table.Cell>{item.description}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}
const FinancialDataTable = ({ data, type, onRemove }) => {
  const sortedData = data
    .sort((a, b) => a.year - b.year || a.quarter - b.quarter)
    .reverse()
  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            content={type ? FinancialDataTypeNames[type] : 'DATA'}
            className="uppercase"
          />
          {!type && <Table.HeaderCell content="DATA TYPE" />}
          <Table.HeaderCell content="QUARTER" />
          <Table.HeaderCell content="YEAR" />
          <Table.HeaderCell content="DESCRIPTION" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedData.length > 0 &&
          sortedData.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {onRemove && (
                  <Icon
                    link
                    color="red"
                    name="trash"
                    onClick={() => {
                      onRemove(item)
                    }}
                  />
                )}
                <NumberFormat usd amount={item.data} />
              </Table.Cell>
              {!type && (
                <Table.Cell content={FinancialDataTypeNames[item.data_type]} />
              )}
              <Table.Cell>Quarter {item.quarter}</Table.Cell>
              <Table.Cell>{item.year}</Table.Cell>
              <Table.Cell>{item.description}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}

const FinancialDataTabs = ({
  data = [],
  documents = [],
  onDataRemove,
  onDocumentRemove,
}) => {
  const { isActive, itemProps, activeTab } = useTab('all')

  let filteredData = React.useMemo(() => {
    const res = {}
    res.all = data
    Object.keys(FinancialDataType).forEach(dataType => {
      res[dataType] = data.filter(d => d.data_type === dataType)
    })
    return res
  }, [data])

  const hasDataOrDoc = data.length > 0 || documents.length > 0
  const isFiltered = !(isActive('documents') || isActive('all'))

  if (!hasDataOrDoc) return null

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Menu pointing secondary className="hidden-x">
            <Menu.Item {...itemProps('all')} content="All" />
            {filteredData[FinancialDataType.SALES_REVENUE].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.SALES_REVENUE)}
                content={FinancialDataTypeNames.SALES_REVENUE}
              />
            )}
            {filteredData[FinancialDataType.EBITDA].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.EBITDA)}
                content={FinancialDataTypeNames.EBITDA}
              />
            )}
            {filteredData[FinancialDataType.NET_PROFIT].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.NET_PROFIT)}
                content={FinancialDataTypeNames.NET_PROFIT}
              />
            )}
            {filteredData[FinancialDataType.TOTAL_ASSETS].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.TOTAL_ASSETS)}
                content={FinancialDataTypeNames.TOTAL_ASSETS}
              />
            )}
            {filteredData[FinancialDataType.TOTAL_LIABILITIES].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.TOTAL_LIABILITIES)}
                content={FinancialDataTypeNames.TOTAL_LIABILITIES}
              />
            )}
            {filteredData[FinancialDataType.SHORT_TERM_DEBTS].length > 0 && (
              <Menu.Item
                {...itemProps(FinancialDataType.SHORT_TERM_DEBTS)}
                content={FinancialDataTypeNames.SHORT_TERM_DEBTS}
              />
            )}
            {documents.length > 0 && (
              <Menu.Item {...itemProps('documents')} content="Documents" />
            )}
          </Menu>
          {isActive('documents') && <DocumentsTable data={documents} />}
          {isActive('all') && (
            <>
              {data.length > 0 && (
                <FinancialDataTable data={data} onRemove={onDataRemove} />
              )}
              {documents.length > 0 && (
                <DocumentsTable data={documents} onRemove={onDocumentRemove} />
              )}
            </>
          )}
          {isFiltered && (
            <FinancialDataTable
              data={filteredData[activeTab]}
              type={activeTab}
              onRemove={onDataRemove}
            />
          )}
          {/*<Button*/}
          {/*  disabled*/}
          {/*  floated="right"*/}
          {/*  size="tiny"*/}
          {/*  color="red"*/}
          {/*  icon="exclamation triangle"*/}
          {/*  labelPosition="right"*/}
          {/*  content="report incorrect data"*/}
          {/*/>*/}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export default FinancialDataTabs
