import React, { useEffect, useCallback, useState } from 'react'
import _ from 'lodash'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { Modal, Button, Message } from 'semantic-ui-react'
import { useModal } from '../util/hooks'
import { client, LOGIN, LOGOUT } from '../util/API/Apollo'
import { DimmerLoader } from '.'

const SessionActivityModal = ({ timeLeft, setLastCheck, open, onClose }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false)

  const [login, { loading: loginLoading }] = useMutation(LOGIN, {
    variables: { email: 'asd', password: 'asd' },
    onCompleted: data => {
      setLastCheck(_.get(data, 'login.payload.expires_at'))
      localStorage.setItem(
        'expires_at',
        _.get(data, 'login.payload.expires_at')
      )
      onClose()
    },
    client,
  })

  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT, {
    onCompleted: () => {
      setIsLoggedOut(true)
    },
    onError: () => {
      setIsLoggedOut(true)
    },
    client,
  })

  useEffect(() => {
    if (!timeLeft) {
      logout()
      return
    }

    if (timeLeft > 60) return onClose()
  }, [timeLeft]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      {(loginLoading || logoutLoading) && <DimmerLoader />}
      <Modal.Header content="Session Timeout" />
      <Modal.Content>
        {isLoggedOut ? (
          <>
            <Message negative>
              <p>Your session has expired.</p>
            </Message>
            <br />
            <Button
              fluid
              content="Go to login"
              onClick={() => {
                window.location.reload(false)
                localStorage.removeItem('expires_at')
              }}
            />
          </>
        ) : (
          <>
            <Message warning>
              <Message.Header>Your session is about to expire!</Message.Header>
              <p>
                You will be logged out in <strong>{timeLeft}</strong> seconds.
                Do you want to logged in?
              </p>
            </Message>
            <br />
            <Button fluid content="Yes, keep me logged in" onClick={login} />
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}

const SessionActivity = () => {
  const sessionActivityModal = useModal()
  const [lastCheck, setLastCheck] = useState(null)
  const [timeLeft, setTimeLeft] = useState(null)

  const handleLastCheck = useCallback(() => {
    if (!localStorage.getItem('expires_at'))
      return window.location.reload(false)

    if (lastCheck !== localStorage.getItem('expires_at'))
      setLastCheck(localStorage.getItem('expires_at'))
  }, [lastCheck])

  useEffect(() => {
    window.addEventListener('storage', handleLastCheck, false)

    return () => window.removeEventListener('storage', handleLastCheck, false)
  }, [handleLastCheck])

  useEffect(() => {
    // initialize last check from localstorage
    if (!lastCheck && localStorage.getItem('expires_at')) {
      setLastCheck(localStorage.getItem('expires_at'))
    }

    // timer function
    const interval = setInterval(() => {
      let currentTime = moment.utc(new Date())
      let expiresAt = moment.utc(localStorage.getItem('expires_at'))
      setTimeLeft(expiresAt.diff(currentTime, 'seconds'))

      if (timeLeft !== null && timeLeft <= 60)
        return sessionActivityModal.show()
      else return sessionActivityModal.hide()
    }, 1000)

    if (timeLeft < 0) clearInterval(interval)

    return () => clearInterval(interval)
  }, [lastCheck, timeLeft]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!sessionActivityModal.isOpen) {
    return null
  }

  return (
    <SessionActivityModal
      timeLeft={timeLeft}
      setLastCheck={setLastCheck}
      {...sessionActivityModal.props}
    />
  )
}

export default SessionActivity
