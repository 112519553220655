import React from 'react'
import { Divider, Header, Icon } from 'semantic-ui-react'
import {
  AgeInfo,
  OfferAmountLabel,
  OfferPriceLabel,
  PPDSLabel,
  SubMessage,
} from '../index'
import { Info, TxStatus } from '../../util/Constant'
import { ContractLink, UserLink } from '../link'

const TradeCommonDetails = ({ trade }) => {
  const offer = trade.accepted_offer

  return (
    <>
      {trade.tx_status === TxStatus.PENDING && (
        <SubMessage
          info
          content={Info.WAITING_BLOCKCHAIN}
          icon="spinner"
          loading
        />
      )}
      <Header as="h4" content="Trade ID" />
      <p>{trade.hashid}</p>
      <Divider />
      <Header as="h4" content="Underlying Asset" />
      <ContractLink contract={offer.listing.token.contract} />
      <Divider />
      <Header as="h4">
        Transfer (Seller
        <Icon name="arrow right" size="small" color="red" className="mx-2" />
        Buyer)
      </Header>
      <>
        <UserLink user={trade.seller_info} />
        <Icon name="arrow right" size="small" color="red" />
        <UserLink user={trade.buyer_info} />
      </>
      <Divider />
      <Header as="h4" content="Age" />
      <AgeInfo iso str={trade.created_at} />
      <Divider />
      <Header as="h4" content="Trade Amount" />
      <OfferAmountLabel amount={offer.token_amount} />
      <Divider />
      <Header as="h4" content="Trade Price" />
      <OfferPriceLabel price={offer.token_price} />
      <Divider />
      <Header as="h4" content="PPDS" />
      <PPDSLabel
        data={{ amount: offer.token_amount, price: offer.token_price }}
      />
    </>
  )
}

export default TradeCommonDetails
