import React from 'react'
import { useParams } from 'react-router'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Button, Icon, Message, Segment } from 'semantic-ui-react'
import TokenizationModal from './TokenizationModal'
import { CSBreadcrumb, PageLoader, SingleGrid } from '../../components'
import { NoData } from '../../components/NotFound'
import TabMenu from '../../components/TabMenu'
import {
  ContractTabMenuItems,
  ContractTabMenuPanes,
} from '../../components/Contract/util'
import { useModal } from '../../util/hooks'
import { ContractStatus, ContractType, TxStatus } from '../../util/Constant'
import { useMe } from '../../util/hooks/me'
import { client, GET_CONTRACT } from '../../util/API/Apollo'
import link from '../../components/link'

const ContractDetailTabs = ({ contract, refetch }) => {
  const tokenizationModal = useModal()
  const { isOwner } = useMe()

  const isSimpleContract =
    contract.contract_type === ContractType.SAFE ||
    contract.contract_type === ContractType.CONVERTIBLE_NOTE ||
    contract.contract_type === ContractType.EQUITY
  const isTokenizable =
    isOwner({ contract }) &&
    contract.tx_status === TxStatus.VERIFIED &&
    isSimpleContract

  return (
    <TabMenu defaultTab="asset-details">
      <TabMenu.Left>
        <TabMenu.Menu>
          <ContractTabMenuItems contract={contract} />
        </TabMenu.Menu>
        {isTokenizable && (
          <>
            <TokenizationModal
              {...tokenizationModal.props}
              contract={contract}
            />
            <Button
              primary
              fluid
              content="Create Digital Securities"
              className="mb-10"
              onClick={tokenizationModal.show}
              data-test-id="start-create-token"
            />
          </>
        )}
      </TabMenu.Left>

      <TabMenu.Right>
        <ContractTabMenuPanes
          contract={contract}
          refetch={refetch}
          showInvitations={contract.tx_status === TxStatus.VERIFIED}
        />
      </TabMenu.Right>
    </TabMenu>
  )
}

const ContractDetails = () => {
  const { uuid } = useParams()
  const { loading, data, refetch } = useQuery(GET_CONTRACT, {
    variables: { uuid },
    client,
  })
  const contract = _.get(data, 'contract')

  if (loading) return <PageLoader />

  if (!contract) {
    return <NoData text="ASSET NOT FOUND" fluid />
  }

  const txPending = _.get(contract, 'tx_status') === TxStatus.PENDING
  const paymentPending = _.get(contract, 'status') === ContractStatus.PAID

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Assets', to: link.contracts }]}
        title={contract && contract.hashid}
      />
      {contract && (
        <SingleGrid padded>
          <Segment padded="very">
            {(txPending || paymentPending) && (
              <Message info>
                <Icon name="spinner" loading />
                This may take a few minutes, please wait. You will get a
                notification when the Asset creation is completed.
              </Message>
            )}
            <ContractDetailTabs contract={contract} refetch={refetch} />
          </Segment>
        </SingleGrid>
      )}
    </>
  )
}

export default ContractDetails
