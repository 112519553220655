import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
  APPROVE_COMPANY_INFO,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'
import { ProviderActionStatus } from '../../util/Constant'
import { PageLoader } from '../../components'
import { useModal } from '../../util/hooks'
import { ConfirmModal, SuccessModal } from '../../components/confirm'
import TabMenu from '../../components/TabMenu'
import CompanySummary from '../../components/Company/CompanySummary'
import FinancialDataTabs from '../../components/FinancialData/FinancialDataTabs'
import CompanyCommonDetails from '../../components/Company/CompanyCommonDetails'
import link from '../../components/link'

const CompanyActionDetails = ({ action }) => {
  const company = action.actionable
  const financialData = _.get(company, 'financial_data', [])
  const financialDocuments = _.get(company, 'financial_documents', [])

  const verified = action.status === ProviderActionStatus.APPROVED
  const confirmModal = useModal()
  const successModal = useModal()

  const [approve, { loading }] = useMutation(APPROVE_COMPANY_INFO, {
    variables: { provider_action_id: action.uuid },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    onCompleted: successModal.show,
    client,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} onConfirm={approve} />
      <SuccessModal
        content="The company has been verified"
        {...successModal.props}
      />
      <PageLoader active={loading} />

      <TabMenu defaultTab="company">
        <TabMenu.Left>
          {!verified ? (
            <>
              <Button
                content="Verify company"
                fluid
                color="green"
                type="button"
                onClick={confirmModal.show}
                disabled={verified}
              />
              <br />

              <Button
                content="Edit company"
                fluid
                as={Link}
                to={link.editCompany(company)}
                onClick={confirmModal.show}
              />
            </>
          ) : (
            <p>
              <Icon name="check" /> Company has been verified.
            </p>
          )}
        </TabMenu.Left>
        <TabMenu.Right>
          <TabMenu.Pane name="company">
            <CompanySummary company={company} />
            <CompanyCommonDetails company={company} />
            <FinancialDataTabs
              data={financialData}
              documents={financialDocuments}
            />
          </TabMenu.Pane>
        </TabMenu.Right>
      </TabMenu>
    </>
  )
}

export default CompanyActionDetails
