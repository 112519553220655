import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Form, Grid, Input, Segment } from 'semantic-ui-react'
import {
  APIPagination,
  ContentLoader,
  CSBreadcrumb,
  SingleGrid,
} from '../../components'
import { ContractTable } from '../../components/Contract'
import { NoData } from '../../components/NotFound'
import {
  usePage,
  useSort,
  useDashboardData,
  useDebounceQuery,
} from '../../util/hooks'
import { client, GET_CONTRACTS, SEARCH_CONTRACTS } from '../../util/API/Apollo'
import { ContractStatus } from '../../util/Constant'
import createAssetImage from '../../images/create_asset.svg'

import { AssetsWidget } from '../../components/Widget'
import link from '../../components/link'

const COUNT = 10

const AllContracts = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const { sort, orderBy } = useSort()
  const { dashboard, loading: statisticsLoading } = useDashboardData()
  const { loading, data } = useQuery(GET_CONTRACTS, {
    variables: {
      page,
      orderBy,
      count: COUNT,
      only: [ContractStatus.DEPLOYED, ContractStatus.PAID],
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const [input, setInput] = useState('')
  const showSearch = input.length >= 2
  const { loading: searchLoading, data: searchData } = useDebounceQuery(
    SEARCH_CONTRACTS,
    {
      variables: { search: input },
      skip: !showSearch,
      client,
    }
  )

  const paginatorInfo = _.get(data, 'contracts.paginatorInfo')
  const contracts = _.get(data, 'contracts.data', [])
  const searchContracts = _.get(searchData, 'searchContracts.data', [])

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="Asset Manager" />

      <SingleGrid padded>
        {contracts.length > 0 ? (
          <>
            <Grid className="py-15">
              <Grid.Row className="py-0">
                <Grid.Column>
                  <Button
                    primary
                    floated="right"
                    as={Link}
                    to={link.newContract}
                    content="Create New Asset"
                  />
                  <Button floated="right" content="export assets" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {!statisticsLoading && (
                    <AssetsWidget divided width={3} data={dashboard.assets} />
                  )}
                  <Segment padded="very">
                    <Form>
                      <Form.Field
                        control={Input}
                        value={input}
                        loading={searchLoading}
                        onChange={(e, { value }) => setInput(value)}
                        placeholder="Search asset"
                        icon="search"
                      />
                    </Form>
                    <br />
                    {showSearch ? (
                      searchContracts.length === 0 && !searchLoading ? (
                        <NoData text="NO ASSET FOUND" />
                      ) : (
                        <ContractTable contracts={searchContracts} />
                      )
                    ) : (
                      <ContractTable
                        contracts={contracts}
                        loading={loading}
                        getSortProps={sort}
                        pagination={
                          <APIPagination
                            paginatorInfo={paginatorInfo}
                            handleChange={setPage}
                            count={COUNT}
                          />
                        }
                      />
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ) : (
          <>
            <NoData
              image={createAssetImage}
              text="THERE ARE NO ASSETS YET"
              fluid
            >
              <Button
                primary
                type={undefined}
                content="add new asset"
                as={Link}
                to={link.newContract}
              />
            </NoData>
          </>
        )}
      </SingleGrid>
    </>
  )
}

export default AllContracts
