import React from 'react'
import { Button, Form, Input, Header } from 'semantic-ui-react'
import { bankInfoSchema } from '../../schema'

const BankInfoStep = ({ formHook, stepsHook, onComplete }) => {
  const { field, form, validate } = formHook
  const handleSubmit = async () => {
    const { bank_name, account_number, rnt_aba, swift_bic } = form.bank_info
    let isEmpty = true
    if (bank_name || account_number || rnt_aba || swift_bic) isEmpty = false

    if (isEmpty || (await validate(bankInfoSchema))) {
      if (stepsHook.isLastStep) {
        onComplete()
      } else {
        stepsHook.nextStep()
      }
    }
  }

  return (
    <>
      <Header as="h3" content="BANK INFO" />
      <p>
        This is needed for you to be able to transact in USD. STC will act as
        your legal transfer agent and escrow agent. Your bank account details
        will be kept private.
      </p>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Field
          label="Bank name"
          placeholder="e.g. City National Bank"
          control={Input}
          {...field('bank_info.bank_name')}
        />
        <Form.Field
          label="Account number"
          placeholder="e.g. 1111-1111-1"
          control={Input}
          {...field('bank_info.account_number')}
        />
        <Form.Field
          label="RNT / ABA"
          placeholder="e.g.  1111-1111-1"
          control={Input}
          {...field('bank_info.rtn_aba')}
        />
        <Form.Field
          label="SWIFT / BIC"
          placeholder="e.g. XINX116Y"
          control={Input}
          {...field('bank_info.swift_bic')}
        />
        <br />
        <Button
          primary
          content={stepsHook.isLastStep ? 'Finish' : 'Next'}
          type="submit"
          floated="right"
          data-test-id="profile-form-next"
        />
        <Button
          content="Back"
          type="button"
          floated="right"
          onClick={stepsHook.prevStep}
        />
      </Form>
      <br />
      <br />
    </>
  )
}

export default BankInfoStep
