import React, { useMemo } from 'react'
import { Button } from 'semantic-ui-react'
import BasicInfoStep from './Steps/BasicInfoStep'
import ContactInfoStep from './Steps/ContactInfoStep'
import FinancialDataStep from './Steps/FinancialDataStep'
import { PageLoader } from '../../index'
import { getSchema, mapCompanyToForm, mapFormToInput } from './util'
import useForm from '../../../util/hooks/form'
import { useMe } from '../../../util/hooks/me'
import { useSteps } from '../../../util/hooks/steps'

export const NextBackButton = ({ stepsHook, handleNextStep }) => {
  const { prevStep, isLastStep, activeIndex } = stepsHook

  return (
    <>
      {isLastStep ? (
        <Button
          primary
          floated="right"
          content="Finish"
          onClick={handleNextStep}
          data-test-id="company-form-finish"
        />
      ) : (
        <Button
          primary
          floated="right"
          content="Next"
          onClick={handleNextStep}
          data-test-id="company-form-next"
        />
      )}
      {activeIndex > 0 && (
        <Button floated="right" content="Back" onClick={prevStep} />
      )}
    </>
  )
}

const CurrentStep = ({ formHook, stepsHook, handleNextStep }) => {
  return (
    <>
      {stepsHook.isActive('basic') && (
        <BasicInfoStep
          formHook={formHook}
          stepsHook={stepsHook}
          handleNextStep={handleNextStep}
        />
      )}
      {stepsHook.isActive('contact') && (
        <ContactInfoStep
          formHook={formHook}
          stepsHook={stepsHook}
          handleNextStep={handleNextStep}
        />
      )}
      {stepsHook.isActive('financials') && (
        <FinancialDataStep
          formHook={formHook}
          stepsHook={stepsHook}
          handleNextStep={handleNextStep}
        />
      )}
    </>
  )
}

export const useCompanySteps = () => {
  const { isProvider } = useMe()
  if (isProvider) {
    return ['basic', 'contact']
  } else {
    return ['basic', 'contact', 'financials']
  }
}

const CompanyForm = ({ handleComplete, mutation, company }) => {
  const initialForm = useMemo(() => mapCompanyToForm(company), [company])
  const formHook = useForm('create-company', initialForm)

  const steps = useCompanySteps()
  const stepsHook = useSteps(steps, 'basic')
  const schema = useMemo(() => getSchema(stepsHook.activeStep), [
    stepsHook.activeStep,
  ])

  const handleNextStep = async () => {
    if (await formHook.validate(schema)) {
      if (!stepsHook.isLastStep) {
        return stepsHook.nextStep()
      }

      handleComplete(mapFormToInput(formHook.form))
    }
  }

  return (
    <>
      <PageLoader active={mutation.loading} />

      <CurrentStep
        formHook={formHook}
        stepsHook={stepsHook}
        handleNextStep={handleNextStep}
      />
    </>
  )
}

export default CompanyForm
