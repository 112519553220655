import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Table } from 'semantic-ui-react'
import {
  AgeInfo,
  DimmerLoader,
  OfferAmountLabel,
  OfferPriceLabel,
} from '../index'
import { NoData } from '../NotFound'
import link, {
  CompanyLink,
  ContractLink,
  DetailsButton,
  UserLink,
} from '../link'

const TradesTable = ({ trades, loading, pagination }) => {
  if (!loading && trades.length < 1) {
    return <NoData text="No result found" />
  }

  return (
    <Table basic="very" padded size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="TRADE ID" />
          <Table.HeaderCell content="UNDERLYING ASSET" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell>
            TRANSFER (Seller <Icon name="arrow right" color="red" /> Buyer)
          </Table.HeaderCell>
          <Table.HeaderCell content="TRADE AMOUNT" />
          <Table.HeaderCell content="TRADE PRICE" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell>
              <DimmerLoader />
            </Table.Cell>
          </Table.Row>
        ) : (
          <>
            {trades.map(trade => {
              const {
                hashid,
                accepted_offer,
                seller_info,
                buyer_info,
                created_at,
              } = trade

              return (
                <Table.Row key={hashid}>
                  <Table.Cell>
                    <Link to={link.trade(trade)}>{hashid}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    <ContractLink
                      contract={accepted_offer.listing.token.contract}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <CompanyLink
                      company={accepted_offer.listing.token.contract.issuer}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <UserLink user={seller_info} />
                    <Icon name="arrow right" color="red" />
                    <UserLink user={buyer_info} />
                  </Table.Cell>
                  <Table.Cell>
                    <OfferAmountLabel amount={accepted_offer.token_amount} />
                  </Table.Cell>
                  <Table.Cell>
                    <OfferPriceLabel price={accepted_offer.token_price} />
                  </Table.Cell>
                  <Table.Cell>
                    <AgeInfo iso str={created_at} />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <DetailsButton trade={trade} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </>
        )}
      </Table.Body>
      {pagination && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8} content={pagination} />
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

export default TradesTable
