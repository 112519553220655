import React from 'react'
import { Button, Segment, Grid, Header, Image } from 'semantic-ui-react'
import { GoBackButton, SaveMutation } from './common'
import FinancialDataForm from '../../components/FinancialData/FinancialDataForm'
import createAssetImage from '../../images/create_asset.svg'

const FinancialDataStepInfo = () => (
  <>
    <Header
      as="h2"
      color="purple"
      textAlign="left"
      content="ADD FINANCIAL DETAILS"
    />
    <div className="text-center">
      <Image size="large" centered src={createAssetImage} />
    </div>
  </>
)

const FinancialDataStep = ({ contract, formHook, stepsHook }) => {
  return (
    <Segment padded="very">
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <FinancialDataStepInfo />
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={16}
            mobile={16}
            verticalAling="middle"
          >
            <FinancialDataForm
              dataProps={formHook.field('financial_data')}
              documentProps={formHook.field('financial_documents')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <GoBackButton
              dirty={formHook.isDirty}
              goBack={stepsHook.prevStep}
            />
            <SaveMutation {...{ contract, formHook, stepsHook }}>
              {({ submit }) => (
                <Button
                  type="submit"
                  primary
                  floated="right"
                  content={formHook.isDirty ? 'Save & Next' : 'Next'}
                  onClick={submit}
                  data-test-id="tokenize-next"
                />
              )}
            </SaveMutation>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default FinancialDataStep
