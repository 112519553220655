import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Table, Button, Icon } from 'semantic-ui-react'
import { AgeInfo, DimmerLoader } from '../'
import { UserTypeLabel } from '../User'
import ActionApprovalStatus from '../Action/ActionApprovalStatus'
import { NoData } from '../NotFound'
import { useApproval } from '../../util/hooks'
import { ApprovalType } from '../../util/Constant'
import link from '../link'

const ProviderVerificationStatus = ({ user }) => {
  const approvalBD = useApproval(
    user.approvals.data,
    ApprovalType.VERIFICATION_BROKER_DEALER
  )
  const approvalFA = useApproval(
    user.approvals.data,
    ApprovalType.VERIFICATION_FINANCIAL_ADVISOR
  )
  return <ActionApprovalStatus approval={approvalBD || approvalFA} icon />
}

const CustomerVerificationStatus = ({ user, approvalType }) => {
  const approval = useApproval(user.approvals.data, approvalType)
  return <ActionApprovalStatus approval={approval} icon />
}

const AccountsTable = ({ accounts, loading, type, pagination }) => {
  if (!loading && accounts.length < 1) {
    return <NoData text="No result found" />
  }

  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            content={type === 'customers' ? 'CUSTOMER NAME' : 'PROVIDER NAME'}
          />
          <Table.HeaderCell content="TYPE" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell content="STATUS" />
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell>
              <DimmerLoader />
            </Table.Cell>
          </Table.Row>
        ) : (
          <>
            {accounts.map(account => {
              const accountLink =
                type === 'customers'
                  ? link.customer(account)
                  : link.provider(account)

              return (
                <Table.Row key={account.uuid}>
                  <Table.Cell>
                    <Link to={accountLink}>{account.fullname}</Link>
                    <p>{account.email}</p>
                  </Table.Cell>
                  <Table.Cell>
                    <UserTypeLabel user={account} />
                  </Table.Cell>
                  <Table.Cell>
                    <AgeInfo iso str={account.created_at} />
                  </Table.Cell>
                  <Table.Cell>
                    {type === 'customers' ? (
                      <>
                        <CustomerVerificationStatus
                          user={account}
                          approvalType={ApprovalType.VERIFICATION_KYC}
                        />
                        <CustomerVerificationStatus
                          user={account}
                          approvalType={ApprovalType.VERIFICATION_ACC}
                        />
                      </>
                    ) : (
                      <ProviderVerificationStatus user={account} />
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      className="p-10"
                      primary
                      content={<Icon name="chevron right" className="m-0" />}
                      as={Link}
                      to={accountLink}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </>
        )}
      </Table.Body>
      {pagination && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={5}>{pagination}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

AccountsTable.propTypes = {
  type: PropTypes.oneOf(['customers', 'providers']),
}

export default AccountsTable
