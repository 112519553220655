import React from 'react'
import { Button, Grid, Header, Segment, Table } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { AgeInfo, AmountLabel } from '../../components'
import { useModal } from '../../util/hooks'
import { client, GET_DRAFT_CONTRACTS } from '../../util/API/Apollo'
import { SuccessModal } from '../../components/confirm'
import RemoveContractButton from '../Contract/RemoveContractButton'
import link, { CompanyLink } from '../link'

const DraftContracts = ({ ...props }) => {
  const { data } = useQuery(GET_DRAFT_CONTRACTS, { client })
  const contracts = _.get(data, 'contracts.data', [])
  const deleteSuccessModal = useModal()

  if (contracts.length === 0) return null
  return (
    <Grid.Row {...props}>
      <Grid.Column>
        <Segment padded="very">
          <Header as="h2" content="DRAFT ASSETS" />
          <SuccessModal
            content="Draft contract deleted!"
            {...deleteSuccessModal.props}
          />
          <Table basic="very" padded size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="ISSUER" />
                <Table.HeaderCell content="AMOUNT" />
                <Table.HeaderCell content="AGE" />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contracts.map(contract => (
                <Table.Row key={contract.uuid}>
                  <Table.Cell>
                    <CompanyLink company={contract.issuer} />
                  </Table.Cell>
                  <Table.Cell>
                    <AmountLabel amount={contract.token_numbers.initial} />
                  </Table.Cell>
                  <Table.Cell>
                    <AgeInfo iso str={contract.created_at} />
                  </Table.Cell>
                  <Table.Cell textAlign="right" singleLine>
                    <RemoveContractButton
                      contract={contract}
                      onDeleted={deleteSuccessModal.show}
                    />
                    <Button
                      primary
                      content="continue"
                      as={Link}
                      to={link.draftContract(contract)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  )
}

export default DraftContracts
