import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Segment, Header, Divider } from 'semantic-ui-react'
import {
  CSBreadcrumb,
  SingleGrid,
  AgeInfo,
  ContentLoader,
  APIPagination,
  DimmerLoader,
} from '../../components'
import { NoData } from '../../components/NotFound'
import { usePage } from '../../util/hooks'
import { client, GET_ACCOUNT_ACTIVITY } from '../../util/API/Apollo'

const COUNT = 25

const Activity = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const { data, loading } = useQuery(GET_ACCOUNT_ACTIVITY, {
    variables: { page, count: COUNT },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const activities = _.get(data, 'me.recent_activity.data', [])
  const paginatorInfo = _.get(data, 'me.recent_activity.paginatorInfo')

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="Account Activity" />

      <SingleGrid padded>
        {activities.length > 0 ? (
          <Segment padded="very">
            <Header as="h2" color="black" content="ACCOUNT ACTIVITY" />
            {loading && <DimmerLoader />}
            {activities.map((activity, index) => (
              <Fragment key={index}>
                <Header sub content={activity.description} className="my-0" />
                <small>
                  <AgeInfo iso str={activity.created_at} />
                </small>
                {index < activities.length - 1 && <Divider />}
              </Fragment>
            ))}
            <APIPagination
              paginatorInfo={paginatorInfo}
              handleChange={setPage}
              count={COUNT}
            />
          </Segment>
        ) : (
          <NoData text="THERE ARE NO ACCOUNT ACTIVITIES YET" fluid />
        )}
      </SingleGrid>
    </>
  )
}

export default Activity
