import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Segment, Grid, Form, Input } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  ContentLoader,
  SingleGrid,
} from '../components'
import { ProvidersWidget } from '../components/Widget'
import { AccountsTable } from '../components/Account'
import { NoData } from '../components/NotFound'
import { usePage, useDebounceQuery, useDashboardData } from '../util/hooks'
import {
  GET_SYSTEM_PROVIDERS,
  SEARCH_OFFICER,
  REQUEST_DATA_EXPORT,
  client,
} from '../util/API/Apollo'
import { DataExportType } from '../util/Constant'
import addProviderImage from '../images/add_provider.svg'
import link from '../components/link'

const COUNT = 10

const Providers = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)
  const [input, setInput] = useState('')
  const noSearch = input.length < 2

  const {
    providers: providersInfo,
    loading: statisticsLoading,
  } = useDashboardData()

  const systemProviders = useQuery(GET_SYSTEM_PROVIDERS, {
    variables: {
      count: COUNT,
      page,
      orderBy: [{ field: 'created_at', order: 'DESC' }],
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const providers = _.get(systemProviders, 'data.systemProviders.data', [])
  const paginatorInfo = _.get(
    systemProviders,
    'data.systemProviders.paginatorInfo'
  )

  const systemProviderSearch = useDebounceQuery(SEARCH_OFFICER, {
    variables: { fullName: input, count: COUNT },
    skip: noSearch,
    client,
  })

  const providerSearch = _.get(systemProviderSearch, 'data.searchOfficer.data')

  const [exportData, exportDataMutation] = useMutation(REQUEST_DATA_EXPORT, {
    variables: { export_type: DataExportType.PROVIDERS },
    client,
  })

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title="Providers" />
      {providers.length > 0 ? (
        <SingleGrid padded>
          <Grid className="py-15">
            <Grid.Row className="py-0">
              <Grid.Column>
                <Button
                  primary
                  floated="right"
                  as={Link}
                  to={link.newProvider}
                  content="Add new provider"
                />
                <Button
                  floated="right"
                  content="export providers"
                  onClick={exportData}
                  loading={exportDataMutation.loading}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ProvidersWidget
                  data={providersInfo}
                  loading={statisticsLoading}
                  divided
                />
                <Segment padded="very">
                  <Form>
                    <Form.Field
                      fluid
                      control={Input}
                      value={input}
                      loading={systemProviderSearch.loading}
                      onChange={(e, { value }) => setInput(value)}
                      placeholder="Search Provider"
                      icon="search"
                    />
                  </Form>
                  <br />
                  <AccountsTable
                    accounts={providerSearch || providers}
                    loading={
                      systemProviderSearch.loading || systemProviders.loading
                    }
                    type="providers"
                    pagination={
                      !providerSearch && (
                        <APIPagination
                          paginatorInfo={paginatorInfo}
                          handleChange={setPage}
                          count={COUNT}
                        />
                      )
                    }
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SingleGrid>
      ) : (
        <NoData
          image={addProviderImage}
          text="there are no providers yet"
          fluid
        >
          <Button
            primary
            as={Link}
            to={link.newProvider}
            content="Add new provider"
          />
        </NoData>
      )}
    </>
  )
}

export default Providers
