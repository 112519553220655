import React, { useMemo } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Button } from 'semantic-ui-react'
import MakeOfferModal from './MakeOfferModal'
import { PageLoader } from '../index'
import { SuccessModal } from '../confirm'
import useForm from '../../util/hooks/form'
import { useModal } from '../../util/hooks'
import { useMe } from '../../util/hooks/me'
import {
  client,
  MAKE_OFFER,
  MAKE_COUNTER_OFFER,
  GET_LISTING_OFFERS,
  GET_OFFER_DETAILS,
} from '../../util/API/Apollo'
import link from '../link'

const MakeOfferAction = ({
  listing,
  lastOffer,
  title,
  disabled,
  previousOffers,
}) => {
  const { isOwner } = useMe()
  const makeOfferModal = useModal()
  const makeOfferSuccessModal = useModal()
  let defaultForm = useMemo(
    () => ({ amount: listing.amount, price: listing.price }),
    [listing.amount, listing.price]
  )
  const formHook = useForm('make-offer', defaultForm)

  const [makeOffer, makeOfferMutation] = useMutation(MAKE_OFFER, {
    variables: {
      input: {
        listing_id: listing.uuid,
        token_amount: formHook.form.amount,
        token_price: formHook.form.price,
        buy: false,
      },
    },
    onCompleted: () => {
      makeOfferModal.hide()
      makeOfferSuccessModal.show()
    },
    refetchQueries: [
      { query: GET_OFFER_DETAILS, variables: { uuid: listing.uuid } },
      {
        query: GET_LISTING_OFFERS,
        variables: { listing_id: listing.uuid },
      },
    ],
    awaitRefetchQueries: true,
    client,
  })

  const [makeCounterOffer, makeCounterOfferMutation] = useMutation(
    MAKE_COUNTER_OFFER,
    {
      variables: {
        input: {
          offer_id: _.get(lastOffer, 'uuid'),
          token_amount: formHook.form.amount,
          token_price: formHook.form.price,
        },
      },
      onCompleted: () => {
        makeOfferModal.hide()
        makeOfferSuccessModal.show()
      },
      refetchQueries: [
        {
          query: GET_OFFER_DETAILS,
          variables: { uuid: _.get(lastOffer, 'uuid') },
        },
      ],
      awaitRefetchQueries: true,
      client,
    }
  )

  const offerTo = isOwner({ listing }) ? 'buyer' : 'seller'

  return (
    <>
      <PageLoader
        active={makeOfferMutation.loading || makeCounterOfferMutation.loading}
      />

      <Button
        primary
        fluid
        size="small"
        content={title || 'MAKE OFFER'}
        onClick={makeOfferModal.show}
        disabled={Boolean(disabled)}
      />
      <br />

      <MakeOfferModal
        listing={listing}
        formHook={formHook}
        onComplete={lastOffer ? makeCounterOffer : makeOffer}
        previousOffers={previousOffers}
        {...makeOfferModal.props}
      />

      {makeOfferMutation.data && (
        <SuccessModal
          content={
            <>
              You have successfully made an offer. The {offerTo} will be
              notified of your offer. You will be notified if and when your
              offer is accepted by the {offerTo}.
            </>
          }
          {...makeOfferSuccessModal.props}
          button={{
            as: Link,
            to: link.offer(makeOfferMutation.data.makeOffer),
          }}
        />
      )}
    </>
  )
}

export default MakeOfferAction
