import React from 'react'
import { Button, Divider, Header, Icon, Segment } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
  ActionRouteTypeMap,
  ApprovalType,
  ContractTypeNames,
  Info,
  ProviderActionType,
} from '../util/Constant'
import { client, GET_PROVIDER_ACTIONS } from '../util/API/Apollo'
import {
  AgeInfo,
  AmountLabel,
  APIPagination,
  AskPriceLabel,
  CSBreadcrumb,
  DimmerLoader,
  SingleGrid,
} from '../components'
import { useApproval, usePage } from '../util/hooks'
import CoolTable from '../components/CoolTable'
import { UserTypeLabel } from '../components/User'
import ActionApprovalStatus from '../components/Action/ActionApprovalStatus'
import ActionTypeLabel from '../components/Action/ActionTypeLabel'
import {
  ContractAmountLabel,
  ContractStatusIcon,
  ContractTradeStatusIcon,
} from '../components/Contract/Contract'
import link, { CompanyLink, ContractLink, UserLink } from '../components/link'

const ActionDetailsButton = ({ action }) => {
  return (
    <Button
      primary
      as={Link}
      to={link.action(action)}
      content={<Icon name="chevron right" className="m-0" />}
      className="p-10"
    />
  )
}

const KYCStatusIcon = ({ action }) => {
  const approval = useApproval(action.approvals, ApprovalType.VERIFICATION_KYC)
  return <ActionApprovalStatus icon approval={approval} />
}
const ACCStatusIcon = ({ action }) => {
  const approval = useApproval(action.approvals, ApprovalType.VERIFICATION_ACC)
  return <ActionApprovalStatus icon approval={approval} />
}
const ProviderActionStatusIcon = ({ action }) => {
  const bdApproval = useApproval(
    action.approvals,
    ApprovalType.VERIFICATION_BROKER_DEALER
  )
  const faApproval = useApproval(
    action.approvals,
    ApprovalType.VERIFICATION_FINANCIAL_ADVISOR
  )
  const approval = bdApproval || faApproval
  return <ActionApprovalStatus icon approval={approval} />
}

export const getTableData = ({ action_type, actions }) => {
  switch (action_type) {
    case ProviderActionType.CUSTOMER_APPROVAL:
    case ProviderActionType.CUSTOMER_KYC:
    case ProviderActionType.CUSTOMER_ACC:
      return {
        headers: [
          { content: 'Customer' },
          { content: 'Customer Type' },
          { content: 'Age' },
          { content: 'Status' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            { content: <UserLink user={action.user} /> },
            { content: <UserTypeLabel user={action.actionable} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            {
              content: (
                <>
                  <KYCStatusIcon action={action} />
                  <ACCStatusIcon action={action} />
                </>
              ),
            },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }
    case ProviderActionType.CUSTOMER_MSG:
    case ProviderActionType.PROVIDER_REQUEST:
      return {
        headers: [
          { content: 'Customer' },
          { content: 'Customer Type' },
          { content: 'Age' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            { content: <UserLink user={action.user} /> },
            { content: <UserTypeLabel user={action.actionable} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }

    case ProviderActionType.PROVIDER_APPROVAL:
      return {
        headers: [
          { content: 'Provider' },
          { content: 'Provider Type' },
          { content: 'Age' },
          { content: 'Status' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            { content: <UserLink user={action.user} /> },
            { content: <UserTypeLabel user={action.actionable} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            { content: <ProviderActionStatusIcon action={action} /> },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }
    case ProviderActionType.ASSET_TRANSFERABILITY:
      return {
        headers: [
          { content: 'Asset ID' },
          { content: 'Asset Type' },
          { content: 'Issuer', info: Info.COMPANY },
          { content: 'Amount' },
          { content: 'Age' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            {
              content: (
                <>
                  <ContractStatusIcon contract={action.actionable} />
                  <ContractTradeStatusIcon contract={action.actionable} />
                  <ContractLink contract={action.actionable} />
                </>
              ),
            },
            { content: ContractTypeNames[action.actionable.contract_type] },
            { content: <CompanyLink company={action.actionable.issuer} /> },
            { content: <ContractAmountLabel contract={action.actionable} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }
    case ProviderActionType.COMPANY_VERIFICATION:
      return {
        headers: [
          { content: 'Company Name' },
          { content: 'Added by' },
          { content: 'Age' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            { content: <CompanyLink company={action.actionable} /> },
            { content: <UserLink user={action.user} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }
    case ProviderActionType.TRADE_APPROVAL:
      return {
        headers: [
          { content: 'Transfer' },
          { content: 'Transfer amount' },
          { content: 'Transfer price' },
          { content: 'Age' },
          { collapsing: true },
        ],
        data: actions.map(action => {
          const offer = action.actionable.accepted_offer
          return {
            key: action.uuid,
            cellData: [
              {
                content: (
                  <>
                    <UserLink user={action.seller_info} />
                    <Icon name="arrow right" color="red" />
                    <UserLink user={action.buyer_info} />
                  </>
                ),
              },
              {
                content: <AmountLabel amount={offer.token_amount} />,
              },
              {
                content: <AskPriceLabel price={offer.token_price} />,
              },
              { content: <AgeInfo iso str={action.created_at} /> },
              { content: <ActionDetailsButton action={action} /> },
            ],
          }
        }),
      }
    //  SHOW ALL
    case undefined:
      return {
        headers: [
          { content: 'Action Type' },
          { content: 'Requester Name' },
          { content: 'Age' },
          { collapsing: true },
        ],
        data: actions.map(action => ({
          key: action.uuid,
          cellData: [
            {
              content: <ActionTypeLabel action_type={action.action_type} />,
              collapsing: true,
            },
            { content: <UserLink user={action.user} /> },
            { content: <AgeInfo iso str={action.created_at} /> },
            { content: <ActionDetailsButton action={action} /> },
          ],
        })),
      }

    default:
  }
}

const COUNT = 10
export const ProviderActionsTable = ({ action_type, isSimple }) => {
  const [page, setPage] = usePage(1)
  const { loading, data } = useQuery(GET_PROVIDER_ACTIONS, {
    variables: {
      action_type,
      page,
      count: COUNT,
      orderBy: [{ field: 'created_at', order: 'DESC' }],
    },
    client,
  })

  const actions = _.get(data, 'providerActions.data', [])
  const paginatorInfo = _.get(data, 'providerActions.paginatorInfo')

  const tableData = getTableData({ action_type, actions })

  return (
    <>
      {loading && <DimmerLoader />}
      {actions.length === 0 ? (
        <Segment basic textAlign="center">
          <Divider />
          <Header as="h4" icon color="black">
            <Icon name="ban" />
            NO ACTION FOUND
          </Header>
          <Divider />
        </Segment>
      ) : (
        <CoolTable
          {...tableData}
          footer={
            !isSimple && (
              <APIPagination
                count={COUNT}
                paginatorInfo={paginatorInfo}
                handleChange={setPage}
              />
            )
          }
        />
      )}
    </>
  )
}

const headerMap = {
  [ProviderActionType.CUSTOMER_APPROVAL]: 'Customer Approvals',
  [ProviderActionType.CUSTOMER_MSG]: 'Customer Medallion Stamps',
  [ProviderActionType.ASSET_TRANSFERABILITY]: 'Asset Transferability',
  [ProviderActionType.PROVIDER_APPROVAL]: 'Provider Approvals',
  [ProviderActionType.TRADE_APPROVAL]: 'Trade Payments',
  [ProviderActionType.PROVIDER_REQUEST]: 'Provider Requests',
}

const ProviderActions = () => {
  const { pathname } = useLocation()
  const actionType = ActionRouteTypeMap[pathname]
  const header = headerMap[actionType]

  return (
    <>
      <CSBreadcrumb title={header} />
      <SingleGrid padded>
        <Segment padded="very">
          <ProviderActionsTable action_type={actionType} key={pathname} />
        </Segment>
      </SingleGrid>
    </>
  )
}

export default ProviderActions
