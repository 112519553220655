import React, { Fragment } from 'react'
import { Segment, Item, Divider, Dropdown, Icon } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { NoData } from '../../NotFound'
import { ActionButton, AgeInfo, DimmerLoader } from '../../index'
import { useMe } from '../../../util/hooks/me'
import {
  client,
  GET_LAST_NOTIFICATIONS,
  READ_NOTIFICATION,
  DELETE_NOTIFICATION,
} from '../../../util/API/Apollo'
import userPlaceholder from '../../../images/user-placeholder.png'
import link from '../../link'

const MessageItem = ({ notif }) => {
  const { from, content } = notif.data
  const { isCustomer } = useMe()

  const [read] = useMutation(READ_NOTIFICATION, {
    client,
    variables: { notification_id: notif.uuid },
    refetchQueries: [{ query: GET_LAST_NOTIFICATIONS }],
  })

  const [remove, { loading: removeLoading }] = useMutation(
    DELETE_NOTIFICATION,
    {
      variables: { notification_id: notif.uuid },
      refetchQueries: [{ query: GET_LAST_NOTIFICATIONS }],
      awaitRefetchQueries: true,
      client,
    }
  )

  return (
    <Segment basic className="clickable m-0">
      <Item.Group>
        <Item
          as={Link}
          to={isCustomer ? link.allUserMessages : link.userMessages(from)}
          onClick={read}
          style={{ position: 'relative' }}
        >
          <DimmerLoader active={removeLoading} />
          <Item.Image
            style={{
              width: '40px',
              height: '40px',
            }}
            size="mini"
            src={_.get(from, 'profile_photo') || userPlaceholder}
            alt={_.get(from, 'fullname')}
          />

          <Item.Content>
            <Item.Header>
              {_.get(from, 'fullname')}
              {!notif.read_at && (
                <Icon name="bell" color="red" className="ml-5" size="small" />
              )}
            </Item.Header>

            <Dropdown
              icon="ellipsis horizontal"
              style={{ float: 'right' }}
              simple
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Remove"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    remove()
                  }}
                />
                {!notif.read_at && (
                  <Dropdown.Item
                    text="Mark as read"
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      read()
                    }}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>

            <Item.Description>{content}</Item.Description>
            <Item.Extra>
              <AgeInfo iso simple str={notif.created_at} />
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

const UserMessages = ({ notifications }) => {
  if (notifications.length === 0) {
    return (
      <>
        <NoData text="You do not have any messages" size="small" />
        <div className="text-center">
          <ActionButton
            primary
            content="Send message"
            className="my-10"
            size="small"
            icon="paper plane"
            type={undefined}
            as={Link}
            to={link.messages}
          />
        </div>
      </>
    )
  }

  return (
    <>
      {notifications.map((notif, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <Divider className="m-0" />}
            <MessageItem notif={notif} />
          </Fragment>
        )
      })}
    </>
  )
}

export default UserMessages
