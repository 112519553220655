import React from 'react'
import { Button, Divider, Grid, Header, Icon, Modal } from 'semantic-ui-react'

export const ConfirmModal = ({
  open,
  onConfirm,
  onClose,
  header,
  content,
  yesButton = {},
  noButton = {},
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} basic size="tiny" {...props}>
      <Header as="h2" content={header || 'Are you sure?'} />
      <Modal.Content className="py-none">
        {content && <p>{content}</p>}
        <Divider />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          inverted
          color="red"
          icon="remove"
          labelPosition="right"
          type="button"
          onClick={onClose}
          {...noButton}
        />
        <Button
          content="OK"
          color="green"
          inverted
          icon="checkmark"
          labelPosition="right"
          type="button"
          onClick={() => {
            onConfirm()
            onClose()
          }}
          {...yesButton}
        />
      </Modal.Actions>
    </Modal>
  )
}

export const ErrorModal = ({ open, onClose, header, content, button = {} }) => {
  return (
    <Modal open={open} onClose={onClose} basic size="tiny">
      <Modal.Content>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Icon name="times circle" size="huge" />
              <Header as="h2" inverted content={header || 'Error'} />
              {content && <p>{content}</p>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider />
              <Button
                type="button"
                inverted
                content="OK"
                onClick={onClose}
                {...button}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export const SuccessModal = ({ open, onClose, header, content, button }) => {
  return (
    <Modal open={open} onClose={onClose} basic size="tiny">
      <Modal.Content>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Icon name="check" size="huge" />
              <Header as="h2" inverted content={header || 'Done!'} />
              {content && <p>{content}</p>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider />
              <Button
                inverted
                color="green"
                content="OK"
                onClick={onClose}
                {...button}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}
