import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL REVENUE',
    key: 'total_revenue',
    isPrice: true,
  },
  {
    title: 'PURCHASES',
    key: 'purchases_volume',
    isPrice: true,
  },
  {
    title: 'SALES',
    key: 'sales_volume',
    isPrice: true,
  },
  {
    title: 'SERVICES FEES PAID',
    key: 'services_fees',
    isPrice: true,
  },
]

const FinancialWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
  style,
  ...props
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      dataSchema={dataSchema}
      title="FINANCIAL SUMMARY"
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
      style={style}
      {...props}
    />
  )
}

export default FinancialWidget
