import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { CSBreadcrumb, SingleGrid } from '../../components'
import { Form, Header, Input, Item, Segment } from 'semantic-ui-react'
import useForm from '../../util/hooks/form'
import { useDebounceQuery } from '../../util/hooks'
import { client, SEARCH_ALL } from '../../util/API/Apollo'
import useSearchResults from '../../components/Search/useSearchResults'
import TabMenu from '../../components/TabMenu'
import util from '../../util'
import { NoData } from '../../components/NotFound'

// const categories = [
//   { key: 'all', text: 'All', value: 'all' },
//   { key: 'contract', text: 'Assets', value: 'contract' },
//   { key: 'token', text: 'Tokens', value: 'token' },
//   { key: 'company', text: 'Companies', value: 'company' },
//   { key: 'listing', text: 'Listings', value: 'listing' },
//   { key: 'offer', text: 'Offers', value: 'offer' },
//   { key: 'trade', text: 'Trades', value: 'trade' },
// ]

/**
 * @param result {{ contract: { results, total }, token, company, ...}}
 * @param field {string}
 * @param size {number}
 * @param title {boolean}
 * @constructor
 */
const ResultSet = ({ results, field, size, title }) => {
  if (!results || !results[field]) return null
  let filteredResults = results[field].results
  if (size) filteredResults = filteredResults.slice(0, size)

  return (
    <>
      {title && <Header as="h2" content={results[field].name} />}
      <Item.Group divided>
        {filteredResults.map(r => {
          return (
            <Item key={r.item.hashid}>
              <Item.Content>
                <Item.Header as={Link} to={r.to}>
                  {r.content}
                </Item.Header>
                <Item.Extra>{r.item.hashid}</Item.Extra>
              </Item.Content>
            </Item>
          )
        })}
      </Item.Group>
    </>
  )
}

const initialForm = {
  search: '',
}
const Search = () => {
  const { field, form, setForm } = useForm('search', initialForm)
  const { query, category } = useParams()
  React.useEffect(() => {
    setForm({
      search: query ? query : '',
    })
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const showSearch = form.search.length >= 2
  const { loading, data } = useDebounceQuery(SEARCH_ALL, {
    variables: { search: form.search, count: 20 },
    skip: !showSearch,
    client,
  })
  const results = useSearchResults({ data })
  const noResult = util.isEmptyObject(results)

  const total = Object.keys(results).reduce(
    (sum, field) => sum + results[field].total,
    0
  )
  // // debug
  // React.useEffect(() => {
  //   window.dev.search()
  // }, [])

  return (
    <>
      <CSBreadcrumb title="Search" />
      <SingleGrid padded>
        <Segment padded="very">
          <Form>
            <Form.Field
              icon="search"
              iconPosition="left"
              fluid
              placeholder="ID, text, etc..."
              data-test-id="search-input"
              control={Input}
              loading={loading}
              {...field('search')}
            />
          </Form>
          {showSearch && noResult && !loading && (
            <NoData text="No result" className="my-40" />
          )}
          {!showSearch && noResult && (
            <Header
              as="h2"
              textAlign="center"
              className="my-50"
              content="Search something"
            />
          )}
          <TabMenu top defaultTab={category ? category : 'all'}>
            {!noResult && (
              <TabMenu.Top>
                <TabMenu.Item name="all" content={`All (${total})`} />
                {results.contracts && (
                  <TabMenu.Item
                    name="contracts"
                    content={`Contracts (${results.contracts.total})`}
                  />
                )}
                {results.tokens && (
                  <TabMenu.Item
                    name="tokens"
                    content={`Tokens (${results.tokens.total})`}
                  />
                )}
                {results.companies && (
                  <TabMenu.Item
                    name="companies"
                    content={`Companies (${results.companies.total})`}
                  />
                )}
                {results.listings && (
                  <TabMenu.Item
                    name="listings"
                    content={`Listings (${results.listings.total})`}
                  />
                )}
                {results.offers && (
                  <TabMenu.Item
                    name="offers"
                    content={`Offers (${results.offers.total})`}
                  />
                )}
                {results.trades && (
                  <TabMenu.Item
                    name="trades"
                    content={`Trades (${results.trades.total})`}
                  />
                )}
              </TabMenu.Top>
            )}
            <TabMenu.Bottom>
              <TabMenu.Pane name="all">
                <ResultSet results={results} field="contracts" size={5} title />
                <ResultSet results={results} field="tokens" size={5} title />
                <ResultSet results={results} field="companies" size={5} title />
                <ResultSet results={results} field="listings" size={5} title />
                <ResultSet results={results} field="offers" size={5} title />
                <ResultSet results={results} field="trades" size={5} title />
              </TabMenu.Pane>
              <TabMenu.Pane name="contracts">
                <ResultSet results={results} field="contracts" />
              </TabMenu.Pane>
              <TabMenu.Pane name="tokens">
                <ResultSet results={results} field="tokens" />
              </TabMenu.Pane>
              <TabMenu.Pane name="companies">
                <ResultSet results={results} field="companies" />
              </TabMenu.Pane>
              <TabMenu.Pane name="listings">
                <ResultSet results={results} field="listings" />
              </TabMenu.Pane>
              <TabMenu.Pane name="offers">
                <ResultSet results={results} field="offers" />
              </TabMenu.Pane>
              <TabMenu.Pane name="trades">
                <ResultSet results={results} field="trades" />
              </TabMenu.Pane>
            </TabMenu.Bottom>
          </TabMenu>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default Search
