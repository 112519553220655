import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import {
  Icon,
  Form,
  Image,
  Input,
  Segment,
  Dimmer,
  Header,
} from 'semantic-ui-react'
import * as PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import Toast from '../util/Toast'
import util from '../util'
import { AssetCodes } from '../util/Constant'
import {
  client,
  UPLOAD_DOCUMENT,
  UPLOAD_IMAGE,
  UPLOAD_MEDIA,
} from '../util/API/Apollo'
import useForm from '../util/hooks/form'

/**
 * Drag and drop file selector for wallet json keys and csv files
 */
export const FileSelect = ({
  value,
  accept,
  disabled,
  error,
  onChange = _.noop,
  ...props
}) => {
  const handleDropAccepted = acceptedFiles => {
    const file = acceptedFiles[0]
    onChange(file, { ...props, value: file })
  }

  const handleDropRejected = () => {
    Toast.error({
      header: 'Error!',
      content: 'File is invalid',
    })
  }

  const className = props.error ? 'error' : props.disabled ? 'disabled' : ''
  return (
    <Dropzone
      multiple={false}
      accept={accept}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps({ className: `dropzone ${className}` })}>
            <input {...getInputProps()} />
            {value && (
              <p className="text-black text-center">
                {value.original_filename || value.name}
              </p>
            )}
            <p>Drag and drop the file here, or click to select</p>
          </div>
        )
      }}
    </Dropzone>
  )
}

const FileUpload = ({
  maxSize = 20 * 1024 * 1024,
  accept = 'application/pdf, image/jpeg, image/png, image/gif',
  asset_code,
  image,
  name,
  value,
  onChange,
  onError,
  children,
  media,
  title,
  description,
  disabled,
  error,
  ...props
}) => {
  const [asset, setAsset] = React.useState(null)

  let mutation, variables
  if (image) {
    mutation = UPLOAD_IMAGE
    variables = { asset_code }
  } else if (media) {
    mutation = UPLOAD_MEDIA
    variables = { title, description }
  } else {
    mutation = UPLOAD_DOCUMENT
    variables = { asset_code }
  }

  const [upload, { loading, errors }] = useMutation(mutation, {
    client,
    variables: { ...variables, asset },
    onCompleted: data => {
      if (errors) {
        Toast.error({
          header: 'Error!',
          content: errors[0].message,
        })
        if (onError) onError(errors[0])
      } else {
        const uploadedFile =
          data.uploadDocument || data.uploadMedia || data.uploadImage
        if (onChange) {
          onChange(uploadedFile, { name, ...props, value: uploadedFile })
        }
      }
    },
  })
  const handleDropAccepted = async acceptedFiles => {
    setAsset(acceptedFiles[0])
    await upload()
  }

  const handleDropRejected = () => {
    Toast.error({
      header: 'Error!',
      content: 'Document cannot be larger than 20 megabytes',
    })
  }

  return (
    <Dropzone
      multiple={false}
      maxSize={maxSize}
      accept={accept}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps, open }) => {
        return children({
          getRootProps,
          getInputProps,
          file: value,
          loading,
          open,
        })
      }}
    </Dropzone>
  )
}

FileUpload.propTypes = {
  maxSize: PropTypes.number, // in bytes
  accept: PropTypes.string, // ReactDropzone formatted file types
  asset_code: PropTypes.string, // for API
  disabled: PropTypes.bool,
  image: PropTypes.bool, // call image upload endpoint if true
  value: PropTypes.shape({
    uuid: PropTypes.string,
    original_filename: PropTypes.string,
    link: PropTypes.string,
    link_as_avatar: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func,
}

export const DocumentUpload = ({ ...props }) => {
  const className = props.error ? 'error' : props.disabled ? 'disabled' : ''
  return (
    <FileUpload {...props}>
      {({ getRootProps, getInputProps, file, loading }) => (
        <Segment basic loading={loading} className="p-none mb-0">
          <div {...getRootProps({ className: `dropzone ${className}` })}>
            <input {...getInputProps()} name={props.name} />
            {file && (
              <p className="text-black text-center">{file.original_filename}</p>
            )}
            <p>Drag and drop the file here, or click to select</p>
          </div>
        </Segment>
      )}
    </FileUpload>
  )
}

const initialForm = { title: '', description: '' }
export const MediaUpload = ({ ...props }) => {
  const { form, field } = useForm(`upload-${props.name}`, initialForm)
  const className = props.error ? 'error' : props.disabled ? 'disabled' : ''
  return (
    <FileUpload media {...form} disabled={!form.title} {...props}>
      {({ getRootProps, getInputProps, file, loading }) => (
        <Segment basic loading={loading} className="p-none mb-0">
          <Form>
            <Form.Field
              label="Title"
              placeholder="Title"
              required
              control={Input}
              {...field('title')}
            />
            <Form.Field
              label="Description"
              placeholder="Description"
              control={Input}
              {...field('description')}
            />
          </Form>
          <br />
          <div {...getRootProps({ className: `dropzone ${className}` })}>
            <input {...getInputProps()} name={props.name} />
            {file && (
              <p className="text-black text-center">{file.original_filename}</p>
            )}
            <p>Drag and drop the file here, or click to select</p>
          </div>
        </Segment>
      )}
    </FileUpload>
  )
}

/**
 * @param button {object} upload logo button props
 * @param placeholder {string} placeholder image
 * @param uploadText {string} button content when no image uploaded
 * @param changeText {string} button content when image is uploaded
 * @param props {object}
 */
export const ImageUpload = ({
  button,
  placeholder,
  uploadText = 'Upload profile picture',
  changeText = 'Change profile picture',
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <FileUpload asset_code={AssetCodes.company_logo} {...props}>
      {({ getInputProps, file, loading, open }) => {
        return (
          <>
            <Segment
              raised
              loading={loading}
              className="p-none"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              onClick={open}
            >
              <Dimmer active={isHovering} style={{ cursor: 'pointer' }}>
                <Header sub inverted icon>
                  <Icon name={file ? 'refresh' : 'cloud upload'} />
                  <br />
                  {file ? changeText : uploadText}
                </Header>
              </Dimmer>
              <Image fluid rounded src={util.prependImage(file, placeholder)} />
              <input {...getInputProps()} name={props.name} />
            </Segment>
          </>
        )
      }}
    </FileUpload>
  )
}
