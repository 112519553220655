import React, { useState, useMemo, useRef } from 'react'
import {
  Button,
  Container,
  Dropdown,
  Header,
  Popup,
  Divider,
} from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import CreateCompanyModal from './CreateCompanyModal'
import { client, SEARCH_COMPANY } from '../../util/API/Apollo'
import util from '../../util'
import { useModal } from '../../util/hooks'

const CompanySearchInput = ({
  value,
  onChange,
  disabled,
  type = 'company',
  autoFocus = false,
  ...props
}) => {
  const [input, setInput] = useState('') // input user entered used by query
  const [waiting, setWaiting] = useState(false)
  const inputTimeout = useRef(null)
  const createCompanyModal = useModal()

  const { loading, data } = useQuery(SEARCH_COMPANY, {
    variables: { search: input, page: 1, count: 25 },
    skip: input.length < 2,
    client,
  })

  const companies = _.get(data, 'searchCompanies.data', [])
  const hasMorePages = _.get(
    data,
    'searchCompanies.paginatorInfo.hasMorePages',
    false
  )

  const className = props.error && 'error'

  const results = useMemo(() => {
    // remove duplicates
    const filteredData = []
    const uniqueNames = new Set()
    for (const company of companies) {
      if (!uniqueNames.has(company.name)) {
        filteredData.push(company)
        uniqueNames.add(company.name)
      }
    }
    // manipulate data for semantic
    return filteredData.map(company => ({
      key: company.uuid,
      value: company.uuid,
      text: company.name,
      company,
    }))
  }, [companies])

  const handleSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length < 2) return
    clearTimeout(inputTimeout.current)
    setWaiting(true)
    inputTimeout.current = setTimeout(() => {
      setInput(searchQuery)
      setWaiting(false)
    }, 500)
  }

  const handleChange = (e, { value }) => {
    const company = results.find(c => c.value === value).company
    onChange(company, { ...props, value: company })
  }

  const handleCreateCompany = value => {
    onChange(value, { ...props, value })
  }

  let noResultMessage = <br />
  if (!(waiting || loading)) {
    if (input.length < 2) {
      noResultMessage = 'Enter at least 2 characters'
    } else {
      noResultMessage = (
        <Container textAlign="center">
          <Header as="h3" content={`${util.capitalize(type)} not found`} />
          <p>If the {type} is not listed, we will add</p>
          <Divider />
          <Button
            type="button"
            content={`Add New ${util.capitalize(type)}`}
            icon="plus"
            labelPosition="right"
            onClick={createCompanyModal.show}
            data-test-id="start-create-new-company"
          />
          <br />
        </Container>
      )
    }
  }

  return (
    <>
      <Popup
        disabled={!hasMorePages}
        content="Please narrow down your search"
        position="top center"
        inverted
        on="focus"
        trigger={
          <Dropdown
            search
            selection
            loading={loading || waiting}
            options={results}
            searchInput={{ name: 'company', autoFocus: autoFocus }}
            onSearchChange={handleSearchChange}
            onChange={handleChange}
            disabled={disabled}
            text={_.get(value, 'name', '')}
            noResultsMessage={noResultMessage}
            placeholder={`Enter ${util.capitalize(type)} Name`}
            className={className}
            icon="search"
            fluid
          />
        }
      />
      <CreateCompanyModal
        onComplete={handleCreateCompany}
        {...createCompanyModal.props}
      />
    </>
  )
}

export default CompanySearchInput
