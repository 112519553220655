import React from 'react'
import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { DimmerLoader, InfoTable, NumberFormat } from '../../components'
import { SuccessModal } from '../../components/confirm'
import { GoBackButton } from './common'
import { useModal } from '../../util/hooks'
import { client, DEPLOY_CONTRACT } from '../../util/API/Apollo'
import {
  AssetCodeNames,
  CONTRACT_DEPLOY_FEE,
  ContractTypeNames,
} from '../../util/Constant'
import PaymentModal from '../../components/PaymentModal'
import createAssetImage from '../../images/create_asset.svg'
import link, { CompanyLink, FileLink } from '../../components/link'

const PayDeployStepInfo = () => (
  <>
    <Header
      as="h2"
      color="purple"
      textAlign="left"
      content="COMPLETE YOUR ASSET"
    />
    <div className="text-center">
      <Image size="large" centered src={createAssetImage} />
    </div>
    <p>
      When you complete this step, the asset will be submitted to STC for
      enabling transferability before the asset can be transfered
    </p>
  </>
)

const ContractInfoTable = ({ contract }) => {
  return (
    <>
      <InfoTable
        data={[
          {
            header: 'Type',
            cell: ContractTypeNames[contract.contract_type],
          },
          {
            header: 'Issuer',
            cell: <CompanyLink company={contract.issuer} />,
          },
          {
            header: 'Fund amount',
            cell: <NumberFormat amount={contract.token_numbers.initial} />,
          },
          ...contract.documents.map(doc => {
            return {
              header: AssetCodeNames[doc.document.asset_code],
              cell: <FileLink file={doc.document} />,
            }
          }),
        ]}
      />
    </>
  )
}

const PayDeployStep = ({ contract, stepsHook }) => {
  const paymentModal = useModal()
  const successModal = useModal()
  const [deploy, deployMutation] = useMutation(DEPLOY_CONTRACT, {
    onCompleted: successModal.show,
    client,
  })

  const handlePayment = payment =>
    deploy({
      variables: {
        contract_id: contract.uuid,
        payment,
      },
    })

  return (
    <Segment padded="very">
      <DimmerLoader active={deployMutation.loading} />
      <PaymentModal
        {...paymentModal.props}
        amount={CONTRACT_DEPLOY_FEE}
        onTxSent={handlePayment}
      />
      {deployMutation.data && (
        <SuccessModal
          content="The Asset has been submitted to STC for confirmation & enabling of transferability. You can view your asset under “Asset Manager” on the sidebar."
          button={{
            as: Link,
            to: link.contract(deployMutation.data.deployContract),
          }}
          {...successModal.props}
        />
      )}

      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <PayDeployStepInfo />
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            <ContractInfoTable contract={contract} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              primary
              floated="right"
              type="button"
              icon="ethereum"
              content="complete"
              onClick={paymentModal.show}
              data-test-id="convert-to-smart-contract"
            />
            <Button
              floated="right"
              content="save & close"
              as={Link}
              to={link.contracts}
            />
            <GoBackButton goBack={stepsHook.prevStep} className="mr-5" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default PayDeployStep
