import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Segment, Grid, Button, Input } from 'semantic-ui-react'
import _ from 'lodash'
import { client, GET_ALL_TRADES } from '../../util/API/Apollo'
import {
  APIPagination,
  CSBreadcrumb,
  PageLoader,
  SingleGrid,
} from '../../components'
import { NoData } from '../../components/NotFound'
import noDataImage from '../../images/no_found.svg'
import { usePage, useDashboardData } from '../../util/hooks'
import TradesTable from '../../components/Trade/TradesTable'
import { TradesWidget } from '../../components/Widget'

const COUNT = 10

const AllOffers = () => {
  const [page, setPage] = usePage(1)
  const { data, loading } = useQuery(GET_ALL_TRADES, {
    variables: { page, count: 10 },
    client,
  })

  const { dashboard, loading: statisticsLoading } = useDashboardData()
  const paginatorInfo = _.get(data, 'activeTrades.paginatorInfo')
  const activeTrades = _.get(data, 'activeTrades.data', [])

  return (
    <>
      <CSBreadcrumb title="All Trades" />

      <SingleGrid padded>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            {activeTrades.length > 0 ? (
              <>
                <Grid className="py-15">
                  <Grid.Row className="p-0">
                    <Grid.Column>
                      <Button floated="right" content="export trades" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      {!statisticsLoading && (
                        <TradesWidget
                          divided
                          width={3}
                          data={dashboard.trades}
                        />
                      )}
                      <Segment padded="very">
                        <>
                          <Input
                            fluid
                            icon="search"
                            labelPosition="left"
                            placeholder="Search trade"
                          />
                          <br />
                          <TradesTable
                            trades={activeTrades}
                            pagination={
                              <APIPagination
                                paginatorInfo={paginatorInfo}
                                handleChange={setPage}
                                count={COUNT}
                              />
                            }
                          />
                        </>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            ) : (
              <NoData
                image={noDataImage}
                text="THERE ARE NO TRADES YET"
                fluid
              />
            )}
          </>
        )}
      </SingleGrid>
    </>
  )
}

export default AllOffers
