import React from 'react'
import { Button, Divider, Segment } from 'semantic-ui-react'
import { useTab, useWalletBalance } from '../../../util/hooks'
import DecryptWithPK from './DecryptWithPK'
import DecryptWithKeystore from './DecryptWithKeystore'
import { DimmerLoader } from '../../index'
import { useMe } from '../../../util/hooks/me'

const WalletDecryptor = ({ onDecrypted }) => {
  const { primary_wallet } = useMe()
  const [wallet, setWallet] = React.useState(null)
  const walletBalance = useWalletBalance(primary_wallet)

  // pk|keystore
  const { itemProps, isActive } = useTab('pk', { disabled: true })

  return (
    <Segment color="black" padded="very">
      <DimmerLoader active={walletBalance.loading} />
      <Button.Group fluid size="large">
        <Button
          icon="key"
          content="WITH PRIVATE KEY"
          data-test-id="decrypt-with-pk"
          {...itemProps('pk')}
        />
        <Button
          icon="file"
          content="WITH KEYSTORE"
          data-test-id="decrypt-with-keystore"
          {...itemProps('keystore')}
        />
      </Button.Group>
      <Divider hidden />

      {isActive('pk') && (
        <DecryptWithPK
          onDecrypted={setWallet}
          decryptedWallet={wallet}
          onSubmit={() => onDecrypted(wallet)}
        />
      )}
      {isActive('keystore') && (
        <DecryptWithKeystore
          onDecrypted={onDecrypted}
          decryptedWallet={wallet}
        />
      )}
    </Segment>
  )
}
export default WalletDecryptor
