import React from 'react'
import { Segment, Grid } from 'semantic-ui-react'
import StepWizard from '../../StepWizard'
import ProfilePreview from './ProfilePreview'
import AccountTypeStep from './Steps/AccountTypeStep'
import BasicInfoStep from './Steps/BasicInfoStep'
import ContactInfoStep from './Steps/ContactInfoStep'
import CompanyInfoStep from './Steps/CompanyInfoStep'
import BankInfoStep from './Steps/BankInfoStep'
import {
  AccountType,
  BankInfo,
  BasicInfo,
  CompanyInfo,
  ContactInfo,
} from './StepsInstruction'

const CurrentStepInfo = ({ stepsHook, isProvider }) => {
  return (
    <>
      {stepsHook.isActive('type') && <AccountType isProvider={isProvider} />}
      {stepsHook.isActive('basic') && <BasicInfo />}
      {stepsHook.isActive('contact') && <ContactInfo />}
      {stepsHook.isActive('company') && <CompanyInfo />}
      {stepsHook.isActive('bank') && <BankInfo />}
    </>
  )
}

const ProfileForm = ({
  formHook,
  stepsHook,
  isCustomer = false,
  isProvider = false,
  isEditing = false,
  onComplete,
}) => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <CurrentStepInfo stepsHook={stepsHook} isProvider={isProvider} />
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={16}
            mobile={16}
            verticalAlign="middle"
          >
            {!isEditing && <StepWizard stepsHook={stepsHook} />}
            <Segment padded="very">
              {stepsHook.activeStep !== 'type' && (
                <ProfilePreview formHook={formHook} />
              )}
              {stepsHook.isActive('type') && (
                <AccountTypeStep
                  formHook={formHook}
                  stepsHook={stepsHook}
                  isProvider={isProvider}
                  isCustomer={isCustomer}
                />
              )}
              {stepsHook.isActive('basic') && (
                <BasicInfoStep formHook={formHook} stepsHook={stepsHook} />
              )}
              {stepsHook.isActive('contact') && (
                <ContactInfoStep
                  formHook={formHook}
                  stepsHook={stepsHook}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  onComplete={onComplete}
                />
              )}
              {stepsHook.isActive('company') && (
                <CompanyInfoStep
                  formHook={formHook}
                  stepsHook={stepsHook}
                  onComplete={onComplete}
                />
              )}
              {stepsHook.isActive('bank') && (
                <BankInfoStep
                  formHook={formHook}
                  stepsHook={stepsHook}
                  onComplete={onComplete}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ProfileForm
