import React from 'react'
import { Divider, Header, Popup } from 'semantic-ui-react'
import {
  AgeInfo,
  AskAmountLabel,
  AskPriceLabel,
  PaymentType,
  PopupIcon,
  PPDSLabel,
} from '../index'
import { Info } from '../../util/Constant'
import { CompanyLink, ContractLink, UserLink } from '../link'

const ListingCommonDetails = ({ listing }) => {
  if (listing) {
    const {
      hashid,
      seller,
      token,
      created_at,
      amount,
      price,
      currencies,
    } = listing

    return (
      <>
        <Header as="h4" content="Listing ID" />
        {hashid}
        <Divider />
        <Header as="h4" content="Underlying Asset" />
        <ContractLink contract={token.contract} />
        <Divider />
        <Header as="h4">
          <PopupIcon content={Info.TOKEN_SELLER} size="small" name="info" />
          Seller
        </Header>
        <UserLink user={seller} />
        <Divider />
        <Header as="h4">
          <PopupIcon content={Info.COMPANY} size="small" name="info" />
          Issuer
        </Header>
        <CompanyLink company={token.contract.issuer} />
        <Divider />
        <Header as="h4" content="Age" />
        <AgeInfo iso str={created_at} />
        <Divider />
        <Header as="h4">
          <Popup size="small" content={Info.TOKEN_AMOUNT} name="info" />
          Ask amount
        </Header>
        <AskAmountLabel amount={amount} />
        <Divider />
        <Header as="h4">
          <Popup size="small" content={Info.TOKEN_PRICE} name="info" />
          Ask price
        </Header>
        <AskPriceLabel price={price} />
        <Divider />
        <Header as="h4">
          <Popup size="small" content={Info.PRICE_PER_TOKEN} name="info" />
          PPDS
        </Header>
        <PPDSLabel data={{ amount, price }} />
        <Divider />
        <Header as="h4" content="Payment type" />
        <PaymentType currencies={currencies} />
      </>
    )
  }

  return null
}

export default ListingCommonDetails
