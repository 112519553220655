import React, { useState } from 'react'
import { Button, Form, Grid, Input, Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { useDashboardData, useDebounceQuery, usePage } from '../../util/hooks'
import { client, GET_ALL_OFFERS, SEARCH_OFFERS } from '../../util/API/Apollo'
import { APIPagination, CSBreadcrumb, DimmerLoader } from '../../components'
import OffersTable from '../../components/Offer/OffersTable'
import { NoData } from '../../components/NotFound'
import noDataImage from '../../images/no_found.svg'
import { OffersWidget } from '../../components/Widget'

const COUNT = 10

const AllOffers = () => {
  const [page, setPage] = usePage(1)

  const { data, loading } = useQuery(GET_ALL_OFFERS, {
    variables: { page, count: 10 },
    client,
  })
  const [input, setInput] = useState('')
  const showSearch = input.length >= 2
  const { loading: searchLoading, data: searchData } = useDebounceQuery(
    SEARCH_OFFERS,
    {
      variables: { search: input },
      skip: !showSearch,
      client,
    }
  )
  const searchOffers = _.get(searchData, 'searchOffers.data', [])
  const allOffers = _.get(data, 'activeOffers.data', [])
  const paginatorInfo = _.get(data, 'activeOffers.paginatorInfo')

  const { dashboard } = useDashboardData()
  const activeOffers = showSearch ? searchOffers : allOffers

  return (
    <>
      <CSBreadcrumb title="All Offers" />
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Button floated="right" content="export offers" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OffersWidget
              divided
              width={3}
              data={_.get(dashboard, 'offers', [])}
            />
            <Segment padded="very">
              <DimmerLoader active={loading} />
              {activeOffers.length > 0 && (
                <>
                  <Form>
                    <Form.Field
                      control={Input}
                      value={input}
                      loading={searchLoading}
                      onChange={(e, { value }) => setInput(value)}
                      icon="search"
                    />
                  </Form>
                  <br />
                  <OffersTable
                    offers={activeOffers}
                    pagination={
                      searchOffers ? null : (
                        <APIPagination
                          count={COUNT}
                          paginatorInfo={paginatorInfo}
                          handleChange={setPage}
                          floated="right"
                        />
                      )
                    }
                  />
                </>
              )}
              {activeOffers.length === 0 && !loading && !searchLoading && (
                <NoData image={noDataImage} text="THERE ARE NO ACTIVE OFFERS" />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
export default AllOffers
