import React from 'react'
import { Grid, Header, Icon } from 'semantic-ui-react'
import UserProvidersTable from '../../components/Provider/UserProvidersTable'

const ProvidersDetails = ({ user }) => {
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column>
          <Header content="PROVIDER DETAILS" />
          {!user.has_broker_dealer && (
            <p>
              <Icon name="info" />
              You need a Broker Dealer to trade
            </p>
          )}

          <br />
          <br />
          <UserProvidersTable user={user} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ProvidersDetails
