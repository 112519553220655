import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Icon, Item, Segment } from 'semantic-ui-react'
import Slider from 'react-slick/lib'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  CompanyVerifiedLabel,
  DimmerLoader,
  MyCompanyLabel,
} from '../../components'
import util from '../../util'
import { countries } from '../../util/Constant'
import { client, GET_COMPANIES } from '../../util/API/Apollo'
import companyPlaceholder from '../../images/company-placeholder.png'
import { CompanyLink } from '../../components/link'

const settings = {
  infinite: true,
  speed: 500,
  dots: false,
  autoplay: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

const LatestCompanies = () => {
  const { data, loading } = useQuery(GET_COMPANIES, {
    variables: { page: 1, count: 10 },
    client,
  })

  const companies = _.get(data, 'companies.data', [])

  if (companies.length < 2) return null

  return (
    <Segment padded="very">
      {loading ? (
        <DimmerLoader active />
      ) : (
        <Slider {...settings}>
          {companies.map((company, index) => (
            <Item.Group key={index}>
              <Item>
                <Item.Image
                  rounded
                  size="tiny"
                  src={util.prependImage(company.logo, companyPlaceholder)}
                />
                <Item.Content>
                  <CompanyLink company={company} />{' '}
                  <CompanyVerifiedLabel company={company} iconOnly />
                  <MyCompanyLabel company={company} iconOnly />
                  <Item.Meta>
                    <Icon name="map marker alternate" className="mr-2" />
                    {company.city}, {countries[company.country]}
                  </Item.Meta>
                </Item.Content>
              </Item>
            </Item.Group>
          ))}
        </Slider>
      )}
    </Segment>
  )
}

export default LatestCompanies
