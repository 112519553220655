import React from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Button } from 'semantic-ui-react'
import PaymentModal from '../PaymentModal'
import { useModal } from '../../util/hooks'
import { TOKEN_COMMISSION_RATE } from '../../util/Constant'
import { CLAIM_TOKEN, client } from '../../util/API/Apollo'
import { PageLoader } from '../index'
import { SuccessModal } from '../confirm'
import link from '../link'

const ClaimTokenButton = ({ token }) => {
  const paymentModal = useModal()
  const successModal = useModal()
  const paymentAmount = Math.ceil(Number(token.amount) * TOKEN_COMMISSION_RATE)

  const [claim, { loading }] = useMutation(CLAIM_TOKEN, {
    client,
    onCompleted: () => {
      paymentModal.hide()
      successModal.show()
    },
  })

  const handlePayment = async payment => {
    await claim({
      variables: {
        token_id: token.uuid,
        payment,
      },
    })
  }

  return (
    <>
      <PageLoader active={loading} />
      <PaymentModal
        {...paymentModal.props}
        onTxSent={handlePayment}
        amount={paymentAmount}
      />
      <SuccessModal
        {...successModal.props}
        content={
          <>
            You have successfully claimed your Digital Securities. You can view
            them under the My Portfolio / My Digital Securities title on the
            sidebar.
          </>
        }
        button={{ as: Link, to: link.token(token) }}
      />
      <Button primary content="claim" onClick={paymentModal.show} />
    </>
  )
}

export default ClaimTokenButton
