import React from 'react'
import { Grid, Header, Segment, Message } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_PROFILE } from '../../util/API/Apollo'
import _ from 'lodash'
import KYCVerificationInfo from '../Verification/KYCVerificationInfo'
import ACCVerificationInfo from '../Verification/ACCVerificationInfo'
import { IfUserIs } from '../index'
import UserProvidersTable from '../Provider/UserProvidersTable'
import ProviderVerificationInfo from '../Verification/ProviderVerificationInfo'

const ProfileStatus = () => {
  const { data, loading } = useQuery(GET_PROFILE, { client })
  const user = _.get(data, 'me')
  if (!user) return null

  return (
    <>
      <IfUserIs not verified>
        <Segment padded="very" loading={loading}>
          <Header as="h2" content="MY ACCOUNT STATUS" />
          <IfUserIs customer>
            <p>
              Awaiting profile and accredited investor status verification from
              STC.
            </p>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column computer="8" tablet="8" mobile="16">
                  <Header
                    as="h4"
                    content="ACCOUNT VERIFICATION"
                    className="mb-0"
                  />
                  <KYCVerificationInfo user={user} />
                </Grid.Column>
                <Grid.Column computer="8" tablet="8" mobile="16">
                  <Header as="h4" content="ACCREDITATION" className="mb-0" />
                  <ACCVerificationInfo user={user} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </IfUserIs>
          <IfUserIs provider>
            <p>
              Your profile information must be verified before you can use
              platform.
            </p>
            <ProviderVerificationInfo user={user} isDashboard />
          </IfUserIs>
        </Segment>
      </IfUserIs>
      <IfUserIs customer>
        <Segment padded="very">
          <Header as="h2" content="MY PROVIDERS" />
          {!user.has_broker_dealer && (
            <Message info>
              <Header
                sub
                content="You need a Broker Dealer to access the Marketplace and start trading"
              />
            </Message>
          )}
          <UserProvidersTable user={user} />
        </Segment>
      </IfUserIs>
    </>
  )
}

export default ProfileStatus
