import React from 'react'
import { Segment, Comment, Icon, Item, Menu } from 'semantic-ui-react'
import Moment from 'react-moment'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { CSBreadcrumb, SingleGrid } from '../../components'
import { useMe } from '../../util/hooks/me'
import CustomerDiscussions from './CustomerDiscussions'
import ProviderDiscussions from './ProviderDiscussions'
import _ from 'lodash'
import userPlaceholder from '../../images/user-placeholder.png'
import { UserTypeNames } from '../../util/Constant'
import Notifications from './Notifications'
import link from '../../components/link'

export const DiscussionItem = ({ user, active, onClick, ...props }) => {
  const avatar = _.get(user, 'profile.photo.link_as_avatar') || userPlaceholder
  const handleClick = e => {
    onClick(e, props)
  }
  return (
    <Item
      className={classNames('discussion', { active })}
      onClick={handleClick}
    >
      <Item.Image size="tiny" style={{ width: 50 }} rounded src={avatar} />
      <Item.Content
        verticalAlign="middle"
        header={user.fullname}
        meta={UserTypeNames[user.user_type]}
      />
      <Icon className="pt-20" name="chevron right" />
    </Item>
  )
}

export const BasicMessage = ({ message }) => {
  const me = useMe()
  const isOwn = message.fromuuid === me.uuid

  return (
    <Comment.Group
      key={message.created_at}
      className={classNames({ rtl: isOwn })}
    >
      <Comment>
        {!isOwn && (
          <Comment.Avatar as="a" src={message.fromavatar || userPlaceholder} />
        )}
        <Comment.Content>
          {!isOwn && <Comment.Author as="a">{message.from}</Comment.Author>}
          <Comment.Metadata>
            <Moment fromNow utc>
              {message.created_at}
            </Moment>
          </Comment.Metadata>
          {isOwn && <Comment.Author as="a">{message.from}</Comment.Author>}
          <Comment.Text>{message.body}</Comment.Text>
        </Comment.Content>
        {isOwn && (
          <Comment.Avatar src={message.fromavatar || userPlaceholder} />
        )}
      </Comment>
    </Comment.Group>
  )
}

const Messages = () => {
  const { pathname } = useLocation()
  const { isCustomer, isProvider, isTransferAgent } = useMe()
  return (
    <>
      <CSBreadcrumb title="Messages" />
      <SingleGrid padded>
        <Segment padded="very">
          <Menu pointing secondary>
            <Menu.Item
              as={Link}
              to={link.allUserMessages}
              active={pathname.startsWith(link.allUserMessages)}
              content="Messages"
            />
            <Menu.Item
              as={Link}
              to={link.systemMessages}
              active={pathname === link.systemMessages}
              content="Notifications"
            />
          </Menu>
          <Switch>
            <Route path={link.allUserMessages}>
              {(isProvider || isTransferAgent) && <ProviderDiscussions />}
              {isCustomer && <CustomerDiscussions />}
            </Route>
            <Route exact path={link.systemMessages}>
              <Notifications />
            </Route>
            <Route component={() => <Redirect to={link.allUserMessages} />} />
          </Switch>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default Messages
