import React from 'react'
import { Button, Form, Input, Header } from 'semantic-ui-react'
import {
  PhoneInput,
  CityField,
  CountryInput,
  StateField,
  ZipcodeInput,
} from '../../../input'
import { getProfileContactDetailsSchema } from '../../schema'

const ContactInfoStep = ({
  formHook,
  stepsHook,
  isCustomer,
  isProvider,
  onComplete,
}) => {
  const [stateId, setStateId] = React.useState(null)
  const { form, field, validate } = formHook
  const handleSubmit = async () => {
    if (
      await validate(getProfileContactDetailsSchema(isProvider || isCustomer))
    ) {
      if (stepsHook.isLastStep) {
        onComplete()
      } else {
        stepsHook.nextStep()
      }
    }
  }

  return (
    <>
      <Header as="h3" content="CONTACT INFO" />
      <Form id="address-info-form" onSubmit={handleSubmit} noValidate>
        <Form.Group widths="equal">
          <Form.Field
            fluid
            label="Email"
            placeholder="yourname@example.com"
            icon="envelope"
            iconPosition="left"
            type="email"
            required
            disabled={!(isCustomer || isProvider)}
            control={Input}
            {...field('email')}
          />
          <Form.Field
            fluid
            label="Alternative email"
            placeholder="yourname@example.com"
            icon="envelope"
            iconPosition="left"
            type="email"
            control={Input}
            {...field('email_alt')}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            fluid
            label="Phone"
            placeholder="e.g +1 (111) 111 - 1111"
            icon="phone"
            iconPosition="left"
            required
            control={PhoneInput}
            {...field('phonenumber')}
          />
          <Form.Field
            fluid
            label="Alternative phone"
            placeholder="e.g +1 (111) 111 - 1111"
            icon="phone"
            iconPosition="left"
            control={PhoneInput}
            {...field('phonenumber_alt')}
          />
        </Form.Group>
        <Form.Field
          fluid
          label="Address"
          placeholder="e.g. 3883 Hillcrest Avenue"
          icon="map marker alternate"
          iconPosition="left"
          required
          control={Input}
          {...field('address')}
        />
        <Form.Group widths="equal">
          <Form.Field
            fluid
            label="Country"
            required
            control={CountryInput}
            {...field('country')}
          />
          <StateField
            fluid
            label="State"
            country={form.country}
            setStateId={setStateId}
            {...field('state')}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <CityField
            fluid
            label="City"
            country={form.country}
            stateId={stateId}
            required
            {...field('city')}
          />
          <Form.Field
            fluid
            label="Zipcode"
            placeholder="e.g. 11111"
            icon="map marker alternate"
            iconPosition="left"
            required
            control={ZipcodeInput}
            {...field('zipcode')}
          />
        </Form.Group>
        <Button
          primary
          content="Next"
          type="submit"
          floated="right"
          data-test-id="profile-form-next"
        />
        <Button
          content="Back"
          type="button"
          floated="right"
          onClick={stepsHook.prevStep}
        />
      </Form>
      <br />
      <br />
    </>
  )
}

export default ContactInfoStep
