import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Segment, Form, Input, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import LatestCompanies from './LatestCompanies'
import CompanyTable from '../../components/Company/CompanyTable'
import {
  CSBreadcrumb,
  IfUserIs,
  SingleGrid,
  APIPagination,
} from '../../components'
import { CompanyWidget } from '../../components/Widget'
import { usePage, useDebounceQuery, useDashboardData } from '../../util/hooks'
import { client, GET_COMPANIES, SEARCH_COMPANY } from '../../util/API/Apollo'
import link from '../../components/link'

const COUNT = 10

const Companies = () => {
  const [page, setPage] = usePage(1)
  const { dashboard, loading: statisticsLoading } = useDashboardData()

  const companiesQuery = useQuery(GET_COMPANIES, {
    variables: { count: COUNT, page },
    client,
  })

  const [input, setInput] = useState('')
  const showSearch = input.length >= 2
  const { loading: searchLoading, data: searchData } = useDebounceQuery(
    SEARCH_COMPANY,
    {
      variables: { search: input },
      skip: !showSearch,
      client,
    }
  )

  const paginatorInfo = _.get(companiesQuery, 'data.companies.paginatorInfo')
  const companies = _.get(companiesQuery, 'data.companies.data', [])
  const searchCompanies = _.get(searchData, 'searchCompanies.data')

  return (
    <>
      <CSBreadcrumb title="Data" />

      <SingleGrid padded>
        <Grid className="py-15">
          <Grid.Row className="p-0">
            <Grid.Column>
              <Button
                primary
                floated="right"
                content="add new company"
                as={Link}
                to={link.newCompany}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <IfUserIs ta>
                {!statisticsLoading && (
                  <CompanyWidget divided data={dashboard.companies} />
                )}
              </IfUserIs>
              <LatestCompanies />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment padded="very">
                <Form>
                  <Form.Field
                    control={Input}
                    value={input}
                    loading={searchLoading}
                    onChange={(e, { value }) => setInput(value)}
                    placeholder="Search companies"
                    icon="search"
                  />
                </Form>
                <br />
                <CompanyTable
                  companies={searchCompanies || companies}
                  loading={companiesQuery.loading}
                  pagination={
                    !searchCompanies && (
                      <APIPagination
                        paginatorInfo={paginatorInfo}
                        handleChange={setPage}
                        count={COUNT}
                      />
                    )
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SingleGrid>
    </>
  )
}

export default Companies
