import React from 'react'
import _ from 'lodash'
import {
  Grid,
  Form,
  Button,
  Image,
  Header,
  Divider,
  Input,
  Modal,
} from 'semantic-ui-react'
import { NextBackButton } from '../CompanyForm'
import { PersonCard } from '../../../index'
import {
  PhoneInput,
  CountryInput,
  StateField,
  CityField,
  ZipcodeInput,
} from '../../../input'
import { contactPerson } from '../util'
import { useModal } from '../../../../util/hooks'
import useForm from '../../../../util/hooks/form'
import issuerContactInfo from '../../../../images/issuer_contact_info.svg'

const ContactPersonModal = ({
  open,
  onClose,
  value = [],
  onChange = _.noop,
  ...props
}) => {
  const { form, field, validate, clear } = useForm(
    'contact-person',
    contactPerson.emptyForm,
    { schema: contactPerson.schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newData = [...value, _.clone(form)]
      onChange(newData, { ...props, value: newData })
      clear()
      onClose()
    }
  }

  const handleClose = () => {
    clear()
    onClose()
  }

  return (
    <Modal dimmer="blurring" closeIcon open={open} onClose={handleClose}>
      <Modal.Header content="ADD CONTACT PERSON" />
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field
              required
              label="First Name"
              icon="user"
              iconPosition="left"
              control={Input}
              {...field('firstname')}
            />
            <Form.Field
              required
              label="Last Name"
              icon="user"
              iconPosition="left"
              control={Input}
              {...field('lastname')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              required
              label="Email"
              icon="envelope"
              iconPosition="left"
              control={Input}
              {...field('email')}
            />
            <Form.Field
              required
              label="Phone"
              icon="phone"
              iconPosition="left"
              control={PhoneInput}
              {...field('phonenumber')}
            />
          </Form.Group>
        </Form>
        <Button fluid color="green" content="Add" onClick={handleSubmit} />
      </Modal.Content>
    </Modal>
  )
}

const ContactInfoStep = ({ formHook, stepsHook, handleNextStep }) => {
  const contactPersonModal = useModal()
  const { form, field, set } = formHook

  const [stateId, setStateId] = React.useState(null)

  const handleRemovePerson = index => {
    const newArray = _.clone(form.contact_people)
    newArray.splice(index, 1)
    set('contact_people', newArray)
  }

  return (
    <>
      <Grid padded>
        <Grid.Row>
          <Grid.Column computer="6" only="computer">
            <Image size="large" src={issuerContactInfo} />
            <Header
              as="h3"
              color="purple"
              textAlign="left"
              content="ADD COMPANY CONTACT INFO"
            />
          </Grid.Column>
          <Grid.Column computer="10" tablet="16" mobile="16">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field
                      required
                      label="Email"
                      icon="envelope"
                      iconPosition="left"
                      control={Input}
                      {...field('email')}
                    />
                    <Form.Field
                      required
                      label="Phone"
                      icon="phone"
                      iconPosition="left"
                      control={PhoneInput}
                      {...field('phonenumber')}
                    />
                    <Form.Field
                      required
                      label="Website"
                      icon="globe"
                      iconPosition="left"
                      control={Input}
                      {...field('homepage')}
                    />
                    <Form.Field
                      label="Address"
                      required
                      fluid
                      icon="map marker alternate"
                      iconPosition="left"
                      control={Input}
                      {...field('address')}
                    />
                    <Form.Field
                      required
                      label="Country"
                      control={CountryInput}
                      {...field('country')}
                    />
                    <StateField
                      setStateId={setStateId}
                      label="State"
                      country={form.country}
                      {...field('state')}
                    />
                    <CityField
                      label="City"
                      required
                      fluid
                      country={form.country}
                      stateId={stateId}
                      {...field('city')}
                    />
                    <Form.Field
                      label="Zipcode"
                      required
                      fluid
                      icon="map marker alternate"
                      iconPosition="left"
                      control={ZipcodeInput}
                      {...field('zipcode')}
                    />
                    <br />
                    <Divider horizontal content="Contact person" />
                    <br />
                  </Form>
                  {form.contact_people.length > 0 ? (
                    <>
                      {form.contact_people.map((p, i) => (
                        <PersonCard
                          fluid
                          key={i}
                          keyIndex={i}
                          person={p}
                          handleRemove={handleRemovePerson}
                        />
                      ))}
                    </>
                  ) : (
                    <Button
                      fluid
                      content="Add contact person"
                      onClick={contactPersonModal.show}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <NextBackButton
                    stepsHook={stepsHook}
                    handleNextStep={handleNextStep}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ContactPersonModal
        open={contactPersonModal.isOpen}
        onClose={contactPersonModal.hide}
        {...field('contact_people')}
      />
    </>
  )
}

export default ContactInfoStep
