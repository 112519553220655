import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { GET_ALL_TOKENS, client } from '../../util/API/Apollo'
import {
  APIPagination,
  CSBreadcrumb,
  SingleGrid,
  PageLoader,
} from '../../components'
import { SecuritiesWidget } from '../../components/Widget'
import { NoData } from '../../components/NotFound'
import noFoundImage from '../../images/no_found.svg'
import { usePage, useDashboardData } from '../../util/hooks'
import TokensTable from '../../components/Token/TokensTable'
const COUNT = 10

const AllTokens = () => {
  const [page, setPage] = usePage(1)
  const { data, loading } = useQuery(GET_ALL_TOKENS, {
    variables: { page, count: 10 },
    client,
  })

  const paginatorInfo = _.get(data, 'activeTokens.paginatorInfo')
  const activeTokens = _.get(data, 'activeTokens.data', [])
  const { dashboard, loading: statisticsLoading } = useDashboardData()

  return (
    <>
      <CSBreadcrumb title="Digital Securities" />
      <SingleGrid padded>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            {activeTokens.length > 0 ? (
              <>
                {!statisticsLoading && (
                  <SecuritiesWidget divided data={dashboard.securities} />
                )}
                <Segment padded="very">
                  <>
                    <TokensTable
                      tokens={activeTokens}
                      footer={
                        <APIPagination
                          paginatorInfo={paginatorInfo}
                          handleChange={setPage}
                          count={COUNT}
                        />
                      }
                    />
                  </>
                </Segment>
              </>
            ) : (
              <NoData
                image={noFoundImage}
                text="THERE ARE NO DIGITAL SECURITIES"
                fluid
              />
            )}
          </>
        )}
      </SingleGrid>
    </>
  )
}

export default AllTokens
