import { Button, Dimmer, Form, Input, Loader, Message } from 'semantic-ui-react'
import { FileSelect } from '../../upload'
import React from 'react'
import debug from 'debug'
import { ethers } from 'ethers'
import { useMe } from '../../../util/hooks/me'
import util from '../../../util'
import useForm from '../../../util/hooks/form'

const log = debug('app:blockchain')

const useDecryption = ({ formHook, onDecrypted }) => {
  const me = useMe()
  // true if currently decrypting a json file
  const [decrypting, setDecrypting] = React.useState(false)
  // error on json process (password, invalid json...)
  const [error, setError] = React.useState(null)
  // between 0 and 1, set by ethers.js
  const [progress, setProgress] = React.useState(null)

  const failDecryption = error => {
    setError(error)
    setDecrypting(false)
  }
  const completeDecryption = wallet => {
    setError(null)
    setDecrypting(null)
    onDecrypted(wallet)
  }

  const decrypt = () => {
    // start decrypting wallet from json
    setDecrypting(true)

    // read json file
    const reader = new FileReader()
    reader.readAsText(formHook.form.jsonFile, 'UTF-8')

    reader.onload = e => {
      const json = e.target.result
      log('json file read:', json)
      ethers.Wallet.fromEncryptedJson(json, formHook.form.password, setProgress)
        .then(wallet => {
          log('wallet decrypted: ', wallet)
          if (
            wallet.address.toLowerCase() ===
            me.primary_wallet.address.toLowerCase()
          ) {
            completeDecryption(wallet)
          } else {
            failDecryption('JSON file does not belong to your wallet address')
          }
        })
        .catch(err => {
          console.error(err)
          failDecryption(util.capitalize(err.message || err))
        })
    }
  }

  return { decrypting, error, progress, decrypt }
}

const initialForm = { jsonFile: null, password: '' }

const DecryptWithKeystore = ({ decryptedWallet, onDecrypted, disabled }) => {
  const formHook = useForm('wallet-decryptor', initialForm)
  const decryptionHook = useDecryption({ formHook, onDecrypted })

  return (
    <Form
      onSubmit={decryptionHook.decrypt}
      error={Boolean(decryptionHook.error)}
    >
      <Form.Field
        required
        label="Keystore File"
        control={FileSelect}
        accept="application/json"
        disabled={Boolean(decryptedWallet) || disabled}
        {...formHook.input('jsonFile')}
      />
      <Form.Field
        required
        control={Input}
        label="Enter the password"
        type="password"
        placeholder="Password"
        {...formHook.input('password')}
        disabled={disabled}
      />
      {decryptionHook.error && <Message error content={decryptionHook.error} />}
      <Dimmer active={decryptionHook.decrypting} inverted>
        <Loader>
          Decrypting: {parseInt(decryptionHook.progress * 100)}% complete
        </Loader>
      </Dimmer>
      <Button
        type="submit"
        primary
        fluid
        content="Continue"
        disabled={!formHook.form.jsonFile || formHook.form.password.length < 9}
      />
    </Form>
  )
}

export default DecryptWithKeystore
