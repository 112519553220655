import React from 'react'
import { useMutation } from 'react-apollo'
import _ from 'lodash'
import { Button, Form, Modal } from 'semantic-ui-react'
import { DocumentUpload } from '../upload'
import { client, SUBMIT_VERIFICATION_INFO } from '../../util/API/Apollo'
import { ApprovalType } from '../../util/Constant'
import { DimmerLoader } from '../index'
import useForm from '../../util/hooks/form'

const initialForm = { doc: null }
const MSGFormModal = ({ open, onClose, refetchQueries }) => {
  const { form, field } = useForm('msg-verification', initialForm)
  const [request, { loading }] = useMutation(SUBMIT_VERIFICATION_INFO, {
    variables: {
      input: {
        approval_type: ApprovalType.VERIFICATION_MSG,
        document: _.get(form, 'doc.uuid'),
      },
    },
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: onClose,
    client,
  })

  return (
    <Modal open={open} as={Form} onClose={onClose} dimmer="blurring" closeIcon>
      <Modal.Header content="ADD MEDALLION SIGNATURE" />
      <DimmerLoader active={loading} />
      <Modal.Content>
        <Form.Field
          label="Upload Document"
          required
          control={DocumentUpload}
          {...field('doc')}
        />
        <Button
          primary
          fluid
          content="Send for approval"
          disabled={!form.doc}
          onClick={request}
          data-test-id="finish-msg-document"
        />
      </Modal.Content>
    </Modal>
  )
}

export default MSGFormModal
