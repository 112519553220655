import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Progress, Header } from 'semantic-ui-react'
import zxcvbn from 'zxcvbn'

const PasswordStrengthMeter = ({ password, onChange }) => {
  const score = useMemo(() => zxcvbn(password).score, [password])

  useEffect(() => {
    onChange(score)
  }, [onChange, score])

  const passwordLabel = score => {
    switch (score) {
      case 0:
        return 'Too Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Fair'
      case 3:
        return 'Good'
      case 4:
        return 'Strong'
      default:
        return 'Weak'
    }
  }

  const barColor = score => {
    switch (score) {
      case 0:
        return 'red'
      case 1:
        return 'orange'
      case 2:
        return 'yellow'
      case 3:
        return 'green'
      case 4:
        return 'green'
      default:
        return ''
    }
  }

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column verticalAlign="middle">
          <Header sub content="Password strength" />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Progress
            percent={score * 25}
            color={barColor(score)}
            size="tiny"
            style={{ margin: '5px 0' }}
          />
          <small>{passwordLabel(score)}</small>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

PasswordStrengthMeter.propTypes = {
  password: PropTypes.string.isRequired,
}

export default PasswordStrengthMeter
