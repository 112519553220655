import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Grid, Menu, Segment, Message, Header } from 'semantic-ui-react'
import {
  ContentLoader,
  CSBreadcrumb,
  IfUserIs,
  PageLoader,
  SingleGrid,
} from '../../components'
import {
  ApproveOfferModal,
  MakeOfferAction,
  Offer,
  OfferStatusMessage,
  PreviousOffers,
  RejectOfferModal,
} from '../../components/Offer'
import { ListingCommonDetails } from '../../components/Listing'
import { SuccessModal } from '../../components/confirm'
import { useModal, useTab } from '../../util/hooks'
import { useMe } from '../../util/hooks/me'
import {
  ACCEPT_OFFER,
  CANCEL_OFFER,
  client,
  GET_OFFER_DETAILS,
  REJECT_OFFER,
} from '../../util/API/Apollo'
import {
  OfferDirection,
  OfferStatus,
  Transferability,
} from '../../util/Constant'
import { NoData } from '../../components/NotFound'
import link from '../../components/link'

const ApproveOfferAction = ({ offer, listing }) => {
  const approveOfferModal = useModal()
  const approveSuccessModal = useModal()

  let isNotTransferable =
    listing.token.transferability.status === Transferability.NOT_ALLOWED

  const [accept, { loading }] = useMutation(ACCEPT_OFFER, {
    variables: { uuid: offer.uuid },
    onCompleted: approveSuccessModal.show,
    refetchQueries: [
      { query: GET_OFFER_DETAILS, variables: { uuid: offer.uuid } },
    ],
    awaitRefetchQueries: true,
    client,
  })

  return (
    <>
      <PageLoader active={loading} />
      <Button
        fluid
        color="green"
        size="small"
        content="accept offer"
        onClick={approveOfferModal.show}
        disabled={isNotTransferable}
      />
      <br />
      {isNotTransferable && (
        <Message size="small" info>
          <Message.Header>
            <Header sub content="Why can't I accept this offer?" />
          </Message.Header>
          <p>
            To trade Digital Securities, underlying asset needs to be verified
            and made transferable first.
          </p>
        </Message>
      )}
      <ApproveOfferModal
        offer={offer}
        onComplete={accept}
        {...approveOfferModal.props}
      />
      <SuccessModal
        content="You accepted the offer"
        {...approveSuccessModal.props}
      />
    </>
  )
}

// Reject and Cancel actions
const RejectOfferAction = ({ offer }) => {
  const { isOwner } = useMe()
  const rejectOfferModal = useModal()
  const rejectSuccessModal = useModal()

  const [cancel, { data: cancelData, loading: cancelLoading }] = useMutation(
    CANCEL_OFFER,
    {
      variables: { uuid: offer.uuid },
      onCompleted: () => {
        rejectOfferModal.hide()
        rejectSuccessModal.show()
      },
      client,
    }
  )

  const [reject, { data: rejectData, loading: rejectLoading }] = useMutation(
    REJECT_OFFER,
    {
      variables: { uuid: offer.uuid },
      onCompleted: () => {
        rejectOfferModal.hide()
        rejectSuccessModal.show()
      },
      client,
    }
  )

  return (
    <>
      <PageLoader active={cancelLoading || rejectLoading} />
      <Button
        fluid
        color="red"
        size="small"
        content={isOwner({ offer }) ? 'Cancel offer' : 'Reject offer'}
        onClick={rejectOfferModal.show}
      />
      <br />
      <RejectOfferModal
        offer={offer}
        isOwner={isOwner({ offer })}
        onComplete={isOwner({ offer }) ? cancel : reject}
        {...rejectOfferModal.props}
      />
      {(cancelData || rejectData) && (
        <SuccessModal
          content={
            isOwner({ offer })
              ? 'You canceled the offer'
              : 'You rejected the offer'
          }
          {...rejectSuccessModal.props}
          button={{
            as: Link,
            to: link.listing(offer.listing),
          }}
        />
      )}
    </>
  )
}

const OfferDetails = () => {
  const { itemProps, isActive } = useTab('most-recent-offer')
  const { uuid } = useParams()

  const offerQuery = useQuery(GET_OFFER_DETAILS, {
    variables: { uuid },
    client,
  })

  if (offerQuery.loading) return <ContentLoader />

  const lastOffer = _.get(offerQuery, 'data.offerDetails.history.0', {})
  const listing = _.get(offerQuery, 'data.offerDetails.listing')
  const previousOffers = _.get(offerQuery, 'data.offerDetails.history', [])

  if (!_.get(offerQuery, 'data.offerDetails')) {
    return <NoData text="OFFER NOT FOUND" fluid />
  }

  const isRejectable = lastOffer.status === OfferStatus.INITIALIZED

  const isIncomingOffer =
    lastOffer.status === OfferStatus.INITIALIZED &&
    lastOffer.direction === OfferDirection.INCOMING

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Offers', to: link.mySaleOffers }]}
        title={lastOffer && lastOffer.hashid}
      />

      <SingleGrid padded>
        <Segment padded="very">
          <Grid>
            <Grid.Row>
              <Grid.Column computer="4">
                <Menu fluid pointing secondary vertical>
                  <Menu.Item {...itemProps('most-recent-offer')} />
                  <Menu.Item {...itemProps('offer-history')} />
                  <Menu.Item {...itemProps('listing-details')} />
                </Menu>
                <IfUserIs customer>
                  {isIncomingOffer && (
                    <ApproveOfferAction offer={lastOffer} listing={listing} />
                  )}
                  {isIncomingOffer && (
                    <MakeOfferAction
                      listing={listing}
                      lastOffer={lastOffer}
                      title="MAKE NEW OFFER"
                      previousOffers={previousOffers}
                    />
                  )}
                  {isRejectable && <RejectOfferAction offer={lastOffer} />}
                </IfUserIs>
                <OfferStatusMessage offer={lastOffer} />
              </Grid.Column>
              <Grid.Column computer="12">
                {isActive('most-recent-offer') && <Offer offer={lastOffer} />}
                {isActive('offer-history') && (
                  <PreviousOffers offers={previousOffers} />
                )}
                {isActive('listing-details') && (
                  <ListingCommonDetails listing={listing} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default OfferDetails
