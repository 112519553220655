import React, { useState } from 'react'
import _ from 'lodash'
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
} from 'semantic-ui-react'
import { UserVerifiedLabel } from '../../components'
import { DateField, PhoneInput, TaxIdInput } from '../../components/input'
import { ImageUpload } from '../../components/upload'
import { AssetCodes, UserTypeNames } from '../../util/Constant'
import userPlaceholder from '../../images/user-placeholder.png'
import ProfileCommonDetails from '../../components/Profile/ProfileCommonDetails'
import { getProfileContactDetailsSchema } from '../../components/Profile/schema'

const AccountDetails = ({ user, formHook = {}, handleUpdate }) => {
  const { primary_wallet, is_verified, profile } = user
  const [isEditing, setIsEditing] = useState(false)
  const { field, validate, isDirty, clear } = formHook

  const handleSubmit = async () => {
    if (await validate(getProfileContactDetailsSchema(false))) {
      handleUpdate()
      setIsEditing(false)
    }
  }

  return (
    <Grid padded>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h3">PROFILE DETAILS</Header>
        </Grid.Column>
        {!user.is_verified && !isEditing && formHook && (
          <Grid.Column verticalAlign="middle" textAlign="right">
            <Icon
              link
              color="grey"
              name="edit"
              onClick={() => setIsEditing(true)}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer="3" tablet="6" mobile="16">
          {!isEditing ? (
            <Image
              rounded
              src={_.get(profile, 'photo.link_as_avatar') || userPlaceholder}
            />
          ) : (
            <Form.Field
              placeholder={userPlaceholder}
              asset_code={AssetCodes.profile_photo}
              control={ImageUpload}
              {...field('photo')}
            />
          )}
        </Grid.Column>
        <Grid.Column computer="13" tablet="10" mobile="16">
          <Header as="h2">{user.fullname}</Header>
          <Label color="purple" size="small" className="uppercase">
            {UserTypeNames[user.user_type]}
          </Label>
          <UserVerifiedLabel isVerified={is_verified} />
          <Divider hidden />
          <p>
            <Icon name="ethereum" />
            {_.get(primary_wallet, 'address') || `You don't have a wallet yet.`}
          </p>
          <Divider hidden />
          {!isEditing ? (
            <ProfileCommonDetails user={user} />
          ) : (
            <Form>
              <Form.Field
                label="Alternative Email"
                placeholder="Alternative Email"
                icon="envelope"
                iconPosition="left"
                type="email"
                control={Input}
                {...field('email_alt')}
              />
              <Form.Field
                label="Phone"
                placeholder="Phone"
                icon="phone"
                iconPosition="left"
                required
                control={PhoneInput}
                {...field('phonenumber')}
              />
              <Form.Field
                label="Alternative Phone"
                placeholder="Alternative Phone"
                icon="phone"
                iconPosition="left"
                control={PhoneInput}
                {...field('phonenumber_alt')}
              />
              <DateField
                label="Birthday"
                fluid
                placeholder="Birthday"
                icon="calendar"
                iconPosition="left"
                required
                past
                {...field('date_of_birth')}
              />
              <Form.Field
                label="Tax ID Number"
                fluid
                placeholder="Tax ID Number"
                icon="id card"
                iconPosition="left"
                required
                control={TaxIdInput}
                {...field('tax_id')}
              />
              <Form.Field
                label="Address"
                fluid
                placeholder="Address"
                icon="map marker alternate"
                iconPosition="left"
                required
                control={Input}
                {...field('address')}
              />
              <Button
                primary
                size="small"
                content="Save"
                type="button"
                floated="right"
                disabled={!isDirty}
                onClick={handleSubmit}
              />
              <Button
                size="small"
                content="Cancel"
                type="button"
                floated="right"
                onClick={() => {
                  setIsEditing(false)
                  clear()
                }}
              />
            </Form>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AccountDetails
