import React, { useRef } from 'react'
import { useParams } from 'react-router'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Button, Segment, Grid, Header, Image } from 'semantic-ui-react'
import {
  CompanyVerifiedLabel,
  CSBreadcrumb,
  MyCompanyLabel,
  PageLoader,
  SingleGrid,
} from '../../components'
import util from '../../util'
import { client, GET_COMPANY } from '../../util/API/Apollo'
import companyPlaceholder from '../../images/company-placeholder.png'
import FinancialDataTabs from '../../components/FinancialData/FinancialDataTabs'
import CompanyCommonDetails from '../../components/Company/CompanyCommonDetails'
import link from '../../components/link'

const CompanyDetails = () => {
  const { uuid } = useParams()
  const financialDataRef = useRef(null)

  const { data, loading } = useQuery(GET_COMPANY, {
    variables: { uuid: uuid },
    client,
  })

  const company = _.get(data, 'company')

  const financialData = _.get(data, 'company.financial_data', [])
  const financialDocuments = _.get(data, 'company.financial_documents', [])

  const scroolToFinancialData = () => {
    financialDataRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  if (loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Data', to: link.companies }]}
        title={company.hashid}
      />
      <SingleGrid padded>
        <Segment padded="very">
          <Grid padded>
            <Grid.Row>
              <Grid.Column
                computer="3"
                tablet="4"
                mobile="16"
                textAlign="center"
              >
                <Image
                  rounded
                  src={util.prependImage(company.logo, companyPlaceholder)}
                />
                <br />
                <br />
                {financialData.length > 0 && (
                  <Button
                    size="tiny"
                    content="View Financial Data"
                    onClick={scroolToFinancialData}
                  />
                )}
              </Grid.Column>
              <Grid.Column computer="13" tablet="10" mobile="16">
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <Header as="h2" content={company.name} />
                      <CompanyVerifiedLabel company={company} />
                      <MyCompanyLabel company={company} />
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" textAlign="right">
                      {/* <Icon link color="grey" name="edit" /> */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <CompanyCommonDetails company={company} />
                <br ref={financialDataRef} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FinancialDataTabs
            data={financialData}
            documents={financialDocuments}
          />
        </Segment>
      </SingleGrid>
    </>
  )
}

export default CompanyDetails
