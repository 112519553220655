import React from 'react'
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react'
import { DateField, NumberInput } from '../../../components/input'
import {
  ContactDistributionFrequency,
  ContractConvertType,
  ContractType,
} from '../../../util/Constant'

const convertTypes = Object.keys(ContractConvertType).map(key => ({
  key,
  value: key,
  text: ContractConvertType[key],
}))

const frequencyTypes = Object.keys(ContactDistributionFrequency).map(key => ({
  key,
  value: key,
  text: ContactDistributionFrequency[key],
}))

const DetailsSimpleForm = ({ contract, formHook, stepsHook }) => {
  const { form, field } = formHook
  const type = form.contract_type
  const numberOfTokens =
    Number(form.details.face_value) * Number(form.details.number_of_shares)

  return (
    <Form>
      {type === ContractType.SAFE && (
        <>
          <DateField
            label="Issuance date"
            past
            placeholder="Issuance date"
            iconPosition="left"
            required
            {...field('agreement_date')}
          />
          <Form.Field
            label="Amount"
            fluid
            icon="dollar"
            iconPosition="left"
            placeholder="Amount"
            required
            control={NumberInput}
            {...field('details.purchase_amount')}
          />
          <Form.Field
            label="Convert to"
            placeholder="Select"
            fluid
            selection
            options={convertTypes}
            control={Dropdown}
            {...field('details.convert_note')}
          />
          <Form.Field
            label="Qualified financing amount"
            fluid
            placeholder="Amount"
            icon="dollar"
            iconPosition="left"
            control={NumberInput}
            {...field('details.qualified_financial_amount')}
          />
          <Form.Field
            label="Early exit multiple"
            fluid
            placeholder="Early exit multiple"
            icon="times"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.early_exit_multiple')}
          />
          <Form.Field
            label="Valuation cap"
            fluid
            placeholder="Valuation cap"
            icon="dollar"
            iconPosition="left"
            control={NumberInput}
            {...field('details.valuation_cap')}
          />
          <Form.Field
            label="Discount rate"
            fluid
            placeholder="Discount rate"
            icon="percent"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.discount_rate')}
          />
          <Form.Field
            label="Face value"
            fluid
            placeholder="Face value"
            icon="dollar"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.face_value')}
          />
        </>
      )}
      {type === ContractType.CONVERTIBLE_NOTE && (
        <>
          <DateField
            label="Issuance date"
            past
            placeholder="Issuance date"
            iconPosition="left"
            required
            {...field('agreement_date')}
          />
          <Form.Field
            label="Amount"
            fluid
            icon="dollar"
            iconPosition="left"
            placeholder="Amount"
            required
            control={NumberInput}
            {...field('details.purchase_amount')}
          />
          <Form.Field
            label="Convert to"
            placeholder="Select"
            fluid
            selection
            options={convertTypes}
            control={Dropdown}
            {...field('details.convert_to')}
          />
          <Form.Field
            label="Accrual frequency"
            placeholder="Accrual frequency"
            fluid
            selection
            options={frequencyTypes}
            control={Dropdown}
            {...field('details.accrual_frequency')}
          />
          <Form.Field
            label="Interest rate"
            fluid
            placeholder="Interest rate"
            icon="percent"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.interest_rate')}
          />
          <DateField
            label="Maturity date"
            placeholder="Maturity date"
            iconPosition="left"
            {...field('details.maturity_date')}
          />
          <Form.Field
            label="Early exit multiple"
            fluid
            placeholder="Early exit multiple"
            icon="times"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.early_exit_multiple')}
          />
          <Form.Field
            label="Qualified financing amount"
            fluid
            placeholder="Amount"
            icon="dollar"
            iconPosition="left"
            control={NumberInput}
            {...field('details.qualified_financing_amount')}
          />
          <Form.Field
            label="Valuation cap"
            fluid
            placeholder="Valuation cap"
            icon="dollar"
            iconPosition="left"
            control={NumberInput}
            {...field('details.valuation_cap')}
          />
          <Form.Field
            label="Discount rate"
            fluid
            placeholder="Discount rate"
            icon="percent"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.discount_rate')}
          />
          <Form.Field
            label="Face value"
            fluid
            placeholder="Face value"
            icon="dollar"
            iconPosition="left"
            float
            control={NumberInput}
            {...field('details.face_value')}
          />
        </>
      )}
      {type === ContractType.EQUITY && (
        <>
          <DateField
            label="Issuance date"
            past
            placeholder="Issuance date"
            iconPosition="left"
            required
            {...field('agreement_date')}
          />
          <Form.Field
            label="Face Value"
            fluid
            placeholder="Face value"
            icon="dollar"
            iconPosition="left"
            float
            required
            control={NumberInput}
            {...field('details.face_value')}
          />
          <Form.Field
            label="Number of shares"
            fluid
            icon="file"
            iconPosition="left"
            placeholder="Number of shares"
            required
            control={NumberInput}
            {...field('details.number_of_shares')}
          />
          <Form.Field
            label="Number of tokens"
            readOnly
            fluid
            icon="coin"
            iconPosition="left"
            placeholder="Number of tokens"
            value={numberOfTokens}
            control={NumberInput}
          />
          <Form.Field
            label="Class"
            placeholder="Select"
            fluid
            selection
            options={convertTypes}
            control={Dropdown}
            {...field('details.convert_to')}
          />
          <Form.Field
            label="Class name"
            fluid
            placeholder="Class name"
            control={Input}
            {...field('details.class_name')}
          />
          <Form.Field
            label="Stock certificate class prefix"
            fluid
            placeholder="Stock certificate class prefix"
            control={Input}
            {...field('details.class_prefix')}
          />
          <Form.Field
            label="Seniority"
            fluid
            placeholder="Seniority"
            control={Input}
            {...field('details.seniority')}
          />
          <Form.Field
            label="Pre-money valuation"
            fluid
            icon="dollar"
            iconPosition="left"
            placeholder="Pre-money valuation"
            control={NumberInput}
            {...field('details.pre_money_valuation')}
          />
        </>
      )}
      <Form.Field
        label="Comments"
        placeholder="Comments"
        control={TextArea}
        {...field('details.comments')}
      />
    </Form>
  )
}

export default DetailsSimpleForm
