import React from 'react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import * as Sentry from '@sentry/browser'
import packageJson from '../package.json'
import Dashboard from './routes/Dashboard'
import Auth from './routes/Auth'
import Footer from './components/Layout/Footer'
import { client } from './util/API/Apollo'
import ActivateAccount from './routes/ActivateAccount'
import ForgotPassword from './routes/ForgotPassword'
import ResetPassword from './routes/ResetPassword'
import CreateProfile from './routes/CreateProfile'
import ProfileDetails from './routes/ProfileDetails'
import ProviderAction from './routes/ProviderActionDetails'
import Settings from './routes/Settings'
import Customers from './routes/Customers'
import Providers from './routes/Providers'
import Messages from './routes/Messages'
import Reports from './routes/Reports'
import FileManager from './routes/FileManager'
import Contracts from './routes/Contracts'
import Tokens from './routes/Tokens'
import TokenizeContract from './routes/TokenizeContract'
import TokenDetails from './routes/TokenDetails'
import ContractDetails from './routes/ContractDetails'
import Companies from './routes/Companies'
import CreateCompany from './routes/CreateCompany'
import CompanyDetails from './routes/CompanyDetails'
import EditCompany from './routes/EditCompany'
import Trades from './routes/Trades'
import TradeDetails from './routes/TradeDetails'
import { AllContracts, AllTokens } from './routes/PorfoliosManager'
import Marketplace from './routes/Marketplace'
import MyListings from './routes/Marketplace/MyListings'
import AllOffers from './routes/MarketplaceActivities/AllOffers'
import Offers from './routes/Offers'
import AllTrades from './routes/MarketplaceActivities/AllTrades'
import ListingDetails from './routes/ListingDetails'
import OfferDetails from './routes/OfferDetails/OfferDetails.js'
import AddNewCustomer from './routes/AddNewCustomer'
import AddNewProvider from './routes/AddNewProvider'
import About from './routes/About'
import Contact from './routes/Contact'
import Support from './routes/Support'
import Terms from './routes/Terms'
import Careers from './routes/Careers'
import PrivacyPolicy from './routes/PrivacyPolicy'
import ProviderActions from './routes/ProviderActions'
import { PageNotFound } from './components/NotFound'
import Sidebar from './components/Layout/Sidebar'
import NavigationBar from './components/Layout/NavigationBar'
import store from './redux/store'
import { MeProvider, useMe } from './util/hooks/me'
import util from './util'
import Dev from './util/Dev'
import CustomerDetails from './routes/CustomerDetails'
import ProviderDetails from './routes/ProviderDetails'
import Search from './routes/Search'
import Activity from './routes/Activity'
import SessionActivity from './components/SessionActivity'
import { hot } from 'react-hot-loader/root'
import { ProviderActionType } from './util/Constant'
import link from './components/link'

const getSentryEnvironment = () => {
  switch (window.location.hostname) {
    case 'dev.creosafe.io':
      return 'dev'
    case 'beta.creosafe.io':
      return 'beta'
    case 'test.creosafe.io':
      return 'test'
    case 'creosafe.io':
      return 'production'
    case 'local.creosafe.io':
    case 'localhost':
    case '127.0.0.1':
      return `local`
    default:
      return `unknown: ${window.location.hostname}`
  }
}

if (!util.isLocal()) {
  Sentry.init({
    dsn: 'https://f47de9ec11404737a66657cd83970e88@sentry.creosafe.io/2',
    environment: getSentryEnvironment(),
    release: packageJson.version,
  })
}

const PurchaseOffers = () => <Offers type="purchase" title="Buy Offers" />

const SalesOffers = () => <Offers type="sales" title="Sell Offers" />

const PendingTrades = () => <Trades type="pending" title="Pending Trades" />

const CompletedTrades = () => (
  <Trades type="completed" title="Completed Trades" />
)

const GuestRouteInner = ({ component: C }) => {
  const me = useMe()
  if (me) return <Redirect to="/" />
  return <C />
}

const PrivateRouteInner = ({ component: C, single }) => {
  const me = useMe()
  const isActivating = useRouteMatch(link.activateToken(':token?'))
  const isCreatingProfile = useRouteMatch(link.createProfile)
  const isCreatingCompany = useRouteMatch(link.newCompany)

  if (!me) return <Redirect to={link.login} />

  if (
    !me.profile &&
    !(isActivating || isCreatingProfile || isCreatingCompany)
  ) {
    return <Redirect to={link.createProfile} />
  }
  if (me.profile && isCreatingProfile) {
    return <Redirect to="/" />
  }

  if (single) return <C />
  return (
    <>
      <Sidebar disabled={!me.primary_wallet} />
      <Sidebar disabled={!me.primary_wallet} mobile />
      <NavigationBar />
      <div id="page">
        <C />
        <Footer />
        <SessionActivity />
      </div>
    </>
  )
}

const GuestRoute = ({ component, ...props }) => {
  return (
    <Route {...props}>
      <MeProvider key={props.path}>
        <GuestRouteInner component={component} />
      </MeProvider>
    </Route>
  )
}

const PrivateRoute = ({ component, single, ...props }) => {
  return (
    <Route {...props}>
      <MeProvider key={props.path}>
        <PrivateRouteInner component={component} single={single} />
      </MeProvider>
    </Route>
  )
}

const Routes = () => {
  return (
    <Switch>
      <GuestRoute exact path={link.login} component={Auth} />
      <GuestRoute exact path={link.forgotPassword} component={ForgotPassword} />
      <GuestRoute
        exact
        path={link.resetPassword({ uuid: ':token' })}
        component={ResetPassword}
      />
      <GuestRoute
        exact
        path={link.activateToken({ uuid: ':token?' })}
        component={ActivateAccount}
      />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path={link.profile} component={ProfileDetails} />
      <PrivateRoute
        path={link.createProfile}
        component={CreateProfile}
        single
      />
      <PrivateRoute exact path={link.settings} component={Settings} />
      <PrivateRoute
        exact
        path={link.search(':category?', ':query?')}
        component={Search}
      />
      <PrivateRoute
        exact
        path={[
          link.actions(ProviderActionType.CUSTOMER_APPROVAL),
          link.actions(ProviderActionType.CUSTOMER_KYC),
          link.actions(ProviderActionType.CUSTOMER_ACC),
          link.actions(ProviderActionType.CUSTOMER_MSG),
          link.actions(ProviderActionType.PROVIDER_APPROVAL),
          link.actions(ProviderActionType.ASSET_TRANSFERABILITY),
          link.actions(ProviderActionType.COMPANY_VERIFICATION),
          link.actions(ProviderActionType.TRADE_APPROVAL),
          link.actions(ProviderActionType.PROVIDER_REQUEST),
        ]}
        component={ProviderActions}
      />
      <PrivateRoute
        exact
        path={[
          '/actions/action/:uuid',
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.CUSTOMER_APPROVAL,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.CUSTOMER_KYC,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.CUSTOMER_ACC,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.CUSTOMER_MSG,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.PROVIDER_APPROVAL,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.ASSET_TRANSFERABILITY,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.COMPANY_VERIFICATION,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.TRADE_APPROVAL,
          }),
          link.action({
            uuid: ':uuid',
            action_type: ProviderActionType.PROVIDER_REQUEST,
          }),
        ]}
        component={ProviderAction}
      />
      {/*<PrivateRoute exact path="/actions/:uuid" component={ProviderAction} />*/}
      <PrivateRoute exact path={link.contracts} component={Contracts} />
      <PrivateRoute exact path={link.tokens} component={Tokens} />
      <PrivateRoute exact path={link.customers} component={Customers} />
      <PrivateRoute
        exact
        path={[link.newContract, link.draftContract({ uuid: ':uuid' })]}
        component={TokenizeContract}
      />
      <PrivateRoute
        exact
        path={link.token({ uuid: ':uuid' })}
        component={TokenDetails}
      />
      <PrivateRoute
        exact
        path={link.contract({ uuid: ':uuid' })}
        component={ContractDetails}
      />
      <PrivateRoute exact path={link.companies} component={Companies} />
      <PrivateRoute exact path={link.newCompany} component={CreateCompany} />
      <PrivateRoute
        exact
        path={link.company({ uuid: ':uuid' })}
        component={CompanyDetails}
      />
      <PrivateRoute
        exact
        path={link.editCompany({ uuid: ':uuid' })}
        component={EditCompany}
      />
      <PrivateRoute exact path={link.tokens} component={AllTokens} />
      <PrivateRoute exact path={link.contracts} component={AllContracts} />
      <PrivateRoute exact path={link.marketplace} component={Marketplace} />
      <PrivateRoute exact path={link.myListings} component={MyListings} />
      <PrivateRoute exact path={link.offerActivities} component={AllOffers} />
      <PrivateRoute exact path={link.tradeActivities} component={AllTrades} />
      <PrivateRoute
        exact
        path={link.listingActivities}
        component={Marketplace}
      />
      <PrivateRoute
        exact
        path={link.listing({ uuid: ':uuid' })}
        component={ListingDetails}
      />
      <PrivateRoute
        exact
        path={link.myPurchaseOffers}
        component={PurchaseOffers}
      />
      <PrivateRoute exact path={link.mySaleOffers} component={SalesOffers} />
      <PrivateRoute
        exact
        path={link.offer({ uuid: ':uuid' })}
        component={OfferDetails}
      />
      <PrivateRoute
        exact
        path={link.myPendingTrades}
        component={PendingTrades}
      />
      <PrivateRoute
        exact
        path={link.myCompletedTrades}
        component={CompletedTrades}
      />
      <PrivateRoute
        exact
        path={link.trade({ uuid: ':uuid' })}
        component={TradeDetails}
      />
      <PrivateRoute path={link.messages} component={Messages} />
      <PrivateRoute exact path={link.customers} component={Customers} />
      <PrivateRoute
        exact
        path={link.customer({ uuid: ':uuid' })}
        component={CustomerDetails}
      />
      <PrivateRoute exact path={link.providers} component={Providers} />
      <PrivateRoute exact path={link.providers} component={ProviderDetails} />
      <PrivateRoute exact path={link.newCustomer} component={AddNewCustomer} />
      <PrivateRoute exact path={link.newProvider} component={AddNewProvider} />
      <PrivateRoute exact path={link.reports} component={Reports} />
      <PrivateRoute exact path={link.fileManager} component={FileManager} />
      <PrivateRoute exact path={link.activity} component={Activity} />
      <PrivateRoute exact path={link.about} component={About} />
      <PrivateRoute exact path={link.contact} component={Contact} />
      <PrivateRoute exact path={link.support} component={Support} />
      <PrivateRoute exact path={link.terms} component={Terms} />
      <PrivateRoute exact path={link.careers} component={Careers} />
      <PrivateRoute exact path={link.privacyPolicy} component={PrivacyPolicy} />
      <PrivateRoute exact path={link.notFound} component={PageNotFound} />
      <PrivateRoute component={() => <Redirect to={link.notFound} />} />
    </Switch>
  )
}

class App extends React.Component {
  componentDidCatch(error, info) {
    console.error('Root error', error, info)
  }

  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <div id="notification-container" />
          <div id="modal-container" />
          <BrowserRouter>
            <Routes />
            {util.isDev() && <Dev />}
          </BrowserRouter>
        </ApolloProvider>
      </Provider>
    )
  }
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
