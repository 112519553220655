import _ from 'lodash'
import { UserType } from '../../../util/Constant'
import util from '../../../util'

const accountTypeStep = {
  key: 'type',
  title: 'Account Type',
}
const detailsStep = {
  key: 'basic',
  title: 'Basic Info',
}
const contactStep = {
  key: 'contact',
  title: 'Contact Info',
}
const bankStep = {
  key: 'bank',
  title: 'Bank Info',
}
const companyStep = {
  key: 'company',
  title: 'Company Info',
}

export const getCreateProfileSteps = (user_type, me) => {
  let steps
  switch (user_type) {
    case UserType.DRAFT:
      steps = []
      break
    case UserType.INDIVIDUAL:
      steps = [accountTypeStep, detailsStep, contactStep, bankStep]
      break
    case UserType.INSTITUTIONAL:
      steps = [accountTypeStep, detailsStep, contactStep, companyStep, bankStep]
      break
    case UserType.BROKER_DEALER:
    case UserType.FINANCIAL_ADVISOR:
      steps = [accountTypeStep, detailsStep, contactStep, companyStep]
      break
    case UserType.TRANSFER_AGENT:
      steps = [accountTypeStep, contactStep, companyStep]
      break
    default:
      steps = [accountTypeStep, detailsStep, contactStep, bankStep]
  }

  // it removes first step
  if (me.user_type !== UserType.DRAFT) {
    steps.shift()
  }

  return steps
}

export const getAddNewCustomerSteps = user_type => {
  switch (user_type) {
    case UserType.INSTITUTIONAL:
      return [accountTypeStep, detailsStep, contactStep, companyStep, bankStep]
    case UserType.INDIVIDUAL:
    default:
      return [accountTypeStep, detailsStep, contactStep, bankStep]
  }
}

export const getAddNewProviderSteps = () => {
  return [accountTypeStep, detailsStep, contactStep, companyStep]
}

export const getEmptyProfileForm = () => ({
  email: '',
  firstname: '',
  lastname: '',
  photo: null,
  email_alt: '',
  phonenumber: '',
  phonenumber_alt: '',
  date_of_birth: '',
  tax_id: '',
  address: '',
  country: 'US',
  state: '',
  city: '',
  zipcode: '',
  company: null,
  company_position: '',
  letter_of_authorization: null,
  bank_info: {
    bank_name: '',
    account_number: '',
    rtn_aba: '',
    swift_bic: '',
  },
})

export const getEmptyNewCustomerForm = () => ({
  ...getEmptyProfileForm(),
  user_type: null,
})

export const getEmptyNewProviderForm = () => ({
  ...getEmptyProfileForm(),
  provider_type: null,
})

export const mapProfileToForm = user => {
  const form = getEmptyProfileForm()
  form.firstname = user.firstname || ''
  form.lastname = user.lastname || ''
  form.email = user.email
  if (user.user_type !== UserType.DRAFT) {
    form.user_type = user.user_type
  }

  const profile = _.get(user, 'profile')
  if (profile) {
    // assign profile fields to form
    for (const key in form) {
      if (profile[key]) form[key] = profile[key]
    }
    if (profile.company) {
      const { __typename, ...companyForm } = profile.company
      form.company = companyForm
    }
    if (profile.bank_info) {
      form.bank_info = {
        bank_name: _.get(profile, 'bank_info.bank_name', ''),
        account_number: _.get(profile, 'bank_info.account_number', ''),
        rtn_aba: _.get(profile, 'bank_info.rtn_aba', ''),
        swift_bic: _.get(profile, 'bank_info.swift_bic', ''),
      }
    }
  }
  return form
}

export const mapFormToInput = (form, { isCustomer, isProvider } = {}) => {
  const input = _.cloneDeep(form)
  if (input.photo) input.photo = input.photo.uuid
  if (input.company) {
    input.company_id = input.company.uuid
    delete input.company
  }
  if (input.letter_of_authorization)
    input.letter_of_authorization = input.letter_of_authorization.uuid
  // email already set creating profile
  if (!(isCustomer || isProvider)) {
    delete input.email
  }

  return util.cleanObject(input)
}
