import React from 'react'
import { Container, Menu } from 'semantic-ui-react'
//import { useLocation } from 'react-router'
//import { Link } from 'react-router-dom'

import { ReactComponent as PoweredByLogo } from '../../images/powered_by.svg'

const Footer = ({ fluid }) => {
  //const { pathname } = useLocation()

  return (
    <footer className={fluid && 'fluid'}>
      <Container fluid>
        <Menu text className="px-15">
          <p>© 2020 STC Digital</p>
          {/*
          <Menu.Item
            name="About"
            active={pathname === '/about'}
            as={Link}
            to={link.about}
          	/>
          <Menu.Item
            name="Contact"
            active={pathname === '/contact'}
            as={Link}
            to={link.contact}
          	/>
          <Menu.Item
            name="Support"
            active={pathname === '/support'}
            as={Link}
            to={link.support}
          	/>
          <Menu.Item
            name="Terms"
            active={pathname === '/terms'}
            as={Link}
            to={link.terms}
          	/>
          <Menu.Item
            name="Careers"
            active={pathname === '/careers'}
            as={Link}
            to={link.careers}
          	/>
          <Menu.Item
            name="Privacy Policy"
            active={pathname === '/privacy-policy'}
            as={Link}
            to={link.privacyPolicy}
          	/>
						*/}
          <Menu.Menu position="right">
            <a
              href="http://creosafe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer-logo">
                <PoweredByLogo />
              </div>
            </a>
          </Menu.Menu>
        </Menu>
      </Container>
    </footer>
  )
}

export default Footer
