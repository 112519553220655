import React from 'react'
import { useParams } from 'react-router'
import { Container, Grid, Step } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import _ from 'lodash'
import { CSBreadcrumb, PageLoader } from '../../components'
import TypeStep from './TypeStep'
import CompanyStep from './CompanyStep'
import DetailsStep from './DetailsStep'
import DocumentsStep from './DocumentsStep'
import { getSchema, mapContractToForm } from './common'
import { client, GET_CONTRACT } from '../../util/API/Apollo'
import { useSteps } from '../../util/hooks/steps'
import PayDeployStep from './PayDeployStep'
import useForm from '../../util/hooks/form'
import { ContractType } from '../../util/Constant'
import FinancialDataStep from './FinancialDataStep'

const stepTitle = {
  type: 'Type',
  company: 'Issuer',
  details: 'Asset Details',
  financials: 'Financial Details',
  shareholders: 'Shareholders',
  documents: 'Documents',
  finish: 'Finish',
}
const StepsHeader = ({ steps, activeIndex }) => (
  <Step.Group fluid ordered>
    {steps.map((key, index) => (
      <Step
        key={index}
        active={activeIndex === index}
        completed={activeIndex > index}
      >
        <Step.Content>
          <Step.Title>{stepTitle[key]}</Step.Title>
        </Step.Content>
      </Step>
    ))}
  </Step.Group>
)

const getDefaultStep = contract => {
  if (!contract) return 'type'

  // const steps = getSteps(contract.contract_type)
  if (_.get(contract, 'documents.length')) {
    return 'finish'
  }
  const isFOF = contract.contract_type === ContractType.FOF
  const isVC = contract.contract_type === ContractType.VC
  if (isFOF || isVC) {
    return 'financials'
  }
  return 'documents'

  // go to net step after details // uncomment in case of optional step
  // const index = steps.findIndex(val => val === 'details')
  // return steps[index + 1]
}

const getSteps = type => {
  const extras = [] // uncomment in case of optional step
  if (type === ContractType.FOF || type === ContractType.VC) {
    extras.push('financials')
  }
  return ['type', 'company', 'details', ...extras, 'documents', 'finish']
}

const TokenizationForm = ({ contract }) => {
  const type = contract && contract.contract_type
  const [schema, setSchema] = React.useState(null)

  // SET STEPS
  const steps = React.useMemo(() => getSteps(type), [type])
  const defaultStep = React.useMemo(() => getDefaultStep(contract), [contract])
  // type|company|details|financials|documents|finish
  const stepsHook = useSteps(steps, defaultStep)
  const { isActive, activeIndex, activeStep } = stepsHook
  // SET FORM
  const initialForm = React.useMemo(() => mapContractToForm(contract), [
    contract,
  ])
  const formHook = useForm('tokenization', initialForm, { schema })
  // change schema based on step and contract type
  React.useEffect(() => {
    setSchema(getSchema(formHook.form.contract_type, activeStep))
  }, [activeStep, formHook.form.contract_type])
  React.useEffect(() => {
    if (contract) formHook.clear()
  }, [activeStep]) // eslint-disable-line react-hooks/exhaustive-deps

  const passProps = { contract, formHook, stepsHook }
  return (
    <Container fluid>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <StepsHeader activeIndex={activeIndex} steps={steps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pt-0">
          <Grid.Column width={16}>
            {isActive('type') && <TypeStep {...passProps} />}
            {isActive('company') && <CompanyStep {...passProps} />}
            {isActive('details') && <DetailsStep {...passProps} />}
            {isActive('financials') && <FinancialDataStep {...passProps} />}
            {/*{isActive('shareholders') && <ShareholdersStep {...passProps}/>}*/}
            {isActive('documents') && <DocumentsStep {...passProps} />}
            {isActive('finish') && <PayDeployStep {...passProps} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

const TokenizeContract = () => {
  const { uuid } = useParams()
  const { loading, data } = useQuery(GET_CONTRACT, {
    variables: { uuid },
    skip: !uuid,
    client,
  })
  if (loading) return <PageLoader active={loading} />

  return (
    <>
      <CSBreadcrumb title="Create New Asset" />
      <TokenizationForm contract={data ? data.contract : undefined} />
    </>
  )
}

export default TokenizeContract
