import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Button, Modal } from 'semantic-ui-react'
import { DimmerLoader } from '../index'
import { SuccessModal } from '../confirm'
import WalletDecryptor from '../Wallet/WalletEncrypter'
import { signMessage, prettifyAddress } from '../../util/blockchain'
import { useModal, useApproval } from '../../util/hooks'
import { ApprovalType, BlockchainSignedMessageTags } from '../../util/Constant'
import {
  APPROVE_NEW_CUSTOMER_DOC,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'

const TxModal = ({ action, approval_type, open, onClose }) => {
  const approval = useApproval(action.approvals, approval_type)
  const [signing, setSigning] = useState(false)
  const [signedMessage, setSignedMessage] = useState(null)

  const [approve, approveMutation] = useMutation(APPROVE_NEW_CUSTOMER_DOC, {
    variables: {
      provider_action_id: action.uuid,
      approval_type,
      signed_message: signedMessage,
    },
    onCompleted: onClose,
    refetchQueries: [
      { query: GET_PROVIDER_ACTION_DETAIL, variables: { uuid: action.uuid } },
    ],
    awaitRefetchQueries: true,
    client,
  })

  const handleApprove = async wallet => {
    setSigning(true)
    const investor_address = action.actionable.primary_wallet.address
    const document_hash = approval.verification_data.document.hash

    const message = `${prettifyAddress(investor_address)}:${
      BlockchainSignedMessageTags[approval_type]
    }:${prettifyAddress(document_hash)}`

    const signedMessage = await signMessage(wallet, message)

    setSignedMessage(signedMessage)
    setSigning(false)
    await approve()
  }

  const loading = signing || approveMutation.loading

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        {approval_type === ApprovalType.VERIFICATION_KYC &&
          'ACCOUNT VERIFICATION'}
        {approval_type === ApprovalType.VERIFICATION_ACC &&
          'ACCREDITED INVESTOR APPROVAL'}
      </Modal.Header>
      <DimmerLoader active={loading} />
      <Modal.Content>
        <WalletDecryptor onDecrypted={handleApprove} />
      </Modal.Content>
    </Modal>
  )
}

const KYCACCApproveButton = ({ action, show, approvalType }) => {
  const txModal = useModal()
  const successModal = useModal()

  return (
    <>
      <SuccessModal
        content="You approved the document"
        {...successModal.props}
      />
      {show && (
        <>
          <TxModal
            {...txModal.props}
            action={action}
            approval_type={approvalType}
          />
          <Button
            content="Approve"
            color="green"
            type="button"
            onClick={txModal.show}
          />
        </>
      )}
    </>
  )
}

export default KYCACCApproveButton
