import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import {
  Header,
  Transition,
  Image,
  Button,
  Form,
  Input,
  Progress,
  Segment,
} from 'semantic-ui-react'
import { SingleGrid } from '../../'
import { CopyInput, DimmerLoader } from '../../index'
import { useMounted } from '../../../util/hooks'
import createWalletImg from '../../../images/create_wallet.svg'

export const WelcomeStep = ({ userFullName, nextStep }) => {
  const mounted = useMounted()

  return (
    <Transition visible={mounted} animation="fade up" duration={200}>
      <div className="text-center">
        <Header as="h2" color="purple">
          Hello {userFullName},
        </Header>
        <Header as="h3" content="Welcome to STC Securities Transfer Platform" />
        <p>Please proceed with this step to create an Ethererum Wallet</p>
        <br />
        <Image size="medium" src={createWalletImg} centered />
        <br />
        <br />
        <Button
          primary
          content="Create Ethereum Wallet Now"
          data-test-id="create-wallet-start"
          onClick={nextStep}
        />
      </div>
    </Transition>
  )
}
export const Info = ({ nextStep }) => {
  const mounted = useMounted()
  return (
    <Transition visible={mounted} animation="scale" duration={200}>
      <SingleGrid>
        <Header as="h1" content="BEFORE YOU START" />
        <Segment basic className="text-justify">
          <Header
            as="h2"
            color="purple"
            content="What is an Ethereum Wallet?"
          />
          <p>
            Ethereum wallets are how users interact with their funds & digital
            assets. Wallets are similar to an account with a bank, but with very
            important differences. Like a bank account, you can receive and
            transfer funds, but at the same time you can store, send and receive
            digital assets that are in these wallets.
          </p>
          <Header
            as="h2"
            color="purple"
            content="Why you need an Ethereum Wallet?"
          />
          <p>
            Your Digital Securities created or bought through STC digital
            securities are stored in this wallet. You can also independently
            verify the Digital Securities that you own within this wallet on the
            Ethereum Blockchain.
          </p>
          <p>
            The Ethereum wallet on the distributed ledger represents an
            immutable proof of ownership of your assets.
          </p>
          <p>
            You can use your Public Key/Wallet ID on Etherscan in order to be
            able to independently verify ownership of Digital Securities in your
            Ethereum wallet
          </p>
          <Header as="h2" color="purple" content="What is a Public Key?" />
          <p>
            A Public Key represents your Ethereum wallet ID that can be shared
            publicly. By analogy, it is your bank account number.
          </p>
          <p>
            Your Public Key is shown in your dashboard and can be publicly seen
            over Ethereum Blockchain. It will also be referred to as Ethereum
            (Wallet) ID in our platform.
          </p>
          <p>
            In cryptography, a public key is a large numerical value that is
            used to encrypt data.
          </p>
          <p>
            The key can be generated by a software program, but more often, it
            is provided by a trusted, designated authority and made available to
            everyone through a publicly accessible repository or directory.
          </p>
          <Header as="h2" color="purple" content="What is a Private Key?" />
          <p>
            A private key is a sophisticated form of cryptography that allows a
            user to access his or her Digital Securities and cryptocurrency in
            your wallet. By analogy, a private key is your digital signature or
            your password to access your Ethereum wallet, and sign transactions
            on Ethereum Blockchain.
          </p>
          <p>
            A user's private key is kept private and known only to the user. The
            private key can be used to encrypt a signature that can be verified
            by anyone with the public key.
          </p>
          <p>
            It is also known as a secret key and therefore must not be shared
            with anybody.
          </p>
          <Header
            as="h2"
            color="purple"
            content="What is a Private Keystore file?"
          />
          <p>
            A Keystore file is an encrypted version of your private key in JSON
            format. It is an encryption of your private key that is protected by
            a password of your choosing. A Keystore file is a secure way of
            using your private key to access your wallet and to sign
            transactions.
          </p>
          <p>
            A Keystore file is safer than a private key because you need a
            password to access it.
          </p>
          <p>
            Please do not share either your Keystore JSON file that you
            downloaded or the password that you determined while creating your
            Ethereum wallet.
          </p>
          <Button
            primary
            floated="right"
            content="next"
            onClick={nextStep}
            data-test-id="create-wallet-next"
          />
        </Segment>
      </SingleGrid>
    </Transition>
  )
}
export const LastWarningStep = ({ nextStep }) => {
  const mounted = useMounted()
  return (
    <Transition visible={mounted} animation="scale" duration={200}>
      <SingleGrid>
        <Segment basic>
          <Header
            as="h2"
            content="YOU ARE ABOUT TO CREATE AN ETHEREUM WALLET"
          />
          <p className="text-justify">
            Your Digital Securities will be created over Ethereum blockchain,
            which enables smart contracts to be built and run without any
            downtime, fraud, control, or interference from a third party and
            your Digital Securities will be stored in an Ethereum wallet.
          </p>
          <Header
            as="h3"
            icon="exclamation"
            color="red"
            content="PLEASE DO NOT SHARE IT WITH ANYBODY"
          />
          <p className="text-justify">
            Once it is created you will be provided with your public key, your
            private key and a password protected Keystore JSON file in which
            your private key is encrypted for security purposes.
            <br />
            It is essential to note that your private key will be used as your
            digital signature to sign off on transactions and therefore must be
            stored securely.
          </p>
          <Button
            primary
            floated="right"
            content="Continue"
            onClick={nextStep}
            data-test-id="create-wallet-next"
          />
        </Segment>
      </SingleGrid>
    </Transition>
  )
}

export const PasswordStep = ({ formHook, nextStep }) => {
  const { form, field } = formHook
  const mounted = useMounted()
  const isFormValid =
    form.password === form.passwordConfirm && form.password.length >= 9

  return (
    <Transition visible={mounted} animation="scale" duration={200}>
      <Segment padded="very">
        <Header as="h2" content="CREATE AN ETHEREUM WALLET" />
        <p>
          {' '}
          You need a password to create and unlock your wallet Keystore JSON
          file. Your password needs to be <strong>at least 9 characters</strong>
        </p>
        <Form onSubmit={nextStep}>
          <Form.Field
            type="password"
            label="Password"
            placeholder="●●●●●●"
            icon="lock"
            iconPosition="left"
            required
            control={Input}
            {...field('password')}
          />
          <Form.Field
            type="password"
            label="Confirm password"
            placeholder="●●●●●●"
            icon="lock"
            iconPosition="left"
            required
            control={Input}
            {...field('passwordConfirm')}
          />
          <Form.Field
            type="text"
            label="You may give your primary wallet a name for your convenience."
            placeholder="My Wallet"
            icon="pencil"
            iconPosition="left"
            control={Input}
            {...field('walletName')}
          />
          <Button
            primary
            fluid
            content="Create & Encrypt Wallet"
            onClick={nextStep}
            disabled={!isFormValid}
            data-test-id="create-wallet-next"
          />
        </Form>
      </Segment>
    </Transition>
  )
}

export const EncryptionStep = ({ formHook, createWallet }) => {
  const [progress, setProgress] = useState(0)
  const mounted = useMounted()

  const setDecryptingProgress = progress => {
    setProgress(0.5 + progress / 2)
  }

  const setEncryptingProgress = progress => {
    setProgress(progress / 2)
  }

  useEffect(() => {
    const generateWallet = async () => {
      const { password, walletName } = formHook.form
      const newWallet = ethers.Wallet.createRandom()
      const fullKeystore = await newWallet.encrypt(
        password,
        { scrypt: { N: 1 << 16, p: 1 } },
        setEncryptingProgress
      )

      const parsedKeystore = JSON.parse(fullKeystore)
      const keystore = {
        address: parsedKeystore['address'],
        id: parsedKeystore['id'],
        version: parsedKeystore['version'],
        Crypto: parsedKeystore['Crypto'],
      }

      const keystoreFile = JSON.stringify(keystore)

      const wallet = await ethers.Wallet.fromEncryptedJson(
        keystoreFile,
        password,
        setDecryptingProgress
      )

      const address = wallet.address.replace('0x', '').toLowerCase()
      const privateKey = wallet.privateKey
      const namePrefix = walletName ? `${walletName}-` : ''
      const filename = `${namePrefix}UTC--${new Date().toISOString()}--${address}`

      createWallet({ privateKey, address, filename, keystoreFile })
    }
    generateWallet().catch(console.error)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Transition visible={mounted} animation="scale" duration={200}>
      <Segment padded="very">
        <Header as="h2" content="ETHEREUM WALLET ENCRYPTION" />
        <Progress percent={parseInt(progress * 100)} progress indicating />
        <p className="text-center">This process will take a few seconds</p>
      </Segment>
    </Transition>
  )
}

export const DownloadJsonStep = ({ wallet, save, saveMutation }) => {
  const [jsonDownloaded, setJsonDownloaded] = useState(false)
  const mounted = useMounted()

  const downloadJson = () => {
    const { keystoreFile, filename } = wallet
    const data = new Blob([keystoreFile], { type: 'text/plain' })
    const tempLink = document.createElement('a')
    tempLink.href = window.URL.createObjectURL(data)
    tempLink.setAttribute('download', `${filename}.json`)
    tempLink.click()
    setJsonDownloaded(true)
  }

  return (
    <Transition visible={mounted} animation="scale" duration={200}>
      <div>
        {!jsonDownloaded && (
          <Segment padded="very">
            <Header
              as="h3"
              content="Please store your private key and Keystore JSON file securely and do not share them with anyone"
            />
            <Header
              as="h4"
              color="red"
              content="Once you download your Keystore JSON file below, your private key will
          appear on the screen."
            />
            <Button
              primary
              fluid
              content="Download Keystore (UTC/JSON)"
              onClick={downloadJson}
              data-test-id="create-wallet-download-json"
            />
          </Segment>
        )}
        {jsonDownloaded && (
          <Segment padded="very">
            <Header as="h2" content="SAVE WALLET" />
            <p>
              Please copy your private key securely. If you do not copy or write
              down your private key now, it cannot be accessible at later stages
              since we do not keep your private key
            </p>
            <Header as="h4" content="Your private key" />
            <CopyInput
              value={wallet.privateKey}
              data-test-id="create-wallet-pk"
            />
            <br />
            <br />
            <DimmerLoader active={saveMutation.loading} />
            <Button
              primary
              fluid
              content="Save wallet"
              onClick={save}
              disabled={!jsonDownloaded}
              data-test-id="create-wallet-finish"
            />
          </Segment>
        )}
      </div>
    </Transition>
  )
}
