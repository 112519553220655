import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Header, Icon, Message, Segment, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CSBreadcrumb, IfUserIs, PageLoader, SingleGrid } from '../components'
import TabMenu from '../components/TabMenu'
import {
  PendingActionsProvider,
  PendingActionsCustomer,
  Activities,
} from '../components/Dashboard'
import {
  FinancialWidget,
  CustomersWidget,
  ProvidersWidget,
  CompanyWidget,
  TradesWidget,
  OffersWidget,
  SecuritiesWidget,
  MarketplaceWidget,
} from '../components/Widget'
import CreateWallet from '../components/Wallet/CreateWallet'
import MyWallets from '../components/Wallet/MyWallets'
import { useDashboardData } from '../util/hooks'
import { useMe } from '../util/hooks/me'
import {
  GET_DASHBOARD_EXTRA,
  GET_RESERVED_TOKENS,
  client,
} from '../util/API/Apollo'
import ProfileStatus from '../components/Profile/ProfileStatus'
import DraftContracts from '../components/Dashboard/DraftContracts'
import TokensTable from '../components/Token/TokensTable'
import link from '../components/link'

const WalletCreatedMessage = () => {
  return (
    <Message success icon>
      <Icon name="check" />
      <Message.Content>
        <Message.Header>
          Your wallet has been created successfully.&nsbp;
          <IfUserIs customer>Your account is waiting for approval.</IfUserIs>
          <IfUserIs provider>
            You need to do certificate verification for account approval.
          </IfUserIs>
        </Message.Header>
      </Message.Content>
    </Message>
  )
}

const ReservedTokens = () => {
  const { data } = useQuery(GET_RESERVED_TOKENS, { client })
  const tokens = _.get(data, 'tokens.data', [])
  if (tokens.length === 0) return null
  return (
    <Grid.Row>
      <Grid.Column className="pb-15">
        <Segment padded="very">
          <Header as="h2" content="RESERVED DIGITAL SECURITIES" />
          <strong>
            <p>
              Digital Securities are mirrored images of your underlying physical
              or book-entry assets. After claiming these Digital Securities, you
              will be able to list them on the Marketplace.
            </p>
            <br />
            <p>
              Pursuant to STC enabling Transferability, you will be able to
              execute a trade and sell them to attain liquidity over the STC
              Digital Securities Platform. 
            </p>
          </strong>
          <TokensTable tokens={tokens} />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  )
}

const ShortCuts = () => {
  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Button
            primary
            fluid
            as={Link}
            to={link.newContract}
            content="ADD ASSET"
          />
          <br />
          <Button
            primary
            fluid
            as={Link}
            to={link.newCompany}
            content="add company"
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            primary
            fluid
            as={Link}
            to={link.newCustomer}
            content="add customer"
          />
          <br />
          <Button
            primary
            fluid
            as={Link}
            to={link.newProvider}
            content="add provider"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const Dashboard = () => {
  const [walletCreated, setWalletCreated] = useState(false)

  const me = useMe()

  const { data, loading } = useQuery(GET_DASHBOARD_EXTRA, {
    client,
  })

  const { dashboard, loading: statisticsLoading } = useDashboardData()

  const activities = _.get(data, 'me.recent_activity.data', [])

  const pendingActions = {
    offers: _.get(data, 'pendingOffers.data', []),
    trades: _.get(data, 'pendingTrades.data', []),
    advice: _.get(data, 'me.solicitations.data', []),
  }

  if (loading || statisticsLoading) return <PageLoader />

  if (!me.primary_wallet) {
    return (
      <>
        <CSBreadcrumb />
        <SingleGrid padded>
          <CreateWallet setWalletCreated={setWalletCreated} />
        </SingleGrid>
      </>
    )
  }

  return (
    <>
      <CSBreadcrumb />
      <SingleGrid padded>
        {walletCreated && <WalletCreatedMessage />}
        <IfUserIs ta>
          <DraftContracts className="mb-15" />
          <PendingActionsProvider />
          <Grid doubling>
            <Grid.Row>
              <Grid.Column computer="6" tablet="16" mobile="16">
                <FinancialWidget
                  divided
                  inverted
                  style={{ backgroundColor: '#13D4AF', border: '0px' }}
                  data={dashboard.financials}
                />
                <ShortCuts />
                <MyWallets />
                <Segment padded="very">
                  <Header as="h2" content="ACCOUNTS" />
                  <TabMenu top defaultTab="CUSTOMERS">
                    <TabMenu.Top>
                      <TabMenu.Item name="CUSTOMERS" />
                      <TabMenu.Item name="PROVIDERS" />
                      <TabMenu.Item name="COMPANIES" />
                    </TabMenu.Top>
                    <TabMenu.Right>
                      <TabMenu.Pane name="CUSTOMERS">
                        <CustomersWidget
                          width={1}
                          divided
                          horizontal
                          className="mb-15"
                          data={dashboard.customers}
                        />
                      </TabMenu.Pane>
                      <TabMenu.Pane name="PROVIDERS">
                        <ProvidersWidget
                          width={1}
                          divided
                          horizontal
                          className="mb-15"
                          data={dashboard.providers}
                        />
                      </TabMenu.Pane>
                      <TabMenu.Pane name="COMPANIES">
                        <CompanyWidget
                          width={1}
                          divided
                          horizontal
                          className="mb-15"
                          data={dashboard.companies}
                        />
                      </TabMenu.Pane>
                    </TabMenu.Right>
                  </TabMenu>
                </Segment>
              </Grid.Column>
              <Grid.Column computer="10" tablet="16" mobile="16">
                <Segment padded="very">
                  <Header as="h2" content="MARKET ACTIVITY" />
                  <MarketplaceWidget
                    width={1}
                    divided
                    horizontal
                    inverted
                    color="blue"
                    className="mb-15"
                    data={dashboard.marketplace}
                  />
                  <TabMenu defaultTab="OFFERS">
                    <TabMenu.Left>
                      <TabMenu.Menu>
                        <TabMenu.Item name="OFFERS" />
                        <TabMenu.Item name="TRADES" />
                        <TabMenu.Item name="SECURITIES" />
                      </TabMenu.Menu>
                    </TabMenu.Left>
                    <TabMenu.Right>
                      <TabMenu.Pane name="OFFERS">
                        <OffersWidget
                          divided
                          width={1}
                          className="mb-15"
                          data={dashboard.offers}
                        />
                      </TabMenu.Pane>
                      <TabMenu.Pane name="TRADES">
                        <TradesWidget
                          divided
                          width={1}
                          className="mb-15"
                          data={dashboard.trades}
                        />
                        <IfUserIs customer>
                          <FinancialWidget
                            divided
                            width={1}
                            className="mb-15"
                            data={dashboard.financials}
                          />
                        </IfUserIs>
                      </TabMenu.Pane>
                      <TabMenu.Pane name="SECURITIES">
                        <SecuritiesWidget
                          divided
                          horizontal
                          width={1}
                          className="mb-15"
                          data={dashboard.securities}
                        />
                      </TabMenu.Pane>
                    </TabMenu.Right>
                  </TabMenu>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </IfUserIs>
        <IfUserIs provider>
          <PendingActionsProvider />
          <Grid doubling>
            <Grid.Row>
              <Grid.Column computer="6" tablet="6" mobile="16">
                <ProfileStatus />
                <MyWallets />
                <Activities activities={activities} />
              </Grid.Column>
              <Grid.Column computer="10" tablet="10" mobile="16">
                <Segment padded="very">
                  <Header as="h2" content="MARKET SUMMARY" />
                  <IfUserIs not verified>
                    <Header
                      sub
                      textAlign="center"
                      content="Your account information needs to be confirmed to see market activities."
                    />
                  </IfUserIs>
                  <IfUserIs verified>
                    <MarketplaceWidget
                      width={1}
                      divided
                      horizontal
                      inverted
                      color="blue"
                      className="mb-15"
                      data={dashboard.marketplace}
                    />
                    <TabMenu defaultTab="OFFERS">
                      <TabMenu.Left>
                        <TabMenu.Menu>
                          <TabMenu.Item name="OFFERS" />
                          <TabMenu.Item name="TRADES" />
                          <TabMenu.Item name="SECURITIES" />
                        </TabMenu.Menu>
                      </TabMenu.Left>
                      <TabMenu.Right>
                        <TabMenu.Pane name="OFFERS">
                          <OffersWidget
                            divided
                            width={1}
                            className="mb-15"
                            data={dashboard.offers}
                          />
                        </TabMenu.Pane>
                        <TabMenu.Pane name="TRADES">
                          <TradesWidget
                            divided
                            width={1}
                            className="mb-15"
                            data={dashboard.trades}
                          />
                          <IfUserIs customer>
                            <FinancialWidget
                              divided
                              width={1}
                              className="mb-15"
                              data={dashboard.financials}
                            />
                          </IfUserIs>
                        </TabMenu.Pane>
                        <TabMenu.Pane name="SECURITIES">
                          <SecuritiesWidget
                            divided
                            horizontal
                            width={1}
                            className="mb-15"
                            data={dashboard.securities}
                          />
                        </TabMenu.Pane>
                      </TabMenu.Right>
                    </TabMenu>
                  </IfUserIs>
                </Segment>
                <Segment padded="very">
                  <Header as="h2" content="CUSTOMERS" />
                  <IfUserIs not verified>
                    <Header
                      sub
                      textAlign="center"
                      content="Your account information needs to be confirmed to see customer summary"
                    />
                  </IfUserIs>
                  <IfUserIs verified>
                    <CustomersWidget
                      width={2}
                      divided
                      horizontal
                      className="mb-15"
                      data={dashboard.customers}
                    />
                  </IfUserIs>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </IfUserIs>
        <IfUserIs customer>
          <IfUserIs verified>
            <ReservedTokens />
            <DraftContracts className="mb-15" />
            <PendingActionsCustomer actions={pendingActions} />
          </IfUserIs>
          <Grid doubling>
            <Grid.Row>
              <Grid.Column computer="8" tablet="8" mobile="16">
                <MyWallets />
                <Segment padded="very">
                  <Header as="h2" content="MARKET SUMMARY" />
                  <IfUserIs not verified>
                    <Header
                      sub
                      textAlign="center"
                      content="You do not currently have a market activity"
                    />
                  </IfUserIs>
                  <IfUserIs verified>
                    <MarketplaceWidget
                      width={1}
                      divided
                      horizontal
                      inverted
                      color="blue"
                      className="mb-15"
                      data={dashboard.marketplace}
                    />
                    <Header as="h2" content="MY MARKET ACTIVITY" />
                    <TabMenu defaultTab="MY TRADES">
                      <TabMenu.Left>
                        <TabMenu.Menu>
                          <TabMenu.Item name="MY TRADES" />
                          <TabMenu.Item name="MY SECURITIES" />
                        </TabMenu.Menu>
                      </TabMenu.Left>
                      <TabMenu.Right>
                        <TabMenu.Pane name="MY TRADES">
                          <TradesWidget
                            divided
                            width={1}
                            className="mb-15"
                            data={dashboard.trades}
                          />
                          <IfUserIs customer>
                            <FinancialWidget
                              divided
                              width={1}
                              className="mb-15"
                              data={dashboard.financials}
                            />
                          </IfUserIs>
                        </TabMenu.Pane>
                        <TabMenu.Pane name="MY SECURITIES">
                          <SecuritiesWidget
                            divided
                            horizontal
                            width={1}
                            className="mb-15"
                            data={dashboard.securities}
                          />
                        </TabMenu.Pane>
                      </TabMenu.Right>
                    </TabMenu>
                  </IfUserIs>
                </Segment>
              </Grid.Column>
              <Grid.Column computer="8" tablet="8" mobile="16">
                <ProfileStatus />
                <Activities activities={activities} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </IfUserIs>
      </SingleGrid>
    </>
  )
}

export default Dashboard
