import React from 'react'
import { Grid, Header, Icon } from 'semantic-ui-react'
import KYCVerificationInfo from '../../components/Verification/KYCVerificationInfo'
import ACCVerificationInfo from '../../components/Verification/ACCVerificationInfo'

const AccountVerification = ({ user }) => {
  return (
    <Grid padded>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Header content="ACCOUNT VERIFICATION" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer="16" tablet="16" mobile="16">
          <p>
            <Icon name="info" />
            Your profile information and accredited investor must be verified
            before you can trade.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer="8" tablet="12" mobile="16">
          <KYCVerificationInfo user={user} />
        </Grid.Column>
        <Grid.Column computer="8" tablet="12" mobile="16">
          <ACCVerificationInfo user={user} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AccountVerification
