import React from 'react'
import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Modal,
  Table,
} from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import { AmountLabel, NumberFormat } from '../../index'
import { useModal, useTab } from '../../../util/hooks'
import {
  client,
  GET_CONTRACT,
  INVITE_SHAREHOLDER,
} from '../../../util/API/Apollo'
import { TxStatus, UserType, UserTypeNames } from '../../../util/Constant'
import InvitationForm from './InvitationForm'
import ImportForm from './ImportForm'
import { SuccessModal } from '../../confirm'

const InvitationRow = ({ value, remove, contract, onInvited }) => {
  const [invite, { loading }] = useMutation(INVITE_SHAREHOLDER, {
    variables: { contract_id: contract.uuid, input: value },
    refetchQueries: [
      { query: GET_CONTRACT, variables: { uuid: contract.uuid } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      remove()
      onInvited()
    },
    client,
  })

  const totalTokens = Number(contract.token_numbers.initial)
  const percentage = (Number(value.share_amount) / totalTokens) * 100

  return (
    <Table.Row>
      <Table.Cell content={`${value.firstname} ${value.lastname}`} />
      <Table.Cell content={UserTypeNames[UserType.INDIVIDUAL]} />
      <Table.Cell>
        <NumberFormat amount={percentage} float digits={2} />
        <Icon name="percent" />
      </Table.Cell>
      <Table.Cell>
        <AmountLabel amount={value.share_amount} />
      </Table.Cell>
      <Table.Cell collapsing>
        <Button content="Remove" color="red" type="button" onClick={remove} />
        <Button
          primary
          content="Invite"
          type="button"
          onClick={invite}
          loading={loading}
          disabled={loading}
        />
      </Table.Cell>
    </Table.Row>
  )
}

const FormList = ({ values, remove, contract }) => {
  const invitedModal = useModal()
  return (
    <>
      <SuccessModal
        {...invitedModal.props}
        content="An invitation has been sent"
      />
      {values.length > 0 && (
        <>
          <Divider />
          <Table basic="very" padded="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="SHAREHOLDER" />
                <Table.HeaderCell content="TYPE" />
                <Table.HeaderCell content="PERCENTAGE" />
                <Table.HeaderCell content="AMOUNT" />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {values.map((value, index) => (
                <InvitationRow
                  key={index}
                  value={value}
                  contract={contract}
                  remove={() => remove(index)}
                  onInvited={invitedModal.show}
                />
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  )
}

const InvitationModal = ({ open, onClose, onSubmit }) => {
  // manually|import
  const { isActive, itemProps } = useTab('manually', {
    disabled: true,
  })

  return (
    <>
      <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
        <Modal.Header content="ADD SHAREHOLDERS" />
        <Modal.Content>
          <Header
            as="h3"
            textAlign="center"
            content="Please select a method to invitation."
          />
          <Button.Group fluid size="large">
            <Button
              icon="keyboard"
              content="Manually"
              data-test-id="manual-shareholder-tab"
              {...itemProps('manually')}
            />
            <Button
              icon="excel file"
              content="Import"
              data-test-id="import-shareholder-tab"
              {...itemProps('import')}
            />
          </Button.Group>
          <br />
          <br />
          <br />
          {isActive('manually') && (
            <InvitationForm onSubmit={value => onSubmit([value])} />
          )}
          {isActive('import') && <ImportForm onSubmit={onSubmit} />}
        </Modal.Content>
      </Modal>
    </>
  )
}

const Invitations = ({ contract }) => {
  const modal = useModal()
  const [values, setValues] = React.useState([])
  const isDisabled = contract.tx_status !== TxStatus.VERIFIED

  const addShareholders = newValues => {
    modal.hide()
    setValues([...values, ...newValues])
  }

  const removeShareholder = index => {
    setValues(values.filter((v, i) => i !== index))
  }

  return (
    <>
      <Container textAlign="center">
        <Header as="h4" content="INVITE THE SHAREHOLDERS OF THE ASSET" />
        <Button
          type="button"
          data-test-id="create-invitations"
          primary
          content="Create invitations"
          onClick={modal.show}
          disabled={isDisabled}
        />
      </Container>
      <FormList
        values={values}
        remove={removeShareholder}
        contract={contract}
      />
      <InvitationModal {...modal.props} onSubmit={addShareholders} />
    </>
  )
}

export default Invitations
