import React from 'react'
import { Segment } from 'semantic-ui-react'
import { CSBreadcrumb, SingleGrid } from '../../components'
import LoginAndSecurity from './LoginAndSecurity'
//import NotificationSettings from './NotificationSettings'
//import SessionsHistory from './SessionsHistory'
//import GeneralSettings from './GeneralSettings'

const Settings = () => {
  return (
    <>
      <CSBreadcrumb title="Settings" />
      <SingleGrid padded>
        <Segment padded="very">
          <LoginAndSecurity />
          {/*<NotificationSettings />*/}
          {/*<SessionsHistory />*/}
          {/*<GeneralSettings />*/}
        </Segment>
      </SingleGrid>
    </>
  )
}

export default Settings
