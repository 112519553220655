import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

export const useSteps = (steps = [], defaultStep) => {
  const [activeStep, setActiveStep] = useState(defaultStep)
  const activeIndex = useMemo(
    () => steps.findIndex(key => key === activeStep),
    [activeStep, steps]
  )

  // last step is doneStep
  const isLastStep = activeStep === steps[steps.length - 1]

  const isActive = key => {
    if (typeof key === 'number') return key === activeIndex
    if (typeof key === 'string') return key === activeStep
    console.error(`invalid type: ${typeof key}`)
  }

  const nextStep = () => {
    const nextIndex = activeIndex + 1
    if (nextIndex >= steps.length) {
      return console.error(
        `invalid step: ${nextIndex} (length = ${steps.length}`
      )
    }
    setActiveStep(steps[nextIndex])
  }

  const prevStep = () => {
    const prevIndex = activeIndex - 1
    if (prevIndex < 0) {
      console.error(`invalid step: ${prevIndex}`)
    }
    setActiveStep(steps[prevIndex])
  }

  return {
    isActive,
    activeIndex,
    nextStep,
    prevStep,
    activeStep,
    setActiveStep,
    isLastStep,
    steps,
  }
}

/**
 * @param steps {[{ key, path }]}
 */
export const useRouteSteps = (steps = []) => {
  const history = useHistory()
  const location = useLocation()
  const activeIndex = useMemo(() => {
    return steps.findIndex(({ path }) => path === location.pathname)
  }, [steps, location.pathname])

  const activeStep = useMemo(() => {
    if (activeIndex === -1) return null
    return steps[activeIndex]
  }, [steps, activeIndex])

  const nextStep = () => {
    const next = activeIndex + 1
    if (next >= steps.length) {
      return console.error(`invalid step: ${next} (length = ${steps.length}`)
    }
    history.push(steps[next].path)
  }

  const prevStep = () => {
    const prev = activeIndex - 1
    if (prev < 0) {
      return console.error(`invalid step: ${prev}`)
    }
    history.push(steps[prev].path)
  }

  return { activeIndex, activeStep, nextStep, prevStep }
}
