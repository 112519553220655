import React from 'react'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Dropdown, Form, Segment, Table } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  PageLoader,
  PopupIcon,
  SingleGrid,
  AgeInfo,
} from '../../components'
import { DateField } from '../../components/input'
import { SuccessModal } from '../../components/confirm'
import { NoData } from '../../components/NotFound'
import { useModal, usePage } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import { useMe } from '../../util/hooks/me'
import {
  DataExportStatus,
  DataExportType,
  DataExportTypeName,
} from '../../util/Constant'
import {
  GET_DATA_EXPORTS,
  REQUEST_DATA_EXPORT,
  client,
} from '../../util/API/Apollo'

const dataExportCustomer = [
  DataExportType.ASSETS,
  DataExportType.SECURITIES,
  DataExportType.OFFERS,
  DataExportType.LISTINGS,
  DataExportType.TRADES,
]

const exportTypeOptionsTA = Object.keys(DataExportType).map(key => ({
  key,
  value: key,
  text: DataExportTypeName[key],
}))

const exportTypeOptionsCustomer = dataExportCustomer.map(key => ({
  key,
  value: key,
  text: DataExportTypeName[key],
}))

const ReportsFilter = ({ onSubmit, formHook }) => {
  const { form, input } = formHook
  const { isTransferAgent } = useMe()

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-0">
        <DateField width={8} disabled placeholder="Select date interval" />
        <Form.Field width={4}>
          <Dropdown
            fluid
            selection
            placeholder="Export Type"
            options={
              isTransferAgent ? exportTypeOptionsTA : exportTypeOptionsCustomer
            }
            {...input('export_type')}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Button
            content="Export XLS"
            primary
            icon="chevron right"
            labelPosition="right"
            fluid
            disabled={!form.export_type}
            type="submit"
          />
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

const ReportStatus = ({ status }) => {
  switch (status) {
    case DataExportStatus.PREPARING:
      return <PopupIcon name="time" color="blue" content="Preparing" />
    case DataExportStatus.READY:
      return <PopupIcon name="check" color="green" content="Ready" />
    default:
      throw new Error(`invalid report status: ${status}`)
  }
}

const ReportsTable = ({ reports, pagination }) => {
  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="EXPORT TYPE" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell content="STATUS" collapsing />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {reports.map(report => {
          const { uuid, export_type, created_at, status } = report

          return (
            <Table.Row key={uuid}>
              <Table.Cell>{DataExportTypeName[export_type]}</Table.Cell>
              <Table.Cell>
                <AgeInfo iso str={created_at} />
              </Table.Cell>
              <Table.Cell>
                <ReportStatus status={status} />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  primary
                  content="Download"
                  as="a"
                  href={report.export_file}
                  target="_blank"
                  disabled={report.status === DataExportStatus.PREPARING}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      {pagination && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={5} content={pagination} />
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

const COUNT = 25

const initialForm = {
  start_date: '',
  end_date: '',
  export_type: '',
}

const Reports = () => {
  const [page, setPage] = usePage(1)
  const success = useModal()
  const formHook = useForm('reports', initialForm)

  const { data, loading, refetch } = useQuery(GET_DATA_EXPORTS, {
    variables: { count: COUNT, page },
    client,
  })

  const [request, requestMutation] = useMutation(REQUEST_DATA_EXPORT, {
    variables: { export_type: formHook.form.export_type },
    onCompleted: success.show,
    client,
  })

  const reports = _.get(data, 'dataExports.data', [])
  const paginatorInfo = _.get(data, 'dataExports.paginatorInfo')

  if (loading || requestMutation.loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb title="Reports" />

      <SingleGrid padded>
        <Segment>
          <ReportsFilter formHook={formHook} onSubmit={request} />
        </Segment>

        <Segment padded="very">
          {reports.length > 0 ? (
            <ReportsTable
              reports={reports}
              pagination={
                <APIPagination
                  count={COUNT}
                  paginatorInfo={paginatorInfo}
                  handleChange={setPage}
                />
              }
            />
          ) : (
            <NoData text="YOU DO NOT HAVE ANY REPORTS" />
          )}
        </Segment>
      </SingleGrid>

      <SuccessModal
        open={success.isOpen}
        onClose={() => {
          success.hide()
          refetch()
        }}
        content="Your report is getting prepared"
      />
    </>
  )
}

export default Reports
