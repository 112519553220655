import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { useModal } from '../util/hooks'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_PUBLIC_PROFILE } from '../util/API/Apollo'
import ProfileModal from './Profile/ProfileModal'
import { ProviderActionType } from '../util/Constant'

const actionRoutes = {
  [ProviderActionType.CUSTOMER_APPROVAL]: 'account',
  [ProviderActionType.CUSTOMER_KYC]: 'account',
  [ProviderActionType.CUSTOMER_ACC]: 'account',
  [ProviderActionType.CUSTOMER_MSG]: 'medallion-signature',
  [ProviderActionType.PROVIDER_APPROVAL]: 'provider',
  [ProviderActionType.ASSET_TRANSFERABILITY]: 'asset',
  [ProviderActionType.COMPANY_VERIFICATION]: 'company',
  [ProviderActionType.TRADE_APPROVAL]: 'trade',
  [ProviderActionType.PROVIDER_REQUEST]: 'provider-request',
  undefined: 'action',
  null: 'action',
}
const link = {
  notFound: '/404',
  login: '/login',
  activate: '/activate',
  activateToken: token => `/activate/${_.get(token, 'uuid')}`,
  forgotPassword: '/forgot-password',
  resetPassword: token => `/reset-password/${token}`,
  searchHome: '/search',
  search: (category, query) => `/search/${category}/${query}`,
  profile: '/profile',
  createProfile: '/create-profile',
  settings: '/settings',
  actions: actionType => `/actions/${actionRoutes[actionType]}`,
  action: action =>
    `/actions/${actionRoutes[_.get(action, 'action_type')]}/${_.get(
      action,
      'uuid'
    )}`,
  contracts: '/asset-manager/assets',
  contract: contract => `/asset-manager/asset/${_.get(contract, 'uuid')}`,
  newContract: '/create-new-asset',
  draftContract: contract => `/create-new-asset/${_.get(contract, 'uuid')}`,
  customers: '/accounts/customers',
  customer: user => `/accounts/customer/${_.get(user, 'uuid')}`,
  newCustomer: '/create-new-customer',
  providers: '/accounts/providers',
  provider: user => `/accounts/providers/${_.get(user, 'uuid')}`,
  newProvider: '/create-new-provider',
  companies: '/data',
  company: company => `/data/${_.get(company, 'uuid')}`,
  editCompany: company => `/data/${_.get(company, 'uuid')}/edit`,
  newCompany: '/create-new-company',
  tokens: '/portfolio-manager/digital-securities',
  token: token => `/portfolio-manager/digital-security/${_.get(token, 'uuid')}`,
  fileManager: '/file-manager',
  marketplace: '/marketplace',
  myListings: '/my-listings',
  listing: listing => `/marketplace/${_.get(listing, 'uuid')}`,
  mySaleOffers: '/my-offers/sales-offers',
  myPurchaseOffers: '/my-offers/purchase-offers',
  offer: offer => `/offer/${_.get(offer, 'uuid')}`,
  myPendingTrades: '/my-trades/pending-trades',
  myCompletedTrades: '/my-trades/completed-trades',
  trade: trade => `/trade/${_.get(trade, 'uuid')}`,
  listingActivities: '/market-activities/listings',
  offerActivities: '/market-activities/offers',
  tradeActivities: '/market-activities/trades',
  messages: '/messages',
  allUserMessages: '/messages/user',
  userMessages: user => `/messages/user${_.get(user, 'uuid')}`,
  systemMessages: '/messages/system',
  reports: '/reports',
  activity: '/activity',
  about: '/about',
  contact: '/contact',
  support: '/support',
  terms: '/terms',
  careers: '/careers',
  privacyPolicy: '/privacy-policy',
}

export const ContractLink = ({ contract }) => {
  const name = _.get(contract, 'details.fund_name')
  const hashid = _.get(contract, 'hashid')
  //const text = showHashid ? hashid : name || hashid
  return (
    <>
      <Link to={link.contract(contract)}>{hashid}</Link>
      {name && (
        <>
          <span style={{ color: '#697080' }}>
            &nbsp;&nbsp;&nbsp;(&nbsp;{name}&nbsp;)
          </span>
        </>
      )}
    </>
  )
}

// proxy component that returns email link
export const Email = ({ value, ...props }) => {
  if (!value) return '-'
  return (
    <a href={`mailto:${value}`} {...props}>
      {value}
    </a>
  )
}
export const CompanyLink = ({ company, ...props }) => {
  if (!company) return null
  return (
    <Link to={link.company(company)} {...props}>
      {company.name}
    </Link>
  )
}
export const WebsiteLink = ({ href, children, ...props }) => {
  if (!href) return '-'
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} {...props}>
      {children || href}
    </a>
  )
}
export const UserLink = ({ user }) => {
  const userProfileModal = useModal()
  const { data, loading } = useQuery(GET_PUBLIC_PROFILE, {
    variables: { user_id: user.uuid },
    skip: !userProfileModal.isOpen,
    client,
  })

  return (
    <>
      <Button basic className="link px-0" onClick={userProfileModal.show}>
        {user.fullname}
      </Button>

      <ProfileModal
        user={_.get(data, 'publicProfile')}
        loading={loading}
        {...userProfileModal.props}
      />
    </>
  )
}
export const FileLink = ({ file }) => {
  if (!file) return '-'
  return (
    <a target="_blank" rel="noopener noreferrer" href={file.link}>
      {file.original_filename}
    </a>
  )
}
export const EtherscanButton = ({ contract }) => (
  <Popup
    inverted
    content="For verification in Ethereum Blockchain"
    trigger={
      <Button
        content="Etherscan"
        inverted
        color="blue"
        size="tiny"
        as="a"
        href={contract.verify_link}
        target="_blank"
      />
    }
  />
)
export const EtherscanTxButton = ({ hash }) => (
  <Popup
    inverted
    content="For verification in Ethereum Blockchain"
    trigger={
      <Button
        content="Etherscan"
        inverted
        color="blue"
        size="tiny"
        as="a"
        href={`https://ropsten.etherscan.io/tx/${hash}`}
        target="_blank"
      />
    }
  />
)
export const DetailsButton = props => {
  const {
    contract,
    company,
    token,
    listing,
    offer,
    trade,
    action,
    ...restProps
  } = props
  let to
  if (contract) to = link.contract(contract)
  if (token) to = link.token(token)
  if (company) to = link.company(company)
  if (listing) to = link.listing(listing)
  if (offer) to = link.offer(offer)
  if (trade) to = link.trade(trade)
  if (action) to = `/actions/${action.uuid}`
  return (
    <Button
      className="p-10"
      primary
      content={<Icon name="chevron right" className="m-0" />}
      as={Link}
      to={to}
      {...restProps}
    />
  )
}

export default link
