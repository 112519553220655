import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { Segment } from 'semantic-ui-react'
import Filter from './Filter'
import MarketplaceTable from './MarketplaceTable'
import {
  APIPagination,
  CSBreadcrumb,
  DimmerLoader,
  IfUserIs,
  SingleGrid,
  ContentLoader,
} from '../../components'
import { MarketplaceWidget } from '../../components/Widget'
import { NoData } from '../../components/NotFound'
import { ContractType, UserType } from '../../util/Constant'
import { client, GET_MARKETPLACE } from '../../util/API/Apollo'
import {
  useDashboardData,
  useDebounceQuery,
  usePage,
  useSort,
} from '../../util/hooks'
import useForm from '../../util/hooks/form'

// get filter form from state and return gql input
const mapFormToInput = filter => {
  const input = {
    contract_type: [],
    seller_type: [],
  }
  const contractTypes = Object.keys(filter.contract)
  const sellerTypes = Object.keys(filter.seller)
  // store true fields of contract filter in array for gql
  input.contract_type = contractTypes.filter(type =>
    Boolean(filter.contract[type])
  )
  input.seller_type = sellerTypes.filter(type => Boolean(filter.seller[type]))
  if (filter.price.min) input.price_min = filter.price.min
  if (filter.price.max) input.price_max = filter.price.max
  if (filter.transferable) input.transferable = filter.transferable
  if (filter.favorite) input.favorite = filter.favorite
  if (filter.search) input.search = filter.search
  return input
}

const COUNT = 10

const initialFilterForm = {
  seller: {
    [UserType.INDIVIDUAL]: false,
    [UserType.INSTITUTIONAL]: false,
  },
  contract: {
    [ContractType.SAFE]: false,
    [ContractType.EQUITY]: false,
    [ContractType.CONVERTIBLE_NOTE]: false,
    [ContractType.VC]: false,
    [ContractType.FOF]: false,
  },
  price: {
    min: '',
    max: '',
  },
  favorite: false,
  transferable: '',
  search: '',
}

const Marketplace = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const formHook = useForm('marketplace', initialFilterForm)
  const [page, setPage] = usePage(1)
  const { sort, orderBy } = useSort()
  const { dashboard, loading: statisticsLoading } = useDashboardData()

  const filterInput = useMemo(() => mapFormToInput(formHook.form), [
    formHook.form,
  ])

  const { loading, data } = useDebounceQuery(GET_MARKETPLACE, {
    variables: {
      filter: filterInput,
      orderBy: orderBy,
      page: page,
      count: COUNT,
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    fetchPolicy: 'network-only',
    client,
  })

  const paginatorInfo = _.get(data, 'marketplace.paginatorInfo')
  const listings = _.get(data, 'marketplace.data', [])

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <IfUserIs ta>
        <CSBreadcrumb title="All Listings" />
      </IfUserIs>
      <IfUserIs not ta>
        <CSBreadcrumb title="Marketplace" />
      </IfUserIs>

      <SingleGrid padded>
        <IfUserIs ta>
          {!statisticsLoading && (
            <MarketplaceWidget divided data={dashboard.marketplace} />
          )}
        </IfUserIs>

        <Segment padded="very">
          <Filter formHook={formHook} />
          {listings.length > 0 ? (
            <>
              <br />
              <Segment basic className="p-0">
                <DimmerLoader active={loading} />
                <MarketplaceTable
                  sort={sort}
                  listings={listings}
                  footer={
                    <APIPagination
                      paginatorInfo={paginatorInfo}
                      handleChange={setPage}
                      count={COUNT}
                    />
                  }
                />
              </Segment>
            </>
          ) : (
            <NoData text="NO LISTINGS IN MARKETPLACE" />
          )}
        </Segment>
      </SingleGrid>
    </>
  )
}

export default Marketplace
