import React from 'react'
import { Segment } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_PROVIDER_ACTION_DETAIL } from '../../util/API/Apollo'
import { ContentLoader, CSBreadcrumb, SingleGrid } from '../../components'
import _ from 'lodash'
import { NoData } from '../../components/NotFound'
import { ProviderActionType } from '../../util/Constant'
import MSGActionDetails from './MSGActionDetails'
import CompanyActionDetails from './CompanyActionDetails'
import NewProviderActionDetails from './NewProviderActionDetails'
import ContractActionDetails from './ContractActionDetails'
import ProviderRequestActionDetails from './ProviderRequestActionDetails'
import NewCustomerActionDetails from './NewCustomerActionDetails'
import TradePaymentActionDetails from './TradePaymentActionDetails'
import link from '../../components/link'

const ProviderAction = () => {
  const { uuid } = useParams()
  const { loading, data } = useQuery(GET_PROVIDER_ACTION_DETAIL, {
    variables: { uuid },
    client,
  })

  if (loading) return <ContentLoader />

  const action = _.get(data, 'providerAction', {})

  if (!action) {
    return <NoData text="ACTION NOT FOUND" fluid />
  }

  let actionDetails
  switch (action.action_type) {
    case ProviderActionType.CUSTOMER_APPROVAL:
      actionDetails = <NewCustomerActionDetails action={action} />
      break
    case ProviderActionType.CUSTOMER_MSG:
      actionDetails = <MSGActionDetails action={action} />
      break
    case ProviderActionType.COMPANY_VERIFICATION:
      actionDetails = <CompanyActionDetails action={action} />
      break
    case ProviderActionType.PROVIDER_APPROVAL:
      actionDetails = <NewProviderActionDetails action={action} />
      break
    case ProviderActionType.ASSET_TRANSFERABILITY:
      actionDetails = <ContractActionDetails action={action} />
      break
    case ProviderActionType.TRADE_APPROVAL:
      actionDetails = <TradePaymentActionDetails action={action} />
      break
    case ProviderActionType.PROVIDER_REQUEST:
      actionDetails = <ProviderRequestActionDetails action={action} />
      break
    default:
      throw new Error(`invalid action type ${action.action_type}`)
  }

  return (
    <>
      <CSBreadcrumb
        items={[
          {
            title: 'Actions',
            to: link.actions(ProviderActionType.CUSTOMER_APPROVAL),
          },
        ]}
        title={action.hashid}
      />
      <SingleGrid padded>
        <Segment padded="very" content={actionDetails} />
      </SingleGrid>
    </>
  )
}

export default ProviderAction
