import React, { useState, useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  Grid,
  Form,
  Segment,
  Header,
  Input,
  Transition,
  Button,
} from 'semantic-ui-react'
import * as yup from 'yup'
import { useHistory, useParams } from 'react-router'
import PasswordStrengthMeter from '../components/Auth/PasswordStrengthMeter'
import { SuccessModal } from '../components/confirm'
import { PageLoader } from '../components'
import { useMounted, useModal } from '../util/hooks'
import { client, RESET_PASSWORD } from '../util/API/Apollo'
import useForm from '../util/hooks/form'
import { ReactComponent as STCLogo } from '../images/stc_logo.svg'
import link from '../components/link'

const getSchema = strength =>
  yup
    .object()
    .shape({
      password_confirmation: yup
        .string()
        .required('New password confirmation is required'),
      password: yup
        .string()
        .required('New password is required')
        .min(8, 'New password must be at least 8 characters')
        .max(20, 'New password cannot exceed 20 characters')
        .test(
          'password-strength-check',
          'Password is not secure enough',
          () => strength >= 2
        ),
    })
    .test(
      'password-match',
      'Passwords do not match',
      value => value.password === value.password_confirmation
    )

const initialForm = {
  password: '',
  password_confirmation: '',
}
const ResetPassword = () => {
  const [passwordStrength, setPasswordStrength] = useState(0)
  const schema = useMemo(() => getSchema(passwordStrength), [passwordStrength])
  const { token } = useParams()
  const history = useHistory()
  const mounted = useMounted()
  const success = useModal()
  const { form, field, validate } = useForm('reset-password', initialForm, {
    schema,
  })

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    variables: { input: { ...form, token } },
    onCompleted: success.show,
    client,
  })

  const handleSubmit = async () => {
    if (await validate()) resetPassword()
  }

  return (
    <>
      <PageLoader active={loading} />
      <SuccessModal
        open={success.isOpen}
        onClose={() => history.push(link.login)}
        content="Your password has been changed. You can log in with your new password now."
      />
      <Grid className="h-100vh" centered celled="internally">
        <Grid.Row>
          <Grid.Column
            computer={5}
            tablet={12}
            mobile={16}
            verticalAlign="middle"
          >
            <Transition visible={mounted} animation="fade up" duration={200}>
              <div>
                <Segment padded="very" color="purple" textAlign="left">
                  <div className="stc-logo">
                    <STCLogo />
                  </div>
                  <Header
                    as="h3"
                    color="purple"
                    textAlign="center"
                    content="Change your password"
                  />
                  <Form onSubmit={handleSubmit}>
                    <Form.Field
                      type="password"
                      label="New password"
                      placeholder="●●●●●●"
                      icon="lock"
                      iconPosition="left"
                      required
                      control={Input}
                      {...field('password')}
                    />
                    <Form.Field
                      type="password"
                      label="Confirm password"
                      placeholder="●●●●●●"
                      icon="lock"
                      iconPosition="left"
                      required
                      control={Input}
                      {...field('password_confirmation')}
                    />
                    <Form.Field>
                      <PasswordStrengthMeter
                        password={form.password}
                        onChange={setPasswordStrength}
                      />
                    </Form.Field>
                    <Form.Field
                      fluid
                      type="submit"
                      content="Change password"
                      color="purple"
                      control={Button}
                    />
                  </Form>
                </Segment>
              </div>
            </Transition>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ResetPassword
