import React from 'react'
import {
  Button,
  Segment,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Modal,
} from 'semantic-ui-react'
import { getDocFields, GoBackButton, SaveMutation } from './common'
import { FormLabelInfo } from '../../components'
import { DocumentUpload } from '../../components/upload'
import { useModal } from '../../util/hooks'
import createAssetImage from '../../images/create_asset.svg'

const AgreementModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Creosafe User Agreement</Modal.Header>
      <Modal.Content>Lorem ipsum</Modal.Content>
      <Modal.Actions>
        <Button
          color="pink"
          content="Close"
          icon="times"
          labelPosition="right"
          type="button"
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  )
}

const DocumentsStepInfo = () => (
  <>
    <Header
      as="h2"
      color="purple"
      textAlign="left"
      content="UPLOAD ASSET DOCUMENT"
    />
    <div className="text-center">
      <Image size="large" centered src={createAssetImage} />
    </div>
  </>
)

const UserAgreementMessage = () => {
  const agreementModal = useModal()
  return (
    <Message info>
      <AgreementModal {...agreementModal.props} />
      <Icon name="exclamation" />
      You need to carefully read and agree to the terms and conditions stated in{' '}
      <a // eslint-disable-line jsx-a11y/anchor-is-valid
        href="#"
        className="underlined"
        onClick={agreementModal.show}
      >
        the user agreement
      </a>{' '}
      to be able to proceed further
    </Message>
  )
}

const DocumentsStep = ({ contract, formHook, stepsHook }) => {
  const { field, isDirty } = formHook
  const docFields = React.useMemo(() => getDocFields(contract.contract_type), [
    contract.contract_type,
  ])

  return (
    <Segment padded="very" as={Form}>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <DocumentsStepInfo />
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            <UserAgreementMessage />
            <Grid className="mt-0">
              <Grid.Row>
                {docFields.map(({ key, title, asset_code, info }) => (
                  <Grid.Column key={key} width={8}>
                    <Form.Field
                      label={
                        <FormLabelInfo content={title} info={info} required />
                      }
                      required
                      asset_code={asset_code}
                      control={DocumentUpload}
                      {...field(`documents.${key}`)}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
            <Message size="mini">
              <Icon name="exclamation" />
              You can only upload pdf, jpg, jpeg, png and gif. Max. file size:
              20Mb
            </Message>
            <Form.Field
              label="I hereby read and accept the user agreement"
              required
              control={Checkbox}
              {...field('details.terms_agreement', { type: 'checkbox' })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SaveMutation {...{ contract, formHook, stepsHook }}>
              {({ submit }) => {
                return (
                  <Button
                    type="submit"
                    primary
                    floated="right"
                    content={formHook.isDirty ? 'Save & Next' : 'Next'}
                    onClick={submit}
                    data-test-id="tokenize-next"
                  />
                )
              }}
            </SaveMutation>
            <GoBackButton dirty={isDirty} goBack={stepsHook.prevStep} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default DocumentsStep
