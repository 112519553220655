import React from 'react'
import { Container, Card } from 'semantic-ui-react'
import { CardTitle } from '../components'

const Support = () => {
  return (
    <Container className="mt-20">
      <Card fluid>
        <CardTitle content="SUPPORT" />
        <Card.Content>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nibh
            dolor, viverra id velit eu, lobortis porta nunc. Vestibulum gravida
            felis varius mauris efficitur ultricies nec ut mauris. Curabitur
            porta ipsum nibh, et suscipit ante posuere vel. Class aptent taciti
            sociosqu ad litora torquent per conubia nostra, per inceptos
            himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Fusce semper, lacus non mollis volutpat, lectus ex eleifend lorem,
            aliquam tristique lectus augue nec risus. Phasellus vel nibh
            suscipit, tincidunt lorem in, vestibulum est. Proin faucibus
            bibendum nibh, vel laoreet magna mattis et. Praesent sed condimentum
            erat.
          </p>
        </Card.Content>
      </Card>
    </Container>
  )
}

export default Support
