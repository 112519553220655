import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import OfferInfoTable from './OfferInfoTable'

const ApproveOfferModal = ({ offer, open, onClose, onComplete }) => {
  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
      <Modal.Header content="ACCEPT OFFER" />
      <Modal.Content>
        <OfferInfoTable offers={[offer]} />
        <Button
          fluid
          color="green"
          size="small"
          content="accept offer"
          onClick={onComplete}
        />
      </Modal.Content>
    </Modal>
  )
}

export default ApproveOfferModal
