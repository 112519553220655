import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Menu, Button } from 'semantic-ui-react'
import { PageLoader } from '../../components'
import { client, LOGOUT } from '../../util/API/Apollo'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'

const CreateProfileNavbar = () => {
  const [logout, { loading }] = useMutation(LOGOUT, {
    onCompleted: () => {
      window.location.reload(false)
    },
    client,
  })

  return (
    <>
      <PageLoader active={loading} />
      <Menu secondary>
        <Menu.Menu position="left">
          <div className="stc-logo">
            <STCLogo style={{ width: 180 }} />
            <br />
            <small>DIGITAL SECURITIES TRANSFER PLATFORM</small>
          </div>
        </Menu.Menu>
        <Menu.Menu position="right">
          <Button basic className="link" onClick={logout}>
            Logout
          </Button>
        </Menu.Menu>
      </Menu>
    </>
  )
}

export default CreateProfileNavbar
