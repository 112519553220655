import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import * as yup from 'yup'
import { DocumentUpload } from '../upload'
import { AssetCodes } from '../../util/Constant'
import useForm from '../../util/hooks/form'
import BankDetails from '../../routes/ProfileDetails/BankDetails'

const schema = yup.object().shape({
  doc: yup
    .object()
    .nullable()
    .required(`Payment receipt is required`),
})
const initialForm = {
  doc: null,
}

const BankStep = ({ onCompleted, bank_info }) => {
  const { form, field, validate } = useForm(
    'payment-receipt-form',
    initialForm,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      onCompleted(form.doc)
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <BankDetails bank_info={bank_info} />
      <Form.Field
        required
        label="Payment receipt"
        asset_code={AssetCodes.proof_of_payment}
        control={DocumentUpload}
        {...field('doc')}
      />
      <Button
        type="submit"
        primary
        fluid
        content="Continue"
        disabled={!form.doc}
        data-test-id="continue-payment-receipt"
      />
      <br />
    </Form>
  )
}

export default BankStep
