import React from 'react'
import _ from 'lodash'
import { Icon, Popup, Table } from 'semantic-ui-react'

const CoolTable = ({ headers, data, footer, startIndex, ...props }) => {
  const hasIndex = typeof startIndex !== 'undefined' // has # column
  const columnCount = React.useMemo(() => {
    return _.get(headers, 'length', 0) + (hasIndex ? 1 : 0)
  }, [headers, hasIndex])

  if (data.length === 0) return null
  return (
    <Table basic="very" padded="very" size="small" {...props}>
      <Table.Header>
        <Table.Row>
          {hasIndex && (
            <Table.HeaderCell
              className="uppercase"
              collapsing
              content="#"
              textAlign="center"
            />
          )}
          {headers.map((header, index) => {
            const { content, children, info, ...rest } = header
            return (
              <Table.HeaderCell className="uppercase" key={index} {...rest}>
                {info && (
                  <Popup
                    trigger={<Icon name="info" />}
                    content={info}
                    size="tiny"
                    inverted
                  />
                )}
                <span>{content || children}</span>
              </Table.HeaderCell>
            )
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ key, cellData }, rowIndex) => {
          return (
            <Table.Row key={key}>
              {hasIndex && (
                <Table.Cell
                  textAlign="center"
                  content={startIndex + rowIndex + 1}
                />
              )}
              {cellData.map((cell, cellIndex) => (
                <Table.Cell key={cellIndex} {...cell} />
              ))}
            </Table.Row>
          )
        })}
      </Table.Body>
      {footer && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={columnCount} content={footer} />
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

export default CoolTable
