import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Table } from 'semantic-ui-react'
import { AgeInfo, OfferAmountLabel, OfferPriceLabel, PPDSLabel } from '../index'
import { NoData } from '../NotFound'
import link, { UserLink } from '../link'

const ListingOffers = ({ offers }) => {
  if (offers.length <= 0) {
    return <NoData text="THERE ARE NO OFFERS YET" />
  }

  return (
    <Table fixed basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="OFFER" />
          <Table.HeaderCell content="OFFER AMOUNT" />
          <Table.HeaderCell content="OFFER PRICE" />
          <Table.HeaderCell content="PPDS" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map(offer => (
          <Table.Row key={offer.hashid}>
            <Table.Cell>
              <UserLink user={offer.from} />
              <Icon name="arrow right" color="red" />
              <UserLink user={offer.to} />
            </Table.Cell>
            <Table.Cell>
              <OfferAmountLabel amount={offer.token_amount} />
            </Table.Cell>
            <Table.Cell>
              <OfferPriceLabel price={offer.token_price} />
            </Table.Cell>
            <Table.Cell>
              <PPDSLabel
                data={{ amount: offer.token_amount, price: offer.token_price }}
              />
            </Table.Cell>
            <Table.Cell>
              <AgeInfo iso str={offer.created_at} />
            </Table.Cell>
            <Table.Cell>
              <Button
                primary
                fluid
                size="small"
                content="View"
                as={Link}
                to={link.offer(offer)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default ListingOffers
