import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import _ from 'lodash'
import debug from 'debug'
import reducers from './reducers'

const log = debug('app:redux')

const logger = store => next => action => {
  const result = next(action)
  log('action: ', action, 'state: ', store.getState())
  return result
}

const savedState = JSON.parse(localStorage.getItem('app-state')) || {}

const store = createStore(reducers, savedState, applyMiddleware(thunk, logger))

store.subscribe(
  _.throttle(() => {
    const state = store.getState()
    localStorage.setItem(
      'app-state',
      JSON.stringify(_.pick(state, ['session']))
    )
  }, 1000)
)

export default store
