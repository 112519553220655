import React from 'react'
import { Button, Dropdown, Form, Modal, TextArea } from 'semantic-ui-react'
import * as yup from 'yup'
import _ from 'lodash'
import useForm from '../../../util/hooks/form'
import { NumberInput } from '../../input'
import {
  FinancialDataType,
  FinancialDataTypeNames,
} from '../../../util/Constant'

const schema = yup.object().shape({
  data_type: yup.string().required('Data type is required'),
  year: yup.string().required('Year is required'),
  quarter: yup.string().required('Quarter is required'),
  data: yup.string().required('Data is required'),
})

const initialForm = {
  data_type: '',
  year: '',
  quarter: '',
  data: '',
  description: '',
}

const dataTypeOptions = Object.keys(FinancialDataType).map(type => ({
  key: type,
  text: FinancialDataTypeNames[type].toUpperCase(),
  value: type,
}))
const years = [
  { key: '2019', text: '2019', value: '2019' },
  { key: '2018', text: '2018', value: '2018' },
  { key: '2017', text: '2017', value: '2017' },
  { key: '2016', text: '2016', value: '2016' },
  { key: '2015', text: '2015', value: '2015' },
  { key: '2014', text: '2014', value: '2014' },
  { key: '2013', text: '2013', value: '2013' },
  { key: '2012', text: '2012', value: '2012' },
  { key: '2011', text: '2011', value: '2011' },
  { key: '2010', text: '2010', value: '2010' },
]
const quarters = [
  { key: 'Quarter 1', text: 'Quarter 1', value: '1' },
  { key: 'Quarter 2', text: 'Quarter 2', value: '2' },
  { key: 'Quarter 3', text: 'Quarter 3', value: '3' },
  { key: 'Quarter 4', text: 'Quarter 4', value: '4' },
]

const FinancialDataModal = ({
  open,
  onClose,
  value = [],
  onChange = _.noop,
  ...props
}) => {
  const { form, field, validate, clear } = useForm(
    'financial-data',
    initialForm,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newData = [...value, _.clone(form)]
      onChange(newData, { ...props, value: newData })
      clear()
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeIcon dimmer="blurring">
      <Modal.Header content="ADD FINANCIAL DATA" />
      <Modal.Content>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Group widths="equal">
            <Form.Field
              required
              label="Data type"
              placeholder="SELECT"
              options={dataTypeOptions}
              selection
              control={Dropdown}
              {...field('data_type')}
            />
            <Form.Field
              required
              label="Year"
              placeholder="SELECT"
              options={years}
              selection
              control={Dropdown}
              {...field('year')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              required
              label="Quarter"
              placeholder="SELECT"
              options={quarters}
              selection
              control={Dropdown}
              {...field('quarter')}
            />
            <Form.Field
              required
              label="Data"
              icon="dollar"
              iconPosition="left"
              {...field('data')}
              control={NumberInput}
            />
          </Form.Group>
          <Form.Field
            label="Description"
            control={TextArea}
            {...field('description')}
          />
          <Button primary type="submit" fluid size="small" content="Add" />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default FinancialDataModal
