import React from 'react'
import {
  Header,
  Button,
  Progress,
  Modal,
  Image,
  Segment,
} from 'semantic-ui-react'
import { MediaUpload } from '../upload'
import { SuccessModal } from '../confirm'
import { useModal } from '../../util/hooks'
import fileUploadImage from '../../images/file_upload.svg'
const FileUploadModal = ({ open, onClose, onChange }) => {
  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
      <Modal.Header content="UPLOAD FILE" />
      <Modal.Content>
        <MediaUpload onChange={onChange} />
      </Modal.Content>
    </Modal>
  )
}

const FileUpload = ({ storage, refetch, files }) => {
  const fileUploadModal = useModal()
  const fileUploadSuccessModal = useModal()

  const handleShare = () => {
    fileUploadModal.hide()
    fileUploadSuccessModal.show()
    refetch()
  }

  return (
    <>
      <Segment basic textAlign="center">
        <Image size="medium" centered src={fileUploadImage} />
        <Header
          sub
          color="grey"
          textAlign="left"
          content={`${storage.used} OF ${storage.total} USED`}
        />
        <Progress percent={100 - storage.percentage} size="tiny" />
        <Button
          primary
          size="small"
          content="Upload new file"
          onClick={fileUploadModal.show}
        />
      </Segment>
      <FileUploadModal onChange={handleShare} {...fileUploadModal.props} />
      <SuccessModal
        content="Your file has been shared successfully."
        {...fileUploadSuccessModal.props}
      />
    </>
  )
}

export default FileUpload
