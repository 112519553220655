import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Header, Icon, Image, Menu, Popup } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import _ from 'lodash'
import { client, LOGOUT } from '../../../util/API/Apollo'
import { UserType } from '../../../util/Constant'
import { useMe } from '../../../util/hooks/me'
import userPlaceholder from '../../../images/user-placeholder.png'
import Messages from './Messages'
import NavSearch from './NavSearch'
import { IfUserIs } from '../../index'
import link from '../../link'

const userTypeNames = {
  [UserType.INDIVIDUAL]: 'Individual Customer',
  [UserType.INSTITUTIONAL]: 'Institutional Customer',
  [UserType.EMPLOYEE]: 'Employee',
  [UserType.FINANCIAL_ADVISOR]: 'Financial Advisor',
  [UserType.BROKER_DEALER]: 'Broker Dealer',
  [UserType.TRANSFER_AGENT]: 'Transfer Agent',
}

const UserPanel = () => {
  const me = useMe()
  const { fullname, profile, primary_wallet, user_type, is_verified } = me
  const photo = _.get(profile, 'photo.link_as_avatar')

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      window.location.reload(false)
    },
    client,
  })

  return (
    <Popup
      id="user-panel"
      on="click"
      position="bottom right"
      positionFixed
      content={
        <>
          <Menu vertical fluid>
            {profile && (
              <div className="user-info">
                <Image rounded src={photo || userPlaceholder} size="tiny" />
                <div className="text" style={{ flexGrow: 1 }}>
                  <Header>{fullname}</Header>
                  <div>{userTypeNames[user_type]}</div>
                  <div className="text-ellipsis text-nowrap">
                    {primary_wallet && (
                      <>
                        <Icon name="ethereum" />
                        {primary_wallet.address}
                      </>
                    )}
                  </div>
                </div>
                {is_verified && (
                  <Icon name="check" size="large" color="green" />
                )}
              </div>
            )}
            <Menu.Item as={Link} to={link.profile}>
              <Icon name="user" />
              Profile
            </Menu.Item>
            <Menu.Item as={Link} to={link.settings}>
              <Icon name="cog" />
              Settings
            </Menu.Item>
            <Menu.Item onClick={logout}>
              <Icon name="sign out alternate" />
              Logout
            </Menu.Item>
          </Menu>
        </>
      }
      trigger={
        <Menu.Item>
          <UserIcon user={me} />
        </Menu.Item>
      }
    />
  )
}

const UserIcon = ({ user }) => {
  const { fullname } = user
  const photo = _.get(user.profile, 'photo.link_as_avatar')

  return (
    <>
      <Menu.Item>Welcome {fullname}</Menu.Item>
      <Image
        src={photo ? photo : userPlaceholder}
        size="mini"
        rounded
        className="mr-5"
      />
    </>
  )
}

const NavigationBar = () => {
  const me = useMe()
  const isAlreadySearch = useRouteMatch('/search/:category?/:slug?')

  return (
    <Menu fixed="top" borderless className="nav">
      <div className="placeholder" />
      {/* purple shit under sidebar */}
      <Menu.Item id="breadcrumb" />
      <Menu.Menu position="right">
        {!isAlreadySearch && (
          <IfUserIs ta>
            <Menu.Item>
              <NavSearch />
            </Menu.Item>
          </IfUserIs>
        )}
        {me && (
          <>
            <Messages />
            <UserPanel />
          </>
        )}
      </Menu.Menu>
    </Menu>
  )
}

export default NavigationBar
