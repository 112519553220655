import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Header, Button } from 'semantic-ui-react'
import { SingleGrid } from './'

import noDataImage from '../images/no_found.svg'

export const NoData = ({
  image,
  text,
  children,
  fluid = false,
  size = 'medium',
  ...props
}) => {
  const content = (
    <div
      style={{
        height: fluid ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      <Image centered size={size} src={image || noDataImage} />
      {text && (
        <Header className="uppercase" as="h5" color="grey" content={text} />
      )}
      {children}
    </div>
  )

  if (fluid) {
    return (
      <SingleGrid padded className="h-fluid">
        {content}
      </SingleGrid>
    )
  }

  return content
}

export const PageNotFound = () => {
  return (
    <SingleGrid padded className="h-fluid">
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          size="medium"
          src={noDataImage}
          centered
          style={{ minHeight: '200px' }}
        />
        <Header as="h4" color="grey" content="PAGE NOT FOUND" />
        <Button as={Link} to="/" content="Go back" />
      </div>
    </SingleGrid>
  )
}
