import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL',
    key: 'count',
  },
  {
    title: 'PENDING',
    key: 'pending_count',
  },
  {
    title: 'ACCEPTED',
    key: 'accepted_count',
  },
  {
    title: 'REJECTED',
    key: 'rejected_count',
  },
  {
    title: 'AMOUNT OF OFFERS',
    key: 'total_amount',
  },
  {
    title: 'PRICE OF OFFERS',
    key: 'total_value',
    isPrice: true,
  },
]

const OffersWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
  horizontal,
  className,
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      horizontal={horizontal}
      dataSchema={dataSchema}
      title="OFFERS SUMMARY"
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
      className={className}
    />
  )
}

export default OffersWidget
