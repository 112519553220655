import React from 'react'
import { Button } from 'semantic-ui-react'
import { ProviderActionStatus } from '../../util/Constant'
import { PageLoader, SubMessage } from '../../components'
import { useModal } from '../../util/hooks'
import { ConfirmModal, SuccessModal } from '../../components/confirm'
import {
  ACCEPT_CUSTOMER,
  client,
  GET_PROVIDER_ACTION_DETAIL,
  REJECT_CUSTOMER,
} from '../../util/API/Apollo'
import { useMutation } from '@apollo/react-hooks'
import TabMenu from '../../components/TabMenu'
import _ from 'lodash'
import ProfileSummary from '../../components/Profile/ProfileSummary'
import ProfileCommonDetails from '../../components/Profile/ProfileCommonDetails'
import CompanySummary from '../../components/Company/CompanySummary'
import BankDetails from '../ProfileDetails/BankDetails'

const ProviderRequestActionDetails = ({ action }) => {
  const user = action.actionable
  const rejected = action.status === ProviderActionStatus.REJECTED
  const accepted = action.status === ProviderActionStatus.ACCEPTED
  const acceptConfirmModal = useModal()
  const rejectConfirmModal = useModal()
  const acceptedModal = useModal()
  const rejectedModal = useModal()

  const [accept, { loading: accepting }] = useMutation(ACCEPT_CUSTOMER, {
    variables: { provider_action_id: action.uuid },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    onCompleted: acceptedModal.show,
    client,
  })

  const [reject, { loading: rejecting }] = useMutation(REJECT_CUSTOMER, {
    variables: { provider_action_id: action.uuid },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    onCompleted: rejectedModal.show,
    client,
  })

  return (
    <>
      <PageLoader active={accepting || rejecting} />
      <ConfirmModal {...acceptConfirmModal.props} onConfirm={accept} />
      <ConfirmModal {...rejectConfirmModal.props} onConfirm={reject} />
      <SuccessModal
        {...acceptedModal.props}
        content="You accepted provider request"
      />
      <SuccessModal
        {...rejectedModal.props}
        content="You rejected provider request"
      />
      <TabMenu defaultTab="account-details">
        <TabMenu.Left>
          <TabMenu.Menu>
            <TabMenu.Item name="account-details" />
            {_.get(user, 'profile.company') && (
              <TabMenu.Item name="company-details" />
            )}
            {_.get(user, 'profile.bank_info.bank_name') && (
              <TabMenu.Item name="bank-details" />
            )}
          </TabMenu.Menu>
          <>
            {accepted && (
              <SubMessage
                success
                icon="check"
                content="Accepted"
                className="w-100p"
              />
            )}
            {rejected && (
              <SubMessage
                negative
                icon="times"
                content="Rejected"
                className="w-100p"
              />
            )}
            {!(accepted || rejected) && (
              <>
                <Button
                  fluid
                  color="green"
                  content="Accept"
                  onClick={acceptConfirmModal.show}
                />
                <br />
                <Button
                  fluid
                  color="red"
                  content="Reject"
                  onClick={rejectConfirmModal.show}
                />
              </>
            )}
          </>
        </TabMenu.Left>
        <TabMenu.Right>
          <ProfileSummary user={user} />
          <TabMenu.Pane name="account-details">
            <ProfileCommonDetails user={user} />
          </TabMenu.Pane>
          <TabMenu.Pane name="company-details">
            <CompanySummary company={user.profile.company} />
          </TabMenu.Pane>
          <TabMenu.Pane name="bank-details">
            <BankDetails user={user} />
          </TabMenu.Pane>
        </TabMenu.Right>
      </TabMenu>
    </>
  )
}

export default ProviderRequestActionDetails
