import React, { useMemo } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import * as yup from 'yup'
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react'
import {
  AvailableTokens,
  NumberFormat,
  PageLoader,
  PPDSLabel,
  SubMessage,
} from '../index'
import { SuccessModal } from '../confirm'
import useForm from '../../util/hooks/form'
import { useModal } from '../../util/hooks'
import { client, CREATE_LISTING } from '../../util/API/Apollo'
import { ProviderType, TxStatus } from '../../util/Constant'
import TokenInfoTable from './TokenInfoTable'
import { NumberInput } from '../input'
import { useMe } from '../../util/hooks/me'
import link, { CompanyLink, ContractLink } from '../link'

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Amount is required')
    .test(
      'not-enough',
      'Please make sure the listing amount does not exceed amount of Digital Securities available for listing',
      function(value) {
        return !(value > this.options.context.amount)
      }
    ),
  price: yup.string().required('Price is required'),
  currencies: yup.array().min(1, 'You must accept at least one currency'),
})

const currencies = ['ETH', 'USDC', 'USD'].map(c => ({
  key: c,
  text: c,
  value: c,
}))

const SellTokensModal = ({ token, formHook, onComplete, open, onClose }) => {
  const { tx_status } = token
  const { form, field, clear } = formHook

  const txVerified = tx_status === TxStatus.VERIFIED

  return (
    <Modal
      closeIcon
      dimmer="blurring"
      open={open}
      onClose={() => {
        clear()
        onClose()
      }}
    >
      <Modal.Header content="SELL DIGITAL SECURITIES" />
      <Modal.Content>
        <TokenInfoTable token={token} />
        <Segment color="black" padded="very">
          <Header as="h2" content="Listing details" />
          <Divider hidden />
          <Form>
            <Popup
              inverted
              content="Number of Digital Securities you wish to sell"
              trigger={
                <Form.Field
                  required
                  icon="coin"
                  iconPosition="left"
                  label="Ask amount"
                  control={NumberInput}
                  disabled={!txVerified}
                  {...field('amount')}
                />
              }
            />
            <Popup
              inverted
              content="The total value of the Digital Securities you wish to sell"
              trigger={
                <Form.Field
                  required
                  icon="dollar"
                  iconPosition="left"
                  label="Ask price"
                  control={NumberInput}
                  disabled={!txVerified}
                  {...field('price')}
                />
              }
            />
            <Header
              sub
              content={
                <>
                  Available for listing <AvailableTokens token={token} />
                  &nbsp; &nbsp;&nbsp; PPDS{' '}
                  <PPDSLabel
                    data={{ amount: form.amount, price: form.price }}
                  />
                </>
              }
            />
            <br />
            <p>
              Please indicate in which currencies you will accept payment. You
              can choose to accept payment by a specific currency, or you can
              give the buyer multiple currency options for payment. The screen
              by default gives the buyer multiple currency payment option.
            </p>
            <Form.Field
              label="Payment type"
              multiple
              selection
              options={currencies}
              control={Dropdown}
              {...field('currencies')}
            />
            <Button
              primary
              fluid
              content="List in the marketplace"
              onClick={onComplete}
              data-test-id="finish-sell-tokens"
            />
          </Form>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

const SellTokensButton = ({ token }) => {
  const me = useMe()
  const sellTokensModal = useModal()
  const successModal = useModal()
  let defaultForm = useMemo(
    () => ({
      amount: token.token_numbers.available,
      price: '',
      currencies: ['ETH', 'USDC', 'USD'],
    }),
    [token.token_numbers]
  )
  const formHook = useForm('sell-tokens', defaultForm, {
    schema,
  })

  const [createListing, { loading, data }] = useMutation(CREATE_LISTING, {
    variables: {
      input: {
        token_id: token.uuid,
        amount: formHook.form.amount,
        price: formHook.form.price,
        currencies: formHook.form.currencies,
      },
    },
    onCompleted: () => {
      sellTokensModal.hide()
      successModal.show()
    },
    client,
  })

  const handleSubmit = async () => {
    if (await formHook.validate(undefined, { amount: token.amount })) {
      await createListing()
    }
  }

  const isVerified = token.tx_status === TxStatus.VERIFIED
  const marketPlaceEnabled = _.get(me, 'providers', []).find(
    p => p.provides === ProviderType.BROKER_DEALER
  )

  if (!isVerified || !marketPlaceEnabled) {
    let message = 'Token is not transferable'

    if (!marketPlaceEnabled) {
      message = 'You should have a broker dealer to sell tokens'
    }

    return (
      <>
        <Button primary disabled fluid content="SELL DIGITAL SECURITIES" />
        <SubMessage icon="exclamation triangle" content={message} />
      </>
    )
  }

  return (
    <>
      <PageLoader active={loading} />

      <Button
        primary
        fluid
        content="SELL DIGITAL SECURITIES"
        onClick={sellTokensModal.show}
        data-test-id="start-sell-tokens"
      />

      <SellTokensModal
        token={token}
        formHook={formHook}
        onComplete={handleSubmit}
        {...sellTokensModal.props}
      />

      {data && (
        <SuccessModal
          content={
            <>
              You have listed <NumberFormat amount={formHook.form.amount} />{' '}
              Digital Securities of{' '}
              <CompanyLink company={_.get(token, 'contract.issuer')} />{' '}
              <ContractLink contract={token.contract} />. You can access this
              Digital Securities listing from both{' '}
              <Link to={link.myListings}>My Listings</Link> and{' '}
              <Link to={link.marketplace}>Marketplace</Link>.
            </>
          }
          {...successModal.props}
          button={{
            content: 'Listing Details',
            as: Link,
            to: link.listing(_.get(data, 'createListing')),
          }}
        />
      )}
    </>
  )
}

export default SellTokensButton
