import React, { PureComponent } from 'react'
import { Message, Transition } from 'semantic-ui-react'
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Toast extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired, // restart timeout if this changes
    header: PropTypes.string,
    content: PropTypes.node,
    duration: PropTypes.number, // in millisecond
  }
  static defaultProps = {
    header: null,
    content: 'Something went wrong. We are trying to fix it',
    duration: 3000,
  }

  state = { visible: false }
  timeout = null

  show = () => this.setState({ visible: true })
  hide = () => this.setState({ visible: false })

  display = () => {
    clearTimeout(this.timeout)
    this.show()
    this.timeout = setTimeout(this.hide, this.props.duration)
  }

  componentDidMount() {
    this.display()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.display()
    }
  }

  render() {
    const { id, duration, ...restProps } = this.props
    return ReactDOM.createPortal(
      <Transition visible={this.state.visible} animation="slide down">
        <Message floating onDismiss={this.hide} {...restProps} />
      </Transition>,
      document.getElementById('notification-container')
    )
  }
}

Toast.show = props => {
  ReactDOM.render(
    <Toast id={Math.random()} {...props} />,
    document.getElementById('notification-container')
  )
}

Toast.error = args => {
  let props = {}
  if (args.content) props = args
  // if object
  else props = { content: args } // if string or node
  Toast.show({ negative: true, ...props })
}
Toast.success = args => {
  let props = {}
  if (args.content) props = args
  // if object
  else props = { content: args } // if string or node
  Toast.show({ positive: true, ...props })
}

export default Toast
