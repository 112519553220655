import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router'
import _ from 'lodash'
import util from '../index'
import { getEthBalance, getUsdcBalance } from '../blockchain'
import { GET_DASHBOARD, client } from '../API/Apollo'

export const useBoolean = initialValue => {
  const [value, setValue] = useState(initialValue)
  const enable = useCallback(() => setValue(true), [setValue])
  const disable = useCallback(() => setValue(false), [setValue])
  const toggle = useCallback(() => setValue(!value), [value, setValue])
  return { value, enable, disable, toggle }
}

export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(null)

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export const useModal = (initialOpen = false) => {
  const [isOpen, setOpen] = useState(initialOpen)
  const show = () => setOpen(true)
  const hide = () => setOpen(false)
  const props = { open: isOpen, onClose: hide }
  return { isOpen, show, hide, props }
}

export const usePage = (initialPage = 1) => {
  const [page, setPage] = React.useState(initialPage)
  const handlePage = page => setPage(page)
  return [page, handlePage]
}

export const useTab = (defaultActiveItem, options = {}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveItem)

  const handleItemClick = (e, { name }) => setActiveTab(name)
  const isActive = name => name === activeTab
  const itemProps = name => ({
    name,
    onClick: handleItemClick,
    active: name === activeTab,
    disabled: name === activeTab && options.disabled,
  })

  return { itemProps, isActive, activeTab, setActiveTab }
}

export const useSort = (
  initialField = 'created_at',
  initialDirection = 'descending'
) => {
  const [field, setField] = useState(initialField)
  const [direction, setDirection] = useState(initialDirection)
  const orderBy = useMemo(
    () => [
      {
        field: field,
        order: direction === 'ascending' ? 'ASC' : 'DESC',
      },
    ],
    [field, direction]
  )

  const toggle = newField => {
    let newDirection = direction
    if (field === newField && direction === 'ascending')
      newDirection = 'descending'
    if (field === newField && direction === 'descending')
      newDirection = 'ascending'
    if (field !== newField) newDirection = 'descending'
    setField(newField)
    setDirection(newDirection)
  }

  // table header props
  const sort = name => ({
    sorted: name === field ? direction : undefined,
    onClick: () => toggle(name),
  })

  return { field, direction, sort, orderBy }
}

export const useMounted = () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [setMounted])

  return mounted
}

export const useRouterState = stateName => {
  const [state, setState] = useState(null)
  const { location, replace } = useHistory()

  useEffect(() => {
    if (location && location.state && location.state[stateName]) {
      setState(location.state[stateName])
    }

    return () => {
      if (location && location.state && location.state[stateName]) {
        const state = { ...location.state }
        delete state[stateName]
        replace({ location, state })
      }
    }
  }, [location, location.state, replace, stateName])

  return state
}

export const useLocalStorage = name => {
  const initialValue = useMemo(() => util.getItemFromStorage(name), [name])
  const [value, setValue] = useState(initialValue)
  const saveValue = val => {
    if (!val) {
      util.removeItemFromStorage(val)
      setValue(undefined)
    } else {
      util.saveItemToStorage(name, val)
      setValue(val)
    }
  }

  return [value, saveValue]
}

export const useConfirmBeforeLeave = active => {
  const handleLeaveWithoutSaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (!active) return

    window.addEventListener('beforeunload', handleLeaveWithoutSaving)
    return () => {
      window.removeEventListener('beforeunload', handleLeaveWithoutSaving)
    }
  }, [active])
}

export const useEthBalance = address => {
  const [balance, setBalance] = useState(null)
  useEffect(() => {
    if (!address) return
    getEthBalance(address).then(balance => setBalance(balance))
  }, [address, setBalance])

  if (!address || balance === null) {
    return { loading: true, balance: null }
  }
  return { loading: false, balance }
}

export const useUsdcBalance = address => {
  const [balance, setBalance] = useState(null)
  useEffect(() => {
    if (!address) return
    getUsdcBalance(address).then(balance => setBalance(balance.toString()))
  }, [address, setBalance])

  if (!address || balance === null) {
    return { loading: true, balance: null }
  }
  return { loading: false, balance }
}

export const useWalletBalance = (wallet = {}) => {
  const { balance: ethBalance, loading: ethLoading } = useEthBalance(
    wallet.address
  )
  const { balance: usdcBalance, loading: usdcLoading } = useUsdcBalance(
    wallet.address
  )
  if (!wallet) return { loading: true }
  return {
    loading: ethLoading || usdcLoading,
    eth: ethBalance,
    usdc: usdcBalance,
  }
}

export const useDebounceQuery = (
  query,
  { variables, skip, ...restOptions }
) => {
  const [waiting, setWaiting] = useState(false)
  const [ready, setReady] = useState(false)
  const inputTimeout = useRef(null)
  const previousVariables = usePrevious(variables)
  const [delayedVariables, setDelayedVariables] = useState(variables)

  useEffect(() => {
    const noChange = _.isEqual(variables, previousVariables)
    if (skip || noChange) return
    clearTimeout(inputTimeout.current)
    setWaiting(true)
    inputTimeout.current = setTimeout(() => {
      setDelayedVariables(_.clone(variables))
      setReady(true)
      setWaiting(false)
    }, 500)
  }, [variables, previousVariables, skip])

  const { loading, data, ...restQuery } = useQuery(query, {
    variables: delayedVariables,
    skip: skip || !ready,
    ...restOptions,
  })
  return {
    loading: loading || waiting,
    data,
    ...restQuery,
  }
}

// find last specific approval from an approval array
export const useApproval = (approvals, type) => {
  return useMemo(() => {
    return _.sortBy(approvals, 'created_at')
      .reverse()
      .find(a => a.approval_type === type)
  }, [approvals, type])
}

export const useDashboardData = () => {
  const { loading, data } = useQuery(GET_DASHBOARD, {
    client,
  })

  return {
    dashboard: _.get(data, 'dashboard'),
    customers: _.get(data, 'dashboard.customers'),
    providers: _.get(data, 'dashboard.providers'),
    companies: _.get(data, 'dashboard.companies'),
    trades: _.get(data, 'dashboard.trades'),
    assets: _.get(data, 'dashboard.assets'),
    offers: _.get(data, 'dashboard.offers'),
    securities: _.get(data, 'dashboard.securities'),
    financials: _.get(data, 'dashboard.financials'),
    marketplace: _.get(data, 'dashboard.marketplace'),
    loading,
  }
}
