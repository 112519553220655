import React from 'react'
import { Header, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { ApprovalStatus, ApprovalType } from '../../util/Constant'
import TabMenu from '../../components/TabMenu'
import ProfileSummary from '../../components/Profile/ProfileSummary'
import ProfileCommonDetails from '../../components/Profile/ProfileCommonDetails'
import CompanySummary from '../../components/Company/CompanySummary'
import { useApproval } from '../../util/hooks'
import ActionApprovalStatus from '../../components/Action/ActionApprovalStatus'
import MSGApproveButton from '../../components/Verification/MSGApproveButton'
import MSGRejectionButton from '../../components/Verification/MSGRejectionButton'
import { FileLink } from '../../components/link'

const MSGActionDetails = ({ action }) => {
  const approval = useApproval(action.approvals, ApprovalType.VERIFICATION_MSG)
  const isWaiting = approval.status === ApprovalStatus.WAITING
  const user = action.actionable

  return (
    <TabMenu defaultTab="action-details">
      <TabMenu.Left>
        <TabMenu.Menu>
          <TabMenu.Item name="action-details" />
          <TabMenu.Item name="account-details" />
          {_.get(user, 'profile.company') && (
            <TabMenu.Item name="company-details" />
          )}
        </TabMenu.Menu>
      </TabMenu.Left>
      <TabMenu.Right>
        <ProfileSummary user={user} />
        <TabMenu.Pane name="action-details">
          <Header as="h2" content="MEDALLION SIGNATURE VERIFICATION" />
          <Table basic="very" padded="very" size="small" fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="DOCUMENT" />
                <Table.HeaderCell content="STATUS" />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <FileLink
                    file={_.get(approval, 'verification_data.document')}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ActionApprovalStatus approval={approval} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <MSGApproveButton action={action} show={isWaiting} />
                  <MSGRejectionButton action={action} show={isWaiting} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </TabMenu.Pane>
        <TabMenu.Pane name="account-details">
          <ProfileCommonDetails user={user} />
        </TabMenu.Pane>
        <TabMenu.Pane name="company-details">
          <CompanySummary company={user.profile.company} />
        </TabMenu.Pane>
      </TabMenu.Right>
    </TabMenu>
  )
}

export default MSGActionDetails
