import React, { useMemo } from 'react'
import { Button, Grid, Header, Icon } from 'semantic-ui-react'
import MSGFormModal from '../../components/Verification/MSGVerificationForm'
import { useModal } from '../../util/hooks'
import {
  ApprovalStatus,
  ApprovalType,
  Info,
  TxStatus,
} from '../../util/Constant'
import { GET_PROFILE } from '../../util/API/Apollo'
import MSGApprovalsTable from '../../components/Verification/MSGApprovalsTable'

export const ApprovalStatusMessage = ({ approval }) => {
  if (approval.tx_status === TxStatus.PENDING)
    return (
      <>
        <Icon loading name="spinner" color="blue" /> {Info.WAITING_BLOCKCHAIN}
      </>
    )

  switch (approval.status) {
    case ApprovalStatus.INITIALIZED:
    case ApprovalStatus.WAITING:
      return (
        <>
          <Icon name="time" color="blue" />
          Waiting for approval
        </>
      )
    case ApprovalStatus.REJECTED:
      return (
        <>
          <Icon name="times" color="red" />
          Rejected
        </>
      )
    case ApprovalStatus.APPROVED:
      return (
        <>
          <Icon name="check" color="green" />
          Approved
        </>
      )
    case ApprovalStatus.CANCELED:
      return (
        <>
          <Icon name="exclamation triangle" /> Canceled
        </>
      )
    default:
      throw new Error(`invalid approval status ${approval.status}`)
  }
}

const MSGDetails = ({ user }) => {
  const formModal = useModal()

  const approvals = useMemo(
    () =>
      user.approvals.data.filter(
        a => a.approval_type === ApprovalType.VERIFICATION_MSG
      ),
    [user.approvals.data]
  )

  return (
    <Grid padded>
      <MSGFormModal
        user={user}
        refetchQueries={[{ query: GET_PROFILE }]}
        onSubmit={formModal.hide}
        onClose={formModal.hide}
        open={formModal.isOpen}
      />
      <Grid.Row>
        <Grid.Column>
          <Header content="MEDALLION SIGNATURES" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer="8" tablet="6" mobile="16">
          <Header as="h4" content="What is Medallion Signature?" />
          <p className="text-justify">
            A medallion signature guarantee (MSG) is a guarantee by the
            transferring financial institution that the signature is genuine and
            the financial institution accepts liability for any forgery. A MSG
            protects shareholders by preventing unauthorized transfers and
            possible investor losses.
            <br />
            <br />
            A MSG is a binding warranty issued by an agent of the authorized
            guarantor institution, that: (a) the signature was genuine; (b) the
            signer was an appropriate person to endorse, and (c) the signer had
            legal capacity to sign.
            <br />
            <br />
            As a user of this Platform you are required to submit these
            certificates because all securities “enter” the platform with a
            medallion signature guarantee at the time of digitization. After
            digitization of a security, submission of a MSG is not required for
            subsequent transactions of the same security because ALL users agree
            contractually that use of their unique Ethereum ID constitutes their
            irrevocable consent and authorization to undertake any and all
            transactions on the platform and use of the ID constitutes full
            incumbency to authorize any such transaction.
          </p>
        </Grid.Column>
        <Grid.Column
          computer="8"
          tablet="10"
          mobile="16"
          textAlign="center"
          verticalAlign="middle"
        >
          <Button
            primary
            size="small"
            content="Add medallion signature"
            onClick={formModal.show}
            data-test-id="start-msg-document"
          />
          <MSGApprovalsTable approvals={approvals} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default MSGDetails
