import * as yup from 'yup'
import { client, IS_ACTIVATED } from './API/Apollo'
import { UserRegistrationStatus } from './Constant'

// email availability
yup.addMethod(yup.string, 'available', function(formats, parseStrict) {
  return this.test('email-available', 'This email is taken', async value => {
    if (!value) return true
    try {
      const { data } = await client.query({
        query: IS_ACTIVATED,
        variables: { email: value },
      })
      // true if user found
      return data.isActivated === UserRegistrationStatus.NOT_FOUND
    } catch (err) {
      console.error(err)
      return false
    }
  })
})
