import React from 'react'
import { Header, Icon, Segment, Table } from 'semantic-ui-react'
import { AgeInfo, DimmerLoader } from '../'
import { FileLink } from '../link'

const AccountFiles = ({ files, loading, pagination }) => {
  return (
    <Segment padded="very">
      <Header as="h3" content="YOUR FILES" />
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="FILE" />
            <Table.HeaderCell content="AGE" />
            <Table.HeaderCell content="SIZE" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell collapsing>
                <DimmerLoader />
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {files.map(file => (
                <Table.Row key={file.uuid}>
                  <Table.Cell>
                    <Icon name="file image" size="large" />
                    <FileLink file={file} />
                  </Table.Cell>
                  <Table.Cell>
                    <AgeInfo iso str={file.created_at} />
                  </Table.Cell>
                  <Table.Cell>
                    <small>{file.size}</small>
                  </Table.Cell>
                </Table.Row>
              ))}
            </>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">{pagination}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  )
}

export default AccountFiles
