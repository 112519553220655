import React from 'react'
import {
  Grid,
  Form,
  Image,
  Header,
  Divider,
  Input,
  TextArea,
} from 'semantic-ui-react'
import { NextBackButton } from '../CompanyForm'
import { ImageUpload } from '../../../upload'
import { TaxIdInput, SectorInput } from '../../../input'
import companyPlaceholder from '../../../../images/company-placeholder.png'
import issuerBasicInfo from '../../../../images/issuer_basic_info.svg'

const BasicInfoStep = ({ formHook, stepsHook, handleNextStep }) => {
  const { field } = formHook

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column computer="6" only="computer">
          <Image size="large" src={issuerBasicInfo} />
          <Header
            as="h3"
            color="purple"
            textAlign="left"
            content="ADD COMPANY INFO"
          />
        </Grid.Column>
        <Grid.Column computer="10" tablet="16" mobile="16">
          <Grid>
            <Grid.Row centered>
              <Grid.Column textAlign="center" width={4}>
                <ImageUpload
                  placeholder={companyPlaceholder}
                  uploadText="Upload Logo"
                  changeText="Change Logo"
                  {...field('logo')}
                />
                <Divider hidden />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Form.Field
                    label="Corporation name"
                    icon="building"
                    iconPosition="left"
                    required
                    control={Input}
                    {...field('name')}
                  />
                  <Form.Field
                    fluid
                    label="Tax ID/EIN"
                    icon="id card"
                    iconPosition="left"
                    required
                    control={TaxIdInput}
                    {...field('tax_id')}
                  />
                  <Form.Field
                    label="Industry"
                    control={SectorInput}
                    {...field('sectors')}
                  />
                  <Form.Field
                    label="About"
                    control={TextArea}
                    {...field('about')}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <NextBackButton
                  stepsHook={stepsHook}
                  handleNextStep={handleNextStep}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default BasicInfoStep
