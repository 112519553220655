import React from 'react'
import { Table } from 'semantic-ui-react'
import { AgeInfo, AmountLabel } from '../index'
import { CompanyLink, ContractLink } from '../link'

const TokenInfoTable = ({ token }) => {
  const { hashid, contract, amount, created_at } = token

  return (
    <Table basic="very" padded fixed size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="DIGITAL SECURITIES ID" />
          <Table.HeaderCell content="UNDERLYING ASSET" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell content="AMOUNT" />
          <Table.HeaderCell content="AGE" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <p>{hashid}</p>
          </Table.Cell>
          <Table.Cell>
            <ContractLink contract={contract} />
          </Table.Cell>
          <Table.Cell>
            <CompanyLink company={contract.issuer} />
          </Table.Cell>
          <Table.Cell>
            <AmountLabel amount={amount} />
          </Table.Cell>
          <Table.Cell>
            <AgeInfo iso str={created_at} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default TokenInfoTable
