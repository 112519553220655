import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import debug from 'debug'
import util from '../index'

const log = debug('app:form')

/**
 * remember names can be path string like 'field.nested_field'
 * @param name {string} used in dev macros
 * @param initialValue {object}
 * @param options {{ schema, abortEarly }}
 */
const useForm = (name, initialValue, options = {}) => {
  const [formValue, setFormValue] = useState(initialValue)
  const [errors, setErrors] = useState({}) // { field: 'error message' }

  useEffect(() => {
    setFormValue(initialValue)
  }, [initialValue])

  const isDirty = useMemo(() => !_.isEqual(initialValue, formValue), [
    initialValue,
    formValue,
  ])

  const handleChange = (e, { name, value }) => {
    const newValue = _.setWith(_.clone(formValue), name, value, _.clone)
    setFormValue(newValue)
    if (_.get(errors, name)) {
      const newErrors = _.setWith(_.clone(errors), name, null, _.clone)
      setErrors(newErrors)
    }
  }

  const handleCheckboxChange = (e, { name, checked }) => {
    handleChange(e, { name, value: checked })
  }

  const setValue = (name, value) => {
    handleChange(null, { name, value })
  }

  const clear = () => {
    setFormValue(initialValue)
    setErrors({})
  }

  const getInputProps = (path, options) => {
    switch (_.get(options, 'type')) {
      case 'radio':
        return {
          name: path,
          value: options.value,
          checked: _.get(formValue, path) === options.value,
          onChange: handleChange,
        }
      case 'checkbox':
        return {
          name: path,
          checked: _.get(formValue, path),
          onChange: handleCheckboxChange,
        }
      default:
        return {
          name: path,
          value: _.get(formValue, path),
          onChange: handleChange,
        }
    }
  }

  const getFieldProps = (path, options) => {
    const props = getInputProps(path, options)
    return {
      ...props,
      error: _.get(errors, path, null),
    }
  }

  const validate = async (dynamicSchema, context) => {
    const schema = dynamicSchema || _.get(options, 'schema')
    const abortEarly = _.get(options, 'abortEarly', false)
    try {
      await schema.validate(formValue, { abortEarly, strict: true, context })
      return true
    } catch (err) {
      log('error validating form', err)
      if (!err.errors) return false
      setErrors(
        err.inner.reduce((errObj, err) => {
          const path = err.path || err.type // for yup.test
          return _.set(errObj, path, err.message)
        }, {})
      )
      return false
    }
  }

  // configure dev function to set value of this form
  React.useEffect(() => {
    if (!util.isDev() || !name) return
    if (!window.setForm) window.setForm = {}
    window.setForm[name] = value => setFormValue(_.merge({}, formValue, value))
    return () => delete window.setForm[name]
  }, [formValue, name])

  return {
    form: formValue,
    input: getInputProps,
    field: getFieldProps,
    set: setValue,
    setForm: setFormValue,
    validate,
    isDirty,
    clear,
    errors,
  }
}

export default useForm
