import React from 'react'
import { Link } from 'react-router-dom'
import {
  Segment,
  Table,
  Header,
  Button,
  Divider,
  Icon,
} from 'semantic-ui-react'
import { AgeInfo } from '../'
import link from '../link'

const AdvicesSimpleTable = ({ advices }) => {
  if (advices.length > 0) {
    return (
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="LISTING ID" />
            <Table.HeaderCell content="AGE" />
            <Table.HeaderCell content="MESSAGE" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {advices.map((advice, index) => {
            const {
              listing_id,
              listing_hashid,
              subject,
              message,
              created_at,
            } = advice

            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <Link to={link.listing({ uuid: listing_id })}>
                    {listing_hashid}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <AgeInfo iso str={created_at} />
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4" content={subject} />
                  <p>{message}</p>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    size="mini"
                    content="View"
                    as={Link}
                    to={link.listing({ uuid: listing_id })}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Segment basic textAlign="center">
      <Divider />
      <Header as="h4" icon color="black">
        <Icon name="ban" />
        NO PENDING ACTIONS FOUND
      </Header>
      <Divider />
    </Segment>
  )
}

export default AdvicesSimpleTable
