import React from 'react'
import { Divider, Header } from 'semantic-ui-react'
import {
  AgeInfo,
  SubMessage,
  TokenAmountInfo,
  TransferabilityInfo,
} from '../index'
import { TxStatus } from '../../util/Constant'
import { CompanyLink, ContractLink } from '../link'

const TokenCommonDetails = ({ token }) => {
  if (token) {
    const { hashid, created_at, contract, tx_status } = token

    return (
      <>
        {tx_status === TxStatus.PENDING && (
          <SubMessage
            info
            content="Your Digital Securities are being verified on Blockchain. This may take a few minutes, please wait."
            icon="spinner"
            loading
          />
        )}
        <Header as="h4" content="Digital Securities ID" />
        {hashid}
        <Divider />
        <Header as="h4" content="Underlying Asset" />
        <ContractLink contract={contract} />
        <Divider />
        <Header as="h4" content="Issuer" />
        <CompanyLink company={contract.issuer} />
        <Divider />
        <Header as="h4" content="Amount" />
        <TokenAmountInfo token={token} />
        <Divider />
        <Header as="h4" content="Age" />
        <AgeInfo iso str={created_at} />
        <Divider />
        {/*<Header as="h4" content="Creation Tx" />*/}
        {/*<a href="/">*/}
        {/*  0xddb0448fec10d44309e63bba99b6a23c279cd710db0e755249b61a5ffd8f4729*/}
        {/*</a>*/}
        {/*<Divider />*/}
        <Header as="h4" content="Transferability status" />
        <TransferabilityInfo token={token} />
        {/*<Divider />*/}
        {/*<Header as="h4" content="Transferable Tx" />*/}
        {/*<a href="/">*/}
        {/*  0xddb0448fec10d44309e63bba99b6a23c279cd710db0e755249b61a5ffd8f4729*/}
        {/*</a>*/}
      </>
    )
  }

  return null
}

export default TokenCommonDetails
