import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Button, Header, Modal } from 'semantic-ui-react'
import ListingInfoTable from './ListingInfoTable'
import { PageLoader } from '../index'
import { useModal } from '../../util/hooks'
import { client, REMOVE_LISTING } from '../../util/API/Apollo'

const RemoveListingButton = ({ listing, onRemove, refetchQueries }) => {
  const modal = useModal()

  const [remove, { loading }] = useMutation(REMOVE_LISTING, {
    variables: {
      uuid: listing.uuid,
    },
    onCompleted: () => {
      modal.hide()
      onRemove()
    },
    refetchQueries,
    awaitRefetchQueries: true,
    client,
  })

  return (
    <>
      <Button fluid color="red" content="Remove" onClick={modal.show} />
      <Modal closeIcon dimmer="blurring" {...modal.props}>
        <Modal.Header content="REMOVE LISTING" />
        <Modal.Content>
          <ListingInfoTable listing={listing} />
          <Header
            as="h4"
            content="Do you want to remove your listing from the marketplace?"
          />
          <Button fluid color="red" content="Remove listing" onClick={remove} />
        </Modal.Content>
      </Modal>
      <PageLoader active={loading} />
    </>
  )
}

export default RemoveListingButton
