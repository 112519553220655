import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import {
  AccountFiles,
  SharedFiles,
  FileUpload,
} from '../../components/FileManager'
import { CSBreadcrumb, APIPagination, PageLoader } from '../../components'
import { SuccessModal } from '../../components/confirm'
import {
  GET_FILES,
  GET_MEDIA,
  GET_MEDIA_STORAGE,
  REMOVE_SHARED_FILE,
  client,
} from '../../util/API/Apollo'
import { usePage, useModal } from '../../util/hooks'

const COUNT = 10

const FileManager = () => {
  const [filesPage, setFilesPage] = usePage(1)
  const [mediaPage, setMediaPage] = usePage(1)
  const removeSuccessModal = useModal()

  const files = useQuery(GET_FILES, {
    variables: {
      count: COUNT,
      page: filesPage,
    },
    client,
  })

  const media = useQuery(GET_MEDIA, {
    variables: {
      count: COUNT,
      page: mediaPage,
    },
    client,
  })

  const storage = useQuery(GET_MEDIA_STORAGE, { client })

  const [remove, removeMutation] = useMutation(REMOVE_SHARED_FILE, {
    onCompleted: () => {
      removeSuccessModal.show()
      media.refetch()
    },
    client,
  })

  const accountFiles = _.get(files, 'data.me.files.data', [])
  const accountFilesPaginator = _.get(files, 'data.me.files.paginatorInfo')

  const sharedFiles = _.get(media, 'data.me.media.data', [])
  const sharedFilesPaginator = _.get(media, 'data.me.media.paginatorInfo')

  const mediaStorage = _.get(storage, 'data.me.media_storage')

  if (storage.loading || removeMutation.loading) return <PageLoader />

  return (
    <>
      <CSBreadcrumb title="File manager" />
      <Grid padded className="h-fluid">
        <Grid.Row>
          {accountFiles.length > 0 && (
            <Grid.Column computer={10} tablet={10} mobile={16}>
              <AccountFiles
                files={accountFiles}
                loading={files.loading}
                pagination={
                  <APIPagination
                    paginatorInfo={accountFilesPaginator}
                    handleChange={setFilesPage}
                    count={COUNT}
                  />
                }
              />
            </Grid.Column>
          )}
          <Grid.Column
            computer={accountFiles.length > 0 ? 6 : 16}
            tablet={accountFiles.length > 0 ? 6 : 16}
            mobile={16}
          >
            <FileUpload storage={mediaStorage} refetch={media.refetch} />
          </Grid.Column>
        </Grid.Row>
        {sharedFiles.length > 0 && (
          <Grid.Row>
            <Grid.Column>
              <SharedFiles
                files={sharedFiles}
                remove={remove}
                loading={media.loading}
                pagination={
                  <APIPagination
                    paginatorInfo={sharedFilesPaginator}
                    handleChange={setMediaPage}
                    count={COUNT}
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <SuccessModal
        content="The shared file has been deleted."
        {...removeSuccessModal.props}
      />
    </>
  )
}

export default FileManager
