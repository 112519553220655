import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import moment from 'moment'
import { Button, Divider, Form, Header, Modal, Radio } from 'semantic-ui-react'
import { DimmerLoader } from '../index'
import { DocumentUpload } from '../upload'
import WalletDecryptor from '../Wallet/WalletEncrypter'
import { signMessage, prettifyAddress } from '../../util/blockchain'
import { useModal } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import { useMe } from '../../util/hooks/me'
import {
  ApprovalType,
  AssetCodeNames,
  AssetCodes,
  BlockchainSignedMessageTags,
} from '../../util/Constant'
import {
  client,
  SUBMIT_NEW_CUSTOMER_DOCUMENT,
  VERIFY_NEW_CUSTOMER_DOCUMENT,
} from '../../util/API/Apollo'

const TxModal = ({ open, onClose, refetchQueries, onSubmit, action, form }) => {
  const [expiresAt, setExpiresAt] = useState(null)
  const [signing, setSigning] = useState(null)
  const [signedMessage, setSignedMessage] = useState(null)

  const [verify, verifyMutation] = useMutation(VERIFY_NEW_CUSTOMER_DOCUMENT, {
    variables: {
      provider_action_id: _.get(action, 'uuid'),
      input: {
        approval_type: ApprovalType.VERIFICATION_KYC,
        expires_at: moment(expiresAt).format('YYYY-MM-DD'),
        document: _.get(form.doc, 'uuid'),
        signed_message: signedMessage,
      },
    },
    onCompleted: onSubmit,
    refetchQueries,
    awaitRefetchQueries: true,
    client,
  })

  const handleVerify = async wallet => {
    setSigning(true)
    const investor_address = action.actionable.primary_wallet.address
    const document_hash = form.doc.hash
    const expires_at = new Date().getTime() + 12 * 30 * 24 * 60 * 60 * 1000

    // Message format: investor_address:1:document_hash
    const message = `${prettifyAddress(investor_address)}:${
      BlockchainSignedMessageTags.VERIFICATION_KYC
    }:${prettifyAddress(document_hash)}`

    const signedMessage = await signMessage(wallet, message)

    setExpiresAt(expires_at)
    setSignedMessage(signedMessage)
    setSigning(false)
    await verify()
  }

  const loading = signing || verifyMutation.loading

  return (
    <Modal open={open} onClose={onClose} dimmer="blurring" closeIcon>
      <WalletDecryptor onDecrypted={handleVerify} />
      <DimmerLoader active={loading} />
    </Modal>
  )
}

const initialForm = {
  asset_code: null,
  doc: null,
}

const KYCVerificationForm = ({
  open,
  onClose,
  refetchQueries,
  onSubmit,
  action,
}) => {
  const { isCustomer, isTransferAgent } = useMe()
  const { form, input, field, clear } = useForm('kyc-verification', initialForm)
  const handleClose = () => {
    clear()
    onClose()
  }

  const txModal = useModal()

  // FOR CUSTOMER VERIFICATION REQUEST
  const [submit, submitMutation] = useMutation(SUBMIT_NEW_CUSTOMER_DOCUMENT, {
    variables: {
      input: {
        approval_type: ApprovalType.VERIFICATION_KYC,
        document: _.get(form.doc, 'uuid'),
      },
    },
    onCompleted: () => {
      clear()
      onSubmit()
    },
    refetchQueries,
    awaitRefetchQueries: true,
    client,
  })

  const handleSubmit = async () => {
    if (isCustomer) {
      await submit()
    } else if (isTransferAgent) {
      txModal.show()
    }
  }

  return (
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      open={open}
      onClose={handleClose}
      closeIcon
      dimmer="blurring"
    >
      <TxModal
        {...txModal.props}
        onSubmit={onSubmit}
        refetchQueries={refetchQueries}
        action={action}
        form={form}
      />
      <Modal.Header content="ACCOUNT VERIFICATION" />
      <Modal.Content>
        <Header as="h4" content="Please select a verification document type" />
        <DimmerLoader active={submitMutation.loading} />
        <Form.Group widths="equal">
          <Form.Field>
            <Radio
              label={AssetCodeNames.passport}
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.passport,
              })}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={AssetCodeNames.license}
              name="asset_code"
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.license,
              })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Radio
              label={AssetCodeNames.national_id}
              name="asset_code"
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.national_id,
              })}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={AssetCodeNames.residence_permit}
              name="asset_code"
              {...input('asset_code', {
                type: 'radio',
                value: AssetCodes.residence_permit,
              })}
            />
          </Form.Field>
        </Form.Group>
        <Divider hidden />
        <Form.Field
          label="Upload Document"
          required
          control={DocumentUpload}
          {...field('doc')}
          asset_code={form.asset_code}
          disabled={!form.asset_code}
        />
        <Button
          fluid
          content="Upload"
          primary
          disabled={!(form.asset_code && form.doc)}
          type="submit"
        />
      </Modal.Content>
    </Modal>
  )
}

export default KYCVerificationForm
