import React from 'react'
import { Dropdown, Form, Input } from 'semantic-ui-react'
import InputMask from 'inputmask'
import SemanticDatePicker from 'react-semantic-ui-datepickers'
import _ from 'lodash'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { sectors } from '../util/Constant'
import util from '../util'
import { client, GET_CITIES, GET_STATES } from '../util/API/Apollo'
import { useQuery } from '@apollo/react-hooks'

// allow empty string on numbers
function onBeforeMask(pastedValue) {
  if (pastedValue === '' || pastedValue === '0' || pastedValue === 0) {
    this.clearMaskOnLostFocus = true
    return null
  } else {
    this.clearMaskOnLostFocus = false
  }
}

InputMask.extendDefaults({
  autoUnmask: true,
})
InputMask.extendAliases({
  integer: {
    alias: 'numeric',
    autoGroup: true,
    groupSeparator: ',',
    digits: 0,
    showMaskOnHover: false,
    showMaskOnFocus: true,
    clearMaskOnLostFocus: true,
    digitsOptional: true,
    rightAlign: false,
    allowMinus: false,
    autoUnmask: true,
    onBeforeMask,
  },
  float: {
    alias: 'numeric',
    autoGroup: true,
    groupSeparator: ',',
    digitsOptional: true,
    showMaskOnHover: false,
    showMaskOnFocus: true,
    clearMaskOnLostFocus: true,
    rightAlign: false,
    allowMinus: false,
    autoUnmask: true,
    onBeforeMask,
  },
})

const phoneMask = new InputMask('+1 (999) 999-9999')
const zipcodeMask = new InputMask('99999[9]')
const taxIdMask = new InputMask('99-9999999')

export const PhoneInput = ({ ...props }) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    phoneMask.mask(ref.current)
  }, [])
  return <Input input={{ ref }} {...props} />
}

export const ZipcodeInput = ({ ...props }) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    zipcodeMask.mask(ref.current)
  }, [])
  return <Input input={{ ref }} {...props} />
}

export const TaxIdInput = ({ ...props }) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    taxIdMask.mask(ref.current)
  }, [])
  return <Input input={{ ref }} {...props} />
}

// proxy component that masks semantic input as number and provides min-max options
export const NumberInput = ({
  value,
  onChange,
  min,
  max,
  float,
  mask,
  ...props
}) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    const masker = new InputMask(float ? 'float' : 'integer', mask)
    masker.mask(ref.current)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e, { value: changeValue, ...newProps }) => {
    if (!onChange) return

    let newValue = changeValue
    if (newValue !== '') {
      if (min && Number(newValue) < Number(min)) newValue = min
      if (max && Number(newValue) > Number(max)) newValue = max
    }
    onChange(e, { ...newProps, value: newValue })
  }

  return (
    <Input input={{ ref }} value={value} onChange={handleChange} {...props} />
  )
}

const OLD_UI_DATE_FORMAT = 'MM-DD-YYYY' // date-fns@1.2.9 which date-picker uses
export const DateField = ({ value, onChange, past, ...props }) => {
  const dateValue = value ? util.parseAPIDate(value) : null

  React.useEffect(() => {
    const inputEl = document.querySelector(`input[name="${props.name}"]`)
    if (inputEl) {
      inputEl.setAttribute('autocomplete', 'off')
      inputEl.parentElement.parentElement.parentElement.style.display = 'block'
    }
  }, [props.name])

  const handleChange = newDateValue => {
    const newValue = util.formatDateForAPI(newDateValue)
    onChange(newValue, { ...props, value: newValue })
  }

  const maxDate = past ? new Date() : null
  return (
    <SemanticDatePicker
      format={OLD_UI_DATE_FORMAT}
      maxDate={maxDate}
      selected={dateValue}
      onDateChange={handleChange}
      {...props}
    />
  )
}

const countryOptions = [
  { key: 'US', value: 'US', flag: 'us', text: 'United States' },
  { key: 'CA', value: 'CA', flag: 'ca', text: 'Canada' },
  { key: 'SG', value: 'SG', flag: 'sg', text: 'Singapore' },
]
const sectorOptions = Object.keys(sectors).map(key => ({
  key,
  text: sectors[key],
  value: key,
}))

export const SectorInput = props => (
  <Dropdown
    placeholder="Sectors"
    multiple
    search
    selection
    options={sectorOptions}
    {...props}
  />
)
export const CountryInput = props => (
  <Dropdown
    placeholder="Country"
    selection
    options={countryOptions}
    {...props}
  />
)

const mapStatesToOptions = data => {
  return Object.keys(data).map(key => ({
    id: data[key].id,
    key: data[key].name,
    text: data[key].name,
    value: data[key].name,
  }))
}

const mapCitiesToOptions = data => {
  return Object.keys(data).map(key => ({
    key: data[key].name,
    text: data[key].name,
    value: data[key].name,
  }))
}

export const StateField = ({
  country,
  value,
  onChange,
  setStateId,
  ...props
}) => {
  const defaultOptions = React.useMemo(
    () => (value ? [{ key: value, text: value, value }] : []),
    [value]
  )
  const [options, setOptions] = React.useState(defaultOptions)
  const { loading } = useQuery(GET_STATES, {
    skip: !country,
    variables: { country },
    onCompleted: data => {
      setOptions([
        ...defaultOptions,
        ...mapStatesToOptions(_.get(data, 'states', [])),
      ])
    },
    client,
  })

  const handleChange = (e, { value }) => {
    const option = options.find(o => o.value === value)
    if (_.get(option, 'id')) setStateId(option.id)
    onChange(e, { ...props, value })
  }

  const handleAddItem = (e, { value }) => {
    setOptions([{ key: value, text: value, value }, ...options])
    handleChange(e, { value })
  }

  return (
    <Form.Field
      search
      selection
      allowAdditions
      additionLabel=""
      additionPosition="bottom"
      onAddItem={handleAddItem}
      loading={loading}
      options={options}
      control={Dropdown}
      placeholder="State"
      value={value}
      onChange={handleChange}
      {...props}
    />
  )
}

export const CityField = ({ country, state, stateId, value, ...props }) => {
  const defaultOptions = React.useMemo(
    () => (value ? [{ key: value, text: value, value }] : []),
    [value]
  )
  const [options, setOptions] = React.useState(defaultOptions)
  const { loading } = useQuery(GET_CITIES, {
    skip: !stateId,
    variables: { country, state: stateId },
    onCompleted: data =>
      setOptions([
        ...defaultOptions,
        ...mapCitiesToOptions(_.get(data, 'cities', [])),
      ]),
    client,
  })
  const handleAddItem = (e, { value }) => {
    setOptions([{ key: value, text: value, value }, ...options])
    props.onChange(e, { ...props, value })
  }

  return (
    <Form.Field
      search
      selection
      options={options}
      allowAdditions
      additionLabel=""
      additionPosition="bottom"
      onAddItem={handleAddItem}
      loading={loading}
      control={Dropdown}
      placeholder="City"
      value={value}
      {...props}
    />
  )
}

export const SelectCurrency = ({ currencies = ['ETH', 'USDC'], ...props }) => {
  const options = React.useMemo(
    () => currencies.map(c => ({ key: c, text: c, value: c })),
    [currencies]
  )
  return (
    <Dropdown
      placeholder="Select Currency"
      selection
      options={options}
      {...props}
    />
  )
}
