import React from 'react'
import { Label } from 'semantic-ui-react'
import { ProviderActionType } from '../../util/Constant'

const ActionTypeLabel = ({ action_type }) => {
  let content
  switch (action_type) {
    case ProviderActionType.CUSTOMER_APPROVAL:
      content = 'NEW CUSTOMER'
      break
    case ProviderActionType.CUSTOMER_KYC:
      content = 'KYC&AML'
      break
    case ProviderActionType.CUSTOMER_ACC:
      content = 'ACCREDITED INVESTOR'
      break
    case ProviderActionType.CUSTOMER_MSG:
      content = 'MEDALLION SIGNATURE'
      break
    case ProviderActionType.COMPANY_VERIFICATION:
      content = 'COMPANY APPROVAL'
      break
    case ProviderActionType.PROVIDER_APPROVAL:
      content = 'PROVIDER APPROVAL'
      break
    case ProviderActionType.ASSET_TRANSFERABILITY:
      content = 'ASSET APPROVAL'
      break
    default:
      throw new Error(`invalid action type: ${action_type}`)
  }
  return <Label content={content} />
}

export default ActionTypeLabel
