import React from 'react'
import { Table } from 'semantic-ui-react'
import {
  AskAmountLabel,
  AskPriceLabel,
  PaymentType,
  PPDSLabel,
} from '../../components'
import { CompanyLink, ContractLink } from '../link'

const ListingInfoTable = ({ listing }) => {
  const { amount, price, token, currencies } = listing

  return (
    <Table basic="very" padded size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="UNDERLYING ASSET" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell content="ASK AMOUNT" />
          <Table.HeaderCell content="ASK PRICE" />
          <Table.HeaderCell content="PPDS" />
          <Table.HeaderCell content="PAYMENT TYPE" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <ContractLink contract={token.contract} />
          </Table.Cell>
          <Table.Cell>
            <CompanyLink company={token.contract.issuer} />
          </Table.Cell>
          <Table.Cell>
            <AskAmountLabel amount={amount} />
          </Table.Cell>
          <Table.Cell>
            <AskPriceLabel price={price} />
          </Table.Cell>
          <Table.Cell>
            <PPDSLabel data={{ amount, price }} />
          </Table.Cell>
          <Table.Cell>
            <PaymentType currencies={currencies} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default ListingInfoTable
