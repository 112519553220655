import React from 'react'
import { Form } from 'semantic-ui-react'
import Papa from 'papaparse'
import { useModal } from '../../../util/hooks'
import { DimmerLoader } from '../../index'
import { SuccessModal } from '../../confirm'
import { FileSelect } from '../../upload'
import { WebsiteLink } from '../../link'

const ImportForm = ({ onSubmit }) => {
  const [file, setFile] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [values, setValues] = React.useState([])
  const importedModal = useModal()

  const handleFile = file => {
    setFile(file)
    setLoading(true)
    Papa.parse(file, {
      complete: results => {
        try {
          const values = results.data.slice(1).map(row => ({
            firstname: row[0],
            lastname: row[1],
            phonenumber: row[2],
            email: row[4],
            share_amount: row[6],
          }))
          setValues(values)
          importedModal.show()
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      },
      error: console.error,
    })
  }

  const handleSubmit = () => {
    importedModal.hide()
    onSubmit(values)
  }

  return (
    <Form>
      <DimmerLoader active={loading} />
      <SuccessModal
        content={`${values.length} shareholders imported`}
        open={importedModal.isOpen}
        onClose={handleSubmit}
      />
      <p>
        You can use this{' '}
        <WebsiteLink href="/">shareholder-example.csv</WebsiteLink> template to
        invite with csv document.
      </p>
      <FileSelect value={file} onChange={handleFile} />
      <br />
    </Form>
  )
}

export default ImportForm
