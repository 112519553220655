import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Segment, Header, Button } from 'semantic-ui-react'
import { AgeInfo } from '../'
import link from '../link'

const Activities = ({ activities }) => {
  if (activities.length > 0) {
    return (
      <Segment padded="very">
        <Header as="h2" color="black" content="ACCOUNT ACTIVITY" />
        {activities.map((activity, index) => (
          <Fragment key={index}>
            <Header sub content={activity.description} className="my-0" />
            <small>
              <AgeInfo iso str={activity.created_at} />
            </small>
          </Fragment>
        ))}
        {activities.length === 5 && (
          <Button
            basic
            attached="bottom"
            content="VIEW ALL"
            as={Link}
            className="mt-15"
            to={link.activity}
          />
        )}
      </Segment>
    )
  }

  return null
}

export default Activities
