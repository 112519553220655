import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL ASSETS',
    key: 'count',
  },
  {
    title: 'TOTAL PENDING ASSETS',
    key: 'pending_count',
  },
  {
    title: 'TRADED ASSETS',
    key: 'traded_count',
  },
  {
    title: 'TOTAL ASSETS AMOUNT',
    key: 'amount',
    isPrice: true,
  },
  {
    title: 'TOTAL PENDING ASSETS AMOUNT',
    key: 'pending_amount',
    isPrice: true,
  },
  {
    title: 'TOTAL TRANSFERABLE AMOUNT',
    key: 'transferable_amount',
    isPrice: true,
  },
]

const AssetsWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      dataSchema={dataSchema}
      title="ASSETS SUMMARY"
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
    />
  )
}

export default AssetsWidget
