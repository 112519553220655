import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import _ from 'lodash'
import { Button, Form, Modal, Radio, Segment } from 'semantic-ui-react'
import { DimmerLoader } from '../index'
import { DocumentUpload } from '../upload'
import WalletDecryptor from '../Wallet/WalletEncrypter'
import { signMessage, prettifyAddress } from '../../util/blockchain'
import { useModal } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import { useMe } from '../../util/hooks/me'
import {
  ApprovalType,
  AssetCodeNames,
  AssetCodes,
  IndividualAccreditationText,
  InstitutionalAccreditationText,
  UserType,
  BlockchainSignedMessageTags,
} from '../../util/Constant'
import {
  client,
  SUBMIT_NEW_CUSTOMER_DOCUMENT,
  VERIFY_NEW_CUSTOMER_DOCUMENT,
} from '../../util/API/Apollo'

const TxModal = ({ open, onClose, refetchQueries, onSubmit, action, form }) => {
  const [signing, setSigning] = useState(null)
  const [signedMessage, setSignedMessage] = useState(null)
  const [expiresAt, setExpiresAt] = useState(null)

  const [verify, verifyMutation] = useMutation(VERIFY_NEW_CUSTOMER_DOCUMENT, {
    variables: {
      provider_action_id: _.get(action, 'uuid'),
      input: {
        approval_type: ApprovalType.VERIFICATION_ACC,
        expires_at: moment(expiresAt).format('YYYY-MM-DD'),
        document: _.get(form.doc, 'uuid'),
        signed_message: signedMessage,
      },
    },
    onCompleted: onSubmit,
    refetchQueries,
    awaitRefetchQueries: true,
    client,
  })

  const handleVerify = async wallet => {
    setSigning(true)
    const investor_address = action.actionable.primary_wallet.address
    const document_hash = form.doc.hash
    const expires_at = new Date().getTime() + 12 * 30 * 24 * 60 * 60 * 1000

    // Message format: investor_address:1:document_hash
    const message = `${prettifyAddress(investor_address)}:${
      BlockchainSignedMessageTags.VERIFICATION_ACC
    }:${prettifyAddress(document_hash)}`

    const signedMessage = await signMessage(wallet, message)

    setExpiresAt(expires_at)
    setSignedMessage(signedMessage)
    setSigning(false)
    await verify()
  }

  const loading = signing || verifyMutation.loading

  return (
    <Modal open={open} onClose={onClose} dimmer="blurring" closeIcon>
      <Modal.Content>
        <WalletDecryptor onDecrypted={handleVerify} />
        <DimmerLoader active={loading} />
      </Modal.Content>
    </Modal>
  )
}

const institutionalOptions = {
  1: [AssetCodes.institutional_1_a, AssetCodes.institutional_1_b],
  2: [AssetCodes.institutional_2_a, AssetCodes.institutional_2_b],
  3: [AssetCodes.institutional_3_a, AssetCodes.institutional_3_b],
  4: [AssetCodes.institutional_4_a, AssetCodes.institutional_4_b],
  5: [AssetCodes.institutional_5_a, AssetCodes.institutional_5_b],
}
const individualOptions = {
  1: [AssetCodes.individual_1_a, AssetCodes.individual_1_b],
  2: [AssetCodes.individual_2_a, AssetCodes.individual_2_b],
  3: [AssetCodes.individual_3_a, AssetCodes.individual_3_b],
  4: [AssetCodes.individual_4_a, AssetCodes.individual_4_b],
}

const initialForm = {
  type: null, // options
  asset_code: null,
  doc: null,
}
const ACCVerificationForm = ({
  open,
  onClose,
  user,
  refetchQueries,
  onSubmit,
  action,
}) => {
  const { isCustomer, isTransferAgent } = useMe()
  const formHook = useForm('acc-verification', initialForm)
  const { form, input, clear } = formHook
  const txModal = useModal()

  const [optionType, setOptionType] = React.useState(null)

  // FOR CUSTOMER VERIFICATION REQUEST
  const [submit, submitMutation] = useMutation(SUBMIT_NEW_CUSTOMER_DOCUMENT, {
    variables: {
      input: {
        approval_type: ApprovalType.VERIFICATION_ACC,
        document: _.get(form.doc, 'uuid'),
      },
    },
    onCompleted: onSubmit,
    refetchQueries,
    awaitRefetchQueries: true,
    client,
  })

  const isIndividual = user.user_type === UserType.INDIVIDUAL
  const options = isIndividual ? individualOptions : institutionalOptions
  const optionText = isIndividual
    ? IndividualAccreditationText
    : InstitutionalAccreditationText

  const handleSubmit = async () => {
    if (isCustomer) {
      await submit()
    } else if (isTransferAgent) {
      txModal.show()
    }
  }

  const handleClose = () => {
    setOptionType(null)
    clear()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      as={Form}
      onSubmit={handleSubmit}
      closeIcon
      dimmer="blurring"
    >
      <TxModal
        {...txModal.props}
        onSubmit={onSubmit}
        refetchQueries={refetchQueries}
        action={action}
        form={form}
      />
      <Modal.Header content="ACCREDITATION" />
      <Modal.Content>
        <DimmerLoader active={submitMutation.loading} />
        <Segment color="black" padded="very">
          {optionType ? (
            <>
              <Form.Group widths="equal">
                {options[form.type].map(assetCode => (
                  <Form.Field key={assetCode}>
                    <Radio
                      label={AssetCodeNames[assetCode]}
                      {...input('asset_code', {
                        type: 'radio',
                        value: assetCode,
                      })}
                    />
                  </Form.Field>
                ))}
              </Form.Group>
              <Form.Field
                label="Upload Document"
                required
                control={DocumentUpload}
                {...input('doc')}
                asset_code={form.asset_code}
                disabled={!form.asset_code}
              />
            </>
          ) : (
            <>
              {Object.keys(options).map(index => (
                <Form.Field key={index}>
                  <Radio
                    label={optionText[index]}
                    {...input('type', {
                      type: 'radio',
                      value: index,
                    })}
                  />
                </Form.Field>
              ))}
            </>
          )}
        </Segment>
        {optionType ? (
          <>
            <Button
              fluid
              content="Upload"
              primary
              disabled={!(form.asset_code && form.doc)}
              type="submit"
              data-test-id="finish-acc-document"
            />
            <br />
            <Button
              fluid
              content="Back"
              type="button"
              onClick={() => setOptionType(null)}
            />
          </>
        ) : (
          <Button
            fluid
            content="Next"
            onClick={() => setOptionType(form.type)}
            primary
            disabled={!form.type}
            type="button"
            data-test-id="continue-acc-document"
          />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default ACCVerificationForm
