import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Modal, Header, Image, Label, Icon, Divider } from 'semantic-ui-react'
import { DimmerLoader } from '../../index'
import { UserTypeNames } from '../../../util/Constant'
import userPlaceholder from '../../../images/user-placeholder.png'
import link from '../../link'

const ProfileModal = ({ user, loading, open, onClose }) => {
  const company = _.get(user, 'company')

  return (
    <Modal
      size="small"
      dimmer="blurring"
      closeIcon
      closeOnEscape
      open={open}
      onClose={onClose}
    >
      <Modal.Content style={{ minHeight: '500px' }}>
        {loading ? (
          <DimmerLoader active />
        ) : (
          <>
            <Image
              rounded
              centered
              size="small"
              style={{ overflow: 'hidden' }}
              label={
                _.get(user, 'is_verified') && {
                  color: 'green',
                  corner: 'right',
                  icon: 'check',
                }
              }
              src={_.get(user, 'photo') || userPlaceholder}
            />
            <Header as="h2" textAlign="center">
              {_.get(user, 'fullname')}
              <br />
              <Label
                color="purple"
                className="uppercase"
                content={
                  UserTypeNames[
                    _.get(user, 'user_type') || _.get(user, 'provider_type')
                  ]
                }
              />
            </Header>
            <Divider hidden />
            <Header as="h4" content="Wallet address" />
            <p>
              <Icon name="ethereum" /> {_.get(user, 'wallet_address')}
            </p>
            <Divider />
            {company && (
              <>
                <Header as="h4" content="Company" />
                <Link to={link.company(company)} target="_blank">
                  {company.name}
                </Link>
                <Divider />
                <Header as="h4" content="Position" />
                <p>{_.get(user, 'position')}</p>
                <Divider />
              </>
            )}
            {_.get(user, 'broker_dealer') && (
              <>
                <Header as="h4" content="Broker dealer" />
                <p>{_.get(user, 'broker_dealer') || '-'}</p>
                <Divider />
              </>
            )}

            {_.get(user, 'financial_advisor') && (
              <>
                <Header as="h4" content="Financial advisor" />
                <p>{_.get(user, 'financial_advisor') || '-'}</p>
                <Divider />
              </>
            )}
            <Header as="h4" content="Transfer agent" />
            <p>{_.get(user, 'transfer_agent')}</p>
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default ProfileModal
