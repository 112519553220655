import React from 'react'
import { Image } from 'semantic-ui-react'
import userTypeInvestorImg from '../../../images/user_type_investor.svg'
import userTypeProviderImg from '../../../images/user_type_provider.svg'
import basicInfoImg from '../../../images/create_profile_alternative.svg'
import contactInfoImg from '../../../images/contact_info.svg'
import issuerInfoImg from '../../../images/issuer_info.svg'
import bankInfoImg from '../../../images/bank_info.svg'

export const AccountType = ({ isProvider }) => {
  return (
    <Image
      centered
      size="large"
      src={isProvider ? userTypeProviderImg : userTypeInvestorImg}
    />
  )
}
export const BasicInfo = () => {
  return <Image centered size="large" src={basicInfoImg} />
}
export const ContactInfo = () => {
  return <Image centered size="large" src={contactInfoImg} />
}
export const CompanyInfo = () => {
  return <Image centered size="large" src={issuerInfoImg} />
}
export const BankInfo = () => {
  return <Image centered size="large" src={bankInfoImg} />
}
