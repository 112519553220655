import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { Redirect } from 'react-router'
import { Card } from 'semantic-ui-react'
import CompanyForm from '../../components/Company/CompanyForm'
import { client, CREATE_COMPANY } from '../../util/API/Apollo'
import { CSBreadcrumb, SingleGrid } from '../../components'
import link from '../../components/link'

const CreateCompany = () => {
  const [createCompany, createCompanyMutation] = useMutation(CREATE_COMPANY, {
    client,
  })

  const createdCompany = _.get(createCompanyMutation, 'data.createCompany')

  if (createdCompany) {
    return <Redirect to={link.company(createdCompany)} />
  }

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Data', to: link.companies }]}
        title="Create Company"
      />
      <SingleGrid padded>
        <Card fluid>
          <Card.Content>
            <CompanyForm
              handleComplete={input => createCompany({ variables: { input } })}
              mutation={createCompanyMutation}
            />
          </Card.Content>
        </Card>
      </SingleGrid>
    </>
  )
}

export default CreateCompany
