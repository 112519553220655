import React from 'react'
import { Button, Form, Header, Input, Loader, Segment } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import CompanySearchInput from '../../../Company/CompanySearchInput'
import { client, GET_COMPANY } from '../../../../util/API/Apollo'
import CompanySummary from '../../../Company/CompanySummary'
import { AssetCodes } from '../../../../util/Constant'
import { DocumentUpload } from '../../../upload'
import { profileCompanySchema } from '../../schema'

const CompanyInfoStep = ({ formHook, stepsHook, onComplete }) => {
  const { field, form, validate } = formHook
  const handleSubmit = async () => {
    if (await validate(profileCompanySchema)) {
      if (stepsHook.isLastStep) {
        onComplete()
      } else {
        stepsHook.nextStep()
      }
    }
  }

  const { data, loading } = useQuery(GET_COMPANY, {
    variables: { uuid: _.get(form, 'company.uuid') },
    skip: !form.company,
    client,
  })

  const company = _.get(data, 'company')

  return (
    <>
      <Header as="h3" content="COMPANY INFO" />
      <Segment padded="very" color="black" inverted>
        <Header as="h4" content="SELECT OR ADD COMPANY" />
        <p>
          Please enter the first 2 letter of your company and select from the
          list. If your company does not appear on the list, please click on the
          add company button and fill out the company info.
        </p>
        <Form>
          <Form.Field control={CompanySearchInput} {...field('company')} />
        </Form>
        {loading && <Loader active />}
      </Segment>
      {company && (
        <>
          <CompanySummary company={company} />
          <p>
            Please upload a copy of the letter of authorization which states you
            are an authorized representative of your institution.
          </p>
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Field
              fluid
              label="Company position"
              placeholder="e.g. Investment manager"
              icon="pencil"
              iconPosition="left"
              required
              control={Input}
              {...field('company_position')}
            />
            <Form.Field
              label="Letter of authorization"
              asset_code={AssetCodes.loa}
              required
              control={DocumentUpload}
              {...field('letter_of_authorization')}
            />
            <Button
              primary
              content={stepsHook.isLastStep ? 'Finish' : 'Next'}
              type="button"
              floated="right"
              onClick={handleSubmit}
              data-test-id="profile-form-next"
            />
            <Button
              content="Back"
              type="button"
              floated="right"
              onClick={stepsHook.prevStep}
            />
          </Form>
          <br />
          <br />
        </>
      )}
    </>
  )
}

export default CompanyInfoStep
