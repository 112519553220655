import React from 'react'
import { Divider, Header } from 'semantic-ui-react'
import _ from 'lodash'
import { PhoneNumber } from '../index'
import { Email, WebsiteLink } from '../link'

const OptionalDetails = ({ contract }) => {
  return (
    <>
      {contract.details.phonenumber && (
        <>
          <Header as="h4" content="Phone number" />
          <PhoneNumber value={contract.details.phonenumber} />
          <Divider />
        </>
      )}
      {contract.details.email && (
        <>
          <Header as="h4" content="Email address" />
          <Email value={contract.details.email} />
          <Divider />
        </>
      )}
      {contract.details.url_web && (
        <>
          <Header as="h4" content="Web address" />
          <WebsiteLink href={contract.details.url_web} />
          <Divider />
        </>
      )}
      {_.get(contract, 'details.contact_people', []).map((person, i) => (
        <React.Fragment key={i}>
          <Header as="h4" content="Contact person" />
          <div>
            {person.firstname} {person.lastname}
          </div>
          <Divider />
          {person.phonenumber && (
            <>
              <Header as="h4" content="Contact person phone number" />
              <PhoneNumber value={person.phonenumber} />
              <Divider />
            </>
          )}
          {person.email && (
            <>
              <Header as="h4" content="Contact person email address" />
              <Email value={person.email} />
              <Divider />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default OptionalDetails
