import React from 'react'
import { Grid, Menu } from 'semantic-ui-react'
import { useTab } from '../util/hooks'
import { PopupIcon } from './index'

const Top = ({ children, itemProps, isActive, ...props }) => {
  const passedChildren = Array.isArray(children)
    ? children.filter(Boolean)
    : children
  const childrenWithProps = React.Children.map(passedChildren, child => {
    return React.cloneElement(child, { itemProps })
  })

  return (
    <Menu fluid secondary size="large" {...props}>
      {childrenWithProps}
    </Menu>
  )
}

const Left = ({ children, itemProps, isActive, ...props }) => {
  const passedChildren = Array.isArray(children)
    ? children.filter(Boolean)
    : children

  const childrenWithProps = React.Children.map(passedChildren, child => {
    return React.cloneElement(child, { itemProps })
  })

  return (
    <Grid.Column computer={4} tablet={16} {...props}>
      {childrenWithProps}
    </Grid.Column>
  )
}

const LeftMenu = ({ itemProps, children }) => {
  const passedChildren = Array.isArray(children)
    ? children.filter(Boolean)
    : children
  const childrenWithProps = React.Children.map(passedChildren, child =>
    React.cloneElement(child, { itemProps })
  )

  return (
    <Menu pointing secondary vertical fluid>
      {childrenWithProps}
    </Menu>
  )
}

const Item = ({ itemProps, name, info, content, ...props }) => {
  if (info) {
    const { children, ...rest } = props
    return (
      <Menu.Item {...itemProps(name)} {...rest}>
        {content}
        <PopupIcon
          style={{ float: 'none' }}
          name="info"
          content={info}
          className="ml-5"
        />
      </Menu.Item>
    )
  }
  return <Menu.Item {...itemProps(name)} {...props} />
}

const Right = ({ isActive, itemProps, children, ...props }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { isActive })
  )

  return (
    <Grid.Column computer={12} tablet={16} {...props}>
      {childrenWithProps}
    </Grid.Column>
  )
}

const Bottom = ({ isActive, itemProps, children, ...props }) => {
  return React.Children.map(children, child =>
    React.cloneElement(child, { isActive, ...props })
  )
}

const Pane = ({ isActive, name, children }) => {
  if (isActive(name)) return children
  return ''
}

const TabMenu = ({ defaultTab, children, top, ...props }) => {
  const { isActive, itemProps } = useTab(defaultTab)
  const passedChildren = Array.isArray(children)
    ? children.filter(Boolean)
    : children
  const childrenWithProps = React.Children.map(passedChildren, child =>
    React.cloneElement(child, { isActive, itemProps })
  )

  if (top) return childrenWithProps
  return (
    <Grid {...props}>
      <Grid.Row>{childrenWithProps}</Grid.Row>
    </Grid>
  )
}

TabMenu.Left = Left
TabMenu.Top = Top
TabMenu.Menu = LeftMenu
TabMenu.Item = Item
TabMenu.Right = Right
TabMenu.Bottom = Bottom
TabMenu.Pane = Pane

export default TabMenu
