import React from 'react'
import {
  Accordion,
  Button,
  Icon,
  Menu,
  Sidebar as SemanticSidebar,
  Popup,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import _ from 'lodash'
import { useMe } from '../../../util/hooks/me'
import { ProviderType } from '../../../util/Constant'
import { ReactComponent as STCLogo } from '../../../images/stc_logo.svg'
import { IfUserIs } from '../../index'
import items from './items'
import link from '../../link'

const SidebarItem = ({ item, path, disabled }) => {
  const { title, icon, to, subitems } = item

  const initialActive = React.useMemo(() => {
    if (path.startsWith(item.to)) return true
    return _.get(item, 'subitems', []).some(subitem =>
      path.startsWith(subitem.to)
    )
  }, [item, path])

  const [isActive, setActive] = React.useState(initialActive)
  const toggleMenu = () => {
    if (!disabled) setActive(!isActive)
  }

  // if nesting accordion menu
  if (subitems) {
    return (
      <Menu.Item className="collapsing" disabled={Boolean(disabled)}>
        <Accordion.Title onClick={toggleMenu}>
          <Icon name={icon} className="mr-15 ml-0" />
          <span style={{ flexGrow: 1 }}>{title}</span>
          <Icon
            floated="right"
            name="chevron down"
            className="m-0"
            style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
          />
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          {subitems.map(({ title, to }) => (
            <Menu.Item key={to} as={Link} to={to} active={path === to}>
              {title}
            </Menu.Item>
          ))}
        </Accordion.Content>
      </Menu.Item>
    )
  }
  const linkProps = disabled ? { disabled: true } : { as: Link, to }
  return (
    <Menu.Item {...linkProps} active={path === to} className="link">
      <Icon name={icon} className="mr-15 ml-0" />
      <span style={{ flexGrow: 1 }}>{title}</span>
    </Menu.Item>
  )
}

const SidebarMenu = ({ disabled, staticContext, ...props }) => {
  const { pathname: path } = useLocation()
  const me = useMe()
  const marketPlaceEnabled = _.get(me, 'providers', []).find(
    // disable marketplace if customer has no approved broker dealer
    p => p.provides === ProviderType.BROKER_DEALER
  )

  const [activePath, setActivePath] = React.useState(path)

  const passProps = { path, disabled, activePath, setActivePath }

  return (
    <SemanticSidebar animation="overlay" vertical as={Menu} {...props}>
      <Menu.Item className="logo" as={Link} to="/">
        <STCLogo fill="white" />
      </Menu.Item>
      <IfUserIs customer>
        <Menu.Item>
          <Popup
            inverted
            position="bottom center"
            size="tiny"
            content="If you are an Issuer or a Shareholder of a physical or book-entry Security, please click here to add your asset."
            trigger={
              <Button
                primary
                className="w-100p py-huge"
                as={Link}
                to={link.newContract}
                content="Add New Asset"
                disabled={disabled}
              />
            }
          />
          <br />
          <br />
          {me.is_verified && (
            <Button.Group fluid color="blue">
              <Button as={Link} to={link.marketplace} content="buy" />
              <Button as={Link} to={link.tokens} content="sell" />
            </Button.Group>
          )}
        </Menu.Item>

        <SidebarItem item={items.dashboard} {...passProps} />
        <SidebarItem item={items.myPortfolio} {...passProps} />
        <SidebarItem item={items.assetManager} {...passProps} />
        <SidebarItem
          item={items.marketplace}
          {...passProps}
          disabled={!marketPlaceEnabled || disabled}
        />
        <SidebarItem item={items.offers} {...passProps} />
        <SidebarItem item={items.trades} {...passProps} />
        <SidebarItem item={items.data} {...passProps} />
        <SidebarItem item={items.reports} {...passProps} />
        <SidebarItem item={items.mediaCenter} {...passProps} />
      </IfUserIs>

      <IfUserIs provider>
        <SidebarItem item={items.dashboard} {...passProps} />
        <IfUserIs bd>
          <SidebarItem item={items.bdActions} {...passProps} />
        </IfUserIs>
        <IfUserIs fa>
          <SidebarItem item={items.faActions} {...passProps} />
        </IfUserIs>
        <SidebarItem item={items.providerCustomers} {...passProps} />
        <SidebarItem item={items.providerMarketplace} {...passProps} />
        <SidebarItem item={items.data} {...passProps} />
        <SidebarItem item={items.message} {...passProps} />
        <SidebarItem item={items.mediaCenter} {...passProps} />
      </IfUserIs>

      <IfUserIs ta>
        <SidebarItem item={items.dashboard} {...passProps} />
        <SidebarItem item={items.taActions} {...passProps} />
        <SidebarItem item={items.accounts} {...passProps} />
        <SidebarItem item={items.portfoliosManager} {...passProps} />
        <SidebarItem item={items.marketplaceActivities} {...passProps} />
        <SidebarItem item={items.data} {...passProps} />
        <SidebarItem item={items.reports} {...passProps} />
        <SidebarItem item={items.mediaCenter} {...passProps} />
      </IfUserIs>
    </SemanticSidebar>
  )
}

const Sidebar = ({ mobile, disabled }) => {
  const [isOpen, setOpen] = React.useState(false)

  if (mobile) {
    return (
      <>
        <Icon
          name={isOpen ? 'chevron up' : 'chevron down'}
          className="toggle-button mobile-only"
          size="big"
          link
          onClick={() => setOpen(!isOpen)}
        />
        <SidebarMenu
          direction="top"
          visible={isOpen}
          className="mobile-only"
          disabled={disabled}
        />
      </>
    )
  }
  return (
    <SidebarMenu
      direction="left"
      className="desktop-only"
      visible
      disabled={disabled}
    />
  )
}

export default Sidebar
