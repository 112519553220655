import React from 'react'
import _ from 'lodash'
import { Redirect, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Card } from 'semantic-ui-react'
import { CSBreadcrumb, SingleGrid, PageLoader } from '../../components'
import CompanyForm from '../../components/Company/CompanyForm'
import { client, GET_COMPANY, UPDATE_COMPANY } from '../../util/API/Apollo'
import link from '../../components/link'

const EditCompany = () => {
  const { uuid } = useParams()
  const getCompanyQuery = useQuery(GET_COMPANY, {
    variables: { uuid },
    client,
  })

  const refetchQueries = [{ query: GET_COMPANY, variables: { uuid } }]

  const company = _.get(getCompanyQuery, 'data.company')

  const [updateCompany, updateCompanyMutation] = useMutation(UPDATE_COMPANY, {
    client,
    refetchQueries,
  })

  const savedCompany = _.get(updateCompanyMutation, 'data.updateCompany')

  if (savedCompany) {
    return <Redirect to={link.company(savedCompany)} />
  }

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Data', to: link.companies }]}
        title="Edit Company"
      />

      <SingleGrid padded>
        {getCompanyQuery.loading ? (
          <PageLoader />
        ) : (
          <Card fluid>
            <Card.Content>
              <CompanyForm
                handleComplete={input =>
                  updateCompany({ variables: { input, company_id: uuid } })
                }
                company={company}
                mutation={updateCompanyMutation}
              />
            </Card.Content>
          </Card>
        )}
      </SingleGrid>
    </>
  )
}

export default EditCompany
