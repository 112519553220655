import React from 'react'
import {
  Button,
  Header,
  Icon,
  Label,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import _ from 'lodash'
import { NumberFormat } from '../index'
import { MIN_TX_COST } from '../../util/Constant'
import { useTab } from '../../util/hooks'

/**
 * @param payment {{
 *  wallet: { eth, usdc },
 *  cost: { eth, usdc },
 *  totalCost: { eth, usdc },
 *  remain: { eth, usdc },
 *  price: { currency, amount }
 * }}
 * @param amount
 * @returns {Node}
 */
const FeesTable = ({ payment, amount }) => {
  const { price, cost } = payment
  return (
    <Table basic="very" fixed padded="very" size="small">
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Header as="h4" content="Price" />
          </Table.Cell>
          <Table.Cell>
            {_.get(price, 'currency') === 'USD' && (
              <Label color="black">
                <NumberFormat usd amount={amount} />
              </Label>
            )}
            {_.get(price, 'currency') === 'USDC' && (
              <Label color="black">
                <NumberFormat usdc amount={cost.usdc} /> /{' '}
                <NumberFormat usd amount={amount} />
              </Label>
            )}
            {_.get(price, 'currency') === 'ETH' && (
              <Label color="black">
                <NumberFormat eth amount={cost.eth} float digits={6} /> /{' '}
                <NumberFormat usd amount={amount} />
              </Label>
            )}
          </Table.Cell>
        </Table.Row>
        {_.get(price, 'currency') !== 'USD' && (
          <Table.Row>
            <Table.Cell>
              <Header as="h4" content="Estimated gas price" />
            </Table.Cell>
            <Table.Cell>
              <NumberFormat eth amount={MIN_TX_COST} float digits={6} />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

const StartStep = ({ onCompleted, formHook, payment, amount, currencies }) => {
  // ETH|USDC|USD
  const { itemProps, activeTab } = useTab(currencies[0])
  const { set, form } = formHook
  React.useEffect(() => {
    set('currency', activeTab)
  }, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

  const canPay =
    form.currency === 'USD' ||
    (payment.hasEnoughBalance.eth && payment.hasEnoughBalance.usdc)

  return (
    <>
      <Segment color="black" padded="very">
        <Header as="h2" content="Payment details" />
        <Button.Group fluid size="large" className="primary-group">
          {currencies.indexOf('ETH') >= 0 && (
            <Button
              icon="ethereum"
              content="PAY WITH ETH"
              data-test-id="pay-with-eth"
              {...itemProps('ETH')}
            />
          )}
          {currencies.indexOf('USDC') >= 0 && (
            <Button
              icon="usdc"
              content="PAY WITH USDC"
              data-test-id="pay-with-usdc"
              {...itemProps('USDC')}
            />
          )}
          {currencies.indexOf('USD') >= 0 && (
            <Button
              icon="dollar"
              content="PAY WITH USD"
              data-test-id="pay-with-usd"
              {...itemProps('USD')}
            />
          )}
        </Button.Group>
        <br />
        <FeesTable payment={payment} amount={amount} />
        <small>
          This is the Digitization Service Fee to create your Asset and list
          your Digital Securities in the Marketplace
        </small>

        {_.get(payment, 'price.currency') === 'ETH' && (
          <Message negative hidden={payment.hasEnoughBalance.eth}>
            <Icon name="exclamation triangle" /> Your wallet does not contain
            enough ETH to perform this operation. You must add{' '}
            <NumberFormat float eth amount={payment.required.eth} digits={6} />{' '}
            to your balance to continue.
          </Message>
        )}
        {_.get(payment, 'price.currency') === 'USDC' && (
          <Message negative hidden={payment.hasEnoughBalance.usdc}>
            <Icon name="exclamation triangle" /> Your wallet does not contain
            enough USDC to perform this operation. You must add{' '}
            <NumberFormat usdc amount={payment.required.usdc} /> to your balance
            to continue.
          </Message>
        )}
      </Segment>
      <Button
        primary
        fluid
        content="Next"
        onClick={onCompleted}
        disabled={!canPay}
        data-test-id="payment-next"
      />
    </>
  )
}

export default StartStep
