import React, { useState } from 'react'
import { Button, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react'
import { CopyInput, DimmerLoader } from '..'
import { NoData } from '../NotFound'
import { useModal } from '../../util/hooks'
import { FileLink } from '../link'

const DeleteFileModal = ({ file, handleRemove, open, onClose }) => {
  if (file) {
    return (
      <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
        <Modal.Header content="DELETE FILE" />
        <Modal.Content>
          <Table basic="very" padded="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="FILE" />
                <Table.HeaderCell content="TITLE" />
                <Table.HeaderCell content="DESCRIPTION" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <FileLink file={file} />
                </Table.Cell>
                <Table.Cell>
                  <p>{file.title}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{file.description || <>&mdash;</>}</p>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Button
            fluid
            size="small"
            color="red"
            content="Delete file"
            onClick={handleRemove}
          />
        </Modal.Content>
      </Modal>
    )
  }

  return null
}

const SharedFiles = ({ files, loading, remove, pagination }) => {
  const deleteFileModal = useModal()
  const [selectedForDelete, setSelectedForDelete] = useState(null)

  const handleRemove = () => {
    remove({ variables: { media_id: selectedForDelete.uuid } })
    setSelectedForDelete(null)
  }

  return (
    <Segment padded="very">
      <Header as="h3" content="SHARED FILES" />
      {files.length > 0 ? (
        <>
          <Table basic="very" padded="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="FILE" />
                <Table.HeaderCell content="TITLE" />
                <Table.HeaderCell content="DESCRIPTION" />
                <Table.HeaderCell content="LINK" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell collapsing>
                    <DimmerLoader />
                  </Table.Cell>
                </Table.Row>
              ) : (
                <>
                  {files.map(file => (
                    <Table.Row key={file.uuid}>
                      <Table.Cell>
                        <Icon name="file image" size="large" />
                        <FileLink file={file} />
                        <br />
                        <small>{file.size}</small>
                      </Table.Cell>
                      <Table.Cell>{file.title}</Table.Cell>
                      <Table.Cell>
                        {file.description || <>&mdash;</>}
                      </Table.Cell>
                      <Table.Cell>
                        <CopyInput value={file.link} />
                        <br />
                        <Button
                          fluid
                          size="tiny"
                          color="red"
                          content="Delete"
                          onClick={() => {
                            setSelectedForDelete(file)
                            deleteFileModal.show()
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">{pagination}</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <DeleteFileModal
            file={selectedForDelete}
            handleRemove={handleRemove}
            {...deleteFileModal.props}
          />
        </>
      ) : (
        <NoData text="No shared files yet" />
      )}
    </Segment>
  )
}

export default SharedFiles
