import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { CSBreadcrumb, PageLoader, SingleGrid } from '../../components'
import { ListingCommonDetails, ListingOffers } from '../../components/Listing'
import { MakeOfferAction } from '../../components/Offer'
import { AdviceModal } from '../../components/Advice'
import { SuccessModal } from '../../components/confirm'
import { NoData } from '../../components/NotFound'
import { useModal } from '../../util/hooks'
import { useMe } from '../../util/hooks/me'
import {
  client,
  GET_LISTING_DETAILS,
  GET_LISTING_OFFERS,
} from '../../util/API/Apollo'
import TabMenu from '../../components/TabMenu'
import { OfferDirection, OfferStatus } from '../../util/Constant'
import RemoveListingButton from '../../components/Listing/RemoveListingButton'
import {
  ContractTabMenuItems,
  ContractTabMenuPanes,
} from '../../components/Contract/util'
import link from '../../components/link'

const RemoveListingAction = ({ listing }) => {
  const removeSuccessModal = useModal()

  return (
    <>
      <RemoveListingButton
        listing={listing}
        onRemove={removeSuccessModal.show}
      />

      <SuccessModal
        content="Your listing has been removed."
        open={removeSuccessModal.isOpen}
        button={{ as: Link, to: link.myListings }}
      />
    </>
  )
}

const ListingDetails = () => {
  const { isOwner, isFinancialAdvisor, isCustomer } = useMe()
  const { uuid } = useParams()
  const adviceModal = useModal()

  const listingQuery = useQuery(GET_LISTING_DETAILS, {
    variables: { uuid },
    client,
  })
  const offersQuery = useQuery(GET_LISTING_OFFERS, {
    variables: { listing_id: uuid },
    client,
  })

  const listing = _.get(listingQuery, 'data.listing')
  const listingOffers = _.get(offersQuery, 'data.listingOffers.data', [])

  const incomingOffers = useMemo(
    () =>
      listingOffers.filter(
        ({ direction, status }) =>
          direction === OfferDirection.INCOMING &&
          status === OfferStatus.INITIALIZED
      ),
    [listingOffers]
  )

  const hasPendingOffer = useMemo(
    () =>
      listingOffers.find(
        ({ direction, status }) =>
          status === OfferStatus.INITIALIZED &&
          direction === OfferDirection.OUTGOING
      ),
    [listingOffers]
  )

  if (listingQuery.loading || offersQuery.loading) {
    return <PageLoader />
  }

  if (!listing) {
    return (
      <NoData text="LISTING NOT FOUND" fluid>
        <Button primary content="Go back" as={Link} to={link.marketplace} />
      </NoData>
    )
  }

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Marketplace', to: link.marketplace }]}
        title={listing.hashid}
      />

      <SingleGrid padded>
        <Segment padded="very">
          <TabMenu defaultTab="listing-details">
            <TabMenu.Left>
              <TabMenu.Menu>
                <TabMenu.Item name="listing-details" />
                {incomingOffers.length > 0 && isOwner({ listing }) && (
                  <TabMenu.Item name="incoming-offers" />
                )}
                {listingOffers.length > 0 && !isOwner({ listing }) && (
                  <TabMenu.Item name="offer-history" />
                )}
                <ContractTabMenuItems contract={listing.token.contract} />
              </TabMenu.Menu>
              {isOwner({ listing }) && (
                <RemoveListingAction listing={listing} />
              )}
              {!isOwner({ listing }) && isCustomer && (
                <MakeOfferAction
                  listing={listing}
                  disabled={hasPendingOffer}
                  previousOffers={listingOffers}
                />
              )}
              {isFinancialAdvisor && (
                <Button
                  primary
                  fluid
                  size="small"
                  content="Advice this list"
                  onClick={adviceModal.show}
                />
              )}
            </TabMenu.Left>
            <TabMenu.Right>
              <TabMenu.Pane name="listing-details">
                <ListingCommonDetails listing={listing} />
              </TabMenu.Pane>
              <TabMenu.Pane name="incoming-offers">
                <ListingOffers offers={incomingOffers} />
              </TabMenu.Pane>
              <TabMenu.Pane name="offer-history">
                <ListingOffers offers={listingOffers} />
              </TabMenu.Pane>
              <ContractTabMenuPanes contract={listing.token.contract} />
            </TabMenu.Right>
          </TabMenu>
        </Segment>
      </SingleGrid>

      {isFinancialAdvisor && (
        <AdviceModal
          listing={listing}
          refetch={listingQuery.refetch}
          {...adviceModal.props}
        />
      )}
    </>
  )
}

export default ListingDetails
