import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import {
  AgeInfo,
  DimmerLoader,
  TokenAmountInfo,
  TransferabilityIcon,
} from '../index'
import { NoData } from '../NotFound'
import { TokenStatus } from '../../util/Constant'
import { useMe } from '../../util/hooks/me'
import ClaimTokenButton from './ClaimTokenButton'
import link, { CompanyLink, ContractLink, DetailsButton } from '../link'

const TokensTable = ({ tokens, loading, footer }) => {
  const { isOwner } = useMe()

  if (!loading && tokens.length < 1) {
    return <NoData text="No result found" />
  }

  return (
    <Table basic="very" padded size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="DIGITAL SECURITIES ID" />
          <Table.HeaderCell content="UNDERLYING ASSET" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell content="AMOUNT" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell>
              <DimmerLoader />
            </Table.Cell>
          </Table.Row>
        ) : (
          <>
            {tokens.map(token => {
              const { hashid, contract, created_at } = token
              const isClaimable =
                isOwner({ token }) && token.status === TokenStatus.RESERVED

              return (
                <Table.Row key={hashid}>
                  <Table.Cell>
                    <TransferabilityIcon token={token} />
                    <Link to={link.token(token)}>{hashid}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    <ContractLink contract={contract} />
                  </Table.Cell>
                  <Table.Cell>
                    <CompanyLink company={contract.issuer} />
                  </Table.Cell>
                  <Table.Cell>
                    <TokenAmountInfo token={token} />
                  </Table.Cell>
                  <Table.Cell>
                    <AgeInfo iso str={created_at} />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {isClaimable ? (
                      <ClaimTokenButton token={token} />
                    ) : (
                      <DetailsButton token={token} />
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </>
        )}
      </Table.Body>
      {footer && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>{footer}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

export default TokensTable
