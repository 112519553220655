import React from 'react'
import { Button, Table } from 'semantic-ui-react'
import {
  ApprovalStatus,
  ApprovalType,
  AssetCodeNames,
  ProviderActionStatus,
} from '../../util/Constant'
import { ConfirmModal, SuccessModal } from '../../components/confirm'
import { useApproval, useModal } from '../../util/hooks'
import { PageLoader } from '../../components'
import TabMenu from '../../components/TabMenu'
import ProfileSummary from '../../components/Profile/ProfileSummary'
import ProfileCommonDetails from '../../components/Profile/ProfileCommonDetails'
import CompanySummary from '../../components/Company/CompanySummary'
import _ from 'lodash'
import ActionApprovalStatus from '../../components/Action/ActionApprovalStatus'
import { useMutation } from '@apollo/react-hooks'
import {
  APPROVE_PROVIDER_INFO,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'
import { FileLink } from '../../components/link'

const ApprovalTable = ({ action }) => {
  const verified = action.status === ProviderActionStatus.APPROVED
  const bdApproval = useApproval(
    action.approvals,
    ApprovalType.VERIFICATION_BROKER_DEALER
  )
  const faApproval = useApproval(
    action.approvals,
    ApprovalType.VERIFICATION_FINANCIAL_ADVISOR
  )
  const approval = bdApproval || faApproval

  const isComplete = approval.status !== ApprovalStatus.WAITING

  const successModal = useModal()
  const confirmModal = useModal()

  const [approve, { loading }] = useMutation(APPROVE_PROVIDER_INFO, {
    variables: { provider_action_id: action.uuid },
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: successModal.show,
    client,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} onConfirm={approve} />
      <PageLoader active={loading} />
      <SuccessModal {...successModal.props} header="Account approved" />
      <Table basic="very" padded="very" size="small" fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="TYPE" />
            <Table.HeaderCell content="CRD" />
            <Table.HeaderCell content="LINK" />
            <Table.HeaderCell content="DOCUMENT" />
            <Table.HeaderCell content="STATUS" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {
                AssetCodeNames[
                  _.get(approval, 'verification_data.document.asset_code')
                ]
              }
            </Table.Cell>
            <Table.Cell>
              {_.get(approval, 'verification_data.proof_crd')}
            </Table.Cell>
            <Table.Cell>
              {_.get(approval, 'verification_data.proof_link')}
            </Table.Cell>
            <Table.Cell>
              <FileLink file={_.get(approval, 'verification_data.document')} />
            </Table.Cell>
            <Table.Cell>
              <ActionApprovalStatus approval={approval} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              {!isComplete && (
                <Button
                  content="Approve"
                  fluid
                  color="green"
                  type="button"
                  onClick={confirmModal.show}
                  disabled={verified}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default function NewProviderActionDetails({ action }) {
  const user = action.actionable

  return (
    <>
      <TabMenu defaultTab="action-details">
        <TabMenu.Left>
          <TabMenu.Menu>
            <TabMenu.Item name="action-details" />
            <TabMenu.Item name="account-details" />
            <TabMenu.Item name="company-details" />
          </TabMenu.Menu>
        </TabMenu.Left>
        <TabMenu.Right>
          <ProfileSummary user={user} />
          <TabMenu.Pane name="action-details">
            <ApprovalTable action={action} />
          </TabMenu.Pane>
          <TabMenu.Pane name="account-details">
            <ProfileCommonDetails user={user} />
          </TabMenu.Pane>
          <TabMenu.Pane name="company-details">
            <CompanySummary company={user.profile.company} />
          </TabMenu.Pane>
        </TabMenu.Right>
      </TabMenu>
    </>
  )
}
