import React from 'react'
import { useHistory, useLocation } from 'react-router'
import ReactTestUtils from 'react-dom/test-utils'
import debug from 'debug'
import _ from 'lodash'
import util from './index'
import {
  AssetCodes,
  ContractType,
  FinancialDataType,
  UserType,
} from './Constant'
import link from '../components/link'

const log = debug('app:dev')

const SELLER_PK =
  '3cc105bc983d9a55c2ff06e1c118f8ecbac5ef0cef88f7f3b4bf1e53e094693f'
const BUYER_PK =
  '39470b9677a9c841d1cf3c10c5d89c0b563e817cda47f4de6bf6c5cb534603b7'
const TA_PK = 'b9bd6f3b6f1b5a7dbd3656208170d9dddd83edaa889bf6cf698ae2fd42f0b7b7'
// const BD_PK = '1d8506e075378cb2784a77a63022b206ee43a7259c53c60c92dd452884eba741'
const BD_PK = 'b9bd6f3b6f1b5a7dbd3656208170d9dddd83edaa889bf6cf698ae2fd42f0b7b7'

export const mockDoc = {
  uuid: '2c6b6002-023a-40ba-858a-3db19adbd207',
  asset_code: 'proof_of_payment',
  original_filename: 'example.pdf',
  hash: '9772f4d42c68fd30ef3ed95319a8e4e496bd23d11bab13b3a437a0395aa1290d',
  link:
    'https://creosafe-media.s3.us-east-2.amazonaws.com/2020-01-09/2c6b6002-0' +
    '23a-40ba-858a-3db19adbd207?response-content-type=application%2Fpdf&resp' +
    'onse-content-disposition=inline%3B%20filename%3D%222c6b6002-023a-40ba-8' +
    '58a-3db19adbd207%22&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorit' +
    'hm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZUEMOOTXJFFJ57AZ%2F20200109%2F' +
    'us-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200109T102714Z&X-Amz-SignedH' +
    'eaders=host&X-Amz-Expires=300&X-Amz-Signature=28d7026150ea22c4d132e1e6b' +
    '3fa884a270aa5c5043d79261331df8f2fc3b7b5',
  link_as_avatar: null,
}

class DevUtils {
  constructor({ history, location }) {
    this.history = history
    this.location = location
  }

  go = async url => {
    if (url === this.location.pathname) return
    this.history.push(url)
    await util.sleep(500)
  }

  elExists = (selector, options = {}) => {
    const parent = options.parent || document
    const el = parent.querySelector(selector)
    return Boolean(el)
  }

  el = async (selector, options = {}) => {
    const parent = options.parent || document
    let el = parent.querySelector(selector)

    if (el) return el
    while (!el) {
      log(`waiting for element '${selector}'`)
      await util.sleep(1000)
      el = parent.querySelector(selector)
    }
    return el
  }

  setInput = async (selector, text, options) => {
    const el = await this.el(selector, options)
    el.value = text
    ReactTestUtils.Simulate.change(el, { value: text })
    await util.sleep(250)
  }

  click = async (selector, options) => {
    const el = await this.el(selector, options)
    el.click()
    await util.sleep(250)
  }

  random = (length = 6) => {
    const min = Math.pow(10, length - 1)
    const max = Math.pow(10, length)
    return String(Math.round(Math.random() * (max - min) + min))
  }

  fillInputName = async (name, text, options) => {
    await this.setInput(`[name="${name}"]:not(.loading)`, text, options)
  }

  setForm = async (name, value) => {
    while (!_.get(window, `setForm.${name}`)) {
      log(`waiting for form '${name}'`)
      await util.sleep(1000)
    }

    window.setForm[name](value)
    await util.sleep(500)
  }

  submitForm = async (selector = 'form') => {
    await this.click(`${selector} [type=submit]`)
    await util.sleep(250)
  }

  waitLoading = async () => {
    while (document.querySelector('.dimmer.active > .content > .loader')) {
      log('waiting for loader elements')
      await util.sleep(500)
    }
  }

  register = async () => {
    await this.logout()
    await util.sleep(500)
    await this.click('#register-btn')

    const rand = this.random()
    const email = `test${rand}@creosafe.com`
    await this.setForm('register', {
      email,
      terms_agreement: true,
    })
    await this.submitForm()
    await this.click('.modal .actions button.green')
    log(`Test user ${rand} created, email: ${email}`)
    return email
  }

  activate = async token => {
    await this.go(link.activateToken(token))
    await this.setForm('activation', {
      password: '123qweQWE!@#',
      password_confirmation: '123qweQWE!@#',
    })
    await this.submitForm()
  }

  // registerFull = async () => {
  //   const email = await this.register()
  //   await this.go(link.login)
  //   await this.login(email)
  //   await this.waitLoading()
  //   await util.sleep(500)
  //   await this.createProfile()
  // }

  logout = async () => {
    if (this.elExists('.nav .user.icon')) {
      await this.click('.nav .user.icon') // click nav menu
      await this.click('.sign.out.icon') // click sign out
    }
  }

  login = async email => {
    await this.logout()
    await this.waitLoading()
    await this.setForm('login', {
      email,
      password: 'demo03',
    })
    await this.submitForm()
  }

  loginSeller = async () => await this.login('seller@creosafe.com')
  loginBuyer = async () => await this.login('buyer@creosafe.com')
  loginSally = async () => await this.login('sallyseller@creosafe.com')
  loginBrenda = async () => await this.login('brendabuyer@creosafe.com')
  loginTA = async () => await this.login('ta@creosafe.com')
  loginBD = async () => await this.login('bd@creosafe.com')

  fillSellerPK = async () => await this.fillInputName('privateKey', SELLER_PK)
  fillBuyerPK = async () => await this.fillInputName('privateKey', BUYER_PK)
  fillTAPK = async () => await this.fillInputName('privateKey', TA_PK)
  fillBDPK = async () => await this.fillInputName('privateKey', BD_PK)

  fillAccountDetails = async addCompany => {
    await this.setForm('profile', {
      firstname: 'Test',
      lastname: 'Testson',
      date_of_birth: '1993-12-31',
      phonenumber: '1234567890',
      tax_id: '121234567',
      address: 'Somewhere',
      state: 'Adana Province',
      city: 'Adana',
      zipcode: '12312',
      bank_info: {
        bank_name: 'Garanti BBVA',
        account_number: '11251241212412421',
        rtn_aba: '21531412',
        swift_bic: '31524312',
      },
    })
    if (addCompany) {
      await this.setForm('profile', {
        company_position: 'Developer',
        letter_of_authorization: mockDoc,
      })
    }
  }

  fillAddNewCustomerDetails = async () => {
    const rand = this.random()
    await this.setForm('new-customer', {
      firstname: 'Test',
      lastname: rand,
      email: `test${rand}@creosafe.com`,
      date_of_birth: '1993-12-31',
      phonenumber: '1234567890',
      tax_id: '121234567',
      address: 'Somewhere',
      state: 'Adana Province',
      city: 'Adana',
      zipcode: '12312',
      // company: null,
      // company_position: '',
      // letter_of_authorization: null,
    })
  }

  fillAddNewProviderDetails = async () => {
    const rand = this.random()
    await this.setForm('new-provider', {
      firstname: 'Test',
      lastname: rand,
      email: `test${rand}@creosafe.com`,
      date_of_birth: '1993-12-31',
      phonenumber: '1234567890',
      tax_id: '121234567',
      address: 'Somewhere',
      state: 'Adana Province',
      city: 'Adana',
      zipcode: '12312',
      // company: null,
      // company_position: '',
      // letter_of_authorization: null,
    })
  }

  createProfile = async (addCompany = false) => {
    await this.fillAccountDetails(addCompany)
    await this.click('[data-test-id=profile-form-next]')
    await this.click('[data-test-id=profile-form-next]')
    if (addCompany) {
      await this.selectCompany()
      await this.click('[data-test-id=profile-form-next]')
    }
    await this.click('[data-test-id=profile-form-next]')
    await this.waitLoading()
    await this.createWallet()
  }
  createIndividual = async () => {
    await this.click(`[data-test-id=select-user-type-${UserType.INDIVIDUAL}]`)
    await this.createProfile(false)
  }
  createInstitutional = async () => {
    await this.click(
      `[data-test-id=select-user-type-${UserType.INSTITUTIONAL}]`
    )
    await this.createProfile(true)
  }
  createProviderProfile = async () => {
    await this.createProfile(true)
  }

  createWallet = async () => {
    await this.click('[data-test-id=create-wallet-start]')
    await this.click('[data-test-id=create-wallet-next]')
    await this.click('[data-test-id=create-wallet-next]')
    await this.setForm('create-wallet', {
      password: '123123123',
      passwordConfirm: '123123123',
      walletName: 'Test Wallet',
    })
    await this.click('[data-test-id=create-wallet-next]')
    await util.sleep(5000)
    await this.click('[data-test-id=create-wallet-download-json]')
    const inputEl = await this.el('[data-test-id=create-wallet-pk]')
    console.log(inputEl.value)
    await this.click('[data-test-id=create-wallet-finish')
  }

  submitKycVerification = async () => {
    await this.click('[data-test-id=start-kyc-document]')
    await util.sleep(500)
    await this.setForm('kyc-verification', {
      doc: mockDoc,
      asset_code: AssetCodes.passport,
    })
    await this.submitForm()
    await this.click('[data-test-id=complete-kyc-document]')
  }
  verifyKyc = async () => {
    await this.click('[data-test-id=start-kyc-verification]')
    await this.setForm('kyc-verification', {
      doc: mockDoc,
      asset_code: AssetCodes.passport,
    })
    await this.submitForm()
    await this.fillInputName('privateKey', TA_PK)
    await this.waitLoading()
    await this.click('[data-test-id=finish-kyc-document]')
  }
  submitAccVerification = async () => {
    await this.click('[data-test-id=start-acc-document]')
    await this.setForm('acc-verification', {
      type: 2,
      doc: mockDoc,
      asset_code: AssetCodes.individual_2_b,
    })
    await this.click('[data-test-id=continue-acc-document]')
    await this.click('[data-test-id=finish-acc-document]')
  }
  verifyAcc = async () => {
    await this.click('[data-test-id=start-acc-verification]')
    await this.setForm('acc-verification', {
      type: 2,
      doc: mockDoc,
      asset_code: AssetCodes.individual_2_b,
    })
    await this.click('[data-test-id=continue-acc-document]')
    await this.fillInputName('privateKey', TA_PK)
    await this.click('[data-test-id=finish-acc-document]')
  }
  verifyNewCustomer = async () => {
    await this.verifyKyc()
    await this.waitLoading()
    await this.verifyAcc()
  }
  requestNewCustomerDocs = async () => {
    await this.click('#kyc-verification-card .extra.content button.pink')
    await this.click('.modal .actions button.green')
    await this.waitLoading()
    await this.click('#acc-verification-card .extra.content button.pink')
    await this.click('.modal .actions button.green')
    await this.waitLoading()
  }
  submitNewCustomerDocs = async () => {
    await this.go(link.profile)
    await this.submitKycVerification()
    await this.waitLoading()
    await this.submitAccVerification()
  }
  submitMSGDocs = async () => {
    await this.click('[data-test-id=start-msg-document]')
    await this.setForm('msg-verification', {
      doc: mockDoc,
      asset_code: AssetCodes.generic,
    })
    await this.click('[data-test-id=finish-msg-document]')
  }
  submitProviderDocs = async () => {
    await this.click('[data-test-id=start-provider-document]')
    await this.setForm('provider-verification', {
      doc: mockDoc,
      asset_code: AssetCodes.provider_proof_individual,
      link: 'google.com',
      crd: '123456',
    })
    await this.click('[data-test-id=finish-provider-document]')
  }
  verifyContract = async () => {
    await this.click('[data-test-id=start-contract-verification]')
    await this.setForm('contract-verification', {
      rofr: false,
      restriction: false,
      transferability_warning: false,
      asset_code: AssetCodes.legal_opinion_letter,
      doc: mockDoc,
      amount: '1000000',
    })
    await this.click('[data-test-id=finish-contract-verification]')
    await this.waitLoading()
    await this.fillTAPK()
    await this.click('[data-test-id=continue-decrypt-wallet]')
  }

  addBalance = async () => {
    await this.click('#add-balance-btn')
    await this.setForm('add-balance', { depositAmount: '0.00000000000000001' })
    await this.click('#add-balance-modal .actions button.primary') // continue
    await this.fillSellerPK()
    await this.waitLoading()
    await this.click(
      '#add-balance-modal .actions button.positive:not(.disabled)'
    ) // continue
    await this.click(
      '#add-balance-modal .actions button.positive:not(.disabled)'
    ) // continue
  }

  addUsdcBalance = async () => {
    await this.click('#add-balance-btn')
    await this.setForm('add-balance', {
      selectedCurrency: 'USDC',
      depositAmount: '1.003',
    })
    await this.click('#add-balance-modal .actions button.primary') // continue
    await this.setForm('add-balance', { privateKey: SELLER_PK })
    await this.click('#add-balance-modal .actions button.positive') // continue
    await this.click('#add-balance-modal .actions button.positive') // continue
  }

  fillInvitationForm = async () => {
    const rand = this.random()
    await this.setForm('provider-invitation', {
      firstname: 'Invited Test',
      lastname: rand,
      email: `test${rand}@creosafe.com`,
      phonenumber: '1234567890',
      company: `ACME ${rand}`,
    })
    await this.submitForm()
  }

  selectCompany = async () => {
    await this.fillInputName('company', 'acme') // search company
    await this.click('.search.dropdown:not(.loading) .menu .item:first-child') // click search result
    await util.sleep(250)
  }

  addShareholder = async defaultEmail => {
    await this.click('[data-test-id=shareholders-tab]') // select tab
    await this.click('[data-test-id=shareholders-invitation-tab]') // select tab
    await this.click('[data-test-id=create-invitations]') // open modal
    await this.click('[data-test-id=manual-shareholder-tab]') // select tab in modal

    let rand = this.random(4)
    await this.setForm('shareholder', {
      firstname: 'Shareholder',
      lastname: rand,
      phonenumber: '1231231231',
      email: defaultEmail || `shareholder${rand}@creosafe.com`,
      share_amount: String(rand),
    })
    await this.submitForm('.modal .form')
    await util.sleep(500)
  }
  addSellerShareholder = async () => {
    await this.addShareholder('seller@creosafe.com')
  }
  //
  // importShareholder = async () => {
  //   await this.click('.pointing.vertical.menu .item:nth-child(2)') // select shareholder tab
  //   await this.click('button.primary .icon.plus') // open modal
  //   await this.click('.modal .grid .column:nth-child(2) button.primary') // import csv
  // }

  payDeploy = async () => {
    await this.waitLoading()
    await this.click('button[data-test-id=convert-to-smart-contract]')
    await this.pay()
  }

  fillSafeDetails = async () => {
    const rand = this.random()
    await this.setForm('tokenization', {
      agreement_date: '2000-12-31',
      details: { purchase_amount: String(rand), terms_agreement: true },
    })
    await this.click(`button[data-test-id=tokenize-next]`)
    await this.waitLoading()
  }
  fillFofDetails = async () => {
    const rand = this.random()
    await this.setForm('tokenization', {
      agreement_date: '2000-12-31',
      details: {
        fund_name: `Fund ${rand}`,
        purchase_amount: String(rand),
        terms_agreement: true,
        share_type: 'RPS',
        nominal_value_per_share: '2',
      },
    })
    await this.click(`button[data-test-id=tokenize-next]`)
    await this.waitLoading()
  }
  fillContractOptionalDetails = async () => {
    const rand = this.random(6)
    await this.click(`button[data-test-id=start-add-optional-details]`)
    await this.setForm('contract-optional-details', {
      phonenumber: '1234567890',
      email: `test${rand}@creosafe.com`,
      url_web: 'google.com',
      contact_firstname: 'Test',
      contact_lastname: rand,
      contact_email: `test${rand}@creosafe.com`,
      contact_phonenumber: '1234567890',
    })
    await this.click(`button[data-test-id=finish-add-optional-details]`)
  }
  fillContractErpDetails = async () => {
    await this.click(`button[data-test-id=start-add-erp-details]`)
    await this.setForm('contract-erp-details', {
      lockup_period: 'Period',
      early_redemption_from_date: '2001-12-31',
      early_redemption_to_date: '2002-12-31',
      early_redemption_penalty: '12',
    })
    await this.click(`button[data-test-id=finish-add-erp-details]`)
  }
  fillContractStrategyDetails = async () => {
    const rand = this.random(6)
    await this.click(`button[data-test-id=start-add-optional-details]`)
    await this.setForm('contract-optional-details', {
      phonenumber: '1234567890',
      email: `test${rand}@creosafe.com`,
      url_web: 'google.com',
      contact_firstname: 'Test',
      contact_lastname: rand,
      contact_email: `test${rand}@creosafe.com`,
      contact_phonenumber: '1234567890',
    })
    await this.click(`button[data-test-id=finish-add-optional-details]`)
  }
  fillLpDetails = async () => {
    const rand = this.random()
    await this.fillContractOptionalDetails()
    await this.fillContractErpDetails()
    await this.fillContractStrategyDetails()
    await this.setForm('tokenization', {
      agreement_date: '2000-12-31',
      details: {
        purchase_amount: String(rand),
        share_type: 'RPS',
        nominal_value_per_share: '2',
      },
    })
  }
  addSafeDocs = async () => {
    await this.setForm('tokenization', {
      documents: {
        underlying_security: mockDoc,
        creosafe_agreement_document: mockDoc,
      },
    })
    await this.click(`button[data-test-id=tokenize-next]`)
    await this.waitLoading()
  }
  addFofDocs = async () => {
    await this.setForm('tokenization', {
      documents: {
        underlying_security: mockDoc,
        creosafe_agreement_document: mockDoc,
        ppm: mockDoc,
      },
    })
    await this.click(`button[data-test-id=tokenize-next]`)
    await this.waitLoading()
  }
  addLpDocs = async () => {
    await this.setForm('tokenization', {
      documents: {
        underlying_security: mockDoc,
        audit_report: mockDoc,
        subscription_document: mockDoc,
      },
    })
    await this.click(`button[data-test-id=tokenize-next]`)
    await this.waitLoading()
  }
  createSafe = async () => {
    await this.click(
      `button[data-test-id=select-contract-type-${ContractType.SAFE}]`
    )
    await this.selectCompany()
    await this.click('button[type=submit]')
    await this.fillSafeDetails()
    await this.addSafeDocs()
    await this.payDeploy()
  }
  createLP = async () => {
    await this.click(
      `button[data-test-id=select-contract-type-${ContractType.VC}]`
    )
    await this.selectCompany()
    await this.click('button[type=submit]')
    await this.fillLpDetails()
    await this.addLpDocs()
    await this.payDeploy()
  }
  createFof = async () => {
    await this.click(
      `button[data-test-id=select-contract-type-${ContractType.FOF}]`
    )
    await this.selectCompany()
    await this.click('button[type=submit]')
    await this.fillFofDetails()
    await this.click(`button[data-test-id=tokenize-next]`) // financials
    await this.addFofDocs()
    await this.payDeploy()
  }

  pay = async () => {
    await this.waitLoading()
    await this.click('button[data-test-id=pay-with-usdc]')
    await this.click('button[data-test-id=payment-next]')
    await this.waitLoading()
    await this.fillSellerPK()
    await this.waitLoading()
    await this.click('button[data-test-id=continue-decrypt-wallet]')
  }

  createToken = async () => {
    const amount = this.random(3)
    await this.click('button[data-test-id=start-create-token]')
    await this.setForm('tokenization', { amount: String(amount) })
    await this.click('button[data-test-id=finish-create-token]')
    await this.pay()
  }

  sellTokens = async () => {
    const amount = this.random(2)
    const price = this.random(2)
    await this.click('button[data-test-id=start-sell-tokens]')
    await this.setForm('sell-tokens', {
      amount: String(amount),
      price: String(price),
    })
    await this.click('button[data-test-id=finish-sell-tokens]')
  }

  addFinancialData = async form => {
    await this.click('button[data-test-id=add-financial-data]')
    await this.setForm('financial-data', form)
    await this.click('.modal .button.primary')
  }
  addFinancialDocument = async form => {
    await this.click('button[data-test-id=add-financial-document]')
    await this.setForm('financial-document', form)
    await this.click('.modal .button.primary')
  }
  addFinancials = async () => {
    await this.addFinancialDocument({
      title: 'Doc 1',
      description: 'Doc 1 Doc 1 Doc 1 Doc 1 Doc 1 Doc 1 Doc 1 Doc 1 Doc 1 ',
      document_file: mockDoc,
    })
    await this.addFinancialDocument({
      title: 'Doc 2',
      document_file: mockDoc,
    })
    await this.addFinancialData({
      data_type: FinancialDataType.SALES_REVENUE,
      year: '2019',
      quarter: '1',
      data: '250000',
      description: 'Description 1',
    })
    await this.addFinancialData({
      data_type: FinancialDataType.SALES_REVENUE,
      year: '2018',
      quarter: '4',
      data: '150000',
    })
    await this.addFinancialData({
      data_type: FinancialDataType.SALES_REVENUE,
      year: '2018',
      quarter: '3',
      data: '100000',
    })
    await this.addFinancialData({
      data_type: FinancialDataType.TOTAL_ASSETS,
      year: '2019',
      quarter: '1',
      data: '125000',
      description: 'Description 2',
    })
    await this.addFinancialData({
      data_type: FinancialDataType.NET_PROFIT,
      year: '2019',
      quarter: '1',
      data: '50000',
      description:
        'Description Description Description Description Description' +
        ' Description Description Description Description',
    })
  }

  // // @TODO approvals
  // tokenizeAndTrade = async () => {
  //   // SELLER tokenizes
  //   await this.tokenizeContract()
  //   await this.waitLoading()
  //   // SELLER sells
  //   await this.sellTokens()
  //   await this.waitLoading()
  //   const lastListingURL = window.location.pathname
  //   // BUYER offers
  //   await this.loginBuyer()
  //   await this.waitLoading()
  //   await this.go(lastListingURL)
  //   await this.waitLoading()
  //   await this.click('button.pink .icon.cart')
  //   await this.click('.modal .actions button.green')
  //   await this.waitLoading()
  //   // SELLER accepts
  //   await this.loginSeller()
  //   await this.waitLoading()
  //   await this.go(link.mySalesOffers)
  //   await this.waitLoading()
  //   await this.click('a.primary .icon.arrow.right') // first offer details @TODO should be recent first
  //   await this.waitLoading()
  //   await this.click('button.green .icon.check') // first offer details
  //   await this.click('.modal .actions button.green')
  // }

  createCompany = async () => {
    const rand = this.random()
    await this.setForm('create-company', {
      name: `ACME ${rand}`,
      founded_date: '1999-12-31',
      tax_id: '123456789',
      headquarter: 'Headquarter',
      sectors: ['computer-software', 'tobacco', 'wireless', 'writing-editing'],
      email: `acme${rand}@creosafe.com`,
      phonenumber: '1234567890',
      address: 'Somewhere',
      state: 'Adana Province',
      city: 'Adana',
      zipcode: '12312',
      homepage: 'google.com',
    })
    await this.click('button[data-test-id=company-form-next]') // step 1
    await this.click('button[data-test-id=company-form-next]') // step 2
    await this.addFinancials()
    await this.click('button[data-test-id=company-form-finish]') // step 3
  }

  searchNav = async () => {
    const input = await this.el('[data-test-id=nav-search] input')
    input.focus()
    await this.setInput('[data-test-id=nav-search] input', 'se')
  }

  search = async () => {
    await this.go(link.searchHome)
    await this.setInput('[data-test-id=search-input] input', 'se')
  }
}

const Dev = () => {
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    localStorage.debug = 'app:*'
    window.dev = new DevUtils({ history, location })
  }, [history, location])
  return null
}

export default Dev
