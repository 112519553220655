import React, { useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { PageLoader, SingleGrid, CSBreadcrumb } from '../components'
import { useModal } from '../util/hooks'
import useForm from '../util/hooks/form'
import { useSteps } from '../util/hooks/steps'
import { ADD_NEW_CUSTOMER, client } from '../util/API/Apollo'
import { SuccessModal } from '../components/confirm'
import {
  getAddNewCustomerSteps,
  getEmptyNewCustomerForm,
  mapFormToInput,
} from '../components/Profile/ProfileForm/util'
import ProfileForm from '../components/Profile/ProfileForm/ProfileForm'
import link from '../components/link'

const initialForm = getEmptyNewCustomerForm()
const AddNewCustomer = () => {
  const formHook = useForm('new-customer', initialForm)
  const { form } = formHook
  const steps = useMemo(() => getAddNewCustomerSteps(form.user_type), [
    form.user_type,
  ])
  const stepsHook = useSteps(steps.map(s => s.key), 'type')

  const successModal = useModal()
  const [addNewCustomer, { loading }] = useMutation(ADD_NEW_CUSTOMER, {
    variables: { input: form },
    onCompleted: () => {
      successModal.show()
    },
    client,
  })

  const handleSubmit = async () => {
    const input = mapFormToInput(form, { isCustomer: true })
    await addNewCustomer({ variables: { input } })
  }

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Customers', to: link.customers }]}
        title="Add new customer"
      />
      <SingleGrid padded>
        <PageLoader active={loading} />
        <SuccessModal
          content="An invitation link has been sent to your customer"
          button={{ as: Link, to: link.customers }}
          {...successModal.props}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ProfileForm
                formHook={formHook}
                stepsHook={stepsHook}
                onComplete={handleSubmit}
                isCustomer
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SingleGrid>
    </>
  )
}

export default AddNewCustomer
