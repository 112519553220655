export const UserType = {
  DRAFT: 'DRAFT',
  // customer
  INDIVIDUAL: 'INDIVIDUAL',
  INSTITUTIONAL: 'INSTITUTIONAL',
  EMPLOYEE: 'EMPLOYEE',
  // provider/partner
  BROKER_DEALER: 'BROKER_DEALER',
  TRANSFER_AGENT: 'TRANSFER_AGENT',
  // ISSUER: 'ISSUER',
  // INSTITUTIONAL_OFFICIAL: 'INSTITUTIONAL_OFFICIAL',
  // ISSUER_OFFICIAL: 'ISSUER_OFFICIAL',
  FINANCIAL_ADVISOR: 'FINANCIAL_ADVISOR',
  // PROVIDER: 'PROVIDER'
}

export const UserTypeNames = {
  [UserType.DRAFT]: 'Draft',
  [UserType.INDIVIDUAL]: 'Individual Investor',
  [UserType.INSTITUTIONAL]: 'Institutional Investor',
  [UserType.EMPLOYEE]: 'Employee',
  [UserType.FINANCIAL_ADVISOR]: 'Financial Advisor',
  [UserType.BROKER_DEALER]: 'Broker Dealer',
  [UserType.TRANSFER_AGENT]: 'Transfer Agent',
}

export const UserRegistrationStatus = {
  NOT_FOUND: 'NOT_FOUND',
  ACTIVATED: 'ACTIVATED',
  NOT_ACTIVATED: 'NOT_ACTIVATED',
}

export const ApprovalType = {
  VERIFICATION_KYC: 'VERIFICATION_KYC',
  VERIFICATION_ACC: 'VERIFICATION_ACC',
  VERIFICATION_MSG: 'VERIFICATION_MSG',
  VERIFICATION_COMPANY: 'VERIFICATION_COMPANY',
  VERIFICATION_BROKER_DEALER: 'VERIFICATION_BROKER_DEALER',
  VERIFICATION_FINANCIAL_ADVISOR: 'VERIFICATION_FINANCIAL_ADVISOR',
  TRANSFERABILITY: 'TRANSFERABILITY',
  TRADE_PAYMENT: 'TRADE_PAYMENT',
}

export const ApprovalStatus = {
  INITIALIZED: 'INITIALIZED',
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  REMOVED: 'REMOVED',
}

export const ContractStatus = {
  DRAFT: 'DRAFT',
  DEPLOYED: 'DEPLOYED',
  PAID: 'PAID',
}

export const ContractType = {
  SAFE: 'SAFE',
  EQUITY: 'EQUITY',
  VC: 'LP_SHARES',
  CONVERTIBLE_NOTE: 'CONVERTIBLE_NOTE',
  FOF: 'FUND_OF_FUNDS',
}
export const ContractTypeNames = {
  [ContractType.SAFE]: 'Safe',
  [ContractType.CONVERTIBLE_NOTE]: 'Convertible Note',
  [ContractType.EQUITY]: 'EQUITY',
  [ContractType.VC]: 'LP Shares',
  [ContractType.FOF]: 'Fund of Funds',
}

export const TokenStatus = {
  AVAILABLE: 'AVAILABLE',
  LOCKED: 'LOCKED',
  FROZEN: 'FROZEN',
  PENDING: 'PENDING',
  RESERVED: 'RESERVED',
}

export const ProviderType = {
  TRANSFER_AGENT: 'TRANSFER_AGENT',
  BROKER_DEALER: 'BROKER_DEALER',
  KYC_AML_VERIFIER: 'KYC_AML_VERIFIER',
  ACCREDITOR: 'ACCREDITOR',
  MSG: 'MSG',
  FINANCIAL_ADVISOR: 'FINANCIAL_ADVISOR',
  LEGAL_ADVISOR: 'LEGAL_ADVISOR',
}

export const ProviderActionType = {
  CUSTOMER_APPROVAL: 'CUSTOMER_APPROVAL',
  CUSTOMER_KYC: 'CUSTOMER_KYC',
  CUSTOMER_ACC: 'CUSTOMER_ACC',
  CUSTOMER_MSG: 'CUSTOMER_MSG',
  PROVIDER_APPROVAL: 'PROVIDER_APPROVAL',
  ASSET_TRANSFERABILITY: 'ASSET_TRANSFERABILITY',
  COMPANY_VERIFICATION: 'COMPANY_VERIFICATION',
  TRADE_APPROVAL: 'TRADE_APPROVAL',
  PROVIDER_REQUEST: 'PROVIDER_REQUEST',
}

export const ProviderActionStatus = {
  APPROVED: 'APPROVED',
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  INITIALIZED: 'INITIALIZED',
  REJECTED: 'REJECTED',
  REMOVED: 'REMOVED',
}

export const ActionTypeByState = {
  account: ProviderActionType.CUSTOMER_APPROVAL,
  medallion: ProviderActionType.CUSTOMER_MSG,
  assets: ProviderActionType.ASSET_TRANSFERABILITY,
  providerApproval: ProviderActionType.PROVIDER_APPROVAL,
  providerRequest: ProviderActionType.PROVIDER_REQUEST,
  company: ProviderActionType.COMPANY_VERIFICATION,
  trade: ProviderActionType.TRADE_APPROVAL,
}

export const ActionRouteTypeMap = {
  '/actions/account': ProviderActionType.CUSTOMER_APPROVAL,
  '/actions/medallion-signature': ProviderActionType.CUSTOMER_MSG,
  '/actions/asset': ProviderActionType.ASSET_TRANSFERABILITY,
  '/actions/provider': ProviderActionType.PROVIDER_APPROVAL,
  '/actions/company': ProviderActionType.COMPANY_VERIFICATION,
  '/actions/trade': ProviderActionType.TRADE_APPROVAL,
  '/actions/provider-request': ProviderActionType.PROVIDER_REQUEST,
}

export const ActionTypeRouteMap = {
  [ProviderActionType.CUSTOMER_APPROVAL]: '/actions/account',
  [ProviderActionType.CUSTOMER_MSG]: '/actions/medallion-signature',
  [ProviderActionType.ASSET_TRANSFERABILITY]: '/actions/asset',
  [ProviderActionType.PROVIDER_APPROVAL]: '/actions/provider',
  [ProviderActionType.ASSET_TRANSFERABILITY]: '/actions/company',
  [ProviderActionType.TRADE_APPROVAL]: '/actions/trade',
  [ProviderActionType.PROVIDER_REQUEST]: '/actions/provider-request',
}

export const CompanyType = {
  INSTITUTION: 'INSTITUTION',
  ISSUER: 'ISSUER',
  PROVIDER: 'PROVIDER',
}

export const InvitationStatus = {
  INITIALIZED: 'INITIALIZED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
}

export const CompanyStatus = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  VERIFIED: 'VERIFIED',
  LOCKED: 'LOCKED',
}

export const OfferStatus = {
  INITIALIZED: 'INITIALIZED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  REMOVED: 'REMOVED',
  BLOCKED: 'BLOCKED',
  EXPIRED: 'EXPIRED',
  HIDDEN: 'HIDDEN',
}

export const OfferDirection = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
}

export const TxStatus = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  VERIFIED: 'VERIFIED',
}

export const SignatureStatus = {
  WAITING: 'WAITING',
  SIGNED: 'SIGNED',
  VERIFIED: 'VERIFIED',
}

export const PaymentStatus = {
  WAITING: 'WAITING',
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
}

export const SignatureType = {
  SELLER_TRADE: 'SELLER_TRADE',
  BUYER_TRADE: 'BUYER_TRADE',
  BROKER_DEALER_TRADE: 'BROKER_DEALER_TRADE',
  PROVIDER_TRANSFERABILITY: 'PROVIDER_TRANSFERABILITY',
  PROVIDER_KYC_AML: 'PROVIDER_KYC_AML',
  PROVIDER_ACCREDITATION: 'PROVIDER_ACCREDITATION',
  PROVIDER_MSG: 'PROVIDER_MSG',
}

export const TradeActors = {
  SELLER: 'SELLER',
  BUYER: 'BUYER',
  TRANSFER_AGENT: 'TRANSFER_AGENT',
  BROKER_DEALER: 'BROKER_DEALER',
}

export const Transferability = {
  NOT_ALLOWED: 'NOT_ALLOWED',
  LIMITED: 'LIMITED',
  FULL: 'FULL',
}

export const TradeStatus = {
  INITIALIZED: 'INITIALIZED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  REMOVED: 'REMOVED',
  COMPLETED: 'COMPLETED',
}

export const BlockchainActions = {
  SERVICE_PAYMENT: 'SERVICE_PAYMENT',
  TOKEN_PAYMENT: 'TOKEN_PAYMENT',
}

export const BlockchainSignedMessageTags = {
  VERIFICATION_KYC: '01',
  VERIFICATION_ACC: '02',
  TRANSFERABLE: '02',
}

export const EventType = {
  TRANSFER: 'TRANSFER',
  INFO: 'INFO',
  OFFER: 'OFFER',
  TX: 'TX',
}

export const ContractShareType = {
  RPS: 'Redeemable Preferred Stock ',
  RCS: 'Redeemable Common Stock',
  NRPS: 'Non-redeemable Preferred Stock',
  NRCS: 'Non-redeemable Common Stock',
  RCN: 'Redeemable Convertible Note',
  NRCN: 'Non-redeemable Convertible Note',
  RN: 'Redeemable Note',
  NRN: 'Non-redeemable Note',
  OTHER: 'Other',
}

export const ContractConvertType = {
  COMMON: 'Common',
  PREFERRED: 'Preferred',
}

export const ContactDistributionFrequency = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly ',
  SEMI_ANNUAL: 'Semi-annual',
  ANNUAL: 'Annual',
  OTHER: 'Other',
}

export const DataExportType = {
  NEW_CUSTOMERS: 'NEW_CUSTOMERS',
  CUSTOMERS: 'CUSTOMERS',
  COMPANIES: 'COMPANIES',
  PROVIDERS: 'PROVIDERS',
  ASSETS: 'ASSETS',
  CUSTOMER_APPROVALS: 'CUSTOMER_APPROVALS',
  COMPANY_APPROVALS: 'COMPANY_APPROVALS',
  PROVIDER_APPROVALS: 'PROVIDER_APPROVALS',
  ASSET_VERIFICATIONS: 'ASSET_VERIFICATIONS',
  ACTIONS: 'ACTIONS',
  ASSET_DETAILS: 'ASSET_DETAILS',
  SECURITIES: 'SECURITIES',
  OFFERS: 'OFFERS',
  LISTINGS: 'LISTINGS',
  TRADES: 'TRADES',
}

export const DataExportTypeName = {
  NEW_CUSTOMERS: 'New Customers',
  CUSTOMERS: 'Customers',
  COMPANIES: 'Companies',
  PROVIDERS: 'Providers',
  ASSETS: 'Assets',
  CUSTOMER_APPROVALS: 'Customer Approvals',
  COMPANY_APPROVALS: 'Company Approvals',
  PROVIDER_APPROVALS: 'Provider Approvals',
  ASSET_VERIFICATIONS: 'Asset Verification',
  ACTIONS: 'Actions',
  ASSET_DETAILS: 'Asset Details',
  SECURITIES: 'Securities',
  OFFERS: 'Offers',
  LISTINGS: 'Listings',
  TRADES: 'Trades',
}

export const DataExportStatus = {
  PREPARING: 'PREPARING',
  READY: 'READY',
}

export const FinancialDataType = {
  SALES_REVENUE: 'SALES_REVENUE',
  EBITDA: 'EBITDA',
  NET_PROFIT: 'NET_PROFIT',
  TOTAL_ASSETS: 'TOTAL_ASSETS',
  TOTAL_LIABILITIES: 'TOTAL_LIABILITIES',
  SHORT_TERM_DEBTS: 'SHORT_TERM_DEBTS',
}

export const FinancialDataTypeNames = {
  [FinancialDataType.SALES_REVENUE]: 'Sales revenue',
  [FinancialDataType.EBITDA]: 'Ebitda',
  [FinancialDataType.NET_PROFIT]: 'Net profit',
  [FinancialDataType.TOTAL_ASSETS]: 'Total assets',
  [FinancialDataType.TOTAL_LIABILITIES]: 'Total liabilities',
  [FinancialDataType.SHORT_TERM_DEBTS]: 'Short term debts',
}

export const NotificationFilter = {
  MESSAGES: 'MESSAGES',
  SYSTEM: 'SYSTEM',
}

export const MIN_TX_COST = 0.0005
export const CONTRACT_DEPLOY_FEE = 50
export const TOKEN_COMMISSION_RATE = 0.005

export const Info = {
  COMPANY: 'Issuer of the underlying security',
  TOKEN_SELLER: 'Seller is the owner of the token offered for sale',
  CONTRACT_CREATION_DATE:
    'The date on which the underlying security is uploaded to system and converted into a smart contract to create asset backed tokens',
  NUMBER_OF_TOKENS: 'The amount or nominal amount of the underlying securities',
  TOKEN_AMOUNT:
    'One Digital Securities value is equal to 1 / (number of underlying security multiplied by the face value of the underlying security. Notional token amount is equal to the number of tokens offered for sale',
  LISTING_AMOUNT: 'Amount of tokens offered for sale',
  MY_TOKEN_AMOUNT:
    'The amount of token that you created and ready to be offered for sale on marketplace',
  TOKEN_PRICE:
    'Ask price refers to the ask block price for the notional token amount offered for sale',
  LISTING_PRICE:
    'The ask block price for the notional token amount offered for sale',
  PRICE_PER_TOKEN:
    'Ask price per Digital Securities refers to the ask price for one Digital Securities value',
  SMART_CONTRACT: 'Lorem ipsum dolor sit amet',
  TRANSFERABLE:
    'Transferability of tokens are allowed by the transfer agent. When the process is completed by the transfer agent it is marked as YES. If the process is ongoing then it is marked as No.',
  LIVE_DATA:
    'If you open live data, you do not have to refresh the page for live data',
  INDIVIDUAL_USER:
    'If you are an individual investor acting on behalf of your account select this',
  INSTITUTIONAL_USER:
    'If you are a representative of an institution and acting on behalf of your institution please select this',
  WAITING_BLOCKCHAIN: 'This may take a few minutes, please wait.',
  ASK_AMOUNT: 'Number of Digital Securities you wish to sell',
  ASK_PRICE: 'The total value of the Digital Securities you wish to sell',
  PPDS:
    'Price per Digital Security. Each Digital Security represents a face value of $1 of the respective Original Security',
  OFFERS_TAB_ITEM:
    'Please click here to take an action on all incoming offers to you',
  TRADES_TAB_ITEM: 'Please click here to take action to finalize a trade',
  ADVICES_TAB_ITEM:
    'Please click here to view investment advice from your financial advisors.',
}

export const AssetCodes = {
  asset_agreement: 'asset_agreement',
  asset_contract: 'asset_contract',
  asset_vc_agreement: 'asset_vc_agreement',
  asset_vc_audit: 'asset_vc_audit',
  asset_vc_subscription: 'asset_vc_subscription',
  asset_underlying_security: 'asset_underlying_security',
  asset_ppm: 'asset_ppm',
  company_logo: 'company_logo',
  generic: 'generic',
  individual_1_a: 'individual_1_a',
  individual_1_b: 'individual_1_b',
  individual_2_a: 'individual_2_a',
  individual_2_b: 'individual_2_b',
  individual_3_a: 'individual_3_a',
  individual_3_b: 'individual_3_b',
  individual_4_a: 'individual_4_a',
  individual_4_b: 'individual_4_b',
  institutional_1_a: 'institutional_1_a',
  institutional_1_b: 'institutional_1_b',
  institutional_2_a: 'institutional_2_a',
  institutional_2_b: 'institutional_2_b',
  institutional_3_a: 'institutional_3_a',
  institutional_3_b: 'institutional_3_b',
  institutional_4_a: 'institutional_4_a',
  institutional_4_b: 'institutional_4_b',
  institutional_5_a: 'institutional_5_a',
  institutional_5_b: 'institutional_5_b',
  license: 'license',
  loa: 'loa',
  national_id: 'national_id',
  passport: 'passport',
  profile_photo: 'profile_photo',
  proof_of_payment: 'proof_of_payment',
  residence_permit: 'residence_permit',
  provider_proof_individual: 'provider_proof_individual',
  provider_proof_institutional: 'provider_proof_institutional',
  legal_opinion_letter: 'legal_opinion_letter',
  authorization_letter: 'authorization_letter',
  blanket_opinion: 'blanket_opinion',
  lockup_agreement: 'lockup_agreement',
  reliance_letter: 'reliance_letter',
}
export const IndividualAccreditationText = {
  1: 'My individual net worth or joint net worth with my spouse exceeds $1 million at the time of the purchase, excluding the value of my primary residence.',
  2: 'My individual income exceeded $200.000 in each of the past two years or the joint income with my spouse exceeded $300.000 in each of the past two years and I expect the same income level this year.',
  3: 'I own assets exceeding $5 million.',
  4: 'I am a director, executive officer or general partner of the company selling the securities.',
}
export const InstitutionalAccreditationText = {
  1: 'We are a business entity each of whose equity owners are accredited investors.',
  2: 'We are a charitable organization, corporation or partnership with assets exceeding $ 5 million.',
  3: 'We are a trust with total assets exceeding $5 million that was not formed to acquire these securities.',
  4: 'We are a bank, insurance company, registered investment company, business development company or small business investment company.',
  5: 'We are an employee benefit plan within the meaning of the Employee Retirement Income Security Act, if a bank, insurance company, or registered investment adviser makes the investment decisions, or if the plan has assets exceeding $ 5 million.',
}
export const AssetCodeNames = {
  asset_agreement: 'User Agreement',
  asset_contract: 'Contract PDF',
  asset_vc_agreement: 'VC LP Agreement',
  asset_vc_audit: 'VC Audit Report',
  asset_vc_subscription: 'Subscription Documentation / Side Letters',
  asset_underlying_security: 'Underlying Security',
  asset_ppm: 'PPM',
  company_logo: 'Company Logo',
  generic: 'All purpose file',
  individual_1_a: 'Net-Worth Based Verification',
  individual_1_b: 'Third-Party Verification',
  individual_2_a: 'Income Based Verification',
  individual_2_b: 'Third-Party Verification',
  individual_3_a: 'Net-Worth Based Verification',
  individual_3_b: 'Third-Party Verification',
  individual_4_a: 'Third-Party Verification',
  individual_4_b: 'Director, Executive Officer, General Partner Verification',
  institutional_1_a:
    'A copy of the formation Certificate and Agreement and a company resolution or a document from the entity authorizing the investment signed by the requisite parties stated in the agreement.',
  institutional_1_b:
    'Documents from each of the equity owners proving that all of the equity owners are accredited investors or a statement to that effect from CPA, attorney, registered investment advisor who has examined their qualifications within the past 3 months.',
  institutional_2_a:
    'A copy of the formation Certificate and Agreement and a company resolution or a document from the entity authorizing the investment signed by the requisite parties stated in the agreement.',
  institutional_2_b:
    'Financial statement or bank statements showing company’s assets and liabilities proving that the company has over $5 million in assets.',
  institutional_3_a:
    'A copy of trust, agency or other agreement and a document authorizing the investment signed by the requisite parties stated in the agreement.',
  institutional_3_b:
    'Documentation that the trust qualifies as an accredited investor because; its assets are exceeding $5 million and that it was not formed to acquire interests.',
  institutional_4_a: 'Documentation as proof of designation.',
  institutional_4_b:
    'Document signed by requisite persons authorizing the investment.',
  institutional_5_a:
    'A bank, insurance company or registered investment adviser must sign the qualification statement on our behalf of the investor.',
  institutional_5_b:
    'Documentation that the plan has assets exceeding $5 million.',
  license: "Driver's License",
  loa: 'Letter of Authorization',
  national_id: 'National Identity Card',
  passport: 'Passport',
  profile_photo: 'Profile Photo',
  proof_of_payment: 'Proof of Payment PDF',
  residence_permit: 'Residence Permit Card',
  provider_proof_individual: 'Individual Provider Proof Document',
  provider_proof_institutional: 'Institutional Provider Proof Document',
  legal_opinion_letter: 'Legal Opinion Letter',
  authorization_letter: 'Authorization Letter',
  blanket_opinion: 'Blanket Opinion',
  lockup_agreement: 'Lockup Agreement',
  reliance_letter: 'Reliance Letter',
}

export const states = {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AS: 'American Samoa',
    GU: 'Guam',
    MP: 'Northern Mariana Islands',
    PR: 'Puerto Rico',
    VI: 'Virgin Islands',
    UM: 'U.S. Minor Outlying Islands',
    FM: 'Federated States of Micronesia',
    MH: 'Marshall Islands',
    PW: 'Palau',
    AA: 'U.S. Armed Forces – Americas',
    AE: 'U.S. Armed Forces – Europe',
    AP: 'U.S. Armed Forces – Pacific',
    CM: 'Northern Mariana Islands',
    CZ: 'Panama Canal Zone',
    NB: 'Nebraska',
    PI: 'Philippine Islands',
    TT: 'Trust Territory of the Pacific Islands',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NF: 'Newfoundland',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    PQ: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
}
export const countries = {
  AD: 'Andorra',
  AE: 'U.A.E',
  AF: 'Afghanistan',
  AG: 'Antigua',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Islands',
  CD: 'Congo',
  CF: 'Central African Republic',
  CG: 'Congo Brazzaville',
  CH: 'Switzerland',
  CI: 'Cote Divoire',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CS: 'Serbia',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  EU: 'European Union',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IO: 'Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts And Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Burma',
  MN: 'Mongolia',
  MO: 'Macau',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre',
  PN: 'Pitcairn Islands',
  PR: 'Puerto Rico',
  PS: 'Palestine',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome',
  SV: 'El Salvador',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Caicos Islands',
  TD: 'Chad',
  TF: 'French Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timorleste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'Us Minor Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'Saint Vincent',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'Us Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis And Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}
export const sectors = {
  accounting: 'Accounting',
  'airlines-aviation': 'Airlines/Aviation',
  'alternative-dispute-resolution': 'Alternative Dispute Resolution',
  'alternative-medicine': 'Alternative Medicine',
  animation: 'Animation',
  'apparel-fashion': 'Apparel & Fashion',
  'architecture-planning': 'Architecture & Planning',
  'arts-crafts': 'Arts & Crafts',
  automotive: 'Automotive',
  'aviation-aerospace': 'Aviation & Aerospace',
  banking: 'Banking',
  biotechnology: 'Biotechnology',
  'broadcast-media': 'Broadcast Media',
  'building-materials': 'Building Materials',
  'business-supplies-equipment': 'Business Supplies & Equipment',
  'capital-markets': 'Capital Markets',
  chemicals: 'Chemicals',
  'civic-social-organization': 'Civic & Social Organization',
  'civil-engineering': 'Civil Engineering',
  'commercial-real-estate': 'Commercial Real Estate',
  'computer-network-security': 'Computer & Network Security',
  'computer-games': 'Computer Games',
  'computer-hardware': 'Computer Hardware',
  'computer-networking': 'Computer Networking',
  'computer-software': 'Computer Software',
  construction: 'Construction',
  'consumer-electronics': 'Consumer Electronics',
  'consumer-goods': 'Consumer Goods',
  'consumer-services': 'Consumer Services',
  cosmetics: 'Cosmetics',
  dairy: 'Dairy',
  'defense-space': 'Defense & Space',
  design: 'Design',
  'education-management': 'Education Management',
  'e-learning': 'E-learning',
  'electrical-electronic-manufacturing':
    'Electrical & Electronic Manufacturing',
  entertainment: 'Entertainment',
  'environmental-services': 'Environmental Services',
  'events-services': 'Events Services',
  'executive-office': 'Executive Office',
  'facilities-services': 'Facilities Services',
  farming: 'Farming',
  'financial-services': 'Financial Services',
  'fine-art': 'Fine Art',
  fishery: 'Fishery',
  'food-beverages': 'Food & Beverages',
  'food-production': 'Food Production',
  fundraising: 'Fundraising',
  furniture: 'Furniture',
  'gambling-casinos': 'Gambling & Casinos',
  'glass-ceramics-concrete': 'Glass, Ceramics & Concrete',
  'government-administration': 'Government Administration',
  'government-relations': 'Government Relations',
  'graphic-design': 'Graphic Design',
  'health-wellness-fitness': 'Health, Wellness & Fitness',
  'higher-education': 'Higher Education',
  'hospital-health-care': 'Hospital & Health Care',
  hospitality: 'Hospitality',
  'human-resources': 'Human Resources',
  'import-export': 'Import & Export',
  'individual-family-services': 'Individual & Family Services',
  'industrial-automation': 'Industrial Automation',
  'information-services': 'Information Services',
  'information-technology-services': 'Information Technology & Services',
  insurance: 'Insurance',
  'international-affairs': 'International Affairs',
  'international-trade-development': 'International Trade & Development',
  internet: 'Internet',
  'investment-banking-venture': 'Investment Banking/Venture',
  'investment-management': 'Investment Management',
  judiciary: 'Judiciary',
  'law-enforcement': 'Law Enforcement',
  'law-practice': 'Law Practice',
  'legal-services': 'Legal Services',
  'legislative-office': 'Legislative Office',
  'leisure-travel': 'Leisure & Travel',
  libraries: 'Libraries',
  'logistics-supply-chain': 'Logistics & Supply Chain',
  'luxury-goods-jewelry': 'Luxury Goods & Jewelry',
  machinery: 'Machinery',
  'management-consulting': 'Management Consulting',
  maritime: 'Maritime',
  'marketing-advertising': 'Marketing & Advertising',
  'market-research': 'Market Research',
  'mechanical-or-industrial-engineering':
    'Mechanical or Industrial Engineering',
  'media-production': 'Media Production',
  'medical-device': 'Medical Device',
  'medical-practice': 'Medical Practice',
  'mental-health-care': 'Mental Health Care',
  military: 'Military',
  'mining-metals': 'Mining & Metals',
  'motion-pictures-film': 'Motion Pictures & Film',
  'museums-institutions': 'Museums & Institutions',
  music: 'Music',
  nanotechnology: 'Nanotechnology',
  newspapers: 'Newspapers',
  'nonprofit-organization-management': 'Nonprofit Organization Management',
  'oil-energy': 'Oil & Energy',
  'online-publishing': 'Online Publishing',
  'outsourcing-offshoring': 'Outsourcing/Offshoring',
  'package-freight-delivery': 'Package/Freight Delivery',
  'packaging-containers': 'Packaging & Containers',
  'paper-forest-products': 'Paper & Forest Products',
  'performing-arts': 'Performing Arts',
  pharmaceuticals: 'Pharmaceuticals',
  philanthropy: 'Philanthropy',
  photography: 'Photography',
  plastics: 'Plastics',
  'political-organization': 'Political Organization',
  'primary-secondary-education': 'Primary/Secondary Education',
  printing: 'Printing',
  'professional-training': 'Professional Training',
  'program-development': 'Program Development',
  'public-policy': 'Public Policy',
  'public-relations': 'Public Relations',
  'public-safety': 'Public Safety',
  publishing: 'Publishing',
  'railroad-manufacture': 'Railroad Manufacture',
  ranching: 'Ranching',
  'real-estate': 'Real Estate',
  recreational: 'Recreational',
  'religious-institutions': 'Religious Institutions',
  'renewables-environment': 'Renewables & Environment',
  research: 'Research',
  restaurants: 'Restaurants',
  retail: 'Retail',
  'security-investigations': 'Security & Investigations',
  semiconductors: 'Semiconductors',
  shipbuilding: 'Shipbuilding',
  'sporting-goods': 'Sporting Goods',
  sports: 'Sports',
  'staffing-recruiting': 'Staffing & Recruiting',
  supermarkets: 'Supermarkets',
  telecommunications: 'Telecommunications',
  textiles: 'Textiles',
  'think-tanks': 'Think Tanks',
  tobacco: 'Tobacco',
  'translation-localization': 'Translation & Localization',
  'transportation-trucking-railroad': 'Transportation/Trucking/Railroad',
  utilities: 'Utilities',
  'venture-capital': 'Venture Capital',
  veterinary: 'Veterinary',
  warehousing: 'Warehousing',
  wholesale: 'Wholesale',
  'wine-spirits': 'Wine & Spirits',
  wireless: 'Wireless',
  'writing-editing': 'Writing & Editing',
}
