import React, { useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Modal,
  Segment,
} from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import * as yup from 'yup'
import _ from 'lodash'
import OfficerSearch from './OfficerSearch'
import { PhoneInput } from '../input'
import useForm from '../../util/hooks/form'
import { useModal } from '../../util/hooks'
import {
  client,
  GET_PROFILE,
  INVITE_PROVIDER,
  REQUEST_PROVIDER,
} from '../../util/API/Apollo'
import { ConfirmModal, ErrorModal, SuccessModal } from '../confirm'
import { PageLoader } from '../index'
import { ProviderType } from '../../util/Constant'

const schema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  phonenumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Phone number is invalid',
    }),
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
  // company: yup.string(), //.required('Company is required'),
  // position: yup.string(), //.required('Position is required'),
})

const FormArea = ({ formHook, onSubmit, providerType }) => {
  const { field } = formHook
  return (
    <Segment color="black" padded="very">
      {providerType === ProviderType.BROKER_DEALER && (
        <>
          <Header as="h2" content="Invite your Broker Dealer" />
          <p>
            If your Broker Dealer is not already registered, you can send an
            invitation by filling out the form below.
          </p>
          <p>
            When you send an invitation, your Broker Dealer will be notified
            that you have a digital security in our platform, and you will need
            the services (digital signature) of your Broker Dealer to transact
            in this platform. Your Broker Dealer will only have to go through a
            simple sign-up process to be able to start providing services to
            you.
          </p>
          <br />
        </>
      )}
      {providerType === ProviderType.FINANCIAL_ADVISOR && (
        <>
          <Header as="h2" content="Invite your Financial Advisor" />
          <p>
            If your Financial Advisor is not already registered, you can send an
            invitation by filling out the form below.
          </p>
          <p>
            This step will notify your Financial Advisor that you have become a
            member in our platform, and you would like to (are open to) receive
            Investment Advice from your financial advisor regarding the private
            securities that are listed in the Marketplace. By means of this
            invitation, your Financial Advisor will become aware of this
            platform (if not already registered with us) and will have the
            opportunity to view investment opportunities in the Market Platform
            and provide you with investment advice.
          </p>
          <br />
        </>
      )}
      <Form onSubmit={onSubmit} noValidate>
        <Form.Group widths="equal">
          <Form.Field
            label="First Name"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="First Name"
            required
            control={Input}
            {...field('firstname')}
          />
          <Form.Field
            label="Last Name"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Last Name"
            required
            control={Input}
            {...field('lastname')}
          />
        </Form.Group>
        {/*<Form.Group widths="equal">*/}
        {/*  <Form.Field*/}
        {/*    label="Company"*/}
        {/*    fluid*/}
        {/*    icon="building"*/}
        {/*    iconPosition="left"*/}
        {/*    placeholder="Company"*/}
        {/*    control={Input}*/}
        {/*    {...field('company')}*/}
        {/*  />*/}
        {/*  <Form.Field*/}
        {/*    label="Position"*/}
        {/*    fluid*/}
        {/*    icon="pencil"*/}
        {/*    iconPosition="left"*/}
        {/*    placeholder="Position"*/}
        {/*    control={Input}*/}
        {/*    {...field('position')}*/}
        {/*  />*/}
        {/*</Form.Group>*/}
        <Form.Group widths="equal">
          <Form.Field
            label="Phone"
            fluid
            icon="phone"
            iconPosition="left"
            placeholder="Phone"
            required
            control={PhoneInput}
            {...field('phonenumber')}
          />
          <Form.Field
            label="Email"
            fluid
            icon="envelope"
            iconPosition="left"
            placeholder="Email"
            required
            control={Input}
            {...field('email')}
          />
        </Form.Group>
        <Button primary fluid content="Invite" type="submit" />
      </Form>
    </Segment>
  )
}

const providerTypeName = {
  [ProviderType.BROKER_DEALER]: 'Broker Dealer',
  [ProviderType.FINANCIAL_ADVISOR]: 'Financial Advisor',
  [ProviderType.TRANSFER_AGENT]: 'Transfer Agent',
}

const initialForm = {
  firstname: '',
  lastname: '',
  // company: '',
  // position: '',
  email: '',
  phonenumber: '',
}
const OfficerForm = ({ open, onClose, providerType }) => {
  const [selectedOfficer, setSelectedOfficer] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const formHook = useForm('provider-invitation', initialForm, { schema })
  const inviteConfirmModal = useModal()
  const selectConfirmModal = useModal()
  const successModal = useModal()
  const errorModal = useModal()

  const [invite, { loading: inviteLoading }] = useMutation(INVITE_PROVIDER, {
    variables: {
      input: { invitation_type: providerType, ...formHook.form },
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROFILE }],
    onCompleted: data => {
      data.inviteProvider ? successModal.show() : errorModal.show()
    },
    client,
  })

  const [select, { loading: selectLoading }] = useMutation(REQUEST_PROVIDER, {
    variables: { officer_id: _.get(selectedOfficer, 'uuid') },
    refetchQueries: [{ query: GET_PROFILE }],
    onCompleted: data => {
      data.requestProvider ? successModal.show() : errorModal.show()
    },
    awaitRefetchQueries: true,
    client,
  })

  const handleSubmit = async () => {
    if (await formHook.validate()) {
      inviteConfirmModal.show()
    }
  }

  const handleClose = () => {
    formHook.clear()
    setSelectedOfficer(null)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} dimmer="blurring" closeIcon>
      <PageLoader active={inviteLoading || selectLoading} />
      <Modal.Header
        className="uppercase"
        content={<>select or invite {providerTypeName[providerType]}</>}
      />
      <Modal.Content>
        {providerTypeName[providerType] === 'Broker Dealer' && (
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <Header
                  as="h4"
                  textAlign="center"
                  content="A Broker Dealer is needed for you to be able to access the Marketplace and start trading within our platform in a compliant manner."
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment inverted padded="very" color="black">
                  <Header as="h2" content="Select your Broker Dealer" />
                  <p>
                    Your Broker Dealer may already be registered with us. Please
                    use the search box below to search for your Broker Dealer.
                  </p>
                  <OfficerSearch
                    autoFocus
                    value={selectedOfficer}
                    onChange={setSelectedOfficer}
                    showForm={setShowForm}
                    type={providerType}
                    action="Select"
                    style={{ flexGrow: 1 }}
                  />
                  <br />
                  <br />
                  <Button
                    primary
                    fluid
                    content="Select"
                    type="button"
                    disabled={!selectedOfficer}
                    onClick={selectConfirmModal.show}
                  />
                  <br />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {providerTypeName[providerType] === 'Financial Advisor' && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Segment inverted padded="very" color="black">
                  <Header as="h2" content="Select your Financial Advisor" />
                  <p>
                    Your Financial Advisor may already be registered with us.
                    Please use the search box above to search for your Financial
                    Advisor.
                  </p>
                  <OfficerSearch
                    autoFocus
                    value={selectedOfficer}
                    onChange={setSelectedOfficer}
                    showForm={setShowForm}
                    type={providerType}
                    action="Select"
                    style={{ flexGrow: 1 }}
                  />
                  <br />
                  <br />
                  <Button
                    primary
                    fluid
                    content="Select"
                    type="button"
                    disabled={!selectedOfficer}
                    onClick={selectConfirmModal.show}
                  />
                  <br />
                  <p>
                    If your Financial Advisor is not already registered, you can
                    send an invitation by filling out the form below.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <ConfirmModal
                {...inviteConfirmModal.props}
                onConfirm={invite}
                content={`You can only invitation sent ${providerTypeName[providerType]}`}
              />
              <ConfirmModal
                {...selectConfirmModal.props}
                onConfirm={select}
                content={`You can only select ${providerTypeName[providerType]}`}
              />
              <ErrorModal
                {...errorModal.props}
                content="You cannot invite this person"
              />
              <SuccessModal
                {...successModal.props}
                onClose={handleClose}
                content="Your invitation has been sent"
              />
              {showForm && (
                <FormArea
                  formHook={formHook}
                  onSubmit={handleSubmit}
                  providerType={providerType}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default OfficerForm
