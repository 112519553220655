import React from 'react'
import { Button } from 'semantic-ui-react'
import { ConfirmModal, SuccessModal } from '../confirm'
import { PageLoader } from '../index'
import { useMutation } from '@apollo/react-hooks'
import {
  client,
  GET_PROVIDER_ACTION_DETAIL,
  REQUEST_NEW_CUSTOMER_DOCUMENT,
} from '../../util/API/Apollo'
import { useModal } from '../../util/hooks'

const KYCACCRequestDocButton = ({ action, show, approvalType }) => {
  const confirmModal = useModal()
  const successModal = useModal()
  const [requestDoc, { loading }] = useMutation(REQUEST_NEW_CUSTOMER_DOCUMENT, {
    variables: {
      provider_action_id: action.uuid,
      approval_type: approvalType,
    },
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: successModal.show,
    client,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} onConfirm={requestDoc} />
      <PageLoader active={loading} />
      <SuccessModal
        content="You requested document from the customer"
        {...successModal.props}
      />
      {show && (
        <>
          <Button
            content="Request documents"
            color="red"
            type="button"
            onClick={confirmModal.show}
          />
        </>
      )}
    </>
  )
}

export default KYCACCRequestDocButton
