import React from 'react'
import { Table } from 'semantic-ui-react'
import _ from 'lodash'
import {
  AgeInfo,
  DigitizedSecurityInfo,
  DimmerLoader,
  TokenizedAmountInfo,
  TransferabilityIcon,
} from '../'
import { ContractStatusIcon, ContractTradeStatusIcon } from './Contract'
import { ContractTypeNames } from '../../util/Constant'
import { CompanyLink, ContractLink, DetailsButton } from '../link'

const ContractTable = ({
  contracts,
  loading,
  getSortProps = _.noop,
  pagination,
}) => {
  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="ASSET ID" />
          <Table.HeaderCell content="ASSET TYPE" />
          <Table.HeaderCell content="ISSUER" />
          <Table.HeaderCell content="AMOUNT" />
          <Table.HeaderCell content="AGE" {...getSortProps('created_at')} />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell>
              <DimmerLoader />
            </Table.Cell>
          </Table.Row>
        ) : (
          <>
            {contracts.map(contract => (
              <Table.Row key={contract.uuid}>
                <Table.Cell>
                  <ContractStatusIcon contract={contract} />
                  <ContractTradeStatusIcon contract={contract} />
                  <TransferabilityIcon token={contract} />
                  <ContractLink contract={contract} />
                </Table.Cell>
                <Table.Cell>
                  {ContractTypeNames[contract.contract_type]}
                </Table.Cell>
                <Table.Cell>
                  <CompanyLink company={contract.issuer} />
                </Table.Cell>
                <Table.Cell>
                  {Number(contract.token_numbers.on_market > 0) ? (
                    <TokenizedAmountInfo contract={contract} />
                  ) : (
                    <DigitizedSecurityInfo contract={contract} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <AgeInfo iso str={contract.created_at} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <DetailsButton contract={contract} />
                </Table.Cell>
              </Table.Row>
            ))}
          </>
        )}
      </Table.Body>
      {pagination && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>{pagination}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

export default ContractTable
