import React from 'react'
import { Container, Divider, Grid, Header } from 'semantic-ui-react'
import { UserVerifiedLabel } from '../index'
import { UserAvatar, UserTypeLabel, UserWalletAddress } from '../User'

const ProfileSummaryCentered = ({ user, withWallet }) => {
  return (
    <>
      <Container textAlign="center">
        <UserAvatar user={user} size="small" centered />
        <Header as="h2">{user.fullname}</Header>
        <UserTypeLabel user={user} />
        <br />
        <br />
        <UserVerifiedLabel isVerified={user.is_verified} />
        {withWallet && (
          <>
            <Divider hidden />
            <UserWalletAddress user={user} />
          </>
        )}
      </Container>
    </>
  )
}

const ProfileSummaryGrid = ({ user, withWallet }) => {
  return (
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column computer="3" tablet="6" mobile="16">
          <UserAvatar user={user} />
        </Grid.Column>
        <Grid.Column computer="13" tablet="10" mobile="16">
          <Header as="h2">{user.fullname}</Header>
          <UserTypeLabel user={user} />
          <UserVerifiedLabel isVerified={user.is_verified} />
          {withWallet && (
            <>
              <Divider hidden />
              <UserWalletAddress user={user} />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const ProfileSummary = ({ user, centered, withWallet = true }) => {
  if (centered) {
    return <ProfileSummaryCentered user={user} withWallet={withWallet} />
  }
  return <ProfileSummaryGrid user={user} withWallet={withWallet} />
}

export default ProfileSummary
