import React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'
import { ethers } from 'ethers'
import debug from 'debug'
import { useMe } from '../../../util/hooks/me'
import useForm from '../../../util/hooks/form'

const log = debug('app:blockchain')

// false if private key is empty or matches str, true otherwise
const hasPrivateKeyError = (pkInput, address) => {
  if (pkInput === '') return false
  const privateKey = pkInput.length === 64 ? `0x${pkInput}` : pkInput

  if (privateKey.length !== 66) return true
  if (!ethers.utils.isHexString(privateKey)) return true

  const newWallet = new ethers.Wallet(privateKey)
  return newWallet.address.toLowerCase() !== address.toLowerCase()
}

const initialForm = { privateKey: '' }

const DecryptWithPK = ({
  decryptedWallet,
  onDecrypted,
  onSubmit,
  disabled,
}) => {
  const me = useMe()
  const { address } = me.primary_wallet

  const formHook = useForm('wallet-decryptor', initialForm)
  const pkIsInvalid = React.useMemo(
    () => hasPrivateKeyError(formHook.form.privateKey, address),
    [formHook.form.privateKey, address]
  )

  const handlePKChange = (e, { value }) => {
    formHook.set('privateKey', value)
    if (!hasPrivateKeyError(value, address) && value.length > 0) {
      const unlockedWallet = new ethers.Wallet(value)
      log('wallet decrypted: ', unlockedWallet)
      onDecrypted(unlockedWallet)
    }
  }

  return (
    <Form>
      <Form.Field error={pkIsInvalid} required>
        <label>Private Key</label>
        <Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Private Key"
          autoComplete="off"
          name="privateKey"
          value={formHook.form.privateKey}
          onChange={handlePKChange}
          disabled={disabled || Boolean(decryptedWallet)}
        />
      </Form.Field>
      <Button
        type="button"
        primary
        fluid
        content="continue"
        onClick={onSubmit}
        disabled={!decryptedWallet}
        data-test-id="continue-decrypt-wallet"
      />
    </Form>
  )
}

export default DecryptWithPK
