import React from 'react'
import { Button } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import { useModal } from '../../util/hooks'
import {
  client,
  GET_TRADE_DETAILS,
  SEND_BANK_PAYMENT,
  SEND_RAW_PAYMENT,
} from '../../util/API/Apollo'
import { BlockchainActions, TradeActors } from '../../util/Constant'
import { SuccessModal } from '../../components/confirm'
import PaymentModal from '../../components/PaymentModal'
import { PageLoader } from '../../components'

const PayTradeButton = ({ trade, show }) => {
  const payModal = useModal()
  const successModal = useModal()

  const payment = trade.payments.find(
    payment => payment.payment_role === TradeActors.BUYER
  )

  const [sendPayment, { loading }] = useMutation(SEND_RAW_PAYMENT, {
    onCompleted: () => {
      payModal.hide()
      successModal.show()
    },
    refetchQueries: [
      { query: GET_TRADE_DETAILS, variables: { uuid: trade.uuid } },
    ],
    awaitRefetchQueries: true,
    client,
  })

  const [sendBankPayment, { loading: bankLoading }] = useMutation(
    SEND_BANK_PAYMENT,
    {
      onCompleted: () => {
        payModal.hide()
        successModal.show()
      },
      refetchQueries: [
        { query: GET_TRADE_DETAILS, variables: { uuid: trade.uuid } },
      ],
      awaitRefetchQueries: true,
      client,
    }
  )

  const handlePayment = async (paymentInput, currency) => {
    if (currency === 'USD') {
      await sendBankPayment({
        variables: {
          payment_id: payment.uuid,
          document: paymentInput.uuid, // file uuid
        },
      })
    } else {
      await sendPayment({
        variables: {
          input: paymentInput,
        },
      })
    }
  }

  return (
    <>
      <PageLoader active={loading || bankLoading} />
      <SuccessModal
        {...successModal.props}
        onClose={() => {
          successModal.hide()
          payModal.hide()
        }}
        content="Your payment is being processed."
      />
      <PaymentModal
        {...payModal.props}
        onTxSent={handlePayment}
        amount={payment.amount}
        action={BlockchainActions.TOKEN_PAYMENT}
        payment_id={payment.uuid}
        recipient={trade.accepted_offer.listing.token.contract.contract_address}
        bank_info={payment.bank_info}
        currencies={['ETH', 'USDC', 'USD']}
      />
      {show && (
        <Button
          content="Pay the transfer price"
          fluid
          primary
          onClick={payModal.show}
        />
      )}
    </>
  )
}

export default PayTradeButton
