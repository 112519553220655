import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import _ from 'lodash'
import {
  AgeInfo,
  AskAmountLabel,
  AskPriceLabel,
  PopupIcon,
  PPDSLabel,
  TransferabilityIcon,
} from '../../components'
import { NoData } from '../../components/NotFound'
import { useMe } from '../../util/hooks/me'
import {
  CompanyLink,
  ContractLink,
  DetailsButton,
  UserLink,
} from '../../components/link'

const TableHeader = ({ sort = _.noop }) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="UNDERLYING ASSET" />
        <Table.HeaderCell content="SELLER" />
        <Table.HeaderCell content="ISSUER" />
        <Table.HeaderCell content="ASK AMOUNT" />
        <Table.HeaderCell content="ASK PRICE" />
        <Table.HeaderCell content="PPDS" />
        <Table.HeaderCell content="AGE" {...sort('created_at')} />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}

export const MarketplaceTable = ({ sort, listings, footer }) => {
  const { isOwner } = useMe()

  if (listings.length < 1) {
    return <NoData text="No result found" />
  }

  return (
    <Table basic="very" padded sortable size="small">
      <TableHeader sort={sort} />
      <Table.Body>
        {listings.map(listing => {
          const { uuid, amount, price, created_at, solicited, token } = listing

          return (
            <Table.Row key={uuid} negative={isOwner({ listing })}>
              <Table.Cell className="uuid">
                {isOwner({ listing }) && (
                  <PopupIcon
                    name="star"
                    color="yellow"
                    content="Your listing"
                  />
                )}
                {solicited && <Icon name="bullhorn" color="red" />}
                <TransferabilityIcon token={token} />{' '}
                <ContractLink contract={token.contract} />
              </Table.Cell>
              <Table.Cell>
                <UserLink user={token.owner} />
              </Table.Cell>
              <Table.Cell>
                <CompanyLink company={token.contract.issuer} />
              </Table.Cell>
              <Table.Cell>
                <AskAmountLabel amount={amount} />
              </Table.Cell>
              <Table.Cell>
                <AskPriceLabel price={price} />
              </Table.Cell>
              <Table.Cell>
                <PPDSLabel data={{ amount, price }} />
              </Table.Cell>
              <Table.Cell>
                <AgeInfo iso str={created_at} />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <DetailsButton listing={listing} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={4}>
            <p>
              <Icon name="check" color="green" />: Transferable securities
            </p>
            <p>
              <Icon name="exclamation triangle" color="yellow" />: Transferable
              securities with restriction
            </p>
            <p>
              <Icon name="time" color="blue" />: Transferability Pending
            </p>
            <p>
              <Icon name="star" color="yellow" />: My listing
            </p>
          </Table.HeaderCell>
          {footer && <Table.HeaderCell colSpan={11}>{footer}</Table.HeaderCell>}
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default MarketplaceTable
