import React from 'react'
import { Button, Segment, Grid, Header, Image } from 'semantic-ui-react'
import _ from 'lodash'
import { ContractType } from '../../../util/Constant'
import DetailsSimpleForm from './DetailsSimpleForm'
import DetailsComplexForm from './DetailsComplexForm'
import { GoBackButton, SaveMutation } from '../common'
import { useModal } from '../../../util/hooks'
import OptionalDetailsForm from './OptionalDetailsForm'
import ERPDetailsForm from './ERPDetailsForm'
import StrategyDetailsForm from './StrategyDetailsForm'
import createAssetImage from '../../../images/create_asset.svg'

const DetailsStepInfo = () => (
  <>
    <Header
      as="h2"
      color="purple"
      textAlign="left"
      content="ADD ASSET DETAILS"
    />
    <div className="text-center">
      <Image size="large" centered src={createAssetImage} />
    </div>
  </>
)

const DetailsStep = ({ contract, formHook, stepsHook, saveMutation }) => {
  const erpModal = useModal()
  const optionalDetailsModal = useModal()
  const strategyModal = useModal()

  const type = _.get(formHook, 'form.contract_type')
  const { field } = formHook
  const isSafe = type === ContractType.SAFE
  const isEquity = type === ContractType.EQUITY
  const isCN = type === ContractType.CONVERTIBLE_NOTE
  const isFOF = type === ContractType.FOF
  const isLP = type === ContractType.VC

  return (
    <Segment padded="very">
      <ERPDetailsForm {...erpModal.props} {...field('erpDetails')} />
      <OptionalDetailsForm
        {...optionalDetailsModal.props}
        {...field('optionalDetails')}
      />
      <StrategyDetailsForm
        {...strategyModal.props}
        {...field('strategyDetails')}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} only="computer">
            <DetailsStepInfo />
            {(isFOF || isLP) && (
              <Button
                fluid
                size="small"
                content="OPTIONAL DETAILS"
                onClick={optionalDetailsModal.show}
                className="mb-10"
                data-test-id="start-add-optional-details"
              />
            )}
            {isLP && (
              <>
                <Button
                  fluid
                  size="small"
                  content="EARLY REDEMPTION PENALTY"
                  onClick={erpModal.show}
                  className="mb-10"
                  data-test-id="start-add-erp-details"
                />
                <Button
                  fluid
                  size="small"
                  content="INVESTMENT STRATEGY"
                  onClick={strategyModal.show}
                  className="mb-10"
                  data-test-id="start-add-strategy-details"
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            {(isSafe || isEquity || isCN) && (
              <DetailsSimpleForm {...{ formHook, stepsHook, saveMutation }} />
            )}
            {(isFOF || isLP) && (
              <DetailsComplexForm {...{ formHook, stepsHook, saveMutation }} />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <GoBackButton
              dirty={formHook.isDirty}
              goBack={stepsHook.prevStep}
            />
            <SaveMutation {...{ contract, formHook, stepsHook }}>
              {({ submit }) => (
                <Button
                  type="submit"
                  primary
                  floated="right"
                  content={formHook.isDirty ? 'Save & Next' : 'Next'}
                  onClick={submit}
                  data-test-id="tokenize-next"
                />
              )}
            </SaveMutation>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default DetailsStep
