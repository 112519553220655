import React from 'react'
import { Button, Icon, Menu, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { UserType, UserTypeNames } from '../../../util/Constant'
import { AmountLabel, NumberFormat, PopupIcon } from '../../index'
import { useTab } from '../../../util/hooks'
import Invitations from './Invitations'
import { NoData } from '../../NotFound'
import {
  client,
  GET_CONTRACT,
  UNINVITE_SHAREHOLDER,
} from '../../../util/API/Apollo'
import { useMutation } from '@apollo/react-hooks'
import { useMe } from '../../../util/hooks/me'

const ShareholderRow = ({ holder, contract }) => {
  const totalTokens = Number(contract.token_numbers.initial)
  const percentage = (Number(holder.shares) / totalTokens) * 100
  return (
    <Table.Row>
      <Table.Cell>
        <PopupIcon name="check" content="Shareholder is claimed" />
        {holder.fullname}
      </Table.Cell>
      <Table.Cell content={UserTypeNames[holder.invested_as]} />
      <Table.Cell>
        <NumberFormat amount={percentage} float digits={2} />
        <Icon name="percent" />
      </Table.Cell>
      <Table.Cell>
        <AmountLabel amount={holder.shares} />
      </Table.Cell>
      <Table.Cell collapsing />
    </Table.Row>
  )
}

const InvitationRow = ({ invitation, contract }) => {
  const totalTokens = Number(contract.token_numbers.initial)
  const percentage = (Number(invitation.share_amount) / totalTokens) * 100

  const [uninvite, { loading }] = useMutation(UNINVITE_SHAREHOLDER, {
    variables: {
      invitation_id: invitation.uuid,
    },
    refetchQueries: [
      {
        query: GET_CONTRACT,
        variables: { uuid: contract.uuid },
      },
    ],
    awaitRefetchQueries: true,
    client,
  })

  return (
    <Table.Row>
      <Table.Cell>
        <PopupIcon name="paper plane" content="Shareholder is invited" />
        {invitation.firstname} {invitation.lastname}
      </Table.Cell>
      <Table.Cell content={UserTypeNames[UserType.INDIVIDUAL]} />
      <Table.Cell>
        <NumberFormat amount={percentage} float digits={2} />
        <Icon name="percent" />
      </Table.Cell>
      <Table.Cell>
        <AmountLabel amount={invitation.share_amount} />
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          type="button"
          content="Remove"
          color="red"
          loading={loading}
          disabled={loading}
          onClick={uninvite}
        />
      </Table.Cell>
    </Table.Row>
  )
}

const Shareholders = ({ contract }) => {
  const shareholders = _.get(contract, 'shareholders.data', [])
  const invitations = _.get(contract, 'shareholder_invitations.data', [])

  if (shareholders.length === 0 && invitations.length === 0) {
    return <NoData text="NO SHAREHOLDER" size="small" className="my-50" />
  }

  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="SHAREHOLDER" />
          <Table.HeaderCell content="TYPE" />
          <Table.HeaderCell content="PERCENTAGE" />
          <Table.HeaderCell content="AMOUNT" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {contract.shareholders.data.map((holder, index) => (
          <ShareholderRow key={index} holder={holder} contract={contract} />
        ))}
        {contract.shareholder_invitations.data.map((invitation, index) => (
          <InvitationRow
            key={index}
            invitation={invitation}
            contract={contract}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

const ContractShareholderTabs = ({ contract, showInvitations }) => {
  // shareholders|invitations
  const { isActive, itemProps } = useTab('shareholders')
  const { isOwner, isTransferAgent } = useMe()

  return (
    <>
      {showInvitations && (isOwner({ contract }) || isTransferAgent) && (
        <Menu pointing secondary>
          <Menu.Item
            {...itemProps('shareholders')}
            data-test-id="shareholders-shareholders-tab"
          />
          <Menu.Item
            {...itemProps('invitations')}
            data-test-id="shareholders-invitation-tab"
          />
        </Menu>
      )}
      {isActive('shareholders') && <Shareholders contract={contract} />}
      {isActive('invitations') && <Invitations contract={contract} />}
    </>
  )
}

export default ContractShareholderTabs
