import { ethers, utils } from 'ethers'
import * as Sentry from '@sentry/browser'
import debug from 'debug'
import _ from 'lodash'

const log = debug('app:blockchain')

export const convertUsdToEth = (value, exchange) => {
  const exchangeRate = String(_.get(exchange, 'price', 1))
  const usdBN = utils.parseUnits(String(value), 2)
  const exchangeRateBN = utils.parseUnits(exchangeRate, 2)
  const wei = usdBN.mul(ethers.constants.WeiPerEther).div(exchangeRateBN)
  return utils.formatUnits(wei, 'ether')
}
export const parseUsdc = value => ethers.utils.parseUnits(value, 6)
export const formatUsdc = value => ethers.utils.formatUnits(value, 6)

export const getEthBalance = async address => {
  let provider = ethers.getDefaultProvider(
    process.env.REACT_APP_BLOCKCHAIN_NETWORK || 'ropsten'
  )
  try {
    const balance = await provider.getBalance(address)
    return ethers.utils.formatEther(balance)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
    return null
  }
}

export const getUsdcBalance = async rawAddress => {
  try {
    const address = rawAddress.substring(rawAddress.startsWith('0x') ? 2 : 0)

    const contractAddress = '0x072Ea7c455e5f3D6F3c318A55aE55D805096bc1A'
    const abi =
      '[{"constant":true,"inputs":[],"name":"name","outputs":[{"name":"","type":"string"}],"payable":false,"stateMut' +
      'ability":"view","type":"function"},{"constant":false,"inputs":[{"name":"spender","type":"address"},{"name":"v' +
      'alue","type":"uint256"}],"name":"approve","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutabil' +
      'ity":"nonpayable","type":"function"},{"constant":true,"inputs":[],"name":"totalSupply","outputs":[{"name":"",' +
      '"type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"' +
      'name":"from","type":"address"},{"name":"to","type":"address"},{"name":"value","type":"uint256"}],"name":"tran' +
      'sferFrom","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutability":"nonpayable","type":"functi' +
      'on"},{"constant":true,"inputs":[],"name":"decimals","outputs":[{"name":"","type":"uint8"}],"payable":false,"s' +
      'tateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"spender","type":"address"},{"n' +
      'ame":"addedValue","type":"uint256"}],"name":"increaseAllowance","outputs":[{"name":"","type":"bool"}],"payabl' +
      'e":false,"stateMutability":"nonpayable","type":"function"},{"constant":true,"inputs":[{"name":"owner","type":' +
      '"address"}],"name":"balanceOf","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"vi' +
      'ew","type":"function"},{"constant":false,"inputs":[],"name":"renounceOwnership","outputs":[],"payable":false,' +
      '"stateMutability":"nonpayable","type":"function"},{"constant":true,"inputs":[],"name":"owner","outputs":[{"na' +
      'me":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"input' +
      's":[],"name":"isOwner","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutability":"view","type":' +
      '"function"},{"constant":true,"inputs":[],"name":"symbol","outputs":[{"name":"","type":"string"}],"payable":fa' +
      'lse,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"spender","type":"address' +
      '"},{"name":"subtractedValue","type":"uint256"}],"name":"decreaseAllowance","outputs":[{"name":"","type":"bool' +
      '"}],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":false,"inputs":[{"name":"to' +
      '","type":"address"},{"name":"value","type":"uint256"}],"name":"transfer","outputs":[{"name":"","type":"bool"}' +
      '],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":true,"inputs":[{"name":"owner' +
      '","type":"address"},{"name":"spender","type":"address"}],"name":"allowance","outputs":[{"name":"","type":"uin' +
      't256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"newO' +
      'wner","type":"address"}],"name":"transferOwnership","outputs":[],"payable":false,"stateMutability":"nonpayabl' +
      'e","type":"function"},{"inputs":[],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"pay' +
      'able":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":true,"name"' +
      ':"from","type":"address"},{"indexed":true,"name":"to","type":"address"},{"indexed":false,"name":"value","type' +
      '":"uint256"}],"name":"Transfer","type":"event"},{"anonymous":false,"inputs":[{"indexed":true,"name":"owner","' +
      'type":"address"},{"indexed":true,"name":"spender","type":"address"},{"indexed":false,"name":"value","type":"u' +
      'int256"}],"name":"Approval","type":"event"},{"anonymous":false,"inputs":[{"indexed":true,"name":"previousOwne' +
      'r","type":"address"},{"indexed":true,"name":"newOwner","type":"address"}],"name":"OwnershipTransferred","type' +
      '":"event"}]'

    let provider = ethers.getDefaultProvider(
      process.env.REACT_APP_BLOCKCHAIN_NETWORK || 'ropsten'
    )
    let contract = new ethers.Contract(contractAddress, abi, provider)
    const balance = await contract.balanceOf(address)
    return formatUsdc(balance)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
    return ''
  }
}

export const prettifyAddress = address => {
  address = address.toLowerCase()

  if (address.startsWith('0x')) {
    return address.substring(2)
  } else {
    return address
  }
}

const convertAddress = address => {
  if (address.startsWith('0x')) {
    address = address.substring(2)
  }
  return address.padStart(64, '0')
}

const convertBool = bool => {
  return (bool ? '1' : '0').padStart(64, '0')
}

const convertBytes32 = bytes32 => {
  if (bytes32.startsWith('0x')) {
    bytes32 = bytes32.substring(2)
  }
  return bytes32
}

const convertString = str => {
  const length = str.length
  let data = '80'.padStart(64, '0') + length.toString(16).padStart(64, '0')
  let result = ''
  for (let i = 0; i < length; i++) {
    result += str.charCodeAt(i).toString(16)
  }
  const remainder = result.length % 64
  data = data + result + '0'.padEnd(64 - remainder, '0')
  return data
}

const convertUint256 = uint256 => {
  return utils
    .bigNumberify(uint256)
    .toHexString()
    .substring(2)
    .padStart(64, '0')
}

/**
 *
 * @param schema
 * @param data {{ value, to }}
 * @returns {string|*}
 */
export const prepareUnsignedData = (schema, data) => {
  const length = schema.parameters.length
  if (length === 0) {
    return schema.method_id
  } else {
    const dataArray = new Array(length + 1)
    dataArray[0] = schema.method_id
    schema.parameters.forEach(parameter => {
      if (data[parameter.name] === undefined) {
        throw new Error(`Missing data for parameter ${parameter.name}`)
      }
      switch (parameter.type) {
        case 'address':
          dataArray[parameter.index + 1] = convertAddress(data[parameter.name])
          break
        case 'bytes32':
          dataArray[parameter.index + 1] = convertBytes32(data[parameter.name])
          break
        case 'uint256':
          dataArray[parameter.index + 1] = convertUint256(data[parameter.name])
          break
        case 'bool':
          dataArray[parameter.index + 1] = convertBool(data[parameter.name])
          break
        case 'string':
          dataArray[parameter.index + 1] = convertString(data[parameter.name])
          break
        default:
          throw new Error(`invalid parameter ${parameter}`)
      }
    })
    return dataArray.join('')
  }
}

export const sendTx = async (wallet, schema, params) => {
  log('signing tx, schema:\n', schema, '\nparameters:\n', params)
  const transaction = {
    gasLimit: schema.gas_limit,
    gasPrice: utils.bigNumberify(schema.gas_price),
    to: params.to,
    value: utils.parseEther('0'),
    data: '0x',
    chainId: ethers.utils.getNetwork(schema.chain).chainId,
  }

  if (schema.method_id === undefined || schema.method_id === '') {
    if (params.value !== undefined) {
      transaction.value = utils.parseEther(params.value)
    } else {
      throw new Error('params.value is undefined')
    }
  } else {
    transaction.data = prepareUnsignedData(schema, params)
  }

  try {
    const connectedWallet = await wallet.connect(
      ethers.getDefaultProvider(schema.chain)
    )
    return await connectedWallet.sendTransaction(transaction)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
  }
}

export const signMessage = async (wallet, message) => {
  try {
    return await wallet.signMessage(message)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
  }
}

export const sendEth = async (wallet, schema, value, to) => {
  return await sendTx(wallet, schema, { to, value })
}

export const sendUsdc = async (wallet, schema, value, to, recipient) => {
  return await sendTx(wallet, schema, {
    to,
    recipient,
    amount: parseUsdc(value).toString(),
  })
}
