import React from 'react'
import _ from 'lodash'
import { ContractType } from '../../util/Constant'
import { useMe } from '../../util/hooks/me'
import TabMenu from '../TabMenu'
import ContractCommonDetails from './ContractCommonDetails'
import ContractOptionalDetails from './ContractOptionalDetails'
import ContractShareholderTabs from './ContractShareholderTabs'
import FinancialDataTabs from '../FinancialData/FinancialDataTabs'
import ContractStrategyDetails from './ContractStrategyDetails'
import ContractERPDetails from './ContractERPDetails'

export const ContractTabMenuItems = ({ contract, ...props }) => {
  const me = useMe()
  const tabs = React.useMemo(() => {
    if (!contract) return []

    const {
      phonenumber,
      email,
      url_web,
      lockup_period,
      early_redemption_from_date,
      early_redemption_to_date,
      early_redemption_penalty,
    } = contract.details

    const tabs = ['asset-details']

    const canSeeOptionalInfo = me.isOwner({ contract }) || me.isTransferAgent
    const hasOptionalInfo =
      phonenumber ||
      email ||
      url_web ||
      _.get(contract, 'details.contact_people.length')
    if (hasOptionalInfo && canSeeOptionalInfo) {
      tabs.push('optional-details')
    }

    const isFOF = contract.contract_type === ContractType.FOF
    const isLP = contract.contract_type === ContractType.VC
    if (isFOF || isLP) {
      tabs.push('shareholders')
    }
    if (_.get(contract, 'financial_data.length')) {
      tabs.push('financial-details')
    }

    if (
      lockup_period ||
      early_redemption_from_date ||
      early_redemption_to_date ||
      early_redemption_penalty
    ) {
      tabs.push('early-redemption-penalty')
    }
    if (_.get(contract, 'details.investment_strategy')) {
      tabs.push('investment-strategy')
    }

    return tabs
  }, [contract, me])

  return tabs.map(tab => (
    <TabMenu.Item key={tab} name={tab} data-test-id={`${tab}-tab`} {...props} />
  ))
}

export const ContractTabMenuPanes = ({
  contract,
  refetch,
  showInvitations,
  ...props
}) => {
  return (
    <>
      <TabMenu.Pane name="asset-details" {...props}>
        <ContractCommonDetails contract={contract} refetch={refetch} />
      </TabMenu.Pane>
      <TabMenu.Pane name="optional-details" {...props}>
        <ContractOptionalDetails contract={contract} />
      </TabMenu.Pane>
      <TabMenu.Pane name="shareholders" {...props}>
        <ContractShareholderTabs
          showInvitations={showInvitations}
          contract={contract}
        />
      </TabMenu.Pane>
      <TabMenu.Pane name="financial-details" {...props}>
        <FinancialDataTabs data={contract.financial_data} />
      </TabMenu.Pane>
      <TabMenu.Pane name="investment-strategy" {...props}>
        <ContractStrategyDetails contract={contract} />
      </TabMenu.Pane>
      <TabMenu.Pane name="early-redemption-penalty" {...props}>
        <ContractERPDetails contract={contract} />
      </TabMenu.Pane>
    </>
  )
}
