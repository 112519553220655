import React from 'react'
import { Icon, Image, Label } from 'semantic-ui-react'
import { UserTypeNames } from '../../util/Constant'
import _ from 'lodash'
import userPlaceholder from '../../images/user-placeholder.png'

export const UserTypeLabel = ({ user }) => {
  return (
    <Label
      basic
      size="small"
      className="uppercase"
      content={UserTypeNames[user.user_type]}
    />
  )
}

export const UserAvatar = ({ user, ...props }) => (
  <Image
    rounded
    src={_.get(user, 'profile.photo.link_as_avatar') || userPlaceholder}
    {...props}
  />
)

export const UserWalletAddress = ({ user }) => (
  <p className="text-nowrap text-ellipsis">
    <Icon name="ethereum" />
    {_.get(user, 'primary_wallet.address') || 'No wallet'}
  </p>
)
