import React from 'react'
import { Grid, Header, Image } from 'semantic-ui-react'
import { NextBackButton } from '../CompanyForm'
import issuerFinancialData from '../../../../images/issuer_financial_data.svg'
import FinancialDataForm from '../../../FinancialData/FinancialDataForm'

const FinancialDataStep = ({ formHook, stepsHook, handleNextStep }) => {
  const { field } = formHook
  return (
    <>
      <Grid padded>
        <Grid.Row>
          <Grid.Column computer="6" only="computer">
            <Image size="large" src={issuerFinancialData} />
            <Header
              as="h3"
              color="purple"
              textAlign="left"
              content="ADD FINANCIAL DATA"
            />
          </Grid.Column>
          <Grid.Column computer="10" tablet="16" mobile="16">
            <FinancialDataForm
              dataProps={field('financial_data')}
              documentProps={field('financial_documents')}
            />
            <NextBackButton
              stepsHook={stepsHook}
              handleNextStep={handleNextStep}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default FinancialDataStep
