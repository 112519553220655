import React from 'react'
import { Button, Divider, Form, Header, Modal } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import TabMenu from '../../components/TabMenu'
import { useApproval, useModal } from '../../util/hooks'
import {
  ApprovalStatus,
  ApprovalType,
  AssetCodes,
  Info,
  PaymentStatus,
  TradeActors,
  TxStatus,
} from '../../util/Constant'
import TradeCommonDetails from '../../components/Trade/TradeCommonDetails'
import ContractCommonDetails from '../../components/Contract/ContractCommonDetails'
import TradeTxsTable from '../../components/Trade/TradeTxsTable'
import { SuccessModal } from '../../components/confirm'
import {
  APPROVE_TRADE_PAYMENT,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'
import { PageLoader, SubMessage } from '../../components'
import { DocumentUpload } from '../../components/upload'
import useForm from '../../util/hooks/form'
import { FileLink } from '../../components/link'

const initialForm = { doc: null }
const ApproveForm = ({ open, onClose, onSubmit }) => {
  const { form, field } = useForm('payment-receipt-form', initialForm)

  const handleSubmit = () => {
    onClose()
    onSubmit(form.doc)
  }

  return (
    <Modal open={open} onClose={onClose} closeIcon dimmer="blurring">
      <Modal.Header content="PAYMENT VERIFICATION" />
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field
            label="Change receipt (Optional)"
            asset_code={AssetCodes.proof_of_payment}
            control={DocumentUpload}
            {...field('doc')}
          />
          <Button
            type="submit"
            primary
            fluid
            content="Continue"
            data-test-id="finish-trade-payment-approval"
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
const TradePaymentActionDetails = ({ action }) => {
  const trade = action.actionable
  const approval = useApproval(action.approvals, ApprovalType.TRADE_PAYMENT)
  const payment = trade.payments.find(
    payment => payment.payment_role === TradeActors.BUYER
  )
  const isReady = approval.status === ApprovalStatus.INITIALIZED
  const isVerified =
    payment.status === PaymentStatus.VERIFIED &&
    payment.tx_status === TxStatus.VERIFIED

  const approveModal = useModal()
  const successModal = useModal()
  const [approve, { loading }] = useMutation(APPROVE_TRADE_PAYMENT, {
    refetchQueries: [
      {
        query: GET_PROVIDER_ACTION_DETAIL,
        variables: { uuid: action.uuid },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: successModal.show,
    client,
  })

  const handleSubmit = async doc => {
    const variables = { provider_action_id: action.uuid }
    if (doc) variables.document = doc.uuid
    await approve({ variables })
  }

  return (
    <>
      <PageLoader active={loading} />
      <ApproveForm {...approveModal.props} onSubmit={handleSubmit} />
      <SuccessModal {...successModal.props} />
      <TabMenu defaultTab="trade-details">
        <TabMenu.Left>
          <TabMenu.Menu>
            <TabMenu.Item name="trade-details" />
            <TabMenu.Item name="transactions" />
            <TabMenu.Item name="asset-details" />
          </TabMenu.Menu>
          {isReady && (
            <>
              <br />
              <Button
                fluid
                color="green"
                content="Verify payment"
                onClick={approveModal.show}
                data-test-id="start-trade-payment-approval"
              />
            </>
          )}
          {isVerified && (
            <>
              <SubMessage success content="Receipt verified" icon="check" />
            </>
          )}
          {!isReady && !isVerified && (
            <>
              <SubMessage
                info
                loading
                content={Info.WAITING_BLOCKCHAIN}
                icon="spinner"
              />
            </>
          )}
        </TabMenu.Left>
        <TabMenu.Right>
          <TabMenu.Pane name="trade-details">
            <Header as="h4" content="Payment receipt" />
            <FileLink file={payment.payment_proof} />
            <Divider />
            <TradeCommonDetails trade={trade} />
          </TabMenu.Pane>
          <TabMenu.Pane name="transactions">
            <TradeTxsTable trade={trade} />
          </TabMenu.Pane>
          <TabMenu.Pane name="asset-details">
            <ContractCommonDetails
              contract={trade.accepted_offer.listing.token.contract}
            />
          </TabMenu.Pane>
        </TabMenu.Right>
      </TabMenu>
    </>
  )
}

export default TradePaymentActionDetails
