import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { Popup, Button } from 'semantic-ui-react'
import { AgeInfo } from '../'
import { EXPORT_CONTRACT, client } from '../../util/API/Apollo'

const ExportContract = ({ contract, refetch }) => {
  const [exportContract, { loading }] = useMutation(EXPORT_CONTRACT, {
    variables: { asset_id: contract.uuid },
    onCompleted: refetch,
    client,
  })

  return (
    <>
      {contract.latest_export && (
        <Popup
          inverted
          position="top center"
          content="Download"
          trigger={
            <Button
              floated="right"
              icon="download"
              as="a"
              href={_.get(contract, 'latest_export.export_file')}
              target="_blank"
            />
          }
        />
      )}
      <Popup
        inverted
        position="top center"
        content={
          <>
            Latest Export:{' '}
            <AgeInfo
              iso
              simple
              str={_.get(contract, 'latest_export.created_at')}
            />
          </>
        }
        disabled={!contract.latest_export}
        trigger={
          <Button
            floated="right"
            content="Export Asset"
            onClick={exportContract}
            loading={loading}
          />
        }
      />
    </>
  )
}

export default ExportContract
