import React from 'react'
import { Button, Form, Input, Header } from 'semantic-ui-react'
import { DateField, TaxIdInput } from '../../../input'
import { profileDetailsSchema } from '../../schema'

const BasicInfoStep = ({ formHook, stepsHook }) => {
  const { field, validate } = formHook
  const handleSubmit = async () => {
    if (await validate(profileDetailsSchema)) {
      stepsHook.nextStep()
    }
  }
  return (
    <>
      <Header as="h3" content="BASIC INFO" />
      <Form id="basic-info-form" onSubmit={handleSubmit} noValidate>
        <Form.Field
          fluid
          label="First name"
          placeholder="e.g. John"
          icon="user"
          iconPosition="left"
          required
          control={Input}
          {...field('firstname')}
        />
        <Form.Field
          fluid
          label="Last name"
          placeholder="e.g. Smith"
          icon="user"
          iconPosition="left"
          required
          control={Input}
          {...field('lastname')}
        />
        <DateField
          fluid
          label="Birthday"
          placeholder="e.g. 10-05-1957"
          icon="calendar"
          iconPosition="left"
          required
          past
          {...field('date_of_birth')}
        />
        <Form.Field
          fluid
          label="Tax ID / EIN"
          placeholder="e.g. 11 - 1111111"
          icon="id card"
          iconPosition="left"
          required
          control={TaxIdInput}
          {...field('tax_id')}
        />
        <Button
          primary
          floated="right"
          content="Next"
          type="submit"
          data-test-id="profile-form-next"
        />
        <Button
          floated="right"
          content="Back"
          type="button"
          onClick={stepsHook.prevStep}
        />
      </Form>
      <br />
      <br />
    </>
  )
}

export default BasicInfoStep
