import React, { useState } from 'react'
import _ from 'lodash'
import * as yup from 'yup'
import { useMutation } from 'react-apollo'
import {
  Grid,
  Header,
  Form,
  Button,
  Input,
  Message,
  Icon,
} from 'semantic-ui-react'
import { SuccessModal } from '../../components/confirm'
import { useModal } from '../../util/hooks'
import { CHANGE_PASSWORD, client } from '../../util/API/Apollo'
import useForm from '../../util/hooks/form'

/* eslint-disable no-template-curly-in-string */
const schema = yup
  .object()
  .shape({
    current_password: yup.string().required('Current password is required'),
    password_confirmation: yup
      .string()
      .required('New password confirmation is required'),
    password: yup
      .string()
      .required('New password is required')
      .min(8, 'New password must be at least ${min} characters')
      .max(20, 'New password cannot exceed ${max} characters'),
  })
  .test(
    'password-match',
    'Passwords do not match',
    value => value.password === value.password_confirmation
  )

const initialForm = {
  current_password: '',
  password: '',
  password_confirmation: '',
}

const LoginAndSecurity = () => {
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const successModal = useModal()
  const { form, field, validate, clear } = useForm(
    'change-password',
    initialForm,
    {
      schema,
      abortEarly: true,
    }
  )

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    variables: { input: form },
    onError: _.noop,
    onCompleted: () => {
      successModal.show()
      setIsChangingPassword(false)
      clear()
    },
    client,
  })

  const handleSubmit = async () => {
    if (await validate()) {
      await changePassword()
    }
  }

  return (
    <>
      <SuccessModal
        header="Your password has been changed!"
        content="You can login with the new password now"
        open={successModal.isOpen}
        onClose={successModal.hide}
      />

      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">LOGIN & SECURITY</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            {/*<Header sub content="Password" />*/}
            {/*<p>Last updated 2 hours ago </p>*/}
            <Button
              size="mini"
              content={isChangingPassword ? 'Cancel' : 'Change password'}
              onClick={() => setIsChangingPassword(!isChangingPassword)}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="middle"></Grid.Column>
        </Grid.Row>

        {isChangingPassword && (
          <Grid.Row>
            <Grid.Column computer="4">
              <Header as="h4" content="You're changing your password now." />

              <Message warning>
                <Message.Content>
                  <Icon name="info" />
                  You will be logged out after you change your password. You
                  will need to sign in again with your new password.
                </Message.Content>
              </Message>

              <Form onSubmit={handleSubmit}>
                <Form.Input
                  label="Current password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="●●●●●●"
                  type="password"
                  required
                  control={Input}
                  {...field('current_password')}
                />
                <Form.Input
                  label="New password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="●●●●●●"
                  type="password"
                  required
                  control={Input}
                  {...field('password')}
                />
                <Form.Input
                  label="Confirm password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="●●●●●●"
                  type="password"
                  required
                  control={Input}
                  {...field('password_confirmation')}
                />
                <Button
                  type="submit"
                  color="purple"
                  content="change password"
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default LoginAndSecurity
