import React from 'react'
import { Header, Segment, Button, Divider, Icon } from 'semantic-ui-react'
import { UserType } from '../../../../util/Constant'

const AccountTypeStep = ({ formHook, stepsHook, isProvider, isCustomer }) => {
  const { set } = formHook

  const handleSubmit = userType => {
    if (isProvider) {
      set('provider_type', userType)
    } else {
      set('user_type', userType)
    }
    stepsHook.nextStep()
  }

  return (
    <>
      {!isProvider ? (
        <>
          <Header as="h3" content="SELECT ACCOUNT TYPE" />
          <Segment
            basic
            textAlign="center"
            padded="very"
            className="clickable"
            onClick={() => handleSubmit(UserType.INDIVIDUAL)}
          >
            <Header as="h4" icon className="uppercase">
              <Icon name="user" />
              Individual Account
            </Header>
            <p>If you are trading for your own personal account</p>
            <Button
              primary
              fluid
              content="continue"
              name={UserType.INDIVIDUAL}
              data-test-id={`select-user-type-${UserType.INDIVIDUAL}`}
            />
          </Segment>
          <Divider horizontal content="or" />
          <Segment
            basic
            textAlign="center"
            padded="very"
            className="clickable"
            onClick={() => handleSubmit(UserType.INSTITUTIONAL)}
          >
            <Header as="h4" className="uppercase" icon>
              <Icon name="building" /> Institutional Account
            </Header>
            <p>If you are trading on behalf of an organization</p>
            <Button
              primary
              fluid
              content="continue"
              name={UserType.INSTITUTIONAL}
              data-test-id={`select-user-type-${UserType.INSTITUTIONAL}`}
            />
          </Segment>
        </>
      ) : (
        <>
          <Header as="h3" content="SELECT PROVIDER TYPE" />
          <Button
            fluid
            primary
            content="ADD A BROKER DEALER"
            name={UserType.BROKER_DEALER}
            onClick={() => handleSubmit(UserType.BROKER_DEALER)}
          />
          <Divider horizontal content="or" />
          <Button
            fluid
            primary
            content="ADD A FINANCIAL ADVISOR"
            name={UserType.FINANCIAL_ADVISOR}
            onClick={() => handleSubmit(UserType.FINANCIAL_ADVISOR)}
          />
        </>
      )}
    </>
  )
}
export default AccountTypeStep
