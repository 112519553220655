import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import { OfferDirection, OfferStatus } from '../../util/Constant'

const OfferStatusMessage = ({ offer }) => {
  const isCanceled = offer.status === OfferStatus.CANCELED
  const isRejected = offer.status === OfferStatus.REJECTED
  const isAccepted = offer.status === OfferStatus.ACCEPTED
  const isAlreadyOffered =
    offer.status === OfferStatus.INITIALIZED &&
    offer.direction === OfferDirection.OUTGOING

  return (
    <>
      {isCanceled && (
        <Message color="red">
          <Icon name="exclamation triangle" size="small" />
          Bidding process ended, because last offer was canceled.
        </Message>
      )}
      {isRejected && (
        <Message color="red">
          <Icon name="exclamation triangle" size="small" />
          Bidding process ended, because last offer was rejected.
        </Message>
      )}
      {isAccepted && (
        <Message color="green">
          <Icon name="check" size="small" />
          Bidding process ended, because last offer was approved.
        </Message>
      )}
      {isAlreadyOffered && (
        <Message color="blue">
          <Icon name="time" size="small" />
          You have already an offer to this listing.
        </Message>
      )}
    </>
  )
}

export default OfferStatusMessage
