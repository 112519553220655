import React from 'react'
import { Icon, Popup, Table } from 'semantic-ui-react'
import { AgeInfo, AmountLabel, AskPriceLabel, IfUserIs } from '../index'
import { OfferDirection } from '../../util/Constant'
import { useMe } from '../../util/hooks/me'
import { ContractLink, DetailsButton, UserLink } from '../link'

const OffersTableLegend = () => (
  <>
    <p>
      <Icon name="arrow up" color="green" />: Outgoing offer
    </p>
    <p>
      <Icon name="arrow down" color="blue" />: Incoming offer
    </p>
  </>
)

const OfferDirectionLabel = ({ offer }) => {
  if (offer.direction === OfferDirection.INCOMING) {
    return (
      <Popup
        inverted
        content="Incoming Offer"
        position="top center"
        trigger={<Icon name="arrow down" className="mr-5" color="blue" />}
      />
    )
  } else if (offer.direction === OfferDirection.OUTGOING) {
    return (
      <Popup
        inverted
        content="Outgoing Offer"
        position="top center"
        trigger={<Icon name="arrow up" className="mr-5" color="green" />}
      />
    )
  }
}

const OffersTable = ({ offers, pagination }) => {
  const me = useMe()

  return (
    <Table basic="very" padded="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="UNDERLYING ASSET" />
          <Table.HeaderCell content="OFFER TO" />
          <Table.HeaderCell content="OFFER AMOUNT" />
          <Table.HeaderCell content="OFFER PRICE" />
          <Table.HeaderCell content="AGE" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map(offer => {
          const {
            hashid,
            direction,
            token_amount,
            token_price,
            created_at,
            listing,
            from,
            to,
          } = offer

          return (
            <Table.Row
              positive={me.isCustomer && direction === OfferDirection.OUTGOING}
              negative={me.isCustomer && direction === OfferDirection.INCOMING}
              key={hashid}
            >
              <Table.Cell>
                <IfUserIs customer>
                  <OfferDirectionLabel offer={offer} />
                </IfUserIs>
                <ContractLink contract={listing.token.contract} />
              </Table.Cell>
              <Table.Cell>
                <UserLink user={from} />
                <Icon name="arrow right" color="red" />
                <UserLink user={to} />
              </Table.Cell>
              <Table.Cell>
                <AmountLabel amount={token_amount} />
              </Table.Cell>
              <Table.Cell>
                <AskPriceLabel price={token_price} />
              </Table.Cell>
              <Table.Cell>
                <AgeInfo iso str={created_at} />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <DetailsButton offer={offer} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={4}>
            <OffersTableLegend />
          </Table.HeaderCell>
          {pagination && <Table.HeaderCell colSpan={7} content={pagination} />}
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default OffersTable
