import React from 'react'
import { StatisticsWidget } from '../Widget'
import { ContentLoader } from '../../'

const dataSchema = [
  {
    title: 'TOTAL',
    key: 'count',
  },
  {
    title: 'PENDING',
    key: 'pending_providers_count',
  },
  {
    title: 'BROKER DEALER',
    key: 'broker_dealer_count',
  },
  {
    title: 'FINANCIAL ADVISOR',
    key: 'financial_advisor_count',
  },
]

const ProvidersWidget = ({
  data,
  loading,
  divided,
  width,
  color,
  inverted,
  noTitle,
  horizontal,
  style,
  className,
}) => {
  if (loading) return <ContentLoader />

  return (
    <StatisticsWidget
      data={data}
      dataSchema={dataSchema}
      title="PROVIDERS SUMMARY"
      noTitle={noTitle}
      divided={divided}
      width={width}
      inverted={inverted}
      color={color}
      horizontal={horizontal}
      style={style}
      className={className}
    />
  )
}

export default ProvidersWidget
