import React from 'react'
import { Divider, Header, Icon } from 'semantic-ui-react'
import {
  AssetCodeNames,
  ContactDistributionFrequency,
  ContractShareType,
  ContractType,
  ContractTypeNames,
  Info,
  TxStatus,
} from '../../util/Constant'
import {
  AgeInfo,
  DateInfo,
  NumberFormat,
  PopupIcon,
  SubMessage,
} from '../index'
import ExportContract from './ExportContract'
import { useMe } from '../../util/hooks/me'
import {
  CompanyLink,
  EtherscanButton,
  FileLink,
  UserLink,
  WebsiteLink,
} from '../link'

const RofrIcon = ({ contract }) => {
  return contract.rofr_status ? (
    <PopupIcon
      name="times"
      color="pink"
      content="The issuer may invoke RoFR"
      size="large"
    />
  ) : (
    <PopupIcon
      name="check"
      color="green"
      content="The issuer will not invoke RoFR"
      size="large"
    />
  )
}

const RestrictionIcon = ({ contract }) => {
  return contract.restriction_status ? (
    <PopupIcon
      name="times"
      color="pink"
      content="This asset has Restriction"
      size="large"
    />
  ) : (
    <PopupIcon
      name="check"
      color="green"
      content="This asset has no Restriction"
      size="large"
    />
  )
}

const ContractCommonDetails = ({ contract, refetch }) => {
  const me = useMe()
  const { details } = contract
  const isSafe = contract.contract_type === ContractType.SAFE
  const isEquity = contract.contract_type === ContractType.EQUITY
  const isCN = contract.contract_type === ContractType.CONVERTIBLE_NOTE
  const isFOF = contract.contract_type === ContractType.FOF
  const isLP = contract.contract_type === ContractType.VC

  return (
    <>
      {contract.tx_status === TxStatus.PENDING && (
        <SubMessage
          info
          content={Info.WAITING_BLOCKCHAIN}
          icon="spinner"
          loading
        />
      )}

      {(me.isOwner({ contract }) || me.isTransferAgent) && (
        <ExportContract contract={contract} refetch={refetch} />
      )}

      <Header as="h4" content="Asset ID" />
      <div>{contract.details.fund_name || contract.hashid}</div>
      <Divider />

      <Header as="h4" content="Asset type" />
      <div>{ContractTypeNames[contract.contract_type]}</div>
      <Divider />

      <Header as="h4" content="Age" />
      <AgeInfo iso str={contract.created_at} />
      <Divider />

      {contract.tx_hash && (
        <>
          <Header as="h4" content="Creation tx" />
          <WebsiteLink href={contract.verify_link}>
            {contract.tx_hash}
          </WebsiteLink>
          <Divider />
        </>
      )}

      <Header as="h4" content="Created by" />
      <UserLink user={contract.owner} />
      <Divider />

      <Header as="h4" content="Issuer" />
      <CompanyLink company={contract.issuer} />
      <Divider />
      {details.fund_name && (
        <>
          <Header as="h4" content="Fund name" />
          <div>{details.fund_name}</div>
          <Divider />
        </>
      )}

      <Header as="h4" content="Issue Date" />
      <DateInfo api str={contract.agreement_date} />
      <Divider />

      {(isSafe || isCN || isFOF || isLP) && (
        <>
          <Header as="h4" content="Fund amount" />
          <NumberFormat usd amount={contract.token_numbers.initial} />
          <Divider />
        </>
      )}
      {(isSafe || isCN) && details.convert_to && (
        <>
          <Header as="h4" content="Convert to" />
          <div>{details.convert_to}</div>
          <Divider />
        </>
      )}
      {details.accrual_frequency && (
        <>
          <Header as="h4" content="Accrual frequency" />
          <div>{ContactDistributionFrequency[details.accrual_frequency]}</div>
          <Divider />
        </>
      )}
      {details.discount_rate && (
        <>
          <Header as="h4" content="Interest rate" />
          <>
            <Icon name="percent" />
            <NumberFormat float amount={details.discount_rate} />
          </>
          <Divider />
        </>
      )}
      {details.maturity_date && (
        <>
          <Header as="h4" content="Maturity date" />
          <DateInfo api str={details.maturity_date} />
          <Divider />
        </>
      )}
      {details.qualified_financing_amount && (
        <>
          <Header as="h4" content="Qualified financing amount" />
          <NumberFormat usd amount={details.qualified_financing_amount} />
          <Divider />
        </>
      )}
      {details.early_exit_multiple && (
        <>
          <Header as="h4" content="Early exit multiple" />
          <NumberFormat float amount={details.early_exit_multiple} />
          <Divider />
        </>
      )}
      {details.valuation_cap && (
        <>
          <Header as="h4" content="Valuation cap" />
          <NumberFormat usd amount={details.valuation_cap} />
          <Divider />
        </>
      )}
      {details.discount_rate && (
        <>
          <Header as="h4" content="Discount rate" />
          <div>
            <Icon name="percent" />
            <NumberFormat float amount={details.discount_rate} />
          </div>
          <Divider />
        </>
      )}
      {details.face_value && (
        <>
          <Header as="h4" content="Face value" />
          <NumberFormat usd digits={3} amount={details.face_value} />
          <Divider />
        </>
      )}
      {details.number_of_shares && (
        <>
          <Header as="h4" content="Number of shares" />
          <div>
            <Icon name="file" />{' '}
            <NumberFormat amount={details.number_of_shares} />
          </div>
          <Divider />
        </>
      )}
      {isEquity && details.convert_to && (
        <>
          <Header as="h4" content="Class" />
          <div>{details.convert_to}</div>
          <Divider />
        </>
      )}
      {details.class_name && (
        <>
          <Header as="h4" content="Class name" />
          <div>{details.class_name}</div>
          <Divider />
        </>
      )}
      {details.class_prefix && (
        <>
          <Header as="h4" content="Stock certificate class prefix" />
          <div>{details.class_prefix}</div>
          <Divider />
        </>
      )}
      {details.seniority && (
        <>
          <Header as="h4" content="Seniority" />
          <div>{details.seniority}</div>
          <Divider />
        </>
      )}
      {details.pre_money_valuation && (
        <>
          <Header as="h4" content="Pre-money valuation" />
          <div>{details.pre_money_valuation}</div>
          <Divider />
        </>
      )}
      {details.share_type && (
        <>
          <Header as="h4" content="Share type" />
          <div>{ContractShareType[details.share_type]}</div>
          <Divider />
        </>
      )}
      {details.nominal_value_per_share && (
        <>
          <Header as="h4" content="Nominal value of share" />
          <NumberFormat usd float amount={details.nominal_value_per_share} />
          <Divider />
        </>
      )}
      {details.distribution_per_annum && (
        <>
          <Header as="h4" content="Distribution per annum" />
          <div>
            <Icon name="percent" />
            <NumberFormat float amount={details.nominal_value_per_share} />
          </div>

          <Divider />
        </>
      )}
      {details.distribution_currency && (
        <>
          <Header as="h4" content="Distribution currency" />
          <div>{details.distribution_currency}</div>
          <Divider />
        </>
      )}
      {details.distribution_frequency && (
        <>
          <Header as="h4" content="Distribution frequency" />
          <div>{ContactDistributionFrequency[details.accrual_frequency]}</div>
          <Divider />
        </>
      )}
      {details.investment_period && (
        <>
          <Header as="h4" content="Investment period" />
          <div>{details.investment_period}</div>
          <Divider />
        </>
      )}
      {details.initial_closing_date && (
        <>
          <Header as="h4" content="Initial closing date" />
          <DateInfo api str={details.initial_closing_date} />
          <Divider />
        </>
      )}
      {details.final_closing_date && (
        <>
          <Header as="h4" content="Final closing date" />
          <DateInfo api str={details.final_closing_date} />
          <Divider />
        </>
      )}
      {details.hurdle_rate && (
        <>
          <Header as="h4" content="Hurdle rate" />
          <div>
            <Icon name="percent" />
            <NumberFormat float amount={details.hurdle_rate} />
          </div>
          <Divider />
        </>
      )}
      {details.irr && (
        <>
          <Header as="h4" content="IRR" />
          <div>{details.irr}</div>
          <Divider />
        </>
      )}
      {details.as_of && (
        <>
          <Header as="h4" content="As of" />
          <div>{details.as_of}</div>
          <Divider />
        </>
      )}
      {details.comments && (
        <>
          <Header as="h4" content="Comments" />
          <p>{details.comments}</p>
          <Divider />
        </>
      )}
      {contract.documents.map((doc, index) => {
        return (
          <React.Fragment key={`${doc.document.uuid}-${index}`}>
            <Header as="h4" content={AssetCodeNames[doc.document.asset_code]} />
            <FileLink file={doc.document} />
            <Divider />
          </React.Fragment>
        )
      })}
      {
        <>
          <Header as="h4" content="RoFR / Restriction" />
          <div>
            <RofrIcon contract={contract} />
            <RestrictionIcon contract={contract} />
          </div>
          <Divider />
        </>
      }
      {contract.verify_link && (
        <>
          <Header as="h4" content="Smart contract" />
          <EtherscanButton contract={contract} />
        </>
      )}
    </>
  )
}

export default ContractCommonDetails
