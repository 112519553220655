import React from 'react'
import { Divider, Header } from 'semantic-ui-react'
import { DateInfo } from '../index'

const ContractERPDetails = ({ contract }) => {
  const { details } = contract
  return (
    <>
      <Header as="h4" content="Lock-up period" />
      <div>{details.lockup_period}</div>
      <Divider />
      <Header as="h4" content="From date" />
      <DateInfo api str={details.early_redemption_from_date} />
      <Divider />
      <Header as="h4" content="To date" />
      <DateInfo api str={details.early_redemption_to_date} />
      <Divider />
      <Header as="h4" content="Early redemption penalty" />
      <div>{details.early_redemption_penalty}</div>
      <Divider hidden />
    </>
  )
}

export default ContractERPDetails
