import { ApolloClient } from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import gql from 'graphql-tag'
import Pusher from 'pusher-js'
import debug from 'debug'
import customFetch from './customFetch'
import introspectionQueryResultData from './fragmentTypes.json'
import PusherLink from './PusherLink'

const log = debug('app:api')

Pusher.Runtime.createXHR = function() {
  const xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}
// Pusher.logToConsole = true

const subscriptionHost =
  process.env.REACT_APP_SUBSCRIPTION_HOST ||
  `subscription.${window.location.hostname}`
const graphqlUri =
  process.env.REACT_APP_GRAPHQL_URI || `${window.location.origin}/graphql`

const pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY || '2673518753198'

const pusher = new Pusher(pusherAppKey, {
  cluster: 'mt1',
  authEndpoint: `/graphql/subscriptions/auth`,
  wsHost: subscriptionHost,
  wsPort: 80,
})

pusher.connection.bind('state_change', function(states) {
  // states = {previous: 'oldState', current: 'newState'}
  console.log('Channels current state is ' + states.current)
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    new PusherLink({ pusher }),

    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) log(`[Network error]: ${networkError}`)
    }),
    new createUploadLink({
      uri: graphqlUri,
      credentials: 'include',
      fetch: customFetch,
    }),
  ]),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
})

const File = gql`
  fragment File on FileAsset {
    uuid
    asset_code
    original_filename
    hash
    link
    link_as_avatar
    title
    description
    size
    created_at
    updated_at
  }
`

const PaginatorInfo = gql`
  fragment PaginatorInfo on PaginatorInfo {
    count
    currentPage
    firstItem
    lastItem
    hasMorePages
    perPage
    lastPage
    total
  }
`

const Person = gql`
  fragment Person on BasicContact {
    firstname
    lastname
    email
    phonenumber
    photo {
      ...File
    }
  }
  ${File}
`

const UserBasic = gql`
  fragment UserBasic on User {
    uuid
    firstname
    lastname
    fullname
    email
    user_type
    is_verified
    profile {
      uuid
    }
  }
`

// company table info
const CompanyBasic = gql`
  fragment CompanyBasic on Company {
    uuid
    hashid
    slug
    company_type
    status
    linked_profile
    profile_score
    name
    email
    founded_date
    homepage
    phonenumber
    address
    city
    state
    zipcode
    country
    misc_info
    is_editable
    logo {
      ...File
    }
    company_owner: owner {
      # error in union
      uuid
    }
  }
  ${File}
`

// approval table info
const ApprovalBasic = gql`
  fragment ApprovalBasic on ApprovalProcess {
    approval_type
    status
    created_at
    reject_reason
    tx_hash
    tx_status
    created_at
    updated_at
    provider {
      uuid
      name
    }
    verification_data {
      document {
        ...File
      }
      proof_link
      proof_crd
      company_type
      amount
      comments
      description
      rofr
      restriction
      transferability_warning
    }
  }
  ${File}
`

const ContractBasic = gql`
  fragment ContractBasic on Contract {
    uuid
    hashid
    contract_type
    contract_address
    created_at
    updated_at
    total_trades
    agreement_date
    verify_link
    details
    tx_status
    rofr_status
    restriction_status
    owner {
      uuid
      fullname
    }
    issuer {
      uuid
      name
    }
    token_numbers {
      initial
      on_market
      total
      available
    }
  }
`

const UserBasicInfo = gql`
  fragment UserBasicInfo on UserInfo {
    uuid
    fullname
  }
`

const DataExportBasic = gql`
  fragment DataExportBasic on DataExportRecord {
    uuid
    export_file
    export_type
    details
    status
    created_at
    updated_at
  }
`

const FinancialDataDetails = gql`
  fragment FinancialDataDetails on BasicFinancialData {
    data_type
    year
    quarter
    data
    description
  }
`

const FinancialDocumentDetails = gql`
  fragment FinancialDocumentDetails on BasicFinancialDocument {
    title
    description
    document_file {
      uuid
      link
      original_filename
    }
  }
`

const ContractFull = gql`
  fragment ContractFull on Contract {
    ...ContractBasic
    status
    token_numbers {
      initial
      total
      available
      on_market
    }
    documents {
      document_key
      document {
        ...File
      }
    }
    financial_data {
      ...FinancialDataDetails
    }
    owner {
      uuid
      fullname
    }
    issuer {
      ...CompanyBasic
    }
    shareholder_invitations(page: 1, count: 100) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        uuid
        firstname
        lastname
        company
        email
        phonenumber
        share_amount
        status
      }
    }
    shareholders(page: 1, count: 100) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        fullname
        shares
        invested_as
      }
    }
    latest_export {
      ...DataExportBasic
    }
  }
  ${ContractBasic}
  ${CompanyBasic}
  ${File}
  ${FinancialDataDetails}
  ${DataExportBasic}
  ${PaginatorInfo}
`

const TokenBasic = gql`
  fragment TokenBasic on Token {
    uuid
    hashid
    created_at
    updated_at
    amount
    status
    tx_status
    transferability {
      status
      restricted
    }
    token_numbers {
      initial
      available
      on_market
      total
    }
    owner {
      uuid
    }
    contract {
      uuid
      details
    }
  }
`

const ListingBasic = gql`
  fragment ListingBasic on MarketplaceListing {
    uuid
    hashid
    amount
    price
    status
    market_data
    created_at
    updated_at
    currencies
    seller {
      uuid
      fullname
    }
    has_offers
  }
`

const ListingRow = gql`
  fragment ListingRow on MarketplaceListing {
    ...ListingBasic
    solicited {
      subject
      message
    }
    seller {
      uuid
      fullname
    }
    token {
      hashid
      owner {
        uuid
        fullname
      }
      transferability {
        restricted
        status
      }
      contract {
        uuid
        hashid
        details
        issuer {
          uuid
          name
        }
      }
    }
  }
  ${ListingBasic}
`

const OfferBasic = gql`
  fragment OfferBasic on Offer {
    uuid
    hashid
    status
    token_price
    token_amount
    created_at
    updated_at
    direction
    from {
      ...UserBasicInfo
    }
    to {
      ...UserBasicInfo
    }
  }
  ${UserBasicInfo}
`

const OfferRow = gql`
  fragment OfferRow on Offer {
    ...OfferBasic
    listing {
      token {
        uuid
        contract {
          uuid
          hashid
          contract_type
          details
          issuer {
            uuid
            name
          }
        }
      }
      seller {
        uuid
        fullname
      }
    }
  }
  ${OfferBasic}
`

const TradeBasic = gql`
  fragment TradeBasic on Trade {
    uuid
    hashid
    created_at
    updated_at
    tx_status
    trade_role
    trade_index
    accepted_offer {
      token_amount
      token_price
      direction
    }
  }
`

const PaymentBasic = gql`
  fragment PaymentBasic on Payment {
    uuid
    tx_hash
    tx_status
    payment_type
    status
    payment_role
    amount
    currency
  }
`

const ProfileDetails = gql`
  fragment ProfileDetails on User {
    ...UserBasic
    is_verified
    password_reminder
    has_broker_dealer
    profile {
      uuid
      phonenumber
      date_of_birth
      address
      country
      state
      city
      zipcode
      email_alt
      phonenumber_alt
      tax_id
      company_position
      bank_info {
        bank_name
        account_number
        rtn_aba
        swift_bic
      }
      photo {
        ...File
      }
      company {
        ...CompanyBasic
      }
      letter_of_authorization {
        ...File
      }
    }
    primary_wallet {
      uuid
      address
    }
    provider_invitations(count: 100, page: 1) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        fullname
        email
        phonenumber
        company
        position
        invitation_type
      }
    }
    provider_requests {
      uuid
      hashid
      action_type
      status
      created_at
      updated_at
      provider {
        name
        provides
        company {
          uuid
          name
        }
        officers {
          uuid
          fullname
        }
      }
    }
    providers {
      uuid
      homepage
      provides
      contract_address
      company {
        uuid
        name
      }
      officers {
        uuid
        firstname
        lastname
        fullname
        email
        user_type
        profile {
          company_position
          phonenumber
          photo {
            link_as_avatar
          }
        }
      }
    }
    approvals(count: 100) {
      paginatorInfo {
        total
      }
      data {
        ...ApprovalBasic
      }
    }
  }
  ${File}
  ${CompanyBasic}
  ${ApprovalBasic}
  ${UserBasic}
  ${PaginatorInfo}
`

export const REGISTER = gql`
  mutation($input: RegisterInput) {
    register(input: $input) {
      uuid
    }
  }
`

export const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      payload {
        jwt_payload
        jwt_type
        expires_in
        expires_at
      }
      me {
        ...UserBasic
        is_verified
        password_reminder
        primary_wallet {
          uuid
          address
        }
        profile {
          photo {
            ...File
          }
        }
        providers {
          uuid
          provides
        } # for sidebar marketplace
      }
    }
  }
  ${UserBasic}
  ${File}
`

export const IS_ACTIVATED = gql`
  query($email: String!) {
    isActivated(email: $email)
  }
`

export const GET_ME = gql`
  query {
    me {
      ...UserBasic
      is_verified
      password_reminder
      primary_wallet {
        uuid
        name
        address
      }
      profile {
        photo {
          ...File
        }
      }
      providers {
        uuid
        provides
      } # for sidebar marketplace
    }
  }
  ${File}
  ${UserBasic}
`

export const ACTIVATE_ACCOUNT = gql`
  mutation($input: ActivateInput) {
    activate(input: $input) {
      uuid
    }
  }
`

export const RESEND_ACTIVATION = gql`
  mutation($email: String!) {
    resendActivation(email: $email) {
      uuid
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation($input: ChangePasswordInput) {
    changePassword(input: $input) {
      uuid
    }
  }
`

export const REQUEST_RESET_PASSWORD = gql`
  mutation($email: String!) {
    requestResetPassword(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      uuid
    }
  }
`

export const LOGOUT = gql`
  mutation {
    logout
  }
`

export const GET_MY_WALLET = gql`
  query {
    me {
      uuid
      primary_wallet {
        uuid
        name
        address
        created_at
        updated_at
      }
    }
  }
`

export const UPLOAD_DOCUMENT = gql`
  mutation($asset: Upload!, $asset_code: String) {
    uploadDocument(asset: $asset, asset_code: $asset_code) {
      ...File
    }
  }
  ${File}
`

export const UPLOAD_MEDIA = gql`
  mutation($asset: Upload!, $title: String, $description: String) {
    uploadMedia(asset: $asset, title: $title, description: $description) {
      uuid
      original_filename
      mime_type
      title
      description
      link
    }
  }
`

export const UPLOAD_IMAGE = gql`
  mutation($asset: Upload!, $asset_code: String) {
    uploadImage(asset: $asset, asset_code: $asset_code) {
      ...File
    }
  }
  ${File}
`

export const GET_EXCHANGE_RATE = gql`
  query {
    exchange {
      uuid
      currency
      price
      updated_at
    }
  }
`

export const GET_PROFILE = gql`
  query {
    me {
      ...ProfileDetails
    }
  }
  ${ProfileDetails}
`

export const GET_PUBLIC_PROFILE = gql`
  query($user_id: UUID!) {
    publicProfile(user_id: $user_id) {
      uuid
      fullname
      user_type
      wallet_address
      photo
      company {
        uuid
        name
      }
      position
      transfer_agent
      broker_dealer
      financial_advisor
    }
  }
`

export const GET_FILES = gql`
  query($count: Int!, $page: Int) {
    me {
      files(count: $count, page: $page) {
        paginatorInfo {
          ...PaginatorInfo
        }
        data {
          ...File
        }
      }
    }
  }
  ${File}
  ${PaginatorInfo}
`

export const GET_MEDIA = gql`
  query($count: Int!, $page: Int) {
    me {
      media(count: $count, page: $page) {
        paginatorInfo {
          ...PaginatorInfo
        }
        data {
          ...File
        }
      }
    }
  }
  ${File}
  ${PaginatorInfo}
`

export const GET_MEDIA_STORAGE = gql`
  query {
    me {
      media_storage {
        total
        used
        percentage
      }
    }
  }
`

export const REMOVE_SHARED_FILE = gql`
  mutation($media_id: UUID!) {
    removeMedia(media_id: $media_id)
  }
`

export const GET_USER_DETAILS = gql`
  query($uuid: String!) {
    user(uuid: $uuid) {
      ...ProfileDetails
    }
  }
  ${ProfileDetails}
`

export const GET_DASHBOARD = gql`
  query {
    dashboard
  }
`

export const GET_DASHBOARD_EXTRA = gql`
  query {
    me {
      solicitations(count: 5) {
        paginatorInfo {
          total
        }
        data {
          listing_id
          listing_hashid
          subject
          message
          created_at
        }
      }
      signatures(count: 1) {
        paginatorInfo {
          total
        }
      }
      trade_payments(count: 1) {
        paginatorInfo {
          total
        }
      }
      recent_activity(count: 5) {
        paginatorInfo {
          ...PaginatorInfo
        }
        data {
          description
          created_at
        }
      }
    }
    pendingOffers(count: 5) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...OfferBasic
        owner {
          uuid
          fullname
        }
      }
    }
    pendingTrades(count: 5) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...TradeBasic
        status
        seller_info {
          ...UserBasicInfo
        }
        buyer_info {
          ...UserBasicInfo
        }
      }
    }
  }
  ${OfferBasic}
  ${TradeBasic}
  ${PaginatorInfo}
  ${UserBasicInfo}
`

export const GET_ACCOUNT_ACTIVITY = gql`
  query($page: Int, $count: Int!) {
    me {
      recent_activity(page: $page, count: $count) {
        paginatorInfo {
          ...PaginatorInfo
        }
        data {
          description
          created_at
        }
      }
    }
  }
  ${PaginatorInfo}
`

export const CREATE_PROFILE = gql`
  mutation($input: CreateProfileInput!) {
    createProfile(input: $input) {
      uuid
    }
  }
`

export const ADD_WALLET = gql`
  mutation($input: AddWalletInput) {
    addWallet(input: $input) {
      uuid
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation($input: CreateProfileInput!) {
    updateProfile(input: $input) {
      uuid
    }
  }
`

export const GET_STATES = gql`
  query($country: String!) {
    states(country: $country) {
      id
      name
      country_id
      country_code
    }
  }
`

export const GET_CITIES = gql`
  query($country: String!, $state: Int) {
    cities(country: $country, state: $state) {
      name
      state_id
      state_code
    }
  }
`

export const ADD_NEW_CUSTOMER = gql`
  mutation($input: AddNewCustomerInput!) {
    addNewCustomer(input: $input) {
      uuid
    }
  }
`

export const ADD_NEW_PROVIDER = gql`
  mutation($input: AddNewProviderInput!) {
    addNewProvider(input: $input) {
      uuid
    }
  }
`

export const GET_PROVIDER_CUSTOMERS = gql`
  query($orderBy: [OrderByClause!], $count: Int!, $page: Int) {
    providerCustomers(orderBy: $orderBy, count: $count, page: $page) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...UserBasic
        created_at
        approvals(count: 100, page: 1) {
          data {
            approval_type
            status
            tx_status
          }
        }
      }
    }
  }
  ${PaginatorInfo}
  ${UserBasic}
`

export const GET_SYSTEM_PROVIDERS = gql`
  query($orderBy: [OrderByClause!], $count: Int!, $page: Int) {
    systemProviders(orderBy: $orderBy, count: $count, page: $page) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...UserBasic
        created_at
        approvals(count: 100, page: 1) {
          data {
            approval_type
            status
            tx_status
          }
        }
        profile {
          company {
            uuid
            name
          }
          company_position
        }
        officer_of {
          uuid
        }
      }
    }
  }
  ${PaginatorInfo}
  ${UserBasic}
`

export const GET_PROVIDER_ACTION_COUNTS = gql`
  query {
    customerApproval: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: CUSTOMER_APPROVAL
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    customerMsg: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: CUSTOMER_MSG
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    assetTransferability: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: ASSET_TRANSFERABILITY
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    providerApproval: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: PROVIDER_APPROVAL
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    providerRequest: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: PROVIDER_REQUEST
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    companyVerification: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: COMPANY_VERIFICATION
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
    tradeApproval: providerActions(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 5
      action_type: TRADE_APPROVAL
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
    }
  }
  ${PaginatorInfo}
`

export const GET_PROVIDER_ACTIONS = gql`
  query(
    $orderBy: [OrderByClause!]
    $count: Int!
    $page: Int
    $action_type: ProviderActionType
  ) {
    providerActions(
      orderBy: $orderBy
      count: $count
      page: $page
      action_type: $action_type
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        uuid
        action_type
        status
        created_at
        updated_at
        user {
          ...UserBasic
        }
        approvals {
          ...ApprovalBasic
        }
        actionable {
          ... on User {
            uuid
            fullname
            user_type
          }
          ... on Profile {
            uuid
          }
          ... on Company {
            ...CompanyBasic
          }
          ... on Contract {
            uuid
            hashid
            contract_type
            tx_status
            issuer {
              uuid
              name
            }
            token_numbers {
              initial
            }
          }
          ... on Provider {
            uuid
            name
            provides
          }
          ... on Trade {
            ...TradeBasic
            seller_info {
              ...UserBasicInfo
            }
            buyer_info {
              ...UserBasicInfo
            }
          }
        }
      }
    }
  }
  ${PaginatorInfo}
  ${ApprovalBasic}
  ${CompanyBasic}
  ${UserBasic}
  ${TradeBasic}
  ${UserBasicInfo}
`

export const GET_PROVIDER_ACTION_DETAIL = gql`
  query($uuid: String!) {
    providerAction(uuid: $uuid) {
      uuid
      hashid
      action_type
      status
      created_at
      updated_at
      approvals {
        ...ApprovalBasic
      }
      actionable {
        ... on User {
          ...UserBasic
          primary_wallet {
            address
          }
          providers {
            provides
            officers {
              uuid
              fullname
            }
          }
          provider_requests {
            provider {
              provides
              officers {
                uuid
                fullname
              }
            }
          }
          provider_invitations(count: 10) {
            data {
              fullname
              invitation_type
            }
          }
          approvals(count: 10) {
            data {
              approval_type
              status
              tx_status
            }
          }
          profile {
            uuid
            phonenumber
            date_of_birth
            address
            country
            state
            city
            zipcode
            bank_info {
              bank_name
              account_number
              rtn_aba
              swift_bic
            }
            email_alt
            phonenumber_alt
            tax_id
            company_position
            letter_of_authorization {
              ...File
            }
            photo {
              ...File
            }
            company {
              ...CompanyBasic
            }
          }
        }
        ... on Company {
          ...CompanyBasic
          contact_people {
            ...Person
          }
          financial_data {
            ...FinancialDataDetails
          }
        }
        ... on Contract {
          ...ContractBasic
          owner {
            uuid
            fullname
            profile_photo
            user_type
            is_verified
            primary_wallet {
              address
            }
          }
          documents {
            document_key
            document {
              ...File
            }
          }
        }
        ... on Trade {
          ...TradeBasic
          accepted_offer {
            uuid
            token_amount
            token_price
            listing {
              ...ListingBasic
              token {
                ...TokenBasic
                owner {
                  uuid
                  fullname
                }
                contract {
                  ...ContractFull
                }
              }
            }
          }
          signatures {
            uuid
            signature_type
            signature_role
            tx_hash
            tx_status
          }
          payments {
            ...PaymentBasic
            payment_proof {
              ...File
            }
          }
          seller_info {
            ...UserBasicInfo
          }
          buyer_info {
            ...UserBasicInfo
          }
        }
      }
    }
  }
  ${File}
  ${CompanyBasic}
  ${ApprovalBasic}
  ${ContractBasic}
  ${UserBasic}
  ${Person}
  ${FinancialDataDetails}
  ${TradeBasic}
  ${ListingBasic}
  ${TokenBasic}
  ${ContractFull}
  ${PaymentBasic}
  ${UserBasicInfo}
`

export const REQUEST_NEW_CUSTOMER_DOCUMENT = gql`
  mutation($provider_action_id: UUID!, $approval_type: ApprovalType!) {
    requestNewCustomerDocument(
      provider_action_id: $provider_action_id
      approval_type: $approval_type
    )
  }
`

export const VERIFY_NEW_CUSTOMER_DOCUMENT = gql`
  mutation(
    $provider_action_id: UUID!
    $input: VerifyNewCustomerDocumentInput!
  ) {
    verifyNewCustomerDocument(
      provider_action_id: $provider_action_id
      input: $input
    )
  }
`

export const SUBMIT_VERIFICATION_INFO = gql`
  mutation($input: SubmitVerificationInfoInput!) {
    submitVerificationInfo(input: $input)
  }
`

export const SUBMIT_NEW_CUSTOMER_DOCUMENT = gql`
  mutation($input: SubmitVerificationInfoInput!) {
    submitNewCustomerDocument(input: $input)
  }
`

export const APPROVE_NEW_CUSTOMER_DOC = gql`
  mutation(
    $provider_action_id: UUID!
    $approval_type: ApprovalType!
    $signed_message: String!
  ) {
    approveNewCustomerDocument(
      provider_action_id: $provider_action_id
      approval_type: $approval_type
      signed_message: $signed_message
    )
  }
`

export const APPROVE_MSG_DOC = gql`
  mutation($provider_action_id: UUID!, $tx_hash: String) {
    approveMSGDocument(
      provider_action_id: $provider_action_id
      tx_hash: $tx_hash
    )
  }
`

export const APPROVE_COMPANY_INFO = gql`
  mutation($provider_action_id: UUID!) {
    approveCompanyInfo(provider_action_id: $provider_action_id)
  }
`

export const APPROVE_TRANSFERABILITY = gql`
  mutation($provider_action_id: UUID!, $input: VerifyTransferabilityInput!) {
    approveTransferability(
      provider_action_id: $provider_action_id
      input: $input
    )
  }
`

export const APPROVE_PROVIDER_INFO = gql`
  mutation($provider_action_id: UUID!) {
    approveProviderInfo(provider_action_id: $provider_action_id)
  }
`

export const APPROVE_TRADE_PAYMENT = gql`
  mutation($provider_action_id: UUID!, $document: UUID) {
    approveTradePayment(
      provider_action_id: $provider_action_id
      document: $document
    )
  }
`

export const REJECT_NEW_CUSTOMER_DOC = gql`
  mutation(
    $provider_action_id: UUID!
    $approval_type: ApprovalType!
    $reason: String
  ) {
    rejectNewCustomerDocument(
      provider_action_id: $provider_action_id
      approval_type: $approval_type
      reason: $reason
    )
  }
`

export const REJECT_MSG_DOC = gql`
  mutation($provider_action_id: UUID!, $reason: String) {
    rejectMSGDocument(provider_action_id: $provider_action_id, reason: $reason)
  }
`

export const GET_PAYMENT_SCHEMA = gql`
  query($action: BlockchainPaymentActions!, $currency: Currency!) {
    blockchainPaymentSchema(action: $action, currency: $currency) {
      schema
      status
      to
      recipient
    }
  }
`

export const GET_TRADE_SIGNATURE_DATA = gql`
  query($trade_id: UUID!) {
    getTradeSignatureData(trade_id: $trade_id)
  }
`

export const SIGN_TRADE = gql`
  mutation($trade_id: UUID!, $signed_message: String!) {
    signTrade(trade_id: $trade_id, signed_message: $signed_message) {
      uuid
      hashid
    }
  }
`

export const SEND_RAW_PAYMENT = gql`
  mutation($input: RawPaymentInput) {
    rawPayment(input: $input) {
      uuid
    }
  }
`

export const SEND_BANK_PAYMENT = gql`
  mutation($payment_id: UUID!, $document: UUID!) {
    submitBankPayment(payment_id: $payment_id, document: $document) {
      uuid
    }
  }
`

export const SEND_RAW_SIGNATURE = gql`
  mutation($input: RawSignatureInput) {
    rawSignature(input: $input) {
      uuid
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation($input: CreateContractInput!) {
    createContract(input: $input) {
      uuid
    }
  }
`

export const DELETE_CONTRACT = gql`
  mutation($contract_id: UUID!) {
    deleteContract(contract_id: $contract_id) {
      uuid
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation($input: CreateContractInput!, $contract_id: UUID!) {
    updateContract(input: $input, contract_id: $contract_id) {
      uuid
    }
  }
`

export const DEPLOY_CONTRACT = gql`
  mutation($contract_id: UUID!, $payment: ServicePaymentInput!) {
    deployContract(contract_id: $contract_id, payment: $payment) {
      uuid
    }
  }
`

export const GET_CONTRACT = gql`
  query($uuid: String!) {
    contract(uuid: $uuid) {
      ...ContractFull
    }
  }
  ${ContractFull}
`

export const GET_CONTRACTS = gql`
  query(
    $orderBy: [OrderByClause!]
    $count: Int!
    $page: Int
    $only: [ContractStatus!]
  ) {
    contracts(orderBy: $orderBy, count: $count, page: $page, only: $only) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...ContractBasic
        status
        transferability {
          status
          restricted
        }
      }
    }
  }
  ${PaginatorInfo}
  ${ContractBasic}
`

export const SEARCH_CONTRACTS = gql`
  query($search: String!) {
    searchContracts(search: $search, count: 25, page: 1) {
      data {
        ...ContractBasic
        status
      }
      paginatorInfo {
        ...PaginatorInfo
      }
    }
  }
  ${ContractBasic}
  ${PaginatorInfo}
`

export const GET_DRAFT_CONTRACTS = gql`
  query {
    contracts(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 10
      page: 1
      only: [DRAFT]
    ) {
      data {
        ...ContractBasic
        status
      }
    }
  }
  ${ContractBasic}
`

export const EXPORT_CONTRACT = gql`
  mutation($asset_id: UUID!) {
    exportAssetDetails(asset_id: $asset_id) {
      uuid
      export_type
      status
      created_at
      updated_at
      export_file
    }
  }
`

export const INVITE_SHAREHOLDER = gql`
  mutation($contract_id: UUID!, $input: ShareholderInvitationInput) {
    inviteShareholder(contract_id: $contract_id, input: $input) {
      uuid
    }
  }
`

export const UNINVITE_SHAREHOLDER = gql`
  mutation($invitation_id: UUID!) {
    uninviteShareholder(invitation_id: $invitation_id)
  }
`

export const INVITE_PROVIDER = gql`
  mutation($input: ProviderInvitationInput) {
    inviteProvider(input: $input) {
      fullname
    }
  }
`

export const REQUEST_PROVIDER = gql`
  mutation($officer_id: UUID!) {
    requestProvider(officer_id: $officer_id)
  }
`

export const SEARCH_OFFICER = gql`
  query($fullName: String!, $user_type: UserType, $count: Int!) {
    searchOfficer(fullName: $fullName, user_type: $user_type, count: $count) {
      data {
        uuid
        fullname
        email
        user_type
        created_at
        approvals(count: 100, page: 1) {
          data {
            approval_type
            status
            tx_status
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`

export const SEARCH_CUSTOMER = gql`
  query($fullName: String!) {
    searchCustomer(fullName: $fullName, count: 10, page: 1) {
      data {
        uuid
        fullname
        email
        user_type
        created_at
        profile {
          photo {
            link_as_avatar
          }
        }
        approvals(count: 100, page: 1) {
          data {
            approval_type
            status
            tx_status
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`

export const ACCEPT_CUSTOMER = gql`
  mutation($provider_action_id: UUID!) {
    acceptCustomer(provider_action_id: $provider_action_id)
  }
`

export const REJECT_CUSTOMER = gql`
  mutation($provider_action_id: UUID!) {
    rejectCustomer(provider_action_id: $provider_action_id)
  }
`

// export const UNINVITE_PROVIDER = gql`
//   mutation ($invitation_id: UUID!) {
//     uninviteProvider(invitation_id: $invitation_id)
//   }
// `

export const CREATE_TOKEN = gql`
  mutation($contract_id: UUID!, $amount: Int!, $payment: ServicePaymentInput!) {
    createToken(contract_id: $contract_id, amount: $amount, payment: $payment) {
      uuid
      amount
    }
  }
`

export const GET_TOKENS = gql`
  query(
    $orderBy: [OrderByClause!]
    $count: Int!
    $page: Int
    $only: [TokenStatus!]
  ) {
    tokens(orderBy: $orderBy, count: $count, page: $page, only: $only) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...TokenBasic
        contract {
          uuid
          hashid
          details
          issuer {
            uuid
            name
          }
        }
      }
    }
  }
  ${PaginatorInfo}
  ${TokenBasic}
`

export const GET_RESERVED_TOKENS = gql`
  query {
    tokens(
      orderBy: [{ field: "created_at", order: DESC }]
      count: 10
      page: 1
      only: [RESERVED]
    ) {
      data {
        ...TokenBasic
        contract {
          uuid
          hashid
          details
          issuer {
            uuid
            name
          }
        }
      }
    }
  }
  ${TokenBasic}
`

export const GET_TOKEN_DETAILS = gql`
  query($uuid: String!) {
    token(uuid: $uuid) {
      ...TokenBasic
      owner {
        ...UserBasic
      }
      contract {
        ...ContractFull
      }
      listings {
        ...ListingBasic
        token {
          hashid
          transferability {
            restricted
            status
          }
          contract {
            uuid
            hashid
            details
          }
        }
      }
    }
  }
  ${TokenBasic}
  ${ListingBasic}
  ${UserBasic}
  ${ContractFull}
`

export const CLAIM_TOKEN = gql`
  mutation($token_id: UUID!, $payment: ServicePaymentInput!) {
    claimToken(token_id: $token_id, payment: $payment) {
      uuid
    }
  }
`

export const SEARCH_TOKENS = gql`
  query($search: String!) {
    searchTokens(search: $search, count: 25, page: 1) {
      data {
        ...TokenBasic
        contract {
          uuid
          hashid
          details
          issuer {
            uuid
            name
          }
        }
      }
      paginatorInfo {
        ...PaginatorInfo
      }
    }
  }
  ${TokenBasic}
  ${PaginatorInfo}
`

export const CREATE_COMPANY = gql`
  mutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      uuid
      name
    }
  }
`

export const GET_COMPANIES = gql`
  query($count: Int!, $page: Int) {
    companies(
      count: $count
      page: $page
      orderBy: [{ field: "created_at", order: DESC }]
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...CompanyBasic
        contact_people {
          ...Person
        }
        financial_data {
          ...FinancialDataDetails
        }
        financial_documents {
          ...FinancialDocumentDetails
        }
      }
    }
  }
  ${PaginatorInfo}
  ${CompanyBasic}
  ${Person}
  ${FinancialDataDetails}
  ${FinancialDocumentDetails}
`

export const SEARCH_COMPANY = gql`
  query($search: String!, $page: Int) {
    searchCompanies(search: $search, count: 25, page: $page) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...CompanyBasic
        contact_people {
          ...Person
        }
        financial_data {
          ...FinancialDataDetails
        }
        financial_documents {
          ...FinancialDocumentDetails
        }
      }
    }
  }
  ${PaginatorInfo}
  ${CompanyBasic}
  ${Person}
  ${FinancialDataDetails}
  ${FinancialDocumentDetails}
`

export const GET_COMPANY = gql`
  query($uuid: String!) {
    company(uuid: $uuid) {
      ...CompanyBasic
      contact_people {
        ...Person
      }
      financial_data {
        ...FinancialDataDetails
      }
      financial_documents {
        ...FinancialDocumentDetails
      }
    }
  }
  ${CompanyBasic}
  ${Person}
  ${FinancialDataDetails}
  ${FinancialDocumentDetails}
`

export const CREATE_LISTING = gql`
  mutation($input: CreateListingInput) {
    createListing(input: $input) {
      uuid
    }
  }
`

export const REMOVE_LISTING = gql`
  mutation($uuid: UUID!) {
    removeListing(listing_id: $uuid)
  }
`

export const GET_MARKETPLACE = gql`
  query(
    $filter: MarketplaceFilterInput
    $orderBy: [OrderByClause!]
    $count: Int!
    $page: Int
  ) {
    marketplace(
      filter: $filter
      orderBy: $orderBy
      count: $count
      page: $page
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...ListingRow
      }
    }
  }
  ${PaginatorInfo}
  ${ListingRow}
`

export const GET_MY_LISTINGS = gql`
  query($page: Int, $count: Int!) {
    listings(
      page: $page
      count: $count
      orderBy: [{ field: "created_at", order: DESC }]
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...ListingBasic
        token {
          hashid
          owner {
            uuid
            fullname
          }
          transferability {
            restricted
            status
          }
          contract {
            uuid
            hashid
            details
            issuer {
              uuid
              name
            }
          }
        }
      }
    }
  }
  ${PaginatorInfo}
  ${ListingBasic}
`

export const GET_LISTING_DETAILS = gql`
  query($uuid: String!) {
    listing(uuid: $uuid) {
      ...ListingBasic
      solicited {
        subject
        message
      }
      solicitable {
        ...UserBasic
        solicited
      }
      seller {
        uuid
        fullname
      }
      token {
        ...TokenBasic
        owner {
          uuid
          fullname
        }
        contract {
          ...ContractFull
        }
      }
    }
  }
  ${ListingBasic}
  ${TokenBasic}
  ${UserBasic}
  ${ContractFull}
`

export const SOLICIT_LISTING = gql`
  mutation($input: SolicitListingInput) {
    solicitListing(input: $input) {
      uuid
    }
  }
`

export const MAKE_OFFER = gql`
  mutation($input: MakeOfferInput!) {
    makeOffer(input: $input) {
      uuid
    }
  }
`

export const GET_PURCHASE_OFFERS = gql`
  query($page: Int, $count: Int!) {
    purchaseOffers(page: $page, count: $count) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...OfferRow
      }
    }
  }
  ${OfferRow}
  ${PaginatorInfo}
`

export const GET_SALES_OFFERS = gql`
  query($page: Int, $count: Int!) {
    salesOffers(page: $page, count: $count) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...OfferRow
      }
    }
  }
  ${OfferRow}
  ${PaginatorInfo}
`

export const GET_LISTING_OFFERS = gql`
  query($listing_id: UUID!) {
    listingOffers(
      listing_id: $listing_id
      count: 10
      page: 1
      orderBy: [{ field: "created_at", order: DESC }]
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...OfferRow
      }
    }
  }
  ${PaginatorInfo}
  ${OfferRow}
`

export const GET_ALL_TOKENS = gql`
  query($count: Int!, $page: Int) {
    activeTokens(count: $count, page: $page) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...TokenBasic
        contract {
          uuid
          hashid
          details
          issuer {
            uuid
            name
          }
        }
      }
    }
  }
  ${PaginatorInfo}
  ${TokenBasic}
`

export const GET_ALL_OFFERS = gql`
  query($page: Int, $count: Int!) {
    activeOffers(page: $page, count: $count) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...OfferRow
      }
    }
  }
  ${PaginatorInfo}
  ${OfferRow}
`

export const SEARCH_OFFERS = gql`
  query($search: String!) {
    searchOffers(search: $search, count: 10, page: 1) {
      data {
        ...OfferRow
      }
      paginatorInfo {
        ...PaginatorInfo
      }
    }
  }
  ${PaginatorInfo}
  ${OfferRow}
`

export const GET_ALL_TRADES = gql`
  query($page: Int, $count: Int!) {
    activeTrades(page: $page, count: $count) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...TradeBasic
        status
        accepted_offer {
          listing {
            token {
              contract {
                uuid
                hashid
                details
                issuer {
                  uuid
                  name
                }
              }
            }
          }
        }
        seller_info {
          ...UserBasicInfo
        }
        buyer_info {
          ...UserBasicInfo
        }
      }
    }
  }
  ${PaginatorInfo}
  ${TradeBasic}
  ${UserBasicInfo}
`

export const SEARCH_TRADES = gql`
  query($search: String!) {
    searchTrades(search: $search, count: 25, page: 1) {
      data {
        ...TradeBasic
        status
        accepted_offer {
          listing {
            token {
              contract {
                uuid
                hashid
                details
                issuer {
                  uuid
                  name
                }
              }
            }
          }
        }
        seller_info {
          ...UserBasicInfo
        }
        buyer_info {
          ...UserBasicInfo
        }
      }
      paginatorInfo {
        ...PaginatorInfo
      }
    }
  }
  ${TradeBasic}
  ${PaginatorInfo}
  ${UserBasicInfo}
`

export const GET_OFFER_DETAILS = gql`
  query($uuid: UUID!) {
    offerDetails(offer_id: $uuid) {
      listing {
        ...ListingBasic
        token {
          ...TokenBasic
          owner {
            uuid
            fullname
          }
          contract {
            ...ContractFull
          }
        }
      }
      history {
        ...OfferBasic
        owner {
          uuid
          fullname
        }
        listing {
          ...ListingBasic
          token {
            ...TokenBasic
            owner {
              uuid
              fullname
            }
            contract {
              ...ContractFull
            }
          }
        }
      }
    }
  }
  ${OfferBasic}
  ${ListingBasic}
  ${TokenBasic}
  ${ContractFull}
`

export const REJECT_OFFER = gql`
  mutation($uuid: UUID!) {
    rejectOffer(offer_id: $uuid) {
      uuid
    }
  }
`

export const CANCEL_OFFER = gql`
  mutation($uuid: UUID!) {
    cancelOffer(offer_id: $uuid) {
      uuid
    }
  }
`

export const MAKE_COUNTER_OFFER = gql`
  mutation($input: CounterOfferInput!) {
    counterOffer(input: $input) {
      uuid
    }
  }
`

export const ACCEPT_OFFER = gql`
  mutation($uuid: UUID!) {
    acceptOffer(offer_id: $uuid) {
      uuid
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation($company_id: UUID!, $input: CreateCompanyInput!) {
    updateCompany(company_id: $company_id, input: $input) {
      uuid
      name
    }
  }
`

export const GET_TRADES = gql`
  query($count: Int!, $page: Int, $completed: Boolean) {
    trades(
      orderBy: [{ field: "created_at", order: DESC }]
      count: $count
      page: $page
      completed: $completed
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        ...TradeBasic
        accepted_offer {
          listing {
            token {
              contract {
                uuid
                hashid
                details
                issuer {
                  uuid
                  name
                }
              }
            }
          }
        }
        seller_info {
          ...UserBasicInfo
        }
        buyer_info {
          ...UserBasicInfo
        }
      }
    }
  }
  ${PaginatorInfo}
  ${TradeBasic}
  ${UserBasicInfo}
`

export const GET_TRADE_DETAILS = gql`
  query($uuid: UUID!) {
    trade(uuid: $uuid) {
      ...TradeBasic
      status
      accepted_offer {
        uuid
        token_amount
        token_price
        listing {
          ...ListingBasic
          token {
            ...TokenBasic
            owner {
              uuid
              fullname
            }
            contract {
              ...ContractFull
            }
          }
        }
      }
      signatures {
        uuid
        signature_type
        signature_role
        tx_hash
        tx_status
        wallet {
          address
        }
      }
      payments {
        ...PaymentBasic
        bank_info {
          bank_name
          account_number
          rtn_aba
          swift_bic
        }
      }
      seller_info {
        ...UserBasicInfo
      }
      buyer_info {
        ...UserBasicInfo
      }
    }
  }
  ${TradeBasic}
  ${ListingBasic}
  ${TokenBasic}
  ${ContractFull}
  ${PaymentBasic}
  ${UserBasicInfo}
`

export const ASK_PROVIDER = gql`
  mutation($provider_type: ProviderType, $message: String!) {
    askProvider(provider_type: $provider_type, message: $message) {
      body
    }
  }
`

export const CONTACT_CUSTOMER = gql`
  mutation($customer_id: UUID!, $message: String!) {
    contactCustomer(customer_id: $customer_id, message: $message) {
      body
    }
  }
`

export const GET_DISCUSSION_WITH_PROVIDER = gql`
  query($provider_type: ProviderType, $count: Int!, $page: Int) {
    discussionWithProvider(
      count: $count
      page: $page
      provider_type: $provider_type
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        body
        created_at
        updated_at
        from
        fromuuid
      }
    }
  }
  ${PaginatorInfo}
`

export const GET_DISCUSSIONS_WITH_CUSTOMERS = gql`
  query($count: Int!, $page: Int) {
    discussionsWithCustomers(count: $count, page: $page) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        uuid
        participants {
          ... on User {
            uuid
            fullname
            user_type
            profile {
              photo {
                link_as_avatar
              }
            }
          }
          ... on Provider {
            uuid
            name
          }
        }
      }
    }
  }
  ${PaginatorInfo}
`

export const GET_DISCUSSION_WITH_CUSTOMER = gql`
  query($uuid: UUID!, $count: Int!, $page: Int) {
    discussionWithCustomer(count: $count, page: $page, customer_id: $uuid) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        body
        created_at
        updated_at
        from
        fromuuid
        fromavatar
      }
    }
  }
  ${PaginatorInfo}
`

export const REQUEST_DATA_EXPORT = gql`
  mutation(
    $export_type: DataExportType
    $start_date: Date
    $end_date: Date
    $limit: Int
  ) {
    requestDataExport(
      export_type: $export_type
      start_date: $start_date
      end_date: $end_date
      limit: $limit
    ) {
      uuid
    }
  }
`

export const GET_DATA_EXPORTS = gql`
  query($count: Int!, $page: Int) {
    dataExports(
      orderBy: [{ field: "created_at", order: DESC }]
      count: $count
      page: $page
    ) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        uuid
        export_type
        status
        created_at
        updated_at
        export_file
      }
    }
  }
  ${PaginatorInfo}
`

export const GET_LAST_NOTIFICATIONS = gql`
  query {
    me {
      system_notifications {
        uuid
        data
        created_at
        read_at
        created_at
      }
      message_notifications {
        uuid
        data
        created_at
        read_at
      }
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query($count: Int!, $page: Int, $only: NotificationFilter) {
    notifications(count: $count, page: $page, only: $only) {
      paginatorInfo {
        ...PaginatorInfo
      }
      data {
        uuid
        data
        created_at
        read_at
        created_at
      }
    }
  }
  ${PaginatorInfo}
`

export const NEW_NOTIFICATION = gql`
  subscription newNotification {
    newNotification {
      data
      created_at
      read_at
    }
  }
`

export const READ_NOTIFICATION = gql`
  mutation($notification_id: UUID!) {
    markNotificationAsRead(notification_id: $notification_id)
  }
`

export const READ_ALL_NOTIFICATIONS = gql`
  mutation {
    markAllNotificationAsRead
  }
`

export const DELETE_NOTIFICATION = gql`
  mutation($notification_id: UUID!) {
    deleteNotification(notification_id: $notification_id)
  }
`

export const SEARCH_ALL = gql`
  query($search: String!, $count: Int!) {
    searchContracts(search: $search, count: $count, page: 1) {
      data {
        ...ContractBasic
        status
      }
      paginatorInfo {
        total
      }
    }
    searchTokens(search: $search, count: $count, page: 1) {
      data {
        ...TokenBasic
        contract {
          uuid
          hashid
          details
          issuer {
            uuid
            name
          }
        }
      }
      paginatorInfo {
        total
      }
    }
    searchCompanies(search: $search, count: $count, page: 1) {
      paginatorInfo {
        total
      }
      data {
        ...CompanyBasic
      }
    }
    marketplace(filter: { search: $search }, count: $count, page: 1) {
      paginatorInfo {
        total
      }
      data {
        ...ListingBasic
      }
    }
    searchOffers(search: $search, count: $count, page: 1) {
      data {
        ...OfferBasic
        owner {
          uuid
          fullname
        }
      }
      paginatorInfo {
        total
      }
    }
    searchTrades(search: $search, count: $count, page: 1) {
      data {
        ...TradeBasic
        accepted_offer {
          token_amount
          token_price
        }
        seller_info {
          ...UserBasicInfo
        }
        buyer_info {
          ...UserBasicInfo
        }
      }
      paginatorInfo {
        total
      }
    }
  }
  ${ContractBasic}
  ${TokenBasic}
  ${CompanyBasic}
  ${ListingBasic}
  ${OfferBasic}
  ${TradeBasic}
  ${UserBasicInfo}
`
