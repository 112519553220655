import React from 'react'
import _ from 'lodash'
import { Icon } from 'semantic-ui-react'
import { NumberFormat } from '../index'
import link from '../link'

const useSearchResults = ({ data }) => {
  return React.useMemo(() => {
    const contractsData = _.get(data, 'searchContracts.data', [])
    const tokensData = _.get(data, 'searchTokens.data', [])
    const companiesData = _.get(data, 'searchCompanies.data', [])
    const listingsData = _.get(data, 'marketplace.data', [])
    const offersData = _.get(data, 'searchOffers.data', [])
    const tradesData = _.get(data, 'searchTrades.data', [])

    const contractsResults = {
      name: 'Assets',
      total: _.get(data, 'searchContracts.paginatorInfo.total', 0),
      results: contractsData.map(c => ({
        item: c,
        content: (
          <>
            {c.issuer.name} (
            <NumberFormat coin amount={c.token_numbers.initial} />)
          </>
        ),
        to: link.contract(c),
      })),
    }
    const tokensResults = {
      name: 'Tokens',
      total: _.get(data, 'searchTokens.paginatorInfo.total', 0),
      results: tokensData.map(t => ({
        item: t,
        content: (
          <>
            {t.contract.issuer.name} (<NumberFormat coin amount={t.amount} />)
          </>
        ),
        to: link.token(t),
      })),
    }
    const companiesResults = {
      name: 'Companies',
      total: _.get(data, 'searchCompanies.paginatorInfo.total', 0),
      results: companiesData.map(c => ({
        item: c,
        content: c.name,
        to: link.company(c),
      })),
    }
    const listingsResults = {
      name: 'Listings',
      total: _.get(data, 'marketplace.paginatorInfo.total', 0),
      results: listingsData.map(l => ({
        item: l,
        content: (
          <>
            <NumberFormat usd amount={l.price} />/
            <NumberFormat coin amount={l.amount} /> ({l.seller.fullname})
          </>
        ),
        to: link.listing(l),
      })),
    }
    const offersResults = {
      name: 'Offers',
      total: _.get(data, 'searchOffers.paginatorInfo.total', 0),
      results: offersData.map(o => ({
        item: o,
        content: (
          <>
            <NumberFormat usd amount={o.token_price} />/
            <NumberFormat coin amount={o.token_amount} /> ({o.owner.fullname})
          </>
        ),
        to: link.offer(o),
      })),
    }
    const tradesResults = {
      name: 'Trades',
      total: _.get(data, 'searchTrades.paginatorInfo.total', 0),
      results: tradesData.map(t => ({
        item: t,
        content: (
          <>
            <NumberFormat usd amount={t.accepted_offer.token_price} />/
            <NumberFormat coin amount={t.accepted_offer.token_amount} /> (
            {t.seller_info.fullname}
            <Icon name="arrow right" />
            {t.buyer_info.fullname})
          </>
        ),
        to: link.trade(t),
      })),
    }

    const results = {}
    if (contractsResults.total > 0) results.contracts = contractsResults
    if (tokensResults.total > 0) results.tokens = tokensResults
    if (companiesResults.total > 0) results.companies = companiesResults
    if (listingsResults.total > 0) results.listings = listingsResults
    if (offersResults.total > 0) results.offers = offersResults
    if (tradesResults.total > 0) results.trades = tradesResults
    return results
  }, [data])
}

export default useSearchResults
