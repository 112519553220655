import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Grid } from 'semantic-ui-react'
import {
  WelcomeStep,
  Info,
  LastWarningStep,
  PasswordStep,
  EncryptionStep,
  DownloadJsonStep,
} from './CreateWalletSteps'
import useForm from '../../../util/hooks/form'
import { useSteps } from '../../../util/hooks/steps'
import { useMe } from '../../../util/hooks/me'
import {
  client,
  ADD_WALLET,
  GET_PROFILE,
  GET_ME,
} from '../../../util/API/Apollo'

const initialForm = {
  password: '',
  passwordConfirm: '',
  walletName: '',
}

const CreateWallet = ({ setWalletCreated }) => {
  const [wallet, setWallet] = useState(null)
  const me = useMe()
  const stepsHook = useSteps(
    [
      'welcome',
      'info',
      'last-warning',
      'password',
      'encryption',
      'download-json',
    ],
    'welcome'
  )
  const formHook = useForm('create-wallet', initialForm)

  const [save, saveMutation] = useMutation(ADD_WALLET, {
    variables: {
      input: {
        address: wallet && '0x' + wallet.address,
        name: formHook.form.walletName,
        is_primary: true,
      },
    },
    onCompleted: () => {
      setWalletCreated(true)
    },
    refetchQueries: [{ query: GET_PROFILE }, { query: GET_ME }],
    awaitRefetchQueries: true,
    client,
  })

  const handleWalletCreation = newWallet => {
    setWallet(newWallet)
    stepsHook.nextStep()
  }

  return (
    <Grid centered verticalAlign="middle" style={{ minHeight: '700px' }}>
      <Grid.Row>
        <Grid.Column computer={8} tablet={10} mobile={16}>
          {stepsHook.isActive('welcome') && (
            <WelcomeStep
              nextStep={stepsHook.nextStep}
              userFullName={me && me.fullname}
            />
          )}
          {stepsHook.isActive('info') && <Info nextStep={stepsHook.nextStep} />}
          {stepsHook.isActive('last-warning') && (
            <LastWarningStep nextStep={stepsHook.nextStep} />
          )}
          {stepsHook.isActive('password') && (
            <PasswordStep formHook={formHook} nextStep={stepsHook.nextStep} />
          )}
          {stepsHook.isActive('encryption') && (
            <EncryptionStep
              formHook={formHook}
              createWallet={handleWalletCreation}
            />
          )}
          {stepsHook.isActive('download-json') && (
            <DownloadJsonStep
              formHook={formHook}
              wallet={wallet}
              save={save}
              saveMutation={saveMutation}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default CreateWallet
