import React from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Form,
  Header,
  Input,
  Segment,
  Transition,
  Modal,
  Icon,
} from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import * as yup from 'yup'
import { client, REGISTER } from '../../util/API/Apollo'
import useForm from '../../util/hooks/form'
import { ReactComponent as STCLogo } from '../../images/stc_logo.svg'
import { useModal, useMounted } from '../../util/hooks'
import { PageLoader } from '../index'
import link from '../link'

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid')
    .available(),
})

const initialForm = {
  email: '',
  terms_agreement: true,
}

const ConfirmationModal = ({ open, onClose, onAccept }) => {
  return (
    <Modal
      dimmer
      closeOnEscape
      closeOnDimmerClick={false}
      size="small"
      open={open}
      onClose={() => onClose(false)}
    >
      <Modal.Header content="TERMS & CONDITIONS" />
      <Modal.Content scrolling>
        <Header sub content="INTRODUCTION" />
        <small>
          Please read these terms and conditions of use of the STC Digital
          Securities Transfer Platform (Terms/Agreement) carefully before
          signing up as a user of any of the products or services (Services)
          offered on the www……..…com website (Website). These Terms will apply
          to your use of the Website as well as to any Services you use, in
          addition to any supplemental terms and conditions you may be required
          to agree to, in order to access those particular Services. The Terms
          constitute a binding agreement between you, as a user of the Website
          and any Services and each member of the STC Digital Securities
          Transfer Platform (as defined below). Your use of the Website and/or
          any Services will confirm your acceptance of these Terms. Please note
          that changes may be made to the Terms from time to time to reflect
          changes to the Services offered, changes to applicable laws or
          regulations or other changes that any member of the STC Digital
          Securities Transfer Platform (The Platform) considers desirable. Any
          such changes will be published on the Website.
        </small>
        <Header sub content="DEFINITIONS" />
        <small>
          In these Terms, capitalised terms have the following meanings:
          <br />
          <br />
          SECURITIES TRANSFER CORPORATION: is a transfer agent regulated by SEC
          located at the address 2901 N Dallas Parkway Suite 380 Plano, Texas
          75093 (hereinafter referred to as “STC).
          <br />
          <br />
          CREOSAFE: is a software company located at the address 351 E 60th st,
          NY, NY 10022 providing and maintaining the software of the STC Digital
          Securities Transfer Platform
          <br />
          <br />
          STC DIGITAL SECURITIES PLATFORM: is a web and DLT based application
          running at the website www. …….com with a backend over Ethereum and
          comprised of individual and institutional members (hereinafter
          referred to as “the Platform”)
          <br />
          <br />
          USER: refers to any natural or legal person who takes an action to
          sign up or login the Platform and accepts this agreement titled Terms
          and Conditions
          <br />
          <br />
          USER GENERATED CONTENT: refers to any content or information provided
          by the User on the Platform/Website including but not limited to User
          Profile, creation of assets and Digital Securities, listing and
          trading of such securities
          <br />
          <br />
          ETHEREUM: is an open source, public, blockchain-based distributed
          computing platform and operating system featuring smart contract
          functionality.
          <br />
          <br />
          ETHEREUM WALLET: a piece of software that allows members to store
          their funds and Digital Securities
          <br />
          <br />
          PUBLIC KEY: is a large numerical value that is used to encrypt data
          and made available to everyone through a publicly accessible
          repository or directory.
          <br />
          <br />
          PRIVATE KEY: is a sophisticated form of cryptography that allows a
          member/user to access his or her Digital Securities and
          cryptocurrency. A member’s/ user’s private key is kept private and
          known only to the member/user. The private key can be used to encrypt
          a signature that can be verified by anyone with the public key. It is
          also known as a secret key and therefore must not be shared with
          anybody.
          <br />
          <br />
          DISTRIBUTED LEDGER TECHNOLOGY (DLT): is a digital system for recording
          the transaction of assets in which the transactions and their details
          are recorded in multiple places at the same time. Unlike traditional
          databases, distributed ledgers have no central data store or
          administration functionality. The Platform uses DLT.
          <br />
          <br />
          DISTRIBUTED OPERATING PLATFORM (DOP) : is a role-based operating
          platform over a DLT in which there are multiple distributed operators
          and each operator has specifically defined capabilities, such as
          approving memberships, inviting users to the Platform, administering
          and verifying users’ KYC & accredited investor status, allowing
          transfers and signing on off transactions. The platform is a DOP.
          <br />
          <br />
          BROKER: is an SEC regulated person engaged in the business of
          effecting transactions in securities for the account of others and
          refers to a full service or discount broker registered at FINRA
          <br />
          <br />
          TRANSFER AGENT: is an SEC regulated financial services company that
          manages and keeps track of registered shareholders for companies that
          issue stock
          <br />
          <br />
          FINANCIAL ADVISOR: is a licensed professional that provides expertise
          for clients decisions around money matters, securities, and
          investments on the Platform.
          <br />
          <br />
          WHITE-LABELED FINANCIAL FIRM: refers to financial firms using the
          Creosafe software under their own brand thereby taking an
          administrative role in operating the platform
          <br />
          <br />
          INVESTOR: is an accredited investor accepted to membership at the
          Platform
          <br />
          <br />
          ACCREDITED INVESTOR: Individuals and firms meeting criteria as defined
          by SEC in Rule 501 of regulation D
          <br />
          <br />
          ISSUER: is a company issuing stocks which are converted into digital
          securities and offered for sale by shareholders on the marketplace at
          the Platform.
          <br />
          <br />
          THE PLATFORM OPERATORS: are distributed operators who perform their
          specifically defined roles under their own brand. White-labeled
          transfer agents, brokers, financial advisors and Creosafe providing
          the Platform software services over DLT are platform operators. STC
          and Creosafe are platform operators.
          <br />
          <br />
          MEMBER: refers to registered and verified users of the Platform
          including financial firms i.e. brokers, transfer agents, financial
          advisors, custodians and banks as well as buyer and seller of
          securities including accredited investors, issuers of securities
          <br />
          <br />
          SECURITY: refers to any existing paper-based security issued by an
          issuer and converted into a digital security over Ethereum blockchain
          <br />
          <br />
          DIGITAL SECURITY: refers to a digital security created in a smart
          contract and underlying of which is an existing paper-based security
          and linked to the underlying
          <br />
          <br />
          MARKETPLACE: is an online listing venue where Digital Securities
          linked to securities issued by issuers in a legally binding manner are
          listed and offered for sale, privately negotiated by potential buyers
          and agreed for a transaction at the discretion of the seller without
          being subject to a predetermined set of rules for matching of the sell
          and buy orders
          <br />
          <br />
          INTENT TO TRANSACT (INVESTMENT AGREEMENT): Acceptance of a buy or sell
          offer, which states the agreed underlying and digital security, the
          quantity and the price, by the counter-party on the Platform
          <br />
          <br />
          TRANSACTION: Execution of an investment agreement over Ethereum
          blockchain by meeting the conditions coded in a smart a smart contract
          such as users’ signature via their private keys.
        </small>
        <Header sub content="INTERPRETATION" />
        <small>
          Unless the context otherwise requires in these Terms:
          <br />
          <br />
          • the singular includes the plural and the masculine includes the
          feminine and neuter genders and vice versa;
          <br />
          <br />
          • references to a person include natural persons, companies,
          partnerships, firms, joint ventures, associations or other bodies of
          persons (whether or not incorporated);
          <br />
          <br />
          • writing and written includes any method of representing or
          reproducing words in a visible form, including in the form of an
          Electronic Record including records over a Distributed Ledger/
          blockchain;
          <br />
          <br />
          • a reference to shall shall be construed as imperative and a
          reference to may shall be construed as permissive;
          <br />
          <br />
          • the term and/or is used to mean both and as well as or. The use of
          and/or in certain contexts in no respects qualifies or modifies the
          use of the terms and or or in others. Or shall not be interpreted to
          be exclusive, and shall not be interpreted to require the conjunctive,
          in each case unless the context requires otherwise;
          <br />
          <br />
          • any phrase introduced by the terms including, includes, in
          particular or any similar expression shall be construed as
          illustrative and shall not limit the sense of the words preceding
          those terms;
          <br />
          <br />
          • headings are inserted for reference only and shall not affect
          construction;
          <br />
          <br />
          • references to any statute or statutory provision include that
          statute or provision as it may have been, or may from time to time be,
          amended, modified, re-enacted, or replaced and include references to
          all by-laws, instruments, orders and regulations for the time being
          made thereunder or deriving validity therefrom;
          <br />
          <br />• any reference to these Terms or to any other terms and
          conditions, agreement or instrument is a reference to these terms or,
          as the case may be, the relevant terms and conditions, agreement or
          instrument as may be amended, restated, supplemented, replaced or
          otherwise modified from time to time.
        </small>
        <Header sub content="REGISTRATION AND USE OF THE PLATFORM" />
        <small>
          At his/her registration, the User shall provide his login and profile
          information, in particular, his/ her last name, first name, email
          address and date of birth, tax id/SSN and address .
          <br />
          <br />
          The User must indicate a login, which includes a User name (valid
          email address) and a password. He/she is solely responsible for
          maintaining the confidentiality of his/her Login. The User agrees not
          to use at any time the Accounts, name or User name owned by another
          User, neither to disclose his/her login to a third party. The User
          agrees to inform the Platform Operators immediately, in case he/she
          suspects a non-authorized use of his/her login He/she is solely
          responsible for any use of his/her login.
          <br />
          <br />
          By accepting this agreement, you agree that the Platform Operators
          processes your registration to the dedicated service providers,
          whenever it deems necessary. The Platform Operators are allowed to
          request for further information and identification data to the User,
          and all supporting documents it may consider useful.
          <br />
          <br />
          The User declares at the registration and throughout the duration of
          this agreement that all information provided during the registration
          are true, accurate and up-to-date.
          <br />
          <br />
          As a user of the Platform you agree that:
          <br />
          <br />
          You shall not, and you shall ensure that no person using your Account
          shall submit any content to or otherwise use the Website or make use
          of any content or Services displayed on or provided through the
          Platform/Website in any manner that would infringe any patent,
          trademark, trade secret, copyright, right of publicity, or other
          intellectual property rights of any other person or entity, or violate
          any law, regulation, court order or contract.
          <br />
          <br />
          You shall not, and you shall ensure that no person using your Account
          shall submit any content to the Platform/Website that:
          <br />
          <br />
          • you know is false, misleading, non-factual, lacking of official
          proof and evidence or inaccurate;
          <br />
          <br />
          • is unlawful, threatening, abusive, harassing, racist, prejudice,
          defamatory, libellous, deceptive, fraudulent, tortious, obscene,
          offensive, profane, or invasive of another's privacy;
          <br />
          <br />
          • constitutes unsolicited or unauthorized advertising or promotional
          material or any junk mail, spam, or chain letters;
          <br />
          <br />
          • contains software viruses or any other computer codes, files, or
          programmes that are designed or intended to disrupt, damage, limit, or
          interfere with the proper function of any software, hardware, or
          telecommunications equipment or to damage or obtain unauthorized
          access to any system, data, password, or other information of the
          Company or any third party; or
          <br />
          <br />
          • impersonates any person or entity, including any employee or
          representative of the Platform.
          <br />
          <br />
          You shall take full responsibility for any damage and lose that you
          may cause by submitting such content as defined in the above bullet
          points. You accept that neither STC nor Creosafe will take any
          responsibility for such losses and damages and you shall not take any
          action to transfer the responsibility to STC and/or Creosafe in any
          circumstances.
          <br />
          <br />
          You shall take all precautions in your capacity to safekeep your
          account credentials and private keys. You shall not share these
          credentials with a third party or with unauthorized personnel. STC
          and/or Creosafe will take no responsibility for any wrongdoing, loss
          or damage if you do not safekeep your login credentials and private
          keys.
          <br />
          <br />
          You shall not take any action that imposes or may impose (as
          determined by the Platform Operators in their sole discretion) an
          unreasonable or disproportionately large load on the Platform's
          infrastructure; interfere or attempt to interfere with the proper
          working of the Website or any Services; bypass any measures used to
          prevent or restrict access to any part of the Website or any Services
          (or other accounts, computer systems, or networks connected to the
          Website); attempt to or actually override any security component
          included in or underlying the Website; run mail list, list serv, or
          any form of auto-responder or "spam" on the Service; use manual or
          automated software, devices, or other processes to access, "scrape",
          "crawl" or "spider" any page of the Website or any Services provided
          on or through the Website; or perform any activities through the
          Website that are not expressly permitted by the Platform Operator
          and/or these Terms.
          <br />
          <br />
          You shall not directly or indirectly decipher, decompile, disassemble,
          reverse engineer, or otherwise attempt to derive any source code or
          underlying ideas or algorithms of any part of the Website or any
          Services, except to the limited extent applicable laws specifically
          prohibit such restriction; modify, translate, or otherwise create
          derivative works of any part of the Website or any Services; or copy,
          rent, lease, distribute, or otherwise transfer any of the rights that
          you receive hereunder.
          <br />
          <br />
          You shall not engage in "framing", "mirroring" or otherwise simulating
          the Website; and you shall not use any manual or automatic mechanism
          for monitoring any activity conducted on or through the Website for
          any purpose, unless expressly permitted by the Platform Operators and/
          or these Terms.
          <br />
          <br />
          The Platform Operators do not guarantee the availability of the
          Website or any Services or that any User Generated Content will be
          made available on the Website. The Platform Operator reserves the
          right, at any time, for any reason, and without notice to: (i) cancel,
          reject, interrupt, remove, or suspend any listing on the marketplace;
          (ii) remove, edit, or modify any content, including, but not limited
          to, any User Generated Content; and/or (iii) remove or block any User
          Generated Content.
          <br />
          <br />
          The Platform operators are not responsible for, do not endorse and
          make no representation whatsoever as to the truth, accuracy,
          correctness or otherwise of any User Generated Content.
          <br />
          <br />
          The inclusion of a link to another website on the Website does not
          imply any endorsement or control of such website. Unless expressly
          stated, no websites linked to the Website are under the control of the
          Platform Operators Accordingly, the Platform Operators assume no
          responsibility for the content of any such websites and disclaims
          liability for any and all forms of loss or damage arising out of the
          use of them.
          <br />
          <br />
          While the Platform Operators take reasonable steps to ensure that the
          Services correspond with their descriptions on the Website, they take
          no responsibility for any variation between such descriptions and the
          actual Services provided.
        </small>
        <Header sub content="MEMBERSHIP" />
        <small>
          All members of the Platform must be either an accredited investor as
          defined by SEC in Rule 501 of regulation D or a regulated financial
          firm in the US. Accredited investors include individuals and legal
          persons. All natural persons must be aged 18 or over to become a
          Member. Each User is responsible for checking any additional age
          restrictions that apply to them in their own jurisdiction.
          Representatives of legal persons must be authorized by the legal
          entity to act on behalf of said legal entity and must be aged 18 or
          over.
          <br />
          <br />
          Applications for membership shall be evaluated and concluded by STC
          and other white-labeled financial firms where applicable. STC and
          related white-labeled firms shall have the right, and is authorized,
          to refuse any applications, or to cancel a previously accepted
          membership in order to safely operate the platform.
          <br />
          <br />
          Membership types include:
          <br />
          <br />
          1. Investor
          <br />
          <br />
          2. Issuer
          <br />
          <br />
          3. Broker
          <br />
          <br />
          4. Transfer Agent
          <br />
          <br />
          5. Financial Advisor
          <br />
          <br />
          6. Custodian
          <br />
          <br />
          7. Bank
          <br />
          <br />
          As a user you must prove that you meet the membership requirements as
          defined above to become a member of the Platform. You accept that all
          information and user generated content that you provided to claim a
          membership status is not false, misleading, non-factual or inaccurate.
          You take full responsibility for any loss or damage that may arise
          from providing any untruthful information and user generated content.
          You shall make sure that the content generated through your account is
          submitted by yourself or a fully authorized personnel and take full
          responsibility for any damage or losses caused by content and
          information submitted by an unauthorized user.
        </small>
        <Header
          sub
          content="CREATION OF DIGITAL SECURITIES AND THEIR TRANSACTIONS"
        />
        <small>
          Digital Securities that you create are not considered new issuances.
          These Digital Securities represent a fraction of the underlying
          existing security, that is the security that you converted into
          Digital Securities, in a legally binding manner. Digital Securities
          that you created are intrinsically tied to its underlying security. By
          selling a digital security, you in fact sell the underlying converted
          security. Once the underlying security becomes void for any reason,
          Digital Securities tied to that security also becomes void.
          <br />
          <br />
          As a user you know and accept that the main goal of the Platform is
          <br />
          <br />
          1. to convert existing (already issued) non-trading securities into
          Digital Securities in a token form in a smart contract over Ethereum
          blockchain
          <br />
          <br />
          2. to list part of whole of these Digital Securities on the
          marketplace for sale
          <br />
          <br />
          3. to start an ask/bid process for listed securities
          <br />
          <br />
          4. to transact Digital Securities at the discretion of the seller
          according to the terms agreed by the seller and buyer.
          <br />
          <br />
          5. and to record the transaction over Ethereum blockchain as a digital
          proof
          <br />
          <br />
          Regarding the creation and transaction of digital security, you shall
          not, and you shall ensure that no person using your Account shall
          submit any security that is NOT genuine and you are not a beneficial
          owner. By selling the Digital Securities, you accept to transfer your
          beneficial ownership rights arising from the underlying original
          security to the buyer at the time of your digital signature via PK is
          recorded on Ethereum blockchain without further notice and liability
          to any third party including but not limited to STC and Creosafe.
        </small>
        <Header sub content="DUE DILIGENCE AND INVESTMENT DECISIONS" />
        <small>
          Although the use of this Platform is to create liquidity for private
          illiquid securities, investors/ users/members must be aware that these
          securities are illiquid in nature and creation of liquidity depends
          only on presence of potential buyers. In absence of potential buyers,
          investors/users/ members of the Platform may still suffer from
          illiquidity. STC and/ Creosafe can not and do not guarantee providing
          liquidity for any digital security listed on the Marketplace of this
          Platform. Therefore, investors shall not presume future liquidity for
          any digital security that they invested over the Platform.
          <br />
          <br />
          The information and content about members and Digital Securities
          provided over the Platform are generated by users. STC and Creosafe do
          not guarantee that information and content submitted by users are
          complete and accurate to make an investment decision about a digital
          and its underlying security. No information, forward looking
          statements, or estimations presented on the Platform represent any
          final determination on investment performance. While the information
          presented on this Platform are submitted by users thought to be
          reasonable and accurate, any investment is speculative in nature.
          Members of the Platform cannot and do not guarantee any rate of return
          or investment timeline based on the information presented on the
          Platform. STC and Creosafe cannot and do not advice any investment in
          Digital Securities listed on Platform.
          <br />
          <br />
          By reading and reviewing the information contained in this Platform,
          the user acknowledges and agrees that STC and Creosafe do not assume
          and hereby disclaim any liability to any party for any loss or damage
          caused by the use of the information contained on the Platform, or
          errors or omissions in the information contained in this Platform, to
          make any investment decision, whether such errors or omissions result
          from negligence, accident or any other cause.
          <br />
          <br />
          Investors/members/users are required to conduct their own
          investigations, analysis, due diligence, draw their own conclusions,
          and make their own decisions. Any areas concerning taxes or specific
          legal or technical questions should be referred to lawyers,
          accountants, consultants, brokers, or other professionals licensed,
          qualified or authorized to render such advice. In no event shall STC
          and/or Creosafe be liable to any party for any direct, indirect,
          special, incidental, or consequential damages of any kind whatsoever
          arising out of the use of this Platform or any information contained
          herein. STC and/or Creosafe specifically disclaim any guarantees,
          including, but not limited to, stated or implied potential profits,
          rates of return, or investment timelines discussed or referred to
          herein.
        </small>
        <Header
          sub
          content="ACCEPTANCE OF AN OFFER AND EXECUTION OF A TRANSACTION"
        />
        <small>
          In order for a transaction to be executed, the following stages must
          be completed:
          <br />
          <br />
          1.Creation of Digital Securities: refers to processes in which a paper
          based security converted into Digital Securities
          <br />
          <br />
          2.Listing of Digital Securities on marketplace: refers to processes in
          which the owner of the security specifies the ask quantity and ask
          price of that digital security to be listed on the marketplace
          <br />
          <br />
          3.Private ask-bid negotiation: refers to processes of private offer
          and counter-offer processes between a potential buyer and the seller
          until one party accept the offer or a counter offer of the
          counter-party.
          <br />
          <br />
          As a user, you are free to withdraw your listing on the marketplace or
          cancel your offers or counteroffers. Once one party accepts the offer
          or counter-party of a counter-party, it means that the parties has
          reached an investment agreement to transact on the terms of the
          accepted offer/ counteroffer. Acceptance of the offer or counteroffer
          by either party means that you agreed on the terms of the investment
          agreement specifically the quantity and price of the digital security
          which is going to be transacted. At this stage both parties are liable
          to meet the terms of the agreed transaction and therefore withdrawal
          from the transaction at this stage is not possible unless both parties
          agree to cancel the agreed transaction. Cancellation fee may apply.
          <br />
          <br />
          The last stage of a transaction is its execution over Ethereum
          blockchain. At this stage, all parties have to sign off on the
          transaction with their private keys. Once a transaction is signed over
          blockchain it is immutable and therefore it can not be cancelled.
          However, if there is a material mistake in the execution process of a
          transaction, the market operators can use a roll-back option. A roll
          back-option refers to the process of burning the Digital Securities,
          which are subject to a mistake and reallocating them to the rightful
          owners. Roll-back fee may apply.
        </small>
        <Header sub content="CONFIDENTIALITY" />
        <small>
          For the purposes of due diligence for a possible investment decision,
          members of the Platform are allowed to share private information among
          each other. Such information can be shared via private links. In case
          a document and information is shared with you via a private link you
          shall consider this as a confidential information and shall agree that
          you will not disclose the link and any document shared with you to any
          third party via the Platform or via any communication tool outside the
          Platform. You shall bear sole responsibility and legal liability if
          you share confidential information in any way without the consent of
          the sender of such information.
        </small>
        <Header sub content="NO INVESTMENT ADVICE" />
        <small>
          The listing of Digital Securities on the marketplace should not be
          regarded as an implied recommendation of such opportunity by STC,
          Creosafe, The Platform Operators or any member of the Platform to
          Investor Members. Investor Members are required to make their own
          assessment of any investment opportunity listed on the marketplace
          even if it was advised by licenced Financial Advisor members on the
          Platform. Neither the Platform Operator nor any other member of the
          Platform, except the Financial Advisor members with proper SEC/FINRA
          licenses, should be regarded as advising on the suitability of any
          investment opportunity presented on the Platform.
        </small>
        <Header sub content="INVESTMENT RISK WARNING" />
        <small>
          Investments through the Platform might be speculative and not intended
          for non-accredited unsophisticated or retail investors. You should not
          make any Investment through the Platform if you are not able to bear
          the loss of your entire Investment.
        </small>
        <Header sub content="DATA PROTECTION PROCEDURES" />
        <small>
          All employees, contractors, agents, consultants, partners or other
          parties working on behalf of the Platform Operators are required to
          comply with the procedures set out in our Data Protection Procedures
          Policy, which is published on the Website.
          <br />
          <br />
          The Platform Operators (and third parties acting on its behalf) will
          use your information for the legitimate purposes of:
          <br />
          <br />
          • understanding and meeting your needs and preferences in using the
          Services;
          <br />
          <br />
          • developing new Services and enhance existing Services;
          <br />
          <br />
          • manage and develop its business and operations;
          <br />
          <br />
          • carrying out any purposes for which you have given your consent; and
          <br />
          <br />
          • meeting legal and regulatory requirements.
          <br />
          <br />
          The Platform Operators will process your personal data legally and
          fairly and not use it outside the purposes stated in these Terms or on
          the Website. The Platform Operators will use their reasonable
          endeavours to ensure that all your personal data it holds is accurate
          and up to date and for this purpose may require further information
          from you as required.
          <br />
          <br />
          The Platform Operators reserves the right to use aggregated personal
          data to understand how Users use the Services, provided that no
          individual can be identified from such data.
          <br />
          <br />
          The Platform Operators may use analytical tools such as Google
          Analytics, which is a web analytics tool to help understand how Users
          engage with the Website. Like many services, Google Analytics uses
          first-party cookies to track user interactions on the Platform. This
          information is used to compile reports and help the Platform Operators
          improve the use of the Platform. You can opt out of Google Analytics
          without it affecting your use of the Website. For more information on
          opting out of being tracked by Google Analytics across all websites
          you use, visit https:// tools.google.com/dlpage/gaoptout.
        </small>
        <Header sub content="TAXES" />
        <small>
          You are reminded that it is your responsibility, and not the
          responsibility of any Platform Operator or member, to pay any taxes
          due and make any required tax filings in any jurisdiction where you
          are tax resident, in respect of any Investment income or gains. It is
          also your responsibility, and not the responsibility of the Platform
          Operators or any Platform member, to reclaim any tax withheld in
          respect of any Investment income or gains, where you are entitled to
          do so, and to claim any other type of tax relief to which you may be
          entitled in respect of any Investment income or gains.
        </small>
        <Header sub content="TERMINATION" />
        <small>
          Your Account may be terminated by the Platform Operators at any time,
          in which case: (i) any balance of your Account, including any amount
          held in an Escrow Account or smart contract, will be returned to you
          without any deduction of withdrawal fees, but after deduction of any
          other amounts owed by you to any member of the Platform; and (ii) any
          underlying securities held by the Platform Operators in respect of
          your Investments will be distributed to you in accordance with the
          applicable Investment Agreement(s) and, to the extent any such
          distribution is not possible, will continue to be held by the Platform
          Platform on the terms of the applicable Investment Agreement(s) and
          these Terms.
          <br />
          <br />
          You may terminate your account at any time after completing your all
          liabilities to a member of the Platform or the Platform Operator in
          particular after agreeing a transaction and in the process of
          execution of such transaction, in which case: (i) any balance of your
          Account, including any amount held in an Escrow Account or smart
          contract, will be returned to you after deduction of withdrawal fees
          and any other amounts owed by you to any member of the Platform; and
          (ii) any underlying securities held by the Platform Operators in
          respect of your Investments will, to the extent possible, be
          distributed to you in accordance with the applicable Investment
          Agreement(s) and, to the extent any such distribution is not possible,
          will continue to be held by the Platform Company on the terms of the
          applicable Investment Agreement(s) and these Terms.
        </small>
        <Header sub content="NO WAIVER" />
        <small>
          No failure to exercise any right or remedy that a person may have
          under these Terms shall operate as a waiver of that right or remedy.
        </small>
        <Header sub content="NO DISPAREGEMENT" />
        <small>
          You shall not publish, disclose any false and injurious statements
          that are derogatory of STC, Creosafe, the Platform, the Platform
          members or any services offered on the Platform.
        </small>
        <Header sub content="ENTIRE AGREEMENT" />
        <small>
          These Terms, together with any supplemental terms and conditions you
          are required to agree to access specific Services on the Platform,
          constitute the entire agreement between you and each member of the
          Platform in connection with your use of the Platform, and any Services
          accessed on or through the Platform.
        </small>
        <Header sub content="GOVERNING LAW AND JURISDICTION" />
        <small>
          These Terms are governed by the laws of Delaware, USA and you agree to
          submit to the exclusive jurisdiction of the Delaware,USA courts in
          respect of any dispute relating to or arising out of the matters
          governed by these Terms.
        </small>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Decline" type="button" onClick={onClose} />
        <Button
          color="green"
          content="Accept"
          type="button"
          onClick={onAccept}
        />
      </Modal.Actions>
    </Modal>
  )
}

const RegisterForm = ({ setCurrentForm }) => {
  const confirmModal = useModal()
  const mounted = useMounted()
  const history = useHistory()
  const { form, field, validate } = useForm('register', initialForm, {
    schema,
  })

  const [register, { loading }] = useMutation(REGISTER, {
    variables: { input: form },
    onCompleted: () => {
      history.push({ pathname: link.login, state: { email: form.email } })
      setCurrentForm('register-completed')
    },
    client,
  })

  const handleValidate = async () => {
    if (await validate()) await confirmModal.show()
  }

  const handleSubmit = async () => {
    confirmModal.hide()
    await register()
  }

  return (
    <>
      <ConfirmationModal onAccept={handleSubmit} {...confirmModal.props} />

      <PageLoader active={loading} />

      <Transition visible={mounted} animation="fade" duration={500}>
        <div>
          <Segment padded="very">
            <div className="stc-logo">
              <STCLogo />
            </div>
            <Header
              as="h5"
              color="purple"
              textAlign="center"
              className="uppercase"
              content="Create an account"
            />
            <Form>
              <Form.Field
                type="email"
                label="Email address"
                placeholder="example@digitalstc.com"
                icon="envelope"
                iconPosition="left"
                control={Input}
                {...field('email')}
              />
              <Form.Field
                fluid
                type="submit"
                content="Create an account"
                color="purple"
                control={Button}
                onClick={handleValidate}
                disabled={!form.email}
              />
            </Form>
            <br />
            <small>
              <Icon name="info" />
              Your email address will act as your username in the STC Digital
              Securities Platform. Please note that it can’t be changed later
              on.
            </small>
          </Segment>
        </div>
      </Transition>
    </>
  )
}

export default RegisterForm
