import React, { Fragment, PureComponent, useRef } from 'react'
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Card,
  Dimmer,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  Loader,
  Message,
  Pagination,
  Popup,
  Table,
} from 'semantic-ui-react'
import InputMask from 'inputmask'
import moment from 'moment'
import _ from 'lodash'
import {
  CompanyStatus,
  Transferability,
  TxStatus,
  UserType,
} from '../util/Constant'
import classNames from 'classnames'
import util from '../util'
import { useMe } from '../util/hooks/me'
import { useMounted } from '../util/hooks'
import userPlaceholder from '../images/user-placeholder.png'
import { Email } from './link'

// block errors of custom icons
Icon.propTypes = PropTypes.any.isRequired

export const PageLoader = ({ active = true }) => (
  <Dimmer page active={Boolean(active)}>
    <Loader />
  </Dimmer>
)
export const DimmerLoader = ({ active = true, ...props }) => (
  <Dimmer inverted active={Boolean(active)} {...props}>
    <Loader />
  </Dimmer>
)
export const ContentLoader = ({ active = true, ...props }) => (
  <Loader active={active} style={{ left: 'calc(50% + 140px)' }} {...props} />
)

/**
 * @param items {{ title, to }}
 * @param title {string}
 * @param props {object} semantic props
 * It renders Breadcrumb component in header // id="breadcrumb"
 */
export const CSBreadcrumb = ({ items = [], title, ...props }) => {
  const mounted = useMounted()

  const renderedBreadCrumb = (
    <Breadcrumb className="p-medium" {...props} size="large">
      <Breadcrumb.Section as={Link} to="/">
        <Icon name="home" />
      </Breadcrumb.Section>
      {items.map(({ title, to }) => (
        <Fragment key={title}>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section as={Link} to={to}>
            {title}
          </Breadcrumb.Section>
        </Fragment>
      ))}
      {title && (
        <>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{title}</Breadcrumb.Section>
        </>
      )}
    </Breadcrumb>
  )

  if (!mounted) return null
  const el = document.getElementById('breadcrumb')
  if (!el) return null
  return ReactDOM.createPortal(renderedBreadCrumb, el)
}

export const PopupIcon = ({ name, content, ...props }) => (
  <Popup
    inverted
    position="top center"
    content={content}
    trigger={<Icon name={name} {...props} />}
  />
)

export const SingleGrid = ({ children, ...props }) => (
  <Grid {...props}>
    <Grid.Row>
      <Grid.Column>{children}</Grid.Column>
    </Grid.Row>
  </Grid>
)

export const CardTitle = ({ content, children, icon, description }) => (
  <Card.Content>
    <Card.Header>
      {icon && <Icon name={icon} />} {content || children}
    </Card.Header>
    {description && (
      <Card.Description className="m-0">{description}</Card.Description>
    )}
  </Card.Content>
)

export const SubMessage = ({
  icon,
  loading,
  content,
  fluid,
  className,
  ...props
}) => {
  return (
    <Message
      compact
      size="small"
      className={classNames(className, { 'w-100p': fluid })}
      {...props}
    >
      <Header sub>
        {icon && (
          <Icon name={icon} style={{ lineHeight: 1 }} loading={loading} />
        )}
        {content}
      </Header>
    </Message>
  )
}

export const PersonCard = ({ person, keyIndex, handleRemove, ...props }) => (
  <Card {...props}>
    <Card.Content>
      <Item.Group>
        <Item style={{ alignItems: 'center' }}>
          <Item.Image
            size="tiny"
            src={util.prependImage(person.photo, userPlaceholder)}
          />
          <Item.Content>
            <Item.Header>
              {person.firstname} {person.lastname}
            </Item.Header>
            <Item.Meta>{person.position}</Item.Meta>
            {person.phonenumber && (
              <Item.Meta>
                <Icon name="phone" color="purple" />{' '}
                <PhoneNumber value={person.phonenumber} />
              </Item.Meta>
            )}
            {person.email && (
              <Item.Meta>
                <Icon name="envelope" color="purple" />{' '}
                <Email value={person.email} />
              </Item.Meta>
            )}
            {person.is_private && (
              <Item.Meta>
                <Icon name="lock" color="purple" /> Private
              </Item.Meta>
            )}
          </Item.Content>
          {handleRemove && (
            <Icon
              color="red"
              name="trash"
              onClick={() => handleRemove(keyIndex)}
            />
          )}
        </Item>
      </Item.Group>
    </Card.Content>
  </Card>
)

/**
 * simple table for simple information
 * @param title {string}
 * @param data {[{ header, cell, info?, headerProps?, cellProps?, className? }]}
 * @param optional {boolean} do not render empty cells
 * @param props semantic table props
 */
export const InfoTable = ({ data, title, optional, ...props }) => {
  let filteredData = data
  if (optional) {
    filteredData = data.filter(({ cell }) => cell)
  }

  return (
    <Table basic="very" singleLine padded fixed size="small" {...props}>
      {title && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan={2} content={title} />
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {filteredData.map(
          (
            {
              header,
              cell,
              info,
              headerProps = {},
              cellProps = {},
              className = '',
            },
            i
          ) => {
            return (
              <Table.Row key={i} className={className}>
                <Table.Cell {...headerProps}>
                  {info ? (
                    <Popup
                      inverted
                      position="top left"
                      content={info}
                      trigger={
                        <b>
                          <div>
                            <small>
                              <Icon name="info circle" />
                            </small>
                            {header}
                          </div>
                        </b>
                      }
                    />
                  ) : (
                    <b>{header}</b>
                  )}
                </Table.Cell>
                <Table.Cell {...cellProps}>{cell}</Table.Cell>
              </Table.Row>
            )
          }
        )}
      </Table.Body>
    </Table>
  )
}

// table headers with info popup
export const TableInfoHeader = ({ content, info, ...props }) => (
  <Table.HeaderCell {...props}>
    <div>
      <Popup
        trigger={<Icon name="info" />}
        content={info}
        size="tiny"
        inverted
      />
      <span>{content}</span>
    </div>
  </Table.HeaderCell>
)

export class APIPagination extends PureComponent {
  static propTypes = {
    paginatorInfo: PropTypes.object,
    count: PropTypes.number,
    handleChange: PropTypes.func,
  }
  static defaultProps = {
    count: 10,
    paginatorInfo: { total: 1, currentPage: 1 },
    handleChange: _.noop,
  }
  handlePageChange = (e, { activePage, ...props }) => {
    this.props.handleChange(activePage, { activePage, ...props })
  }

  render() {
    const { handleChange, paginatorInfo, count, ...props } = this.props
    if (!paginatorInfo) return null

    const { total, currentPage } = paginatorInfo

    const totalPages = Math.ceil(total / count)

    if (totalPages > 1) {
      return (
        <Pagination
          activePage={currentPage}
          onPageChange={this.handlePageChange}
          totalPages={totalPages}
          floated="right"
          {...props}
        />
      )
    } else {
      return null
    }
  }
}

// format number and prepend icon (currency: USD|USDC|ETH)
export const NumberFormat = ({
  amount,
  float,
  digits,
  currency,
  usd,
  eth,
  usdc,
  coin,
}) => {
  let icon = null

  if (usd || currency === 'USD') icon = <Icon name="dollar" fitted />
  if (eth || currency === 'ETH') icon = <Icon name="ethereum" fitted />
  if (usdc || currency === 'USDC') icon = <Icon name="usdc" fitted />
  if (coin) icon = <Icon name="coin" fitted />

  const numStr = float
    ? util.formatNumberFloat(amount, digits)
    : util.formatNumber(amount)

  return (
    <>
      {icon ? icon : ''} {numStr}
    </>
  )
}

export const MoneyFormat = ({ value, colored = false }) => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  let formattedValue
  if (Math.abs(value) > 999999999) {
    formattedValue = (Math.abs(value) / 1000000000).toFixed(2) + 'B'
  } else if (Math.abs(value) > 999999) {
    formattedValue = (Math.abs(value) / 1000000).toFixed(2) + 'M'
  } else if (Math.abs(value) > 999) {
    formattedValue = (Math.abs(value) / 1000).toFixed(2) + 'K'
  } else {
    formattedValue = Math.abs(value).toFixed(2)
  }

  return (
    <Popup
      trigger={
        <p
          style={{
            color:
              colored && value > 0
                ? 'green'
                : colored && value < 0
                ? 'red'
                : '',
          }}
          className="text-nowrap"
        >
          $ {formattedValue}
        </p>
      }
      content={`${currency.format(value)}`}
      size="tiny"
      position="top center"
      inverted
    />
  )
}

// proxy component that returns phonenumber link as formatted
export const PhoneNumber = ({ value, ...props }) => {
  if (!value) return '-'
  const formatted = InputMask.format(value, '+1 (999) 999-9999')
  return (
    <a href={`tel:${formatted}`} {...props}>
      {formatted}
    </a>
  )
}

export const TaxIdFormat = ({ value }) => {
  if (!value) return '-'
  return InputMask.format(value, '99-9999999')
}

export const ActionButton = ({ ...props }) => {
  return (
    <Button
      type="button"
      primary
      content="Next"
      icon="chevron right"
      labelPosition="right"
      {...props}
    />
  )
}

export const getTransferability = token => {
  if (token.tx_status === TxStatus.PENDING) {
    return {
      info: 'This Digital Securities has been creating on smart contract',
      color: 'blue',
      icon: 'spinner',
      loading: true,
    }
  }

  if (token.transferability.status === Transferability.NOT_ALLOWED) {
    return {
      info: 'Transferability pending',
      color: 'blue',
      icon: 'clock',
    }
  }

  // if token is partially transferable
  const available = _.get(token, 'token_numbers.available')
  const on_market = _.get(token, 'token_numbers.on_market')

  let amountInfo = null
  if (available && on_market) {
    const transferable = Number(available) + Number(on_market)
    amountInfo = (
      <Label color="blue" pointing="left">
        <NumberFormat amount={transferable} /> available for transfer
      </Label>
    )
  }

  if (token.transferability.restricted === true) {
    return {
      info: <>Transferable with restriction {amountInfo} (enabled by STC.)</>,
      color: 'yellow',
      icon: 'exclamation triangle',
    }
  }

  return {
    info: <>Transferable without restriction {amountInfo} (enabled by STC.)</>,
    color: 'green',
    icon: 'check',
  }
}
export const TransferabilityInfo = ({ token }) => {
  const { info, color, icon, loading } = getTransferability(token)
  return (
    <>
      <Icon name={icon} loading={loading} color={color} />
      {info}
    </>
  )
}
export const TransferabilityIcon = ({ token }) => {
  const { info, color, icon, loading } = getTransferability(token)
  return (
    <Popup
      inverted
      position="top center"
      content={info}
      trigger={<Icon name={icon} color={color} loading={loading} />}
    />
  )
}

export const UserVerifiedLabel = ({ isVerified }) => {
  if (isVerified) {
    return <Label color="green" size="small" icon="check" content="VERIFIED" />
  }
  return null
}

export const CompanyVerifiedLabel = ({ company, iconOnly }) => {
  if (company.status === CompanyStatus.VERIFIED) {
    if (iconOnly) {
      return (
        <Popup
          inverted
          position="top center"
          content="VERIFIED COMPANY"
          trigger={<Icon name="check" color="green" />}
        />
      )
    }

    return <Label color="green" size="small" icon="check" content="VERIFIED" />
  }
  return null
}

export const MyCompanyLabel = ({ company, iconOnly }) => {
  if (company.company_owner) {
    if (iconOnly) {
      return (
        <Popup
          inverted
          position="top center"
          content="MY COMPANY"
          trigger={<Icon name="star" color="yellow" />}
        />
      )
    }

    return (
      <Label color="yellow" size="small" icon="star" content="MY COMPANY" />
    )
  }

  return null
}

export const TokenAmountInfo = ({ token }) => {
  const { on_market } = token.token_numbers

  return (
    <>
      <AmountLabel amount={token.amount} />
      &nbsp;
      {Number(on_market) > 0 && (
        <Label
          color={Number(on_market) === token.amount ? 'red' : 'green'}
          pointing="left"
        >
          <NumberFormat amount={on_market} /> on Marketplace
        </Label>
      )}
    </>
  )
}

export const TokenizedAmountInfo = ({ contract }) => {
  const { initial, on_market } = contract.token_numbers

  return (
    <>
      <AmountLabel amount={initial} />
      &nbsp;
      {Number(on_market) > 0 && (
        <Label
          color={Number(on_market) === Number(initial) ? 'red' : 'blue'}
          pointing="left"
        >
          <NumberFormat amount={on_market} /> on Marketplace
        </Label>
      )}
    </>
  )
}

export const DigitizedSecurityInfo = ({ contract }) => {
  const { total, available, initial } = contract.token_numbers

  return (
    <>
      <AmountLabel amount={initial} />
      &nbsp;
      {Number(total) > 0 && (
        <Popup
          inverted
          content="The number of Digital Securities created or allocated"
          position="top center"
          trigger={
            <Label
              pointing="left"
              color={Number(available) === 0 ? 'red' : 'blue'}
            >
              {Number(available) === 0 ? (
                'All'
              ) : (
                <NumberFormat amount={Number(total)} />
              )}{' '}
              digitized
            </Label>
          }
        />
      )}
    </>
  )
}

export const CopyInput = ({ value, ...props }) => {
  const inputRef = useRef(null)
  const handleClick = () => {
    inputRef.current.select()
    document.execCommand('copy')
    inputRef.current.focus()
  }
  return (
    <Input
      fluid
      ref={inputRef}
      value={value}
      action={{
        labelPosition: 'right',
        icon: 'copy',
        content: 'Copy',
        onClick: handleClick,
      }}
      {...props}
    />
  )
}

const providerTypes = [UserType.BROKER_DEALER, UserType.FINANCIAL_ADVISOR]
const customerTypes = [UserType.INDIVIDUAL, UserType.INSTITUTIONAL]
// <IfUserIs ta/>
// <IfUserIs customer/>
// <IfUserIs verified/>
// <IfUserIs not provider/>
// <IfUserIs a={UserType.TRANSFER_AGENT}/>
// <IfUserIs not a={UserType.TRANSFER_AGENT/>
// <IfUserIs not oneOf={UserType.TRANSFER_AGENT/>
export const IfUserIs = ({
  a,
  not,
  oneOf,
  provider,
  customer,
  ta,
  fa,
  bd,
  verified,
  hasWallet,
  hasProfile,
  children,
}) => {
  const { user_type: type, is_verified, profile, primary_wallet } = useMe()
  let show = false
  if (a) show = type === a
  if (oneOf) show = oneOf.indexOf(type) >= 0
  if (ta) show = type === UserType.TRANSFER_AGENT
  if (bd) show = type === UserType.BROKER_DEALER
  if (fa) show = type === UserType.FINANCIAL_ADVISOR
  if (customer) show = customerTypes.indexOf(type) >= 0
  if (provider) show = providerTypes.indexOf(type) >= 0
  if (verified) show = is_verified
  if (hasProfile) show = Boolean(profile)
  if (hasWallet) show = Boolean(primary_wallet)

  if (not) show = !show
  if (show) return children
  return null
}

export const DateInfo = ({ str, api, iso = true }) => {
  if (!str) return '-'
  let momentObj
  if (api) momentObj = moment.utc(str, 'YYYY-MM-DD')
  else if (iso) momentObj = moment.utc(str)

  return momentObj.format('ll')
}

export const AgeInfo = ({ str, api, iso = true, simple = false }) => {
  let momentObj
  if (api) momentObj = moment.utc(str, 'YYYY-MM-DD')
  else if (iso) momentObj = moment.utc(str)

  return (
    <>
      {!simple && <PopupIcon name="time" content={momentObj.format('LLL')} />}
      <span className="capitalize">{momentObj.fromNow()}</span>
    </>
  )
}

export const FormLabelInfo = ({ content, info }) => {
  return (
    <>
      <label style={{ display: 'inline-block' }}>{content}</label>
      <Popup
        inverted
        position="top center"
        content={info}
        trigger={<Icon name="info circle" />}
      />
    </>
  )
}

export const AmountLabel = ({ amount }) => {
  return (
    <Popup
      inverted
      content="The number of Digital Securities that you want to buy/sell. The face value of each digital security is $1"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          className="text-nowrap"
          content={<NumberFormat amount={amount} />}
        />
      }
    />
  )
}

export const AskAmountLabel = ({ amount }) => {
  return (
    <Popup
      inverted
      content="The number of Digital Securities for sale"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          className="text-nowrap"
          content={<NumberFormat amount={amount} />}
        />
      }
    />
  )
}

export const OfferAmountLabel = ({ amount }) => {
  return (
    <Popup
      inverted
      content="The number of Digital Securities you offer to buy/sell"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          className="text-nowrap"
          content={<NumberFormat amount={amount} />}
        />
      }
    />
  )
}

export const AskPriceLabel = ({ price }) => {
  return (
    <Popup
      inverted
      content="The value of the Digital Securities for sale"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          content={
            <>
              $<NumberFormat amount={price} />
            </>
          }
        />
      }
    />
  )
}

export const OfferPriceLabel = ({ price }) => {
  return (
    <Popup
      inverted
      content="The value of the Digital Securities you offer to buy/sell"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          className="text-nowrap"
          content={
            <>
              $<NumberFormat amount={price} />
            </>
          }
        />
      }
    />
  )
}

export const PPDSLabel = ({ data }) => {
  const amount = Number(data.amount)
  const price = Number(data.price)

  if (!amount || !price) return null

  return (
    <Popup
      inverted
      position="right center"
      content="Price Per Digital Security. Each digital security represents a face value of $1 of the respective original security"
      trigger={
        <Label
          size="large"
          icon="question circle"
          color="black"
          className="text-nowrap"
          content={
            <>
              $<NumberFormat float digits={2} amount={price / amount} />
            </>
          }
        />
      }
    />
  )
}

export const PaymentType = ({ currencies }) => {
  return (
    <>
      {currencies.includes('ETH') && <Label icon="ethereum" content="ETH" />}
      {currencies.includes('USDC') && <Label icon="usdc" content="USDC" />}
      {currencies.includes('USD') && <Label icon="usd" content="USD" />}
    </>
  )
}

export const AvailableTokens = ({ token }) => {
  return (
    <Popup
      inverted
      content="Amount of Digital Securities available for listing"
      trigger={
        <Label
          size="large"
          color="black"
          content={
            <>
              <NumberFormat amount={token.token_numbers.available} />
              <span> / </span>
              <NumberFormat amount={token.token_numbers.total} />
            </>
          }
        />
      }
    />
  )
}
