import React, { useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import * as yup from 'yup'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
  Table,
  TextArea,
} from 'semantic-ui-react'
import {
  AskAmountLabel,
  AskPriceLabel,
  DimmerLoader,
  PaymentType,
  PPDSLabel,
} from '..'
import { UserTypeLabel } from '../User'
import { SuccessModal } from '../confirm'
import { useModal } from '../../util/hooks'
import { useSteps } from '../../util/hooks/steps'
import useForm from '../../util/hooks/form'
import { client, SOLICIT_LISTING } from '../../util/API/Apollo'
import { CompanyLink, UserLink } from '../link'

const schema = yup
  .object()
  .shape({
    subject: yup.string().required('Subject is required'),
    description: yup.string().required('Description is required'),
  })
  .test('user-selected', 'You must select at least one investor', value => {
    if (!value.selectAll) {
      const selectedInvestors = Object.keys(value.selectedUsers).filter(
        uuid => value.selectedUsers[uuid]
      )
      return selectedInvestors.length > 0
    }
    return true
  })

const initialForm = {
  selectAll: false, // if all users are selected
  selectedUsers: {}, // { uuid: boolean }
  subject: '',
  description: '',
}

const steps = ['investor', 'advice']

const AdviceModal = ({ listing, refetch, open, onClose }) => {
  const successModal = useModal()
  const { form, field, validate, set, setForm } = useForm(
    'advice',
    initialForm,
    {
      schema,
    }
  )
  const { isActive, nextStep, prevStep } = useSteps(steps, 'investor')

  const userIds = useMemo(() => {
    return Object.keys(form.selectedUsers).filter(
      uuid => form.selectedUsers[uuid]
    )
  }, [form.selectedUsers])

  const [solicit, { loading }] = useMutation(SOLICIT_LISTING, {
    variables: {
      input: {
        listing_id: listing.uuid,
        customers: userIds,
        subject: form.subject,
        message: form.description,
        inverted: form.selectAll,
      },
    },
    onCompleted: () => {
      onClose()
      successModal.show()
      refetch()
    },
    client,
  })

  const handleAdvise = async () => {
    if (await validate()) await solicit()
  }

  const handleSelectAll = (e, { checked }) => {
    setForm({ ...form, selectedUsers: {}, selectAll: checked })
  }

  const isInvestorChecked = uuid => {
    const enabled = Boolean(form.selectedUsers[uuid])
    return form.selectAll ? !enabled : enabled
  }

  const handleInvestorCheck = (e, { name: uuid }) => {
    const enabled = Boolean(form.selectedUsers[uuid])

    if (enabled) {
      delete form.selectedUsers[uuid]
      set('selectedUsers', form.selectedUsers)
    } else {
      set('selectedUsers', { ...form.selectedUsers, [uuid]: !enabled })
    }
  }

  return (
    <>
      <Modal closeIcon dimmer="blurring" open={open} onClose={onClose}>
        <DimmerLoader active={loading} />
        <Modal.Header content="ADVICE THIS LISTING" />
        <Modal.Content>
          {isActive('investor') && (
            <>
              <Table basic="very" padded="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content="SELLER" />
                    <Table.HeaderCell content="ISSUER" />
                    <Table.HeaderCell content="ASK AMOUNT" />
                    <Table.HeaderCell content="ASK PRICE" />
                    <Table.HeaderCell content="PPDS" />
                    <Table.HeaderCell content="PAYMENT TYPE" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <UserLink user={listing.seller} />
                    </Table.Cell>
                    <Table.Cell>
                      <CompanyLink company={listing.token.contract.issuer} />
                    </Table.Cell>
                    <Table.Cell>
                      <AskAmountLabel amount={listing.amount} />
                    </Table.Cell>
                    <Table.Cell>
                      <AskPriceLabel price={listing.price} />
                    </Table.Cell>
                    <Table.Cell>
                      <PPDSLabel
                        data={{ amount: listing.amount, price: listing.price }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <PaymentType currencies={listing.currencies} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Segment padded="very" color="black">
                <Header as="h2" content="Select investor" />
                <Input
                  fluid
                  selection
                  search
                  basic
                  size="small"
                  icon="search"
                  iconPosition="right"
                  placeholder="Search customer"
                  disabled
                />
                <Divider hidden />
                <Table basic="very" padded="very" size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        <Checkbox
                          as="header"
                          label="SELECT ALL"
                          checked={form.selectAll}
                          onChange={handleSelectAll}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell content="CUSTOMER" />
                      <Table.HeaderCell content="CUSTOMER TYPE" />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listing.solicitable.map(user => (
                      <Table.Row key={user.uuid}>
                        <Table.Cell textAlign="center">
                          {user.solicited ? (
                            <Icon name="bell" />
                          ) : (
                            <Checkbox
                              name={user.uuid}
                              checked={isInvestorChecked(user.uuid)}
                              onChange={handleInvestorCheck}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <UserLink user={user} />
                        </Table.Cell>
                        <Table.Cell>
                          <UserTypeLabel user={user} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
              <Divider hidden />
              <Button
                primary
                fluid
                size="small"
                content="Next"
                onClick={nextStep}
                disabled={_.isEmpty(form.selectedUsers) && !form.selectAll}
              />
            </>
          )}
          {isActive('advice') && (
            <>
              <Segment color="black" padded="very">
                <Header as="h2" content="Make advice to your customers" />
                <Form onSubmit={handleAdvise}>
                  <Form.Field
                    fluid
                    required
                    size="small"
                    label="Subject"
                    control={Input}
                    {...field('subject')}
                  />
                  <Form.Field
                    required
                    size="small"
                    label="Message"
                    control={TextArea}
                    {...field('description')}
                  />
                  <Form.Button primary fluid size="small" content="Advice" />
                </Form>
              </Segment>
              <Divider hidden />
              <Button size="small" content="back" onClick={prevStep} />
            </>
          )}
        </Modal.Content>
      </Modal>

      <SuccessModal
        content="You advised selected investor(s) successfully."
        {...successModal.props}
      />
    </>
  )
}

export default AdviceModal
