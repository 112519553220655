import React from 'react'
import { ConfirmModal, SuccessModal } from '../confirm'
import { Button } from 'semantic-ui-react'
import { PageLoader } from '../index'
import { useModal } from '../../util/hooks'
import { useMutation } from '@apollo/react-hooks'
import {
  APPROVE_MSG_DOC,
  client,
  GET_PROVIDER_ACTION_DETAIL,
} from '../../util/API/Apollo'

const MSGApproveButton = ({ action, show }) => {
  const confirmModal = useModal()
  const successModal = useModal()

  const [reject, { loading }] = useMutation(APPROVE_MSG_DOC, {
    variables: {
      provider_action_id: action.uuid,
    },
    refetchQueries: [
      { query: GET_PROVIDER_ACTION_DETAIL, variables: { uuid: action.uuid } },
    ],
    onCompleted: successModal.show,
    awaitRefetchQueries: true,
    client,
  })

  return (
    <>
      <ConfirmModal {...confirmModal.props} onConfirm={reject} />
      <SuccessModal
        content="You approved the document"
        {...successModal.props}
      />
      <PageLoader active={loading} />

      {show && (
        <>
          <Button color="green" content="Verify" onClick={confirmModal.show} />
        </>
      )}
    </>
  )
}

export default MSGApproveButton
