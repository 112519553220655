import React, { useState } from 'react'
import { Button, Form, Header, Icon, Input, Item } from 'semantic-ui-react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_PROFILE } from '../../util/API/Apollo'
import {
  CompanyVerifiedLabel,
  MyCompanyLabel,
  SingleGrid,
} from '../../components'
import CompanySearchInput from '../../components/Company/CompanySearchInput'
import CompanySummary from '../../components/Company/CompanySummary'
import { DocumentUpload } from '../../components/upload'
import { AssetCodes } from '../../util/Constant'
import util from '../../util'
import companyPlaceholder from '../../images/company-placeholder.png'
import { profileCompanySchema } from '../../components/Profile/schema'
import { CompanyLink } from '../../components/link'

const CompanyDetails = ({ profile, formHook, handleUpdate }) => {
  const { data } = useQuery(GET_PROFILE, { client })
  const user = _.get(data, 'me', {})
  const [isEditing, setIsEditing] = useState(false)
  const { company, company_position } = profile
  const { field, validate, clear, isDirty, form } = formHook

  const handleSubmit = async () => {
    if (await validate(profileCompanySchema)) {
      handleUpdate()
      setIsEditing(false)
    }
  }

  return (
    <SingleGrid padded>
      <div className="flex-spread">
        <Header as="h3" content="COMPANY DETAILS" />
        {!user.is_verified && !isEditing && (
          <Icon
            link
            floated="right"
            color="grey"
            name="edit"
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>
      {!isEditing ? (
        <Item.Group relaxed>
          <Item>
            <Item.Image
              src={util.prependImage(company.logo, companyPlaceholder)}
              // className="img-rounded"
              rounded
            />
            <Item.Content>
              <Item.Header className="py-5" as="h4">
                <CompanyLink company={company} />
              </Item.Header>
              <br />
              <Item.Header as="h4">
                <CompanyVerifiedLabel company={company} />
                <MyCompanyLabel company={company} />
              </Item.Header>
              <br />
              <Item.Header className="py-5" as="h4" content="Position" />
              <Item.Meta content={company_position} />
            </Item.Content>
          </Item>
        </Item.Group>
      ) : (
        <>
          <CompanySearchInput {...field('company')} />
          <CompanySummary company={form.company} />
          <Form>
            <Form.Field
              label="Company Position"
              fluid
              placeholder="Company Position"
              icon="pencil"
              iconPosition="left"
              required
              control={Input}
              {...field('company_position')}
            />
            <Form.Field
              label="Letter of Authorization"
              asset_code={AssetCodes.loa}
              required
              control={DocumentUpload}
              {...field('letter_of_authorization')}
            />
            <br />
            <Button
              primary
              size="small"
              content="Save"
              type="button"
              floated="right"
              disabled={!isDirty}
              onClick={handleSubmit}
            />
            <Button
              size="small"
              content="Cancel"
              type="button"
              floated="right"
              onClick={() => {
                setIsEditing(false)
                clear()
              }}
            />
          </Form>
        </>
      )}
    </SingleGrid>
  )
}

export default CompanyDetails
