import React from 'react'
import { Button, Divider, Form, Grid, Header, Input } from 'semantic-ui-react'
import { Mutation } from 'react-apollo'
import { ReactComponent as STCLogo } from '../images/stc_logo.svg'
import { REQUEST_RESET_PASSWORD } from '../util/API/Apollo'
import { useModal } from '../util/hooks'
import { SuccessModal } from '../components/confirm'
import { PageLoader } from '../components'
import useForm from '../util/hooks/form'

const initialForm = { email: '' }
const ForgotPassword = () => {
  const { input, form } = useForm('forgot-password', initialForm)
  const success = useModal()

  return (
    <Mutation
      mutation={REQUEST_RESET_PASSWORD}
      variables={{ email: form.email }}
      onCompleted={success.show}
    >
      {(request, { loading, data }) => {
        return (
          <Grid
            className="h-100vh"
            verticalAlign="middle"
            centered
            as={Form}
            onSubmit={request}
          >
            <SuccessModal
              open={success.isOpen}
              onClose={success.hide}
              content="An email has been sent to reset password"
            />
            <PageLoader active={loading} />
            <Grid.Row>
              <Grid.Column computer={4} tablet={10} mobile={16}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div className="stc-logo">
                        <STCLogo />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header
                        as="h3"
                        textAlign="center"
                        color="purple"
                        content="Forgot your Password"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Input
                        fluid
                        icon="envelope"
                        iconPosition="left"
                        placeholder="Email"
                        type="email"
                        {...input('email')}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Button
                        fluid
                        color="purple"
                        content="Reset My Password"
                        type="submit"
                        disabled={Boolean(!form.email || data)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Divider
                        horizontal
                        content="did not receive reset password email"
                      />
                      <Header as="h4" textAlign="center">
                        If your reset email doesn't show up, make sure to check
                        your spam/junk folders or send again a key for reset
                        your password
                      </Header>
                      <Button fluid content="Send Again" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      }}
    </Mutation>
  )
}

export default ForgotPassword
