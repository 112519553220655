import React from 'react'
import { Button, Header, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { useApproval, useModal } from '../../util/hooks'
import CompanySummary from '../../components/Company/CompanySummary'
import MSGApprovalsTable from '../../components/Verification/MSGApprovalsTable'
import UserProvidersTable from '../../components/Provider/UserProvidersTable'
import { ApprovalStatus, ApprovalType } from '../../util/Constant'
import { GET_PROVIDER_ACTION_DETAIL } from '../../util/API/Apollo'
import KYCVerificationForm from '../../components/Verification/KYCVerificationForm'
import KYCACCRejectionButton from '../../components/Verification/KYCACCRejectionButton'
import KYCRequestDocButton from '../../components/Verification/KYCACCRequestDocButton'
import KYCACCApproveButton from '../../components/Verification/KYCACCApproveButton'
import ActionApprovalStatus from '../../components/Action/ActionApprovalStatus'
import ACCVerificationForm from '../../components/Verification/ACCVerificationForm'
import ProfileCommonDetails from '../../components/Profile/ProfileCommonDetails'
import ProfileSummary from '../../components/Profile/ProfileSummary'
import TabMenu from '../../components/TabMenu'
import BankDetails from '../ProfileDetails/BankDetails'
import { FileLink } from '../../components/link'

const KYCACCTable = ({ action, approvalType }) => {
  const formModal = useModal()
  const approval = useApproval(action.approvals, approvalType)
  const isNewEmptyUser = !approval
  const isWaiting = _.get(approval, 'status') === ApprovalStatus.WAITING

  return (
    <>
      {approvalType === ApprovalType.VERIFICATION_KYC && (
        <KYCVerificationForm
          {...formModal.props}
          action={action}
          user={action.actionable}
          onSubmit={formModal.hide}
          refetchQueries={[
            {
              query: GET_PROVIDER_ACTION_DETAIL,
              variables: { uuid: action.uuid },
            },
          ]}
        />
      )}
      {approvalType === ApprovalType.VERIFICATION_ACC && (
        <ACCVerificationForm
          {...formModal.props}
          action={action}
          user={action.actionable}
          onSubmit={formModal.hide}
          refetchQueries={[
            {
              query: GET_PROVIDER_ACTION_DETAIL,
              variables: { uuid: action.uuid },
            },
          ]}
        />
      )}
      <Table basic="very" padded="very" size="small" fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="DOCUMENT" />
            <Table.HeaderCell content="STATUS" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <FileLink file={_.get(approval, 'verification_data.document')} />
            </Table.Cell>
            <Table.Cell>
              <ActionApprovalStatus approval={approval} />
            </Table.Cell>
            <Table.Cell textAlign="right"></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {isNewEmptyUser && (
        <>
          <Button
            content="Verify account"
            color="green"
            type="button"
            onClick={formModal.show}
            data-test-id={
              approvalType === ApprovalType.VERIFICATION_KYC
                ? 'start-kyc-verification'
                : 'start-acc-verification'
            }
          />
        </>
      )}
      <KYCRequestDocButton
        action={action}
        show={isNewEmptyUser}
        approvalType={approvalType}
      />
      <KYCACCRejectionButton
        action={action}
        show={isWaiting}
        approvalType={approvalType}
      />
      <KYCACCApproveButton
        action={action}
        show={isWaiting}
        approvalType={approvalType}
      />
    </>
  )
}

const NewCustomerActionDetails = ({ action }) => {
  const user = action.actionable
  const msgApprovals = _.get(action, 'actionable.approvals.data', []).filter(
    a => a.approval_type === ApprovalType.VERIFICATION_MSG
  )
  return (
    <TabMenu defaultTab="action-details">
      <TabMenu.Left>
        <TabMenu.Menu>
          <TabMenu.Item name="action-details" />
          <TabMenu.Item name="account-details" />
          {_.get(user, 'profile.company') && (
            <TabMenu.Item name="company-details" />
          )}
          {_.get(user, 'profile.bank_info.bank_name') && (
            <TabMenu.Item name="bank-details" />
          )}
          {msgApprovals.length > 0 && <TabMenu.Item name="medallion-stamps" />}
          <TabMenu.Item name="providers" />
        </TabMenu.Menu>
      </TabMenu.Left>
      <TabMenu.Right>
        <ProfileSummary user={user} />
        <TabMenu.Pane name="action-details">
          <Header as="h2" content="ACCOUNT VERIFICATION" />
          <KYCACCTable
            action={action}
            approvalType={ApprovalType.VERIFICATION_KYC}
          />
          <Header as="h2" content="ACCREDITED INVESTOR" />
          <KYCACCTable
            action={action}
            approvalType={ApprovalType.VERIFICATION_ACC}
          />
        </TabMenu.Pane>
        <TabMenu.Pane name="account-details">
          <ProfileCommonDetails user={user} />
        </TabMenu.Pane>
        <TabMenu.Pane name="company-details">
          <CompanySummary company={user.profile.company} />
        </TabMenu.Pane>
        <TabMenu.Pane name="bank-details">
          <BankDetails user={user} />
        </TabMenu.Pane>
        <TabMenu.Pane name="medallion-stamps">
          <MSGApprovalsTable approvals={msgApprovals} />
        </TabMenu.Pane>
        <TabMenu.Pane name="providers">
          <UserProvidersTable user={action.actionable} />
        </TabMenu.Pane>
      </TabMenu.Right>
    </TabMenu>
  )
}

export default NewCustomerActionDetails
