import React from 'react'
import { Button, Form, Header, Input, Modal } from 'semantic-ui-react'
import _ from 'lodash'
import * as yup from 'yup'
import { PhoneInput } from '../../../components/input'
import useForm from '../../../util/hooks/form'

const URL_REGEX = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
  phonenumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Phone number is invalid',
    }),
  url_web: yup
    .string()
    .required('Website is required')
    .matches(URL_REGEX, {
      message: 'Website is invalid',
      excludeEmptyString: true,
    }),
  contact_firstname: yup.string().required('First name is required'),
  contact_lastname: yup.string().required('Last name is required'),
  contact_phonenumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{7,10}$/, {
      excludeEmptyString: true,
      message: 'Phone number is invalid',
    }),
  contact_email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
})

const OptionalDetailsForm = ({ open, onClose, value, onChange, ...props }) => {
  const { field, form, validate, clear } = useForm(
    'contract-optional-details',
    value,
    { schema }
  )

  const handleSubmit = async () => {
    if (await validate()) {
      const newValue = _.clone(form)
      onChange(newValue, { ...props, value: newValue })
      onClose()
    }
  }
  const handleClose = () => {
    clear()
    onClose()
  }

  return (
    <Modal closeIcon dimmer="blurring" open={open} onClose={handleClose}>
      <Modal.Header content="ADD OPTIONAL DETAILS" />
      <Modal.Content>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Field
            required
            label="Phone"
            placeholder="Phone"
            fluid
            control={PhoneInput}
            {...field('phonenumber')}
          />
          <Form.Field
            required
            label="Email"
            type="email"
            placeholder="Email"
            fluid
            control={Input}
            {...field('email')}
          />
          <Form.Field
            required
            label="Website"
            placeholder="https://"
            fluid
            icon="globe"
            iconPosition="left"
            control={Input}
            {...field('url_web')}
          />
          <Header as="h4" content="CONTACT PERSON" />
          <Form.Group widths="equal">
            <Form.Field
              required
              label="First Name"
              icon="user"
              iconPosition="left"
              fluid
              placeholder="First Name"
              control={Input}
              {...field('contact_firstname')}
            />
            <Form.Field
              required
              label="Last Name"
              icon="user"
              iconPosition="left"
              fluid
              placeholder="Last Name"
              control={Input}
              {...field('contact_lastname')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              required
              label="Phone"
              icon="phone"
              iconPosition="left"
              fluid
              placeholder="Phone"
              control={PhoneInput}
              {...field('contact_phonenumber')}
            />
            <Form.Field
              required
              label="Email"
              icon="envelope"
              iconPosition="left"
              fluid
              placeholder="Email"
              control={Input}
              {...field('contact_email')}
            />
          </Form.Group>
          <Button
            type="submit"
            primary
            fluid
            content="ADD"
            data-test-id="finish-add-optional-details"
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default OptionalDetailsForm
