import { combineReducers } from 'redux'

const initialState = { reducer: {} }

const reducer = (state = initialState.reducer, action) => {
  switch (action.type) {
    case 'MOCK_ACTION_1':
      return state
    case 'MOCK_ACTION_2':
      return state
    default:
      return state
  }
}

const reducers = combineReducers({
  reducer,
})

export default reducers
