import React from 'react'
import { Button, Divider } from 'semantic-ui-react'
import TabMenu from '../../components/TabMenu'
import {
  ContractTabMenuItems,
  ContractTabMenuPanes,
} from '../../components/Contract/util'
import ProfileSummary from '../../components/Profile/ProfileSummary'
import ContractVerificationForm from '../../components/Verification/ContractVerificationForm'
import { useApproval, useModal } from '../../util/hooks'
import { ApprovalStatus, ApprovalType } from '../../util/Constant'

const ContractActionDetails = ({ action }) => {
  const contract = action.actionable
  const approval = useApproval(action.approvals, ApprovalType.TRANSFERABILITY)
  const isReady = approval.status === ApprovalStatus.INITIALIZED
  const formModal = useModal()

  return (
    <>
      <ContractVerificationForm {...formModal.props} action={action} />
      <TabMenu defaultTab="asset-details">
        <TabMenu.Left>
          <TabMenu.Menu>
            <ContractTabMenuItems contract={contract} />
          </TabMenu.Menu>
          {isReady && (
            <>
              <br />
              <Button
                fluid
                color="green"
                content="Verify asset"
                onClick={formModal.show}
                data-test-id="start-contract-verification"
              />
            </>
          )}
        </TabMenu.Left>
        <TabMenu.Right>
          <>
            <ProfileSummary user={contract.owner} />
            <Divider />
          </>
          <ContractTabMenuPanes contract={contract} showInvitations={false} />
        </TabMenu.Right>
      </TabMenu>
    </>
  )
}

export default ContractActionDetails
