import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { client, GET_USER_DETAILS } from '../util/API/Apollo'
import { useParams } from 'react-router'
import { CSBreadcrumb, PageLoader, SingleGrid } from '../components'
import TabMenu from '../components/TabMenu'
import _ from 'lodash'
import ProfileSummary from '../components/Profile/ProfileSummary'
import ProfileCommonDetails from '../components/Profile/ProfileCommonDetails'
import CompanySummary from '../components/Company/CompanySummary'
import BankDetails from './ProfileDetails/BankDetails'
import MSGApprovalsTable from '../components/Verification/MSGApprovalsTable'
import UserProvidersTable from '../components/Provider/UserProvidersTable'
import { ApprovalType } from '../util/Constant'
import { Segment } from 'semantic-ui-react'
import link from '../components/link'

const CustomerDetails = () => {
  const { uuid } = useParams()
  const { loading, data } = useQuery(GET_USER_DETAILS, {
    variables: { uuid },
    client,
  })

  if (loading) return <PageLoader active={loading} />
  const { user } = data

  const msgApprovals = _.get(user, 'approvals.data', []).filter(
    a => a.approval_type === ApprovalType.VERIFICATION_MSG
  )

  return (
    <>
      <CSBreadcrumb
        items={[{ title: 'Customers', to: link.newCustomer }]}
        title={user.hashid}
      />
      <SingleGrid padded>
        <Segment padded="very">
          <TabMenu defaultTab="account-details">
            <TabMenu.Left>
              <TabMenu.Menu>
                <TabMenu.Item name="account-details" />
                {_.get(user, 'profile.company') && (
                  <TabMenu.Item name="company-details" />
                )}
                {_.get(user, 'profile.bank_info.bank_name') && (
                  <TabMenu.Item name="bank-details" />
                )}
                {msgApprovals.length > 0 && (
                  <TabMenu.Item name="medallion-stamps" />
                )}
                <TabMenu.Item name="providers" />
              </TabMenu.Menu>
            </TabMenu.Left>
            <TabMenu.Right>
              <ProfileSummary user={user} />
              <TabMenu.Pane name="account-details">
                <ProfileCommonDetails user={user} />
              </TabMenu.Pane>
              <TabMenu.Pane name="company-details">
                <CompanySummary company={user.profile.company} />
              </TabMenu.Pane>
              <TabMenu.Pane name="bank-details">
                <BankDetails user={user} />
              </TabMenu.Pane>
              <TabMenu.Pane name="medallion-stamps">
                <MSGApprovalsTable approvals={msgApprovals} />
              </TabMenu.Pane>
              <TabMenu.Pane name="providers">
                <UserProvidersTable user={user} />
              </TabMenu.Pane>
            </TabMenu.Right>
          </TabMenu>
        </Segment>
      </SingleGrid>
    </>
  )
}

export default CustomerDetails
