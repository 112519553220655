import React, { useState } from 'react'
import { Switch, Route, useHistory, useParams, Redirect } from 'react-router'
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Segment,
  TextArea,
} from 'semantic-ui-react'
import _ from 'lodash'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  client,
  CONTACT_CUSTOMER,
  GET_DISCUSSION_WITH_CUSTOMER,
  GET_DISCUSSIONS_WITH_CUSTOMERS,
  SEARCH_CUSTOMER,
} from '../../util/API/Apollo'
import { DimmerLoader } from '../../components'
import { useDebounceQuery } from '../../util/hooks'
import useForm from '../../util/hooks/form'
import { BasicMessage, DiscussionItem } from './Messages'
import link from '../../components/link'

const initialForm = { message: '' }
const ReplyMutation = ({ customer_id, refetch = _.noop, isNewDiscussion }) => {
  const { input, form, clear } = useForm('message', initialForm)
  const handleSubmit = () => {
    clear()
    refetch()
  }

  const [ask, { data, loading }] = useMutation(CONTACT_CUSTOMER, {
    variables: { customer_id, message: form.message },
    onCompleted: handleSubmit,
    client,
  })

  if (isNewDiscussion && data) {
    return <Redirect to={link.userMessages({ uuid: customer_id })} />
  }

  return (
    <Form onSubmit={ask} loading={loading}>
      <Form.Field>
        <TextArea placeholder="Reply" {...input('message')} />
      </Form.Field>
      <Button
        primary
        content="Send"
        icon="paper plane"
        labelPosition="right"
        floated="right"
        type="submit"
        disabled={!form.message}
      />
    </Form>
  )
}

const DiscussionSelect = ({ discussions, activeItem, onChange }) => {
  const history = useHistory()
  const { uuid } = useParams()
  const [input, setInput] = useState('')
  const noSearch = input.length < 2

  const { loading: searching, data } = useDebounceQuery(SEARCH_CUSTOMER, {
    variables: { fullName: input },
    skip: noSearch,
  })

  const customers = _.get(data, 'searchCustomer.data', [])

  return (
    <>
      <Form>
        <Form.Field
          control={Input}
          value={input}
          loading={searching}
          onChange={(e, { value }) => setInput(value)}
          placeholder="Search Customer"
          icon="search"
        />
      </Form>
      <Item.Group link>
        {noSearch
          ? discussions.map(discussion => {
              const customer = discussion.participants.find(
                p => p.__typename === 'User'
              )
              return (
                <DiscussionItem
                  key={discussion.uuid}
                  user={customer}
                  onClick={() => {
                    onChange(customer)
                    history.push(link.userMessages(customer))
                  }}
                  active={uuid === customer.uuid}
                />
              )
            })
          : customers.map(user => {
              return (
                <DiscussionItem
                  key={user.uuid}
                  user={user}
                  onClick={() => {
                    onChange(user)
                    history.push(link.allUserMessages)
                  }}
                  active={_.get(activeItem, 'uuid') === user.uuid}
                />
              )
            })}
      </Item.Group>
    </>
  )
}

const Discussion = () => {
  const { uuid } = useParams()
  const { loading, data, refetch } = useQuery(GET_DISCUSSION_WITH_CUSTOMER, {
    variables: {
      uuid: uuid,
      count: 10,
      page: 1,
    },
    skip: !uuid,
    client,
  })

  const messages = _.get(data, 'discussionWithCustomer.data', []).reverse()

  return (
    <Segment className="pb-50">
      <DimmerLoader active={loading} />
      {messages.map((message, index) => (
        <BasicMessage key={index} message={message} />
      ))}
      <ReplyMutation refetch={refetch} customer_id={uuid} />
    </Segment>
  )
}

const EmptyDiscussion = ({ customer, isNewDiscussion }) => {
  return (
    <Segment className="pb-50">
      <ReplyMutation
        customer_id={customer.uuid}
        isNewDiscussion={isNewDiscussion}
      />
    </Segment>
  )
}

const ProviderDiscussions = () => {
  // active tab is uuid of selected user
  const { loading, data } = useQuery(GET_DISCUSSIONS_WITH_CUSTOMERS, {
    variables: { count: 10, page: 1 },
    client,
  })
  const [newDiscussion, setNewDiscussion] = useState(null) // Select user to discuss

  if (loading) {
    return (
      <Container fluid textAlign="center" className="py-massive">
        <DimmerLoader active={loading} />
      </Container>
    )
  }

  const discussions = _.get(data, 'discussionsWithCustomers.data', [])

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Switch>
            <Route path={link.userMessages({ uuid: ':uuid' })}>
              <Grid.Column width={4}>
                <DiscussionSelect
                  activeItem={newDiscussion}
                  discussions={discussions}
                  onChange={setNewDiscussion}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Switch>
                  <Route path={link.userMessages({ uuid: ':uuid' })}>
                    <Discussion />
                  </Route>
                  <Route>
                    {newDiscussion ? (
                      <EmptyDiscussion
                        customer={newDiscussion}
                        isNewDiscussion
                      />
                    ) : (
                      <Container fluid className="flex center h-100p">
                        <Header as="h3" icon color="black">
                          <Icon name="comment" />
                          SELECT A DISCUSSION
                        </Header>
                      </Container>
                    )}
                  </Route>
                </Switch>
              </Grid.Column>
            </Route>
            <Route>
              <Grid.Column width={4}>
                <DiscussionSelect
                  activeItem={newDiscussion}
                  discussions={discussions}
                  onChange={setNewDiscussion}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Switch>
                  <Route path={link.userMessages({ uuid: ':uuid' })}>
                    <Discussion />
                  </Route>
                  <Route>
                    {newDiscussion ? (
                      <EmptyDiscussion
                        customer={newDiscussion}
                        isNewDiscussion
                      />
                    ) : (
                      <Container fluid className="flex center h-100p">
                        <Header as="h3" icon color="black">
                          <Icon name="comment" />
                          SELECT A DISCUSSION
                        </Header>
                      </Container>
                    )}
                  </Route>
                </Switch>
              </Grid.Column>
            </Route>
          </Switch>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ProviderDiscussions
