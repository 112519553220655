import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Segment, Form, Input, Message } from 'semantic-ui-react'
import {
  APIPagination,
  CSBreadcrumb,
  ContentLoader,
  SingleGrid,
} from '../../components'
import TradesTable from '../../components/Trade/TradesTable'
import { client, GET_TRADES, SEARCH_TRADES } from '../../util/API/Apollo'
import { usePage, useDebounceQuery } from '../../util/hooks'
import { NoData } from '../../components/NotFound'

const TradesIntro = ({ type }) => (
  <Message>
    <Message.Header>
      {type === 'pending'
        ? 'Pending Trades refers to Trades in execution process after private negotations & offer exchange has been completed.'
        : 'Completed Trades'}
    </Message.Header>
  </Message>
)

const COUNT = 10

const Trades = ({ type, title }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = usePage(1)

  const { data, loading } = useQuery(GET_TRADES, {
    variables: {
      page,
      count: 10,
      completed: type === 'completed',
    },
    onCompleted: () => {
      setPageLoading(false)
    },
    client,
  })

  const [input, setInput] = useState('')
  const showSearch = input.length >= 2
  const { loading: searchLoading, data: searchData } = useDebounceQuery(
    SEARCH_TRADES,
    {
      variables: { search: input },
      skip: !showSearch,
      client,
    }
  )

  const trades = _.get(data, 'trades.data', [])
  const paginatorInfo = _.get(data, 'trades.paginatorInfo')
  const searchTrades = _.get(searchData, 'searchTrades.data')

  if (pageLoading) return <ContentLoader />

  return (
    <>
      <CSBreadcrumb title={title} />

      <SingleGrid padded>
        {trades.length > 0 ? (
          <>
            <Grid className="py-15">
              <Grid.Row>
                <Grid.Column>
                  <Segment padded="very">
                    <TradesIntro type={type} />
                    <br />
                    <Form>
                      <Form.Field
                        control={Input}
                        value={input}
                        loading={searchLoading}
                        onChange={(e, { value }) => setInput(value)}
                        placeholder="Search trades"
                        icon="search"
                      />
                    </Form>
                    <br />
                    <TradesTable
                      trades={searchTrades || trades}
                      loading={loading}
                      pagination={
                        !searchTrades && (
                          <APIPagination
                            paginatorInfo={paginatorInfo}
                            handleChange={setPage}
                            count={COUNT}
                          />
                        )
                      }
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ) : (
          <>
            <NoData text="THERE ARE NO TRADES YET" fluid />
          </>
        )}
      </SingleGrid>
    </>
  )
}

export default Trades
